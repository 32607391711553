import React, { ChangeEventHandler, FormEvent } from 'react';
import { FiSearch } from 'react-icons/fi'

import { Loader } from '../Loader';

interface SearchInputProps {
    onSubmit: (event: FormEvent) => void;
    onChange: ChangeEventHandler<HTMLInputElement>;
    value: string;
    isFetchingData: boolean;
    required?: boolean;
    placeholder?: string;
    isFullWidth?: boolean;
}

export function SearchInput({ 
    onSubmit,
    onChange,
    value,
    isFetchingData,
    required = false,
    placeholder = "",
    isFullWidth = false
}: SearchInputProps) {
    return(
        <form className="int-my-3" onSubmit={onSubmit}>
            <div
                className="int-input-group-icon"
                style={{ maxWidth: isFullWidth ? '100%' : '520px'}}
            >
                <input
                    data-testid="search-input"
                    className="int-form-control"
                    required={required}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
                <div className="int-group-right-icon" onClick={onSubmit}>
                    { 
                        !isFetchingData 
                        ? <FiSearch /> 
                        : <Loader size="sm" />
                    }
                </div>
            </div>
        </form>
    )
}
import React, { ReactNode } from 'react';

interface H3Props {
    children: ReactNode;
    margin?: string;
    padding?: string;
}

export function H3({
    children, 
    margin = "", 
    padding = "", 
    ...rest 
}: H3Props) {
    return(
        <h3 className={`int-h3 ${margin} ${padding}`} {...rest}>
            {children}
        </h3>
    )
}
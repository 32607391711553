import React, { useState, useEffect } from "react";
import { BsArrowClockwise } from "react-icons/bs";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Loader } from "../../../components/Loader";
import { formatInteger, formatDecimal, formatDate } from "../../../common/formatters";
import { SmartTable } from '../../../components/Table'

export function Guarantee({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion })
{
    const [guarantees, setGuarantees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientGuarantee()
    {
        setIsLoading(true);

        try
        {
            const response = await fetch(`/api/client/${account}/guarantee`);

            if (!response.ok)
            {
                throw new Error("Ocorreu um erro ao buscar garantias do cliente");
            }

            const data = await response.json();

            setGuarantees(data);
        }
        catch (err)
        {
            console.log(err);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if(updateAllGrids){
            fetchClientGuarantee()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientGuarantee();
    }, [account]);

    const columns = [
        {
            Header: 'Tipo',
            accessor: data => data.guaranteeType?.toUpperCase() ?? "-",
        },
        {
            Header: 'Ativo',
            accessor: data => data.asset?.toUpperCase() ?? "-",
        },
        {
            Header: 'Quantidade',
            accessor: data => formatInteger(data.quantity),
        },
        {
            Header: 'Vencimento',
            accessor: data => formatDate(data.dueDate),
        },
        {
            Header: 'Valor',
            accessor: data => formatDecimal(data.value),
        }
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Garantias (exceto Ações)"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={guarantees.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientGuarantee}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={guarantees} columns={columns} localStorageKey={'ClientGuaranteeFactorParams'} />
                )}
        </SectionWrapper>
    )
}

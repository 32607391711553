import React, { useState, useEffect } from "react";
import { BsTrashFill } from "react-icons/bs";

import { SectionWrapper } from "../../../components/SectionWrapper";
import { Button, Field, PolicyField, DownloadXLSXButton } from '../../../components/Forms'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Alert } from "../../../components/Alert";
import { Link } from "../../../components/Link";
import { Loader } from "../../../components/Loader";
import { SmartTable } from "../../../components/Table";

import { formatInteger, formatObjectNumberValues, formatDecimal, formatArrayNumberValues } from "../../../common/formatters"

import { API_HEADER } from "../../../constants/api";
import { UploadCSVModal } from "../../../components/UploadCSV/UploadCSVModal";
import { parameterValueHeader } from "../../../common/parameterValueHeader";
import RadioButton from "../../../components/RadioButton";


type InboundBmfParameter = {
    symbol: string;
    maxOpenSizeBuy: number | string;
    maxOpenSizeSell: number | string;
    maxOrderSize: number | string;
    maxIntradayBuyMovementQuantity: number | string;
    maxIntradaySellMovementQuantity: number | string;
    marginByContract: number | string;
}

type BmfParameterKey = {
    market: string;
    symbol: string;
}

type BmfParameter = {
    key: BmfParameterKey;
    values: { [parameter: string]: number };
    policyIds: { [parameter: string]: number };
    isAllInheritedParams: boolean;
}

interface CustomBmfParametersProps {
    policyId: string;
    paramsCustomBmf: BmfParameter[];
    fetchData: () => void;
    setShowSearchParamsModal: (value: boolean) => void;
    keys: any;
    policy: any;
}

export function CustomBmfParameters({ setShowSearchParamsModal, policyId, paramsCustomBmf, fetchData, keys, policy }: CustomBmfParametersProps) {
    const [disabled, setDisabled] = useState(false);
    const [deletdParam, setDeletedParam] = useState<BmfParameter | null>();
    const [errorDeletedParam, setErrorDeletedParam] = useState<Error | null>();
    const [errorNewParam, setErrorNewParam] = useState<Error | null>();
    const [showDeletedParameter, setShowDeletedParameter] = useState(false);
    const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);;
    const [showFormAddParameter, setShowFormAddParameter] = useState(false);
    const [parameter, setParameter] = useState<InboundBmfParameter | null>();
    const [showAddParameter, setShowAddParameter] = useState(false);
    const [showEditParameter, setShowEditParameter] = useState(false);
    const [showFormEditParameter, setShowFormEditParameter] = useState(false);
    const [showReplaceParamsCSV, setShowReplaceParamsCSV] = useState(false);
    const [showLoaderReplaceParamsCSV, setShowLoaderReplaceParamsCSV] = useState(false)
    const [errorReplaceParamsCSV, setErrorReplaceParamsCSV] = useState<Error | null>();
    const [paramsBmfExport, setParamsBmfExport] = useState([]);
    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)
    const [radioChecked, setRadioChecked] = React.useState("");

    const getValuesParamsEdit = (values, policyIds, paramName, formatter) => {
        return values[paramName] == null  || policyIds[paramName] != policyId ? "" : formatter(values[paramName])
    }

    const setParamsEdit = (row, value) => {
        const values = row.original.values
        const policyIds = row.original.policyIds

        const param = {
            symbol: value,
            maxOpenSizeBuy: getValuesParamsEdit(values, policyIds, 'MaxOpenSizeBuy', formatInteger),
            maxOpenSizeSell: getValuesParamsEdit(values, policyIds, 'MaxOpenSizeSell', formatInteger),
            maxOrderSize: getValuesParamsEdit(values, policyIds, 'MaxOrderSize', formatInteger),
            maxIntradayBuyMovementQuantity: getValuesParamsEdit(values, policyIds, 'MaxIntradayBuyMovementQuantity', formatInteger),
            maxIntradaySellMovementQuantity: getValuesParamsEdit(values, policyIds, 'MaxIntradaySellMovementQuantity', formatInteger),
            marginByContract: getValuesParamsEdit(values, policyIds, 'MarginByContract', formatDecimal)       
        }

        setParameter(param);
        setShowFormEditParameter(true);
    }

    const ParameterValue= (row, value, paramName, formatter) => {
        if (value == null){
            return "-"
        } else if (row.original.policyIds[paramName] != policyId) {
            return (
                <Link color="gray" href={`/policies/${row.original.policyIds[paramName]}`}>
                    {formatter(value)}
                </Link>
            )
        } else {
            return formatter(value)
        }  
    }

    const SymbolParamButton = (row, value) => {
        return (
            <p
                onClick={() => setParamsEdit(row, value)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
            >
                { value ?? "TODOS" }
            </p>
        )
    }

    const DeleteButton = (row) => {
        return (
            row.original.isAllInheritedParams ? (
                <BsTrashFill
                    size={20}
                    color="#AAA"
                />
            ) :(
                <BsTrashFill
                    data-testid="param-delete-button"
                    size={20}
                    color="var(--int-colors-orange-400)"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setErrorDeletedParam(null);
                        setDeletedParam(row.original);
                        setShowDeletedParameter(true);
                    }}
                />  
            )
        )
    }

    const columns = [
        {
            Header: 'Símbolo',
            accessor: 'key.symbol',
            Cell: ({ row, value }) => SymbolParamButton(row, value)
        },
        {
            Header: () => parameterValueHeader(keys, 'QMáx de Expo Compra', 'MaxOpenSizeBuy'),
            accessor: 'values.MaxOpenSizeBuy',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOpenSizeBuy', formatInteger)
        },
        {
            Header: () => parameterValueHeader(keys, 'QMáx de Expo Venda', 'MaxOpenSizeSell'),
            accessor: 'values.MaxOpenSizeSell',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOpenSizeSell', formatInteger)
        },
        {
            Header: () => parameterValueHeader(keys, 'TamMax de Ordem', 'MaxOrderSize'),
            accessor: 'values.MaxOrderSize',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOrderSize', formatInteger)
        },
        {
            Header: () => parameterValueHeader(keys, 'QMáx de Mov Compra', 'MaxIntradayBuyMovementQuantity'),
            accessor: 'values.MaxIntradayBuyMovementQuantity',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxIntradayBuyMovementQuantity', formatInteger)
        },
        {
            Header: () => parameterValueHeader(keys, 'QMáx de Mov Venda', 'MaxIntradaySellMovementQuantity'),
            accessor: 'values.MaxIntradaySellMovementQuantity',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxIntradaySellMovementQuantity', formatInteger)
        },
        {
            Header:  () => parameterValueHeader(keys, 'Margem por contrato', 'MarginByContract'),
            accessor: 'values.MarginByContract',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MarginByContract', formatDecimal)
        },
        {
            id: 'deleteParam',
            width: '1%',
            Cell: ({ row }) => DeleteButton(row )   
        }
    ]

    const columnsOperator = columns.filter(x => {
        return !x.accessor?.includes('MarginByContract') &&
               !x.accessor?.includes('MaxOpenSizeBuy') &&
               !x.accessor?.includes('MaxOpenSizeSell')
    })

    const columnsClient = columns.filter(x => {
        return !x.accessor?.includes('MaxIntradayBuyMovementQuantity') &&
               !x.accessor?.includes('MaxIntradaySellMovementQuantity')
    })

    const submitReplaceBmfParams = (arr) => {
        setDisabled(true);
        setShowLoaderReplaceParamsCSV(true);

        const parameters = formatArrayNumberValues(arr, ['maxOpenSizeBuy', 'maxOpenSizeSell', 'maxOrderSize', 'maxIntradayBuyMovementQuantity', 'maxIntradaySellMovementQuantity', 'marginByContract']);

        var body = parameters.map(param => ({
            ...param,
            maxOpenSizeBuy: param.maxOpenSizeBuy ?? null,
            maxOpenSizeSell: param.maxOpenSizeSell ?? null,
            maxOrderSize: param.maxOrderSize ?? null,
            maxIntradayBuyMovementQuantity: param.maxIntradayBuyMovementQuantity ?? null,
            maxIntradaySellMovementQuantity: param.maxIntradaySellMovementQuantity ?? null,
            marginByContract: param.marginByContract ?? null
        }))

        const options = {
            method: "PUT",
            headers: API_HEADER,
            body: JSON.stringify(body)
        };

        fetch(`/api/Policy/${policyId}/parameters/bmf`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorReplaceParamsCSV(await response.json());
                    return setDisabled(false);
                }

                setShowReplaceParamsCSV(true);
                return setDisabled(false);
            })
            .catch((error) => console.log(error))
            .finally(() => setShowLoaderReplaceParamsCSV(false));
    };
    
    /** Apagar parâmetro API*/
    const submitParamDelete = () => {
        setDisabled(true);

        const requestBody = Object.keys(deletdParam.values).map(param => {
            return {
                symbol: deletdParam.key.symbol,
                market: "FUT",
                name: param
            }
        })

        const options = {
            method: "DELETE",
            headers: API_HEADER,
            body: JSON.stringify(requestBody)
        };

        fetch(`/api/policy/${policyId}/parameters`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorDeletedParam(await response.json());
                    return setDisabled(false);
                }

                setShowDeletedParameter(false);
                setShowSuccessDeleteModal(true);
                return setDisabled(false);
            })
            .catch((error) => console.log(error));
    };

    /**Adicionar ou Atualizar parâmetro API*/
    const submitParam = (event) =>
    {
        event.preventDefault();
        
        var body = formatObjectNumberValues(parameter, ['maxOpenSizeBuy', 'maxOpenSizeSell', 'maxOrderSize', 'maxIntradayBuyMovementQuantity', 'maxIntradaySellMovementQuantity', 'marginByContract'])

        body = {
            ...body,
            maxOpenSizeBuy: body.maxOpenSizeBuy ?? null,
            maxOpenSizeSell: body.maxOpenSizeSell ?? null,
            maxOrderSize: body.maxOrderSize ?? null,
            maxIntradayBuyMovementQuantity: body.maxIntradayBuyMovementQuantity ?? null,
            maxIntradaySellMovementQuantity: body.maxIntradaySellMovementQuantity ?? null,
            marginByContract: body.marginByContract ?? null
        }

        const options = {
            method: "POST",
            headers: API_HEADER,
            body: JSON.stringify(body)
        };

        fetch(`/api/policy/${policyId}/parameter/bmf`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorNewParam(await response.json());
                    return setDisabled(false);
                }
                
                if(showFormAddParameter === true){
                    setShowFormAddParameter(false);
                    setShowAddParameter(true);
                }
                else{
                    setShowFormEditParameter(false);
                    setShowEditParameter(true);
                }
                
                

                return setDisabled(false);
            })
            .catch((error) => console.log(error));
    };

    /** Atualiza os dados do novo parâmetro */
    const handleNewParam = (event, mapValue = (e) => e) => {
        const { name, value } = event.target;

        return setParameter({ ...parameter, [name]: mapValue(value) });
    };

    /** Baixar modelo de upload de parâmetro */
    async function handleModelDownload() {
        fetch('/assets/Intelirisk-ModeloListaParametrosBmf.xlsx', {
            headers: {
                'Content-Type': 'application/xlsx',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Intelirisk-ModeloListaParametrosBmf.xlsx`);

                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    useEffect(() => {
        if (paramsCustomBmf?.length > 0){
            const params = paramsCustomBmf
                .map(x => ({
                    "Símbolo": x.key.symbol ?? "TODOS",
                    "Qt Max. De Exposição Comprada": x.values.MaxOpenSizeBuy ?? "",
                    "Qt Max. De Exposição Vendida": x.values.MaxOpenSizeSell ?? "",
                    "Qt máx de movimentação Compra": x.values.maxIntradayBuyMovementQuantity ?? "",
                    "Qt máx de movimentação Venda": x.values.maxIntradaySellMovementQuantity ?? "",
                    "Tam Max. De Ordem": x.values.MaxOrderSize ?? "",
                    "Margem Por Contrato": x.values.MarginByContract ?? ""
                }))

            setParamsBmfExport(params)
        }
    }, [paramsCustomBmf])

    const options = [
        { value: "tudo", label: "Tudo" },
        { value: "cliente", label: "Cliente" },
        { value: "operador", label: "Operador" }
      ]

    return (
        <>
            <SectionWrapper
                headerTag="h3"
                header="Parâmetros BMF"
                isAccordion
            >  
                <RadioButton 
                    margin={{ left: "", top: "" }}
                    name="tablePolicy"
                    policy={policy?.policyType}
                    options={options}
                    radioChecked={radioChecked}
                    setRadioChecked={setRadioChecked}
                /> 

                {paramsCustomBmf?.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum paramêtro BMF foi encontrado
                    </p>
                ) : (
                    <SmartTable 
                        data={paramsCustomBmf ?? []} 
                        columns={radioChecked === "cliente" ? columnsClient : radioChecked === "operador" ? columnsOperator : columns} 
                        removeScroll
                        localStorageKey={'PolicyCustomBmfParams'}
                        columnsResizable
                    />
                )}

                <div className="int-d-flex int-align-items-center int-justify-content-between int-mt-4">
                    <div  className="int-d-flex">
                        <Button
                            variant="outline"
                            onClick={() => {
                                setParameter(null);
                                setShowFormAddParameter(true);
                                setErrorNewParam(null);
                            }}
                        >
                            Adicionar novo parâmetro
                        </Button>

                        <Button
                            margin="int-ml-3"
                            onClick={() => setShowSearchParamsModal(true)}
                        >
                            Buscar parâmetro
                        </Button>
                    </div>
                
                    <div  className="int-d-flex">
                        { paramsCustomBmf?.length <= 0 ? (
                            <Button variant="outline" onClick={handleModelDownload} >
                                Baixar modelo
                            </Button>
                        ) : (
                            <DownloadXLSXButton
                                dataJs={paramsBmfExport}
                                fileName={`${policy?.name}_parametros_bmf_${new Date(Date.now()).toLocaleDateString()}`}
                                variant="outline"
                            >
                                Exportar parâmetros
                            </DownloadXLSXButton>
                        )}  

                        <Button 
                            margin="int-ml-3" 
                            onClick={() => setIsCSVModalOpen(true)} 
                        >
                            Importar / Substituir parâmetros
                        </Button>
                    </div>

                </div>

                {errorReplaceParamsCSV?.message && (
                    <Alert margin="int-mt-4">
                        <p style={{ whiteSpace: "pre-wrap" }}>{errorReplaceParamsCSV?.message}</p>
                    </Alert>
                )}
            </SectionWrapper>
            {/* Modal - Formulário Adicionar novo parâmetro */}
            <Modal
                size="xlg"
                header="Adicionar novo parâmetro"
                isOpen={showFormAddParameter}
                onClose={() => {setShowFormAddParameter(false)}}
            >
                <RadioButton 
                    margin={{ left: "1rem", top: ".5rem" }}
                    name="addPolicy"
                    policy={policy?.policyType}
                    options={options}
                    radioChecked={radioChecked}
                    setRadioChecked={setRadioChecked}
                />
                <form onSubmit={submitParam}>
                    <ModalBody>
                        <div className="int-row int-d-flex int-align-items-end">

                            <div className="int-col int-pl-0">
                                <Field
                                    data-testid="bmfParameters-symbol"
                                    format="Symbol"
                                    label="Símbolo"
                                    name="symbol"
                                    value={parameter?.symbol ?? ""}
                                    onChange={event => handleNewParam(event, (n) => n.trim().toUpperCase())}
                                />
                            </div>

                            {radioChecked === "cliente" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        data-testid="bmfParameters-MaxOpenSizeBuy"
                                        label="QMáx de Expo Compra"
                                        keys={keys.flat()}
                                        keyName="MaxOpenSizeBuy"
                                        name="maxOpenSizeBuy"
                                        value={parameter?.maxOpenSizeBuy ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}

                            {radioChecked === "cliente" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        data-testid="bmfParameters-MaxOpenSizeSell"
                                        label="QMáx de Expo Venda"
                                        keys={keys.flat()}
                                        keyName="MaxOpenSizeSell"
                                        name="maxOpenSizeSell"
                                        value={parameter?.maxOpenSizeSell ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}

                            <div className="int-col int-pl-0">
                                <PolicyField
                                    data-testid="bmfParameters-MaxOrderSize"
                                    label="TamMáx de Ordem"
                                    keys={keys.flat()}
                                    keyName="MaxOrderSize"
                                    name="maxOrderSize"
                                    value={parameter?.maxOrderSize ?? ""}
                                    onChange={event => handleNewParam(event)}
                                />
                            </div>

                            {radioChecked === "operador" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        data-testid="bmfParameters-MaxIntradayBuyMovementQuantity"
                                        label="QMáx de Mov Compra"
                                        keys={keys.flat()}
                                        keyName="MaxIntradayBuyMovementQuantity"
                                        name="maxIntradayBuyMovementQuantity"
                                        value={parameter?.maxIntradayBuyMovementQuantity ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}

                            {radioChecked === "operador" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        data-testid="bmfParameters-MaxIntradaySellMovementQuantity"
                                        label="QMáx de Mov Venda"
                                        keys={keys.flat()}
                                        keyName="MaxIntradaySellMovementQuantity"
                                        name="maxIntradaySellMovementQuantity"
                                        value={parameter?.maxIntradaySellMovementQuantity ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}
                            
                            {radioChecked === "cliente" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        data-testid="bmfParameters-MarginByContract"
                                        label="Margem por Contrato"
                                        keys="Currency"
                                        keyName="MarginByContract"
                                        name="marginByContract"
                                        value={parameter?.marginByContract ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}
                        </div>

                        {errorNewParam?.message && (
                            <Alert data-testid="message-error-add-parameters">
                                Ocorreu um erro: {errorNewParam?.message}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {setShowFormAddParameter(false)}}
                        >
                            Cancelar
                        </Button>
                        <Button
                            data-testid="add-new-param"
                            margin="int-ml-2"
                            type='submit'
                            disabled={
                                parameter?.maxOrderSize ||
                                parameter?.maxOpenSizeSell ||
                                parameter?.maxOpenSizeBuy ||
                                parameter?.marginByContract ? false : true}
                        >
                            Adicionar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Formulário Editar parâmetro */}
            <Modal
                size="xlg"
                header="Editar Parâmetro"
                isOpen={showFormEditParameter}
                onClose={() => {setShowFormEditParameter(false)}}
            >
                <RadioButton 
                    margin={{ left: "1rem", top: ".5rem" }}
                    name="editPolicy"
                    policy={policy?.policyType}
                    options={options}
                    radioChecked={radioChecked}
                    setRadioChecked={setRadioChecked}
                />

                <form onSubmit={submitParam}>
                    <ModalBody>
                        <div className="int-row int-d-flex int-align-items-end">

                            <div className="int-col int-pl-0">
                                <Field
                                    format="Symbol"
                                    label="Símbolo"
                                    name="symbol"
                                    disabled
                                    value={parameter?.symbol ?? ""}
                                    onChange={event => handleNewParam(event, (n) => n.trim().toUpperCase())}
                                />
                            </div>

                            {radioChecked === "cliente" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        label="QMáx de Expo Compra"
                                        keys={keys.flat()}
                                        keyName="MaxOpenSizeBuy"
                                        name="maxOpenSizeBuy"
                                        value={parameter?.maxOpenSizeBuy ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}

                            {radioChecked === "cliente" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        label="QMáx de Expo Venda"
                                        keys={keys.flat()}
                                        keyName="MaxOpenSizeSell"
                                        name="maxOpenSizeSell"
                                        value={parameter?.maxOpenSizeSell ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}

                            <div className="int-col int-pl-0">
                                <PolicyField
                                    label="TamMáx. de Ordem"
                                    keys={keys.flat()}
                                    keyName="MaxOrderSize"
                                    name="maxOrderSize"
                                    value={parameter?.maxOrderSize ?? ""}
                                    onChange={event => handleNewParam(event)}
                                />
                            </div>

                            {radioChecked === "operador" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        label="QMáx de Mov Compra"
                                        keys={keys.flat()}
                                        keyName="MaxIntradayBuyMovementQuantity"
                                        name="maxIntradayBuyMovementQuantity"
                                        value={parameter?.maxIntradayBuyMovementQuantity ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}

                            {radioChecked === "operador" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        label="QMáx de Mov Venda"
                                        keys={keys.flat()}
                                        keyName="MaxIntradaySellMovementQuantity"
                                        name="maxIntradaySellMovementQuantity"
                                        value={parameter?.maxIntradaySellMovementQuantity ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}

                            {radioChecked === "cliente" || radioChecked === "tudo" ? (
                                <div className="int-col int-pl-0">
                                    <PolicyField
                                        label="Margem por Contrato"
                                        keys="Currency"
                                        keyName="MarginByContract"
                                        name="marginByContract"
                                        value={parameter?.marginByContract ?? ""}
                                        onChange={event => handleNewParam(event)}
                                    />
                                </div>
                            ) : ""}
                        </div>

                        {errorNewParam?.message && (
                            <Alert data-testid="message-error-update-parameters">
                                Ocorreu um erro: {errorNewParam?.message}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {setShowFormEditParameter(false)}}
                        >
                            Cancelar
                        </Button>
                        <Button
                            margin="int-ml-2"
                            type='submit'
                        >
                            Atualizar parâmetro
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Mensagem Apagar Parâmetro */}
            <Modal
                header="Tem certeza que quer apagar o parâmetro?"
                isOpen={showDeletedParameter}
                onClose={() => setShowDeletedParameter(false)}
            >
                <ModalBody>
                    <p>
                        Caso você apague esse parâmetro, não será possível desfazer
                        essa ação.
                    </p>

                    {errorDeletedParam && (
                        <Alert>
                            Ocorreu um erro ao apagar o parâmetro: {errorDeletedParam.message}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() => setShowDeletedParameter(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        data-testid="confirm-delete-param-button"
                        disabled={disabled}
                        margin="int-ml-2"
                        onClick={() => submitParamDelete()}
                    >
                        Apagar
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal - Mensagem remoção com sucesso  */}
            <SimpleModal
                header="Os dados foram alterados com sucesso"
                isOpen={showSuccessDeleteModal}
                onClose={() => setShowSuccessDeleteModal(false)}
                bodyMessage="A politíca foi atualizada"
                footerButton={{
                    label: "Concluir",
                    onClick: () => {
                        fetchData();
                        setShowSuccessDeleteModal(false);
                    }
                }}
            />

            {/* Modal - Mensagem Adicionar Parâmetro  */}
            <SimpleModal
                header="O parâmetro foi adicionado!"
                isOpen={showAddParameter}
                onClose={() => setShowAddParameter(false)}
                bodyMessage="O parâmetro foi adicionado com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchData();
                        setShowAddParameter(false);
                        setParameter(null)
                    }
                }}
            />

            {/* Modal - Mensagem Editar Parâmetro  */}
            <SimpleModal
                header="O parâmetro foi atualizado!"
                isOpen={showEditParameter}
                onClose={() => setShowEditParameter(false)}
                bodyMessage="O parâmetro que você editou foi atualizado com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchData();
                        setShowEditParameter(false);
                        setParameter(null)
                    }
                }}
            />

            {/* Modal - Mensagem Substituir Lista de Parâmetros */}
            <SimpleModal
                header="A lista de parâmetros foi atualizada!"
                isOpen={showReplaceParamsCSV} 
                onClose={() => setShowReplaceParamsCSV(false)}
                bodyMessage="A lista de parâmetros que você adicionou foi substituída com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchData();
                        setShowReplaceParamsCSV(false);
                    }
                }}
            />

            <UploadCSVModal
                isModalOpen={isCSVModalOpen}
                onHide={() => setIsCSVModalOpen(false)}
                onAdd={submitReplaceBmfParams}
                header={`Importar / Substituir Lista de Parâmetros: Parâmetro BMF`}
                model={"ParametrosBmf"}
                policyApplication={true}
                parameterName="Parâmetro BMF"
                isClient={policy?.policyType == "Client"? true : false}
            />

            {showLoaderReplaceParamsCSV && <Loader full />}
        </>
    )
}
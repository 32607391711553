import React, { LiHTMLAttributes } from 'react';
import { HashLink } from "react-router-hash-link";

interface TabItemProps extends LiHTMLAttributes<HTMLLIElement> {
    label: string;
    route: string;
    disabled?: boolean
}

export function TabItem({ 
    label, 
    route, 
    disabled = false,
    ...rest
}: TabItemProps) {
    return(
        <li className="int-tab" {...rest} style={{whiteSpace: "nowrap"}}>
            <HashLink 
                className="int-tab-link"
                disabled={disabled} 
                smooth 
                to={route} 
            >
                {label}
            </HashLink>
        </li>
    )
}
import React, { useEffect, useState, useMemo, useRef, CSSProperties } from 'react';
import { 
    useFilters, 
    useGlobalFilter, 
    usePagination, 
    useSortBy, 
    useTable, 
    useColumnOrder, 
    ColumnInstance,
    useFlexLayout,
    useRowSelect,
    useResizeColumns
} from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import { DefaultColumnFilter, GlobalFilter } from './Filters';
import { Pagination } from './Pagination';
import { ReorderColumnsModal } from './ReorderColumnsModal';
import { UtilsHeader } from './UtilsHeader';
import { TableCell } from './TableCell';

import "./styles.css"

interface SmartTableProps {
    isRefreshing?: boolean;
    autoRefresh?: boolean;
    columnFilters?: boolean;
    globalFilter?: boolean;
    removeScroll?: boolean;
    lockHeader?: boolean;
    lotOfColumnsLockHeader?: boolean;
    columnsResizable?: boolean;
    gapColumnsFilter?: number;
    count?: number;
    localStorageKey: string;
    columns: any[];
    data: any[];
    onFiltersChange?: (value: any) => void;
    fetchData?: () => void;
    smartTableToParent?:  (value: any) => void;
    bodyRowStyles?: (row: any) => CSSProperties;
    minToPagination?: number;
}

export function SmartTable({ 
    isRefreshing,
    autoRefresh,
    columnFilters = false, 
    globalFilter = false,
    removeScroll = false,
    lockHeader = false,
    lotOfColumnsLockHeader = false,
    columnsResizable = false,
    gapColumnsFilter = 8,
    count = 0,
    smartTableToParent,
    fetchData,
    localStorageKey,
    columns, 
    data,
    onFiltersChange,
    bodyRowStyles,
    minToPagination = 20,
}: SmartTableProps) {
    const filterTypes = useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )
    const localStorageHiddenColumns = localStorage.getItem(`HiddenColumnsTable_${localStorageKey}`)

    const localStorageHiddenColumnsLength = localStorageHiddenColumns?.split(",").length;

    const minCellWidth = 36
    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: minCellWidth,
            width: 130
        }),
        []
    )
    const hiddenColumns = localStorageHiddenColumns ? JSON.parse(localStorageHiddenColumns) : []

    const resizeColumnsPlugins = columnsResizable ? [useFlexLayout, useRowSelect, useResizeColumns] : [useResizeColumns]

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, 

        // Pagination
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        state: { pageIndex, pageSize },

        // Hide Columns
        allColumns,
        getToggleHideAllColumnsProps,

        // Filtering
        visibleColumns,
        setGlobalFilter,

        // Column Order
        setColumnOrder,

        // Visible rows
        filteredRows,

        //Column Resizing
        resetResizing
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, hiddenColumns: hiddenColumns },
            defaultColumn,
            filterTypes,
            autoResetFilters: false
        },
        useFilters,
        useGlobalFilter, 
        useSortBy,
        usePagination,
        useColumnOrder,
        ...resizeColumnsPlugins
    )

    const tableRef = useRef(null)

    const [tableColumnsOrder, setTableColumnsOrder] = useState<ColumnInstance<object>[] | unknown[]>();
    const [isReorderColumnsModalOpen, setIsReorderColumnsModalOpen] = useState(false);
    const [fontSizeTable, setFontSizeTable] = useState<number>(16);
    const [tableHeight, setTableHeight] = useState<number>(250);

    useEffect(() => setTableColumnsOrder(allColumns), [allColumns])
    useEffect(() => {
        setPageSize(minToPagination)
        updateResizerHeight()

        const localStorageFontSize = localStorage.getItem(`FontSizeTable_${localStorageKey}`)
        const fontSizeColumns = localStorageFontSize ? JSON.parse(localStorageFontSize) : 16
        setFontSizeTable(fontSizeColumns)
    }, [])
    
    useEffect(() => localStorage.setItem(`FontSizeTable_${localStorageKey}`, `${fontSizeTable}`), [fontSizeTable])

    useEffect(() => { 
        const columns = allColumns?.map(item => item.id)
        const visible = visibleColumns?.map(item => item.id)
        const hidden = columns.filter(item => !visible.includes(item))

        localStorage.setItem(`HiddenColumnsTable_${localStorageKey}`, JSON.stringify(hidden));
    }, [visibleColumns])

    useEffect(() => {
        setTableHeight(tableRef.current?.offsetHeight);
        if (onFiltersChange) onFiltersChange(filteredRows)  
    }, [state.filters])

    const updateResizerHeight = () => {
        if(tableRef.current) {
            setTimeout(() => {
                setTableHeight(tableRef.current?.offsetHeight);
            }, 1000)
        } 
    }

    useEffect(() => {
        updateResizerHeight();
    }, [tableRef.current, tableRef.current?.offsetHeight, fontSizeTable]);

    let idPrefix = uuidv4();

    const [paginationData, setPaginationData] = useState({items: "", page: ""});
    const [paginationDataCheck, setPaginationCheckData] = useState({items: "", page: ""});

    const paginationToSmartTable = (childdata) => {
        setPaginationData(childdata);
    }

    useEffect(() => {
        paginationToSmartTable(paginationToSmartTable)
    }, [paginationData])
    
    useEffect(() => { 
        if((smartTableToParent != null ) && (paginationData?.items != paginationDataCheck?.items || paginationData?.page != paginationDataCheck?.page)){
            setPaginationCheckData(paginationData)
            var skip = (parseInt(paginationData?.items) * (parseInt(paginationData?.page) - 1))
            smartTableToParent({
                skip: skip < 0 ? 0 : skip,
                top: paginationData?.items})  
        }  
    }, [paginationToSmartTable])
    return (
        <>
            <UtilsHeader 
                onOpenUtilsModal={() => setIsReorderColumnsModalOpen(true)}
                onResetResize={resetResizing}
                onFontSizeUpdate={setFontSizeTable}
                fontSizeTable={fontSizeTable}
                idPrefix = {idPrefix}
            />  

            {/* Tabela */}
            <div 
                className="int-d-block" 
                style={{ overflowX: removeScroll ? "visible" : "auto" }}
            >
                <table 
                    ref={tableRef} 
                    className="int-table"
                    {...getTableProps({style: 
                        lockHeader == true ?{
                            display: 'flex', position: 'relative', maxHeight: 'calc(60vh)', minWidth: 'max-content', flexDirection: 'column', fontSize: `${fontSizeTable}px`} 
                        : lotOfColumnsLockHeader ? {
                            display:'block', position:'relative', maxHeight: 'calc(60vh)', fontSize: `${fontSizeTable}px`}
                        :  {fontSize: `${fontSizeTable}px`}})}
                >
                    <thead
                        style={ lockHeader || lotOfColumnsLockHeader? { position:'sticky', top: 0} : {}}
                    >
                        {headerGroups.map(headerGroup => (
                            <tr 
                                id={idPrefix.concat('-header')} 
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map((column, index) => {
                                    column.canResize = columnsResizable
                                    const columnObj = columns.find(x => x.Header == column.Header);

                                    const columnAlign = columnObj?.columnAlign
                                    const singleLine = columnObj?.singleLine
                                    const hint = columnObj?.hint
                                    const maxWidth = columnObj?.maxWidth
                                    const minWidth = columnObj?.minWidth

                                    return (
                                    <TableCell 
                                        key={index} 
                                        component='th'
                                        textAlign={columnAlign ?? 'left'}
                                        hint={hint?? null}
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps({
                                                    style: columnsResizable ? {
                                                        minWidth: minWidth != null ? `${minWidth}px` : `${minCellWidth}px`,
                                                        maxWidth: maxWidth != null ? `${maxWidth}px` : 'unset',
                                                        padding: '8px',
                                                        display: 'flex',
                                                        alignItems: 'flex-end',
                                                        overflowX: 'visible',
                                                        textOverflow: 'clip',
                                                        flexDirection: `${columnAlign == 'right' ? 'row-reverse' : 'row'}`,
                                                        whiteSpace: `${singleLine == false ? 'normal' : 'nowrap'}`,
                                                    } :
                                                    {
                                                        minWidth: minWidth != null ? `${minWidth}px` : `${minCellWidth}px`,
                                                        maxWidth: maxWidth != null ? `${maxWidth}px` : 'unset',
                                                        padding: '8px',
                                                        whiteSpace: `${singleLine == false ? 'normal' : 'nowrap'}`,
                                                    }
                                            })
                                        )}
                                    >
                                        {column.render('Header')}
                                        {column.isSorted &&
                                            <span data-testid="smart-table-span" className="int-pl-auto">
                                                {column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'}
                                            </span>
                                        }

                                        {column.canResize && (
                                            <div
                                                title=""
                                                onClick={(event) => event.stopPropagation()}
                                                data-testid="resizer-bar"
                                                {...column.getResizerProps({
                                                    style: { 
                                                        height: tableHeight || (tableRef.current?.offsetHeight || '100%')
                                                    }
                                                })}
                                                className={
                                                    `int-table-resizer ${column.isResizing ? 'isResizing' : ''}`
                                                }   
                                            />
                                        )}       
                                    </TableCell>
                                )})}
                            </tr>
                            ))}

                        {/* Filtros de Coluna */}
                        {columnFilters && headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <th style={{padding: '8px', minWidth: `${minCellWidth}px`}} key={index}
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps({style: {
                                                padding: `12px ${gapColumnsFilter}px`, 
                                                overflow: 'hidden', 
                                                textAlign: columns.find(x => x.Header == column.Header)?.columnAlign ?? 'left'
                                            }})
                                        )}
                                        onChange={updateResizerHeight}
                                        onClick={() => column.isSorted = column.isSorted}
                                    >
                                        {column.canFilter ? 
                                            column.render('Filter')
                                            : null
                                        }
                                    </th>
                                ))}
                            </tr>
                        ))}

                        {/* Filtro Global */}
                        {globalFilter && (
                            <tr>
                                <th
                                    colSpan={visibleColumns.length}
                                    style={{ textAlign: 'left' }}
                                >
                                    <GlobalFilter
                                        globalFilter={state.globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                </th>
                            </tr>
                        )}
                    </thead>
                    <tbody id={idPrefix.concat('-body')} {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr key={index} data-testid="table-body-row" {...row.getRowProps({style: bodyRowStyles?.(row)})}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <TableCell 
                                                value={cell.value} 
                                                key={index} 
                                                textAlign={columns.find(x => x.Header == cell.column.Header)?.columnAlign ?? 'left'}
                                                {...cell.getCellProps({style: {padding: '8px'}})}
                                                >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* Paginação */}
            {(data.length > 20 || count >= 20 ) &&(
                <Pagination
                    pageOptions={pageOptions}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    count={count}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    setPageSize={setPageSize}
                    onUpdate={updateResizerHeight}
                    paginationToSmartTable={paginationToSmartTable}
                    fetchData={fetchData}
                    isRefreshing={isRefreshing}
                    autoRefresh={autoRefresh}
                />
            )}
            <ReorderColumnsModal
                isOpen={isReorderColumnsModalOpen}
                onClose={() => setIsReorderColumnsModalOpen(false)}

                tableColumnsOrder={tableColumnsOrder}
                setTableColumnsOrder={setTableColumnsOrder}
                setColumnOrder={setColumnOrder}
                
                allColumns={allColumns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            />
        </>
    )
}

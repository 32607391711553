export const getPolicyKeyTypeByName = (all, name) => {
  const policy = all.find(x => x.name === name);

  return policy?.policyKeyType;
}

export const getPolicyParentNames = (all, policy) => {
  let id = policy?.parentID;
  const parentsNames = [];

  while (id !== null) {
    const parentPolicy = all.find(p => p.id === id)

    if (parentPolicy) {
      const isInternal = parentPolicy.name.startsWith('__');

      if (!isInternal) parentsNames.push({ 
        name: parentPolicy.name, 
        id: parentPolicy.id
      })

      id = parentPolicy.parentID;
    }
    else {
      id = null;
    }
  }
  
  return parentsNames.reverse();
}

export const getPolicyNameById = (all, id) => {
    const parent = all.find((x) => x.id === id);

    return parent?.name;
};
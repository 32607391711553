import React, { useState } from 'react'

import { FiZoomIn, FiZoomOut, FiXCircle, FiSettings, FiClipboard } from 'react-icons/fi'

interface TableUtilsProps {
    onOpenUtilsModal: () => void;
    onFontSizeUpdate: (x:number) => void;
    onResetResize: () => void;
    fontSizeTable: number;
    idPrefix: string;
}
export function HeaderElementsToClipboard(elHeaders){
    let columnsHeaders = []

    for (let i = 0; i < elHeaders.length; i++) columnsHeaders.push(elHeaders[i].textContent)
    let headersClipboard = columnsHeaders.join('\t')
    return headersClipboard;
}

export function BodyElementsToClipboard(elTableDataRows){
    let rowsData = []

    for (let i = 0; i < elTableDataRows.length; i++){
        let auxArray = []
        let auxRowsData = ''
        if (elTableDataRows[i].getAttribute('id') === 'tr-section-table')
            continue
        if(elTableDataRows[i]?.cells !== undefined){
            for (let j = 0; j < elTableDataRows[i].cells.length; j++){
                auxArray.push(elTableDataRows[i].cells.item(j).textContent)
                auxRowsData = auxArray.join('\t')
            }
        }
        else{
            auxArray.push(elTableDataRows[i].textContent)
            auxRowsData = auxArray.join('\n\t')
        }
        rowsData.push(auxRowsData)
    }
    return rowsData;
}

export function UtilsHeader({ 
    onOpenUtilsModal, 
    onResetResize,
    onFontSizeUpdate, 
    fontSizeTable,
    idPrefix
}: TableUtilsProps) {
    const [copySuccess, setCopySuccess] = useState<string | null>('');

    async function handleCopyTableToClipboard() {
        if(document.getElementById(idPrefix.concat('-header')) !== null){
            
            const elHeaders = document.getElementById(idPrefix.concat('-header')).querySelectorAll('th');            
            let columnsHeaders = HeaderElementsToClipboard(elHeaders);

            const elTableDataRows = document.getElementById(idPrefix.concat('-body')).querySelectorAll('tr')
            let rowsData = BodyElementsToClipboard(elTableDataRows);

            let dataClipboard = rowsData.join('\n')
            let tableClipboard = columnsHeaders.concat('\n', dataClipboard)

            try {
                await navigator.clipboard.writeText(tableClipboard)
                setCopySuccess('Copiado!')

            } catch (err) {
                setCopySuccess('Não foi possível copiar o conteúdo.')
            }

        }else{
            setCopySuccess('Não foi possível copiar o conteúdo.')
        }
        setTimeout(() => setCopySuccess(null), 1200)
    }

    function handleFontSizeTable (fontSize: number, fontSizeUpdate: number) {   
        if(fontSize === 10 && fontSize > fontSizeUpdate) return

        onFontSizeUpdate(fontSizeUpdate)
    }

    function handleResetToDefaultState() {
        onResetResize()
        onFontSizeUpdate(16)
    }

    return( 
        <>
            <div className="int-d-flex int-align-items-center int-justify-content-between">
                <p className="int-my-3">
                    Clique nos cabeçalhos da tabela para ordenar o conteúdo.
                </p>

                <div className="int-d-flex int-align-items-center int-justify-content-end">
                    <div className='int-mr-4'>
                        <FiZoomIn
                            className='int-mr-2'
                            size={22} 
                            color="var(--int-colors-orange-400)" 
                            onClick={() => handleFontSizeTable(fontSizeTable, fontSizeTable + 2)}
                            style={{ cursor: 'pointer' }}
                            data-testid='zoomIn-button'
                        />
                        <FiZoomOut
                            className='int-mr-2'
                            size={22} 
                            color="var(--int-colors-orange-400)" 
                            onClick={() => handleFontSizeTable(fontSizeTable, fontSizeTable - 2)}
                            style={{ cursor: 'pointer' }}
                            data-testid='zoomOut-button'
                        />
                        <FiXCircle
                            className='int-mr-2'
                            size={22} 
                            color="var(--int-colors-orange-400)" 
                            onClick={() => handleResetToDefaultState()}
                            style={{ cursor: 'pointer' }}
                            data-testid='defaultZoom-button'
                        />
                    </div>

                    {navigator.clipboard && (
                        <div className="int-d-flex int-align-items-center">
                            {copySuccess && <p className="int-my-auto int-mr-3">{copySuccess}</p>}
                            <FiClipboard 
                                size={22} 
                                color="var(--int-colors-orange-400)" 
                                onClick={handleCopyTableToClipboard}
                                style={{ cursor: 'pointer' }}
                                data-testid='clipboard-button'
                            />
                        </div>
                    )}
                    
                    <FiSettings
                        className='int-ml-2'
                        size={22} 
                        color="var(--int-colors-orange-400)" 
                        onClick={onOpenUtilsModal}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </div>
        </>
    )
}
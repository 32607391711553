import React, { useEffect, useState } from "react"
import { BsArrowClockwise } from "react-icons/bs"
import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { TableCell } from "../../../components/Table"
import { formatDecimal } from "../../../common/formatters"

type PatrimonyType = {
    basicPatrimony: number | string;
    extraPatrimony: number | string;
    customPatrimony: number | string;
    bovespaCustodyAmount: number | string;
    termCustodyAmount: number | string;
    futOpcCustodyAmount: number | string;
    flexOpcCustodyAmount: number | string;
    fixedIncomeCustodyAmount: number | string;
    tesouroDiretoCustodyAmount: number | string;
    fundsCustodyAmount: number | string;
    clubsCustodyAmount: number | string;
    guaranteesExceptSharesAmount: number | string;
    btcCustodyAmountTaker: number | string;
    btcCustodyAmountDonor: number | string;
    goldCustodyAmount: number | string;
    goldDaytrade: number | string;
    depositedIntradayMarging: number | string;
    refundedIntradayMarging: number | string;
    tdSettlementSyncMismatchValue: number | string;
    finSettlementSyncMismatchValue: number | string;
    fundsSettlementSyncMismatchValue: number | string;
    clubsSettlementSyncMismatchValue: number | string;
    balance: number | string;
    bmfAdjustPosition: number | string;
    bmfAdjustDaytrade: number | string;
    bmfAdjustTotal: number | string;
}

type UsedLimitGroup = {
    execBuy: number | string;
    execSell: number | string;
    openBuy: number | string;
    openSell: number | string;
    uncoveredExecSell: number | string;
    UncoveredLeverageExecSell: number | string;
}

type LimitPosition = {
    balanceAmount: number | string;
    basicLimit: number | string;
    bovespaCustodyAmount: number | string;
    termCustodyAmount: number | string;
    fixedIncomeCustodyAmount: number | string;
    tesouroDiretoCustodyAmount: number | string;
    fundsCustodyAmount: number | string;
    clubsCustodyAmount: number | string;
    guaranteesExceptSharesAmount: number | string;
    extraLimit: number | string;
    bmfAdjustTotal: number | string;
    usedLimit: number | string;
    usedLimitBmf: number | string;
    usedLimitByVisFra: UsedLimitGroup;
    usedLimitByFut: UsedLimitGroup;
    usedLimitByOption: UsedLimitGroup;
    usedLimitByGold: UsedLimitGroup;
    bovespaIntradayProfit: number | string;
    coveredSellAmount: number | string;
    btcCustodyAmountTaker: number | string;
    btcCustodyAmountDonor: number | string;
    goldCustodyAmount: number | string;
    flexOpcCustodyAmount: number | string;
    goldDaytrade: number | string;
    depositedIntradayMarging: number | string;
    refundedIntradayMarging: number | string;
    tdSettlementSyncMismatchValue: number | string;
    finSettlementSyncMismatchValue: number | string;
    fundsSettlementSyncMismatchValue: number | string;
    clubsSettlementSyncMismatchValue: number | string;
    tradeOnlyWithBalance: boolean;
    leverageAdjust: number | string;
    availableLimitForOptionsUncoveredSell?: number | string;
}

type ClientLimits = {
    limitPositionD1: LimitPosition;
}

export function PatrimonyLimits({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion,clientLimit}) {
    const [patrimony, setPatrimony] = useState<PatrimonyType>()
    const [limits, setLimits] = useState<ClientLimits>()
    const [isLoading, setIsLoading] = useState(true)

    async function fetchClient() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/limits`)

            if (!response.ok) {
                throw new Error("Ocorreu um erro ao buscar dados de patrimônio do cliente")
            }

            const data = await response.json()

            fillLimitsPatrimony(data)

        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const fillLimitsPatrimony = (data) => {
        const patrimony = {
            basicPatrimony: formatDecimal(data.patrimony.basicPatrimony),
            extraPatrimony: formatDecimal(data.patrimony.extraPatrimony),
            customPatrimony: formatDecimal(data.patrimony.customPatrimony),
            bovespaCustodyAmount: formatDecimal(data.patrimony.bovespaCustodyAmount),
            termCustodyAmount: formatDecimal(data.patrimony.termCustodyAmount),
            futOpcCustodyAmount: formatDecimal(data.patrimony.futOpcCustodyAmount),
            flexOpcCustodyAmount: formatDecimal(data.patrimony.flexOpcCustodyAmount),
            fixedIncomeCustodyAmount: formatDecimal(data.patrimony.fixedIncomeCustodyAmount),
            tesouroDiretoCustodyAmount: formatDecimal(data.patrimony.tesouroDiretoCustodyAmount),
            fundsCustodyAmount: formatDecimal(data.patrimony.fundsCustodyAmount),
            clubsCustodyAmount: formatDecimal(data.patrimony.clubsCustodyAmount),
            guaranteesExceptSharesAmount: formatDecimal(data.patrimony.guaranteesExceptSharesAmount),
            btcCustodyAmountTaker: formatDecimal(data.patrimony.btcCustodyAmountTaker),
            btcCustodyAmountDonor: formatDecimal(data.patrimony.btcCustodyAmountDonor),
            goldCustodyAmount: formatDecimal(data.patrimony.goldCustodyAmount),
            goldDaytrade: formatDecimal(data.patrimony.goldDaytrade),
            depositedIntradayMarging: formatDecimal(data.patrimony.depositedIntradayMarging),
            refundedIntradayMarging: formatDecimal(data.patrimony.refundedIntradayMarging),
            tdSettlementSyncMismatchValue: formatDecimal(data.patrimony.tdSettlementSyncMismatchValue),
            finSettlementSyncMismatchValue: formatDecimal(data.patrimony.finSettlementSyncMismatchValue),
            fundsSettlementSyncMismatchValue: formatDecimal(data.patrimony.fundsSettlementSyncMismatchValue),
            clubsSettlementSyncMismatchValue: formatDecimal(data.patrimony.clubsSettlementSyncMismatchValue),
            balance: formatDecimal(data.patrimony.balance),
            bmfAdjustPosition: formatDecimal(data.patrimony.bmfAdjustPosition),
            bmfAdjustDaytrade: formatDecimal(data.patrimony.bmfAdjustDaytrade),
            bmfAdjustTotal: formatDecimal(data.patrimony.bmfAdjustTotal),
        }
        const limits = {
            limitPositionD1: {
                balanceAmount: formatDecimal(data.limitPositionD1?.balanceAmount),
                bovespaCustodyAmount: formatDecimal(data.limitPositionD1?.bovespaCustodyAmount),
                termCustodyAmount: formatDecimal(data.limitPositionD1?.termCustodyAmount),
                fixedIncomeCustodyAmount: formatDecimal(data.limitPositionD1?.fixedIncomeCustodyAmount),
                tesouroDiretoCustodyAmount: formatDecimal(data.limitPositionD1?.tesouroDiretoCustodyAmount),
                fundsCustodyAmount: formatDecimal(data.limitPositionD1?.fundsCustodyAmount),
                clubsCustodyAmount: formatDecimal(data.limitPositionD1?.clubsCustodyAmount),
                guaranteesExceptSharesAmount: formatDecimal(data.limitPositionD1?.guaranteesExceptSharesAmount),
                bovespaIntradayProfit: formatDecimal(data.limitPositionD1?.bovespaIntradayProfit),
                coveredSellAmount: formatDecimal(data.limitPositionD1?.coveredSellAmount),
                btcCustodyAmountTaker: formatDecimal(data.limitPositionD1?.btcCustodyAmountTaker),
                btcCustodyAmountDonor: formatDecimal(data.limitPositionD1?.btcCustodyAmountDonor),
                goldCustodyAmount: formatDecimal(data.limitPositionD1?.goldCustodyAmount),
                goldDaytrade: formatDecimal(data.limitPositionD1?.goldDaytrade),
                flexOpcCustodyAmount: formatDecimal(data.limitPositionD1?.flexOpcCustodyAmount),
                depositedIntradayMarging: formatDecimal(data.patrimony.depositedIntradayMarging),
                refundedIntradayMarging: formatDecimal(data.patrimony.refundedIntradayMarging),
                tdSettlementSyncMismatchValue: formatDecimal(data.limitPositionD1?.tdSettlementSyncMismatchValue),
                finSettlementSyncMismatchValue: formatDecimal(data.limitPositionD1?.finSettlementSyncMismatchValue),
                fundsSettlementSyncMismatchValue: formatDecimal(data.limitPositionD1?.fundsSettlementSyncMismatchValue),
                clubsSettlementSyncMismatchValue: formatDecimal(data.limitPositionD1?.clubsSettlementSyncMismatchValue),
                usedLimit: formatDecimal(data.limitPositionD1?.usedLimit),
                usedLimitBmf: formatDecimal(data.limitPositionD1?.usedLimitBmf),
                extraLimit: formatDecimal(data.limitPositionD1?.extraLimit),
                basicLimit: formatDecimal(data.limitPositionD1?.basicLimit),
                bmfAdjustTotal: formatDecimal(data.limitPositionD1?.bmfAdjustTotal),
                tradeOnlyWithBalance: data.limitPositionD1?.tradeOnlyWithBalance,
                leverageAdjust: formatDecimal(data.limitPositionD1?.leverageAdjust),
                availableLimitForOptionsUncoveredSell: formatDecimal(data.limitPositionD1?.availableLimitForOptionsUncoveredSell),
                usedLimitByVisFra: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.uncoveredLeverageExecSell),
                },
                usedLimitByFut: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByFut.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByFut.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.uncoveredLeverageExecSell),
                },
                usedLimitByOption: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByOption.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByOption.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.uncoveredLeverageExecSell),
                },
                usedLimitByGold: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByGold.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByGold.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.uncoveredLeverageExecSell)
                }
            },

        }
        setPatrimony(patrimony)
        setLimits(limits)
        setIsLoading(false)
    }


    useEffect(() => {
        if (updateAllGrids) {
            fetchClient()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        if(clientLimit) {
            fillLimitsPatrimony(clientLimit)
        }
    }, [clientLimit])

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Patrimônio e Limites"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClient}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? "" :
                (

                    <table className="int-table">
                        <thead>
                            <tr id='tr-section-table'>
                                <th><h3 className="int-h3">Patrimônio</h3></th>
                                <th><h3 className="int-h3">Limites</h3></th>
                            </tr>
                        </thead>
                        <tr id='tr-section-table'>
                            <td style={{ verticalAlign: 'top', paddingRight: '15px' }}>
                                <table className="int-table">
                                    <thead>
                                        <tr >
                                            <TableCell style={{ width: '80%' }} component='th' value={'Descrição'} />
                                            <TableCell style={{ width: '20%' }} component='th' value={'Valor'} textAlign='right' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Financeiro'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Financeiro'} />
                                            <TableCell value={patrimony?.balance} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Valor Adicional'} />
                                            <TableCell value={patrimony?.extraPatrimony} textAlign='right' />
                                        </tr>
                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Bovespa'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia Bovespa (a Mercado)'} />
                                            <TableCell value={patrimony?.bovespaCustodyAmount} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'BTC Tomador (a Mercado)'} />
                                            <TableCell value={patrimony?.btcCustodyAmountTaker} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'BTC Doador (a Mercado)'} />
                                            <TableCell value={patrimony?.btcCustodyAmountDonor} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia de Termos (L/P)'} />
                                            <TableCell value={patrimony?.termCustodyAmount} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Custódia de Opções Flexíveis (L/P)'} />
                                            <TableCell value={patrimony?.flexOpcCustodyAmount} textAlign='right' />
                                        </tr>

                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'BMF'} />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Ajuste BMF (Posição)'} />
                                            <TableCell value={patrimony?.bmfAdjustPosition} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Ajuste BMF (Operações Dia)'} />
                                            <TableCell value={patrimony?.bmfAdjustDaytrade} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Ouro (Posição)'} />
                                            <TableCell value={patrimony?.goldCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Ouro (Operações Dia)'} />
                                            <TableCell value={patrimony?.goldDaytrade} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Opções de Contratos Futuros (L/P)'} />
                                            <TableCell value={patrimony?.futOpcCustodyAmount} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Resultado BMF'} />
                                            <TableCell value={patrimony?.bmfAdjustTotal} textAlign='right' />
                                        </tr>


                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Renda Fixa'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia Renda Fixa (a Mercado)'} />
                                            <TableCell value={patrimony?.fixedIncomeCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia Tesouro Direto (a Mercado)'} />
                                            <TableCell value={patrimony?.tesouroDiretoCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Tesouro Direto'} />
                                            <TableCell value={patrimony?.tdSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Renda Fixa'} />
                                            <TableCell value={patrimony?.finSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>

                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Fundos e Clubes'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Fundos de Investimentos (a Mercado)'} />
                                            <TableCell value={patrimony?.fundsCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Clubes de Investimentos (a Mercado)'} />
                                            <TableCell value={patrimony?.clubsCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Fundos'} />
                                            <TableCell value={patrimony?.fundsSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Clubes'} />
                                            <TableCell value={patrimony?.clubsSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>

                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Garantias (bolsa)'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Garantias (exceto ações)'} />
                                            <TableCell value={patrimony?.guaranteesExceptSharesAmount} textAlign='right' />
                                        </tr>


                                        <tr>
                                            <TableCell value={'Depósito de Margem (Dia)'} />
                                            <TableCell value={patrimony?.depositedIntradayMarging} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Estorno de Margem (Dia)'} />
                                            <TableCell value={patrimony?.refundedIntradayMarging} textAlign='right' />
                                        </tr>

                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Resumo'} />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Patrimônio (Calculado)'} />
                                            <TableCell value={patrimony?.basicPatrimony} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Patrimônio (Parametrizado)'} />
                                            <TableCell value={patrimony?.customPatrimony} textAlign='right' />
                                        </tr>
                                    </tbody>
                                </table>

                            </td>
                            <td style={{ verticalAlign: 'top', }} id='tr-section-table'>
                                <table className="int-table">
                                    <thead>
                                        <tr>
                                            <TableCell style={{ width: '80%' }} component='th' value={'Descrição'} />
                                            <TableCell style={{ width: '20%' }} component='th' value={'Valor'} textAlign='right' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Financeiro'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Financeiro'} />
                                            <TableCell value={limits?.limitPositionD1?.balanceAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Negociar apenas com Financeiro'} />
                                            <TableCell value={limits?.limitPositionD1?.tradeOnlyWithBalance ? "Sim" : "Não"} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Limite Adicional'} />
                                            <TableCell value={limits?.limitPositionD1?.extraLimit} textAlign='right' />
                                        </tr>


                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Bovespa'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia Bovespa (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.bovespaCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'BTC Tomador (a Mercado)'} />
                                            <TableCell value={limits?.limitPositionD1?.btcCustodyAmountTaker} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'BTC Doador (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.btcCustodyAmountDonor} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia de Termos (L/P)'} />
                                            <TableCell value={limits?.limitPositionD1?.termCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia de Opções Flexíveis (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.flexOpcCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'L/P DayTrade (Bovespa)'} />
                                            <TableCell value={limits?.limitPositionD1?.bovespaIntradayProfit} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Vendas Cobertas'} />
                                            <TableCell value={limits?.limitPositionD1?.coveredSellAmount} textAlign='right' />
                                        </tr>


                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'BMF'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Resultado BMF'} />
                                            <TableCell value={limits?.limitPositionD1?.bmfAdjustTotal} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Ouro (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.goldCustodyAmount} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'L/P DayTrade (Ouro)'} />
                                            <TableCell value={limits?.limitPositionD1?.goldDaytrade} textAlign='right' />
                                        </tr>

                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Renda Fixa'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia Renda Fixa (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.fixedIncomeCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Custódia Tesouro Direto (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.tesouroDiretoCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Tesouro Direto'} />
                                            <TableCell value={limits?.limitPositionD1?.tdSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Renda Fixa'} />
                                            <TableCell value={limits?.limitPositionD1?.finSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>


                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Fundos e Clubes'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Fundos de Investimentos (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.fundsCustodyAmount} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Clubes de Investimentos (c/ Deságio)'} />
                                            <TableCell value={limits?.limitPositionD1?.clubsCustodyAmount} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Fundos'} />
                                            <TableCell value={limits?.limitPositionD1?.fundsSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Descasamento de Liquidação de Clubes'} />
                                            <TableCell value={limits?.limitPositionD1?.clubsSettlementSyncMismatchValue} textAlign='right' />
                                        </tr>

                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Garantias (bolsa)'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Garantias (exceto ações)'} />
                                            <TableCell value={limits?.limitPositionD1?.guaranteesExceptSharesAmount} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Depósito de Margem (Dia)'} />
                                            <TableCell value={limits?.limitPositionD1?.depositedIntradayMarging} textAlign='right' />
                                        </tr>

                                        <tr>
                                            <TableCell value={'Estorno de Margem (Dia)'} />
                                            <TableCell value={limits?.limitPositionD1?.refundedIntradayMarging} textAlign='right' />
                                        </tr>

                                        <tr className="bg-secondary">
                                            <TableCell colSpan={2} className="int-table-divider" value={'Resumo'} />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Bônus de Alavancagem'} />
                                            <TableCell value={limits?.limitPositionD1?.leverageAdjust} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Limite em uso BMF'} />
                                            <TableCell value={limits?.limitPositionD1?.usedLimitBmf} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Limite em uso Bovespa'} />
                                            <TableCell value={limits?.limitPositionD1?.usedLimit} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell value={'Limite Operacional Disponível'} />
                                            <TableCell value={limits?.limitPositionD1?.basicLimit} textAlign='right' />
                                        </tr>
                                        <tr>
                                            <TableCell className="int-pr-1" value={'Lim Max Disp. Venda de Opção Desc.'} textAlign='left' />
                                            <TableCell value={limits?.limitPositionD1?.availableLimitForOptionsUncoveredSell} textAlign='right' />
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                )}
        </SectionWrapper>
    );
}
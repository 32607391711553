import React, { useEffect, useState } from 'react';
import { BsArrowClockwise } from 'react-icons/bs';

import { SmartTable } from '../../../components/Table';
import { Loader } from '../../../components/Loader';
import { Link } from "../../../components/Link";
import { SectionWrapper } from '../../../components/SectionWrapper'

import { formatDecimal, formatInteger, formatDateTime } from '../../../common/formatters';
import {
    riskOrderTypeEnum,
    riskOrderSideEnum,
    riskOrderStatusEnum,
    riskTimeInForceEnum
} from '../../../common/enums';
import { ordersRowStyles } from '../../../common/variables';
 
const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value }) => value.split('-', 1)[0]
  },
  {
    Header: 'Data',
    accessor: 'date',
    Cell: ({ value }) => formatDateTime(value)
  },
  {
    Header: 'Conta',
    accessor: 'account',
    Cell: ({ value }) => String(value)
  },
  {
    Header: 'Ativo',
    accessor: 'symbol',
    Cell: ({ value }) => value.toUpperCase()
  },
  {
    Header: 'Preço',
    accessor: 'price',
    Cell: ({ value }) => formatDecimal(value),
    columnAlign: 'right',
  },
  {
    Header: 'Pr. Med. Exec.',
    accessor: 'averagePrice',
    Cell: ({ value }) => formatDecimal(value),
    columnAlign: 'right',
  },
  {
    Header: 'Vol. Exec.',
    accessor: 'volume',
    Cell: ({ value }) => formatDecimal(value),
    columnAlign: 'right',
    hint: 'Volume executado'
  },
  {
    Header: 'Tipo',
    accessor: 'ordType',
    Cell: ({ value }) => riskOrderTypeEnum(value)
  },
  {
    Header: 'Lado',
    accessor: 'side',
    Cell: ({ value }) => riskOrderSideEnum(value)
  },
  {
    Header: 'Validade',
    accessor: 'timeInForce',
    Cell: ({ value }) => riskTimeInForceEnum(value)
  },
  {
    Header: 'Status',
    accessor: ({ status }) => riskOrderStatusEnum(status)
  },
  {
    Header: 'Qnt. Exec.',
    accessor: 'executedQty',
    Cell: ({ value }) => formatInteger(value),
    columnAlign: 'right',
  },
  {
    Header: 'Qnt. Can.',
    accessor: 'cancelledQty',
    Cell: ({ value }) => formatInteger(value),
    columnAlign: 'right',
  },
  {
    Header: 'Total',
    accessor: 'ordQty',
    Cell: ({ value }) => formatInteger(value),
    columnAlign: 'right',
  },
];

export function OrdersHistory({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientsOrdersData() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/orders/${account}/active`)

            if(!response.ok) {
                throw new Error("Ocorreu um erro ao buscar dados das Ordens do cliente")
            }

            const data = await response.json()
            setOrders(data)
        } catch (err) {
            
        } finally {    
            setIsLoading(false)
        }
    }
    
    useEffect(() => {
      if(updateAllGrids){
          fetchClientsOrdersData()
      }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientsOrdersData()
    }, [account]);

    return (
        <SectionWrapper 
            id={id + (isLoading ? '-loading' : '')}
            header="Ordens Ativas"
            headerTag="h3"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={orders.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-align-items-center">
                <Link
                    href={`/order-tracking?account=${account}`}
                >
                    Ver todas as ordens
                </Link>

                <button
                    className="int-btn-icon int-btn-icon-outline int-ml-auto"
                    disabled={isLoading}
                    onClick={fetchClientsOrdersData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex/>}

            {isLoading ? '' : 
            (
                <SmartTable 
                    columns={columns} 
                    data={orders} 
                    localStorageKey={'ClientOrdersHistory'} 
                    bodyRowStyles={ordersRowStyles}
                />
            )}
        </SectionWrapper>
    );
};

import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs";

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatInteger, formatDecimal, formatMonthName, formatDate } from '../../../common/formatters';

export function BmfCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [custody, setCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientCustody() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/client/${account}/bmfCustody`);

            const data = await response.json();

            const custodyItemsData = data?.map(item => ({
                ...item,
                available: formatInteger(item.available),
                maturityMonth: formatMonthName(item.maturityMonth),
                projectedD1: formatInteger(item.projectedD1),
                total: formatInteger(item.total),
                price: formatDecimal(item.price),
                settlementPrice: formatDecimal(item.settlementPrice),
                contractMultiplier: formatDecimal(item.contractMultiplier),
                bmfAdjustPosition: formatDecimal(item.bmfAdjustPosition),
                bmfAdjustDaytrade: formatDecimal(item.bmfAdjustDaytrade),
                bmfAdjustTotal: formatDecimal(item.bmfAdjustTotal)
            }))

            setCustody(custodyItemsData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientCustody()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientCustody();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol,
        },
        {
            Header: 'Vcto (mês)',
            accessor: data => data.maturityMonth,
            columnAlign: 'right',
            hint: 'Mês de vencimento'
        },
        {
            Header: 'Qtde Abertura',
            accessor: data => data.available,
            columnAlign: 'right',
            hint: 'Quantidade de abertura'

        },
        {
            Header: 'Qtde Dia',
            accessor: data => data.projectedD1,
            columnAlign: 'right',
            hint: 'Quantidade dia'
        },
        {
            Header: 'Total',
            accessor: data => data.total,
            columnAlign: 'right',
        },
        {
            Header: 'Cotação',
            accessor: data => data.price,
            columnAlign: 'right',
        },
        {
            Header: 'Ajuste',
            accessor: data => data.settlementPrice,
            columnAlign: 'right',
        },
        {
            Header: 'Tamanho',
            accessor: data => data.contractMultiplier,
            columnAlign: 'right',
        },
        {
            Header: 'Ajuste (Posição)',
            accessor: data => data.bmfAdjustPosition,
            columnAlign: 'right',
        },
        {
            Header: 'Ajuste (Dia)',
            accessor: data => data.bmfAdjustDaytrade,
            columnAlign: 'right',
        },
        {
            Header: 'Ajuste Total',
            accessor: data => data.bmfAdjustTotal,
            columnAlign: 'right',
        },
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Custódia BMF"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={custody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button 
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading} 
                    onClick={fetchClientCustody}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex/>}

            { isLoading ? "" : 
            (
                <SmartTable data={custody} columns={columns} localStorageKey={'ClientBmfCustody'} />
            )}
        </SectionWrapper>
    );
}

import React, { useEffect, useState } from 'react'

import { Loader } from '../../../components/Loader';
import { Link } from '../../../components/Link';
import { SmartTable } from '../../../components/Table'
import { SectionWrapper } from '../../../components/SectionWrapper';
import { Alert } from '../../../components/Alert';

export function VirtualMarketList() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    (async() => {
        setLoading(true)

        try {
            const response = await fetch('/api/virtualMarket')

            if(!response.ok) {
                throw new Error
            }

            const data = await response.json()
            setData(data)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
        }
        
    })()
  }, [])

  const columns = [
    {
      Header: 'Nome',
      accessor: 'name',
      Cell: ({ value }) => <Link href={`/virtual-market/${value}`}>{value.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}</Link> 
    },
    {
      Header: 'Ativos',
      accessor: data => data?.symbols?.map(i => i.symbol).sort().slice(0, 6).join(' '),
      Cell: ({ value }) => value?.replace(/\s/g, ', ')
    }
  ]

  return (
    <>
      <SectionWrapper header="Listas de Ativos">
        {loading && <Loader flex/>}

        {error && (
            <Alert>
                Ocorreu um erro ao carregar dados dos Mercados Virtuais. Tente 
                novamente mais tarde.
            </Alert>
        )}

        {loading || !!error ? '' : data?.length <= 0 ? (
            <p className="int-mt-5 int-text-center">
                Nenhuma lista de ativos encontrada!
            </p>
        ) : (
            <SmartTable columnFilters data={data} columns={columns} localStorageKey={'VirtualMarketList'} /> 
        )}
      </SectionWrapper>
    </>
  )
}


import React, { useEffect, useState } from "react"
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table"

import { formatDecimal, formatDate } from "../../../common/formatters"

export function Margin({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [margin, setMargin] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    async function fetchClientMargin() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/margin`)

            if (!response.ok) {
                throw new Error("Ocorreu um erro ao buscar dados da chamada de margem do cliente")
            }

            const data = await response.json()

            const margin = data?.map(item => ({
                ...item,
                account: item.account,
                requiredMargin: formatDecimal(item.requiredMargin),
                depositedMargin: formatDecimal(item.depositedMargin),
                valExcess: formatDecimal(item.valueExcess),
                valueExcessCash: formatDecimal(item.valueExcessCash),
                valueReturn: formatDecimal(item.valueReturn),
                marginCall: formatDecimal(item.marginCall)
            }))

            setMargin(margin)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(updateAllGrids){
            fetchClientMargin()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientMargin()
    }, [account])

    const columns = [
        {
            Header: 'Margem Requerida',
            accessor: data => data.requiredMargin,
        },
        {
            Header: 'Valor Total da Garantia',
            accessor: data => data.depositedMargin,
        },
        {
            Header: 'Valor Execedente',
            accessor: data => data.valExcess,
        },
        {
            Header: 'Valor Excd. em Espécie',
            accessor: data => data.valueExcessCash,
            hint: "Valor Excedente em Espécie"
        },
        {
            Header: 'Valor Devolvido',
            accessor: data => data.valueReturn,
        },
        {
            Header: 'Chamada de Margem',
            accessor: data => data.marginCall,
        },
    ]

    return (
        <SectionWrapper
            id={id+ (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Margem (Abertura)"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={margin.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientMargin}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? "" :
                (
                    <SmartTable data={margin} columns={columns} localStorageKey={'ClientMargin'} />
                )}
        </SectionWrapper>
    );
}
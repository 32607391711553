import React, { useEffect, useRef, useState } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';

import { Button, Field, Select } from '../../components/Forms';
import { SectionWrapper } from '../../components/SectionWrapper';

import { removeArrayItem, setArrayOfObjects } from '../../common/variables';

type TMarketData = {
    symbol: string;
    underlyingSymbol: string;
    price: string;
    priceDivisor: string;
    lastPrice: string;
    closingPrice: string;
    bidPrice: string;
    askPrice: string;
    margin: string;
    market: string;
    contractMultiplier: number;
    maturityDate: string;
    currencyFactor: number;
}

interface MarketDataProps {
    marketData: TMarketData[];
    setMarketData: (value: TMarketData[]) => void;
    marketDataItem: TMarketData;
    allAccordionsOpen?: {
        isAllAccordionOpen: boolean;
        count: number;
    };
    setValidationErrors: React.Dispatch<React.SetStateAction<[]>>;
    deleteOldErrors: (headerName: string, index: number) => void;
}

const headerName = "Cotação";

export function MarketData({
    marketData,
    setMarketData,
    marketDataItem,
    allAccordionsOpen,
    setValidationErrors,
    deleteOldErrors
}: MarketDataProps) {
    const [standardMarkets, setStandardMarkets] = useState<string[]>([])
    const highlightedItemRef = useRef(null);

    const leftAddonStyle = {
        "paddingInlineStart?": "0.5rem",
        "paddingInlineEnd": "0.5rem"
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('/api/policy/market/standard')

                if (!response.ok) {
                    const error = await response.json()
                    throw new Error(error?.message)
                }

                const data = await response.json()
                setStandardMarkets(data)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])

    function addNewMarketData() {
        if (marketData.length !== 0) resetAnimation();
        setMarketData([marketDataItem, ...marketData])
        if (marketData.length !== 0) activateAnimation();
    }

    function resetAnimation(): void {
        highlightedItemRef.current.style.animation = '';
        highlightedItemRef.current.style.animationIterationCount = '';
        void highlightedItemRef.current.offsetWidth;
    }

    function activateAnimation(): void {
        const animation = 'smooth-fade-in 3s linear forwards 1';
        highlightedItemRef.current.style.animation = animation;
        highlightedItemRef.current.children[0].children[0].children[0].focus();
    }

    function removeAllMarketData() {
        setMarketData([marketDataItem])
    }

    function removeAllErrors() {
        setValidationErrors([]);
    }

    return (
        <SectionWrapper
            headerTag="h3"
            header={headerName}
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
        >
            <div className="int-d-flex int-justify-content-end int-mb-4">
                <button
                    data-testid="marketData-add-row"
                    type="button"
                    className="int-btn-icon int-btn-icon-outline"
                    onClick={addNewMarketData}
                >
                    <FiPlus size={20} />
                </button>
            </div>
            <div style={{ overflowX: 'auto' }}>
                <table className="int-table">
                    <thead>
                        <tr style={{whiteSpace: "nowrap"}}>
                            <th>Ativo</th>
                            <th>Ativo Objeto</th>
                            <th>Pr. Divisor</th>
                            <th>Último Preço</th>
                            <th>Pr. Fechamento</th>
                            <th>Pr. Compra</th>
                            <th>Pr. Venda</th>
                            <th>Mercado</th>
                            <th>Margem</th>
                            <th>Tam. Contrato</th>
                            <th>Moeda</th>
                            <th >Dt. Vencimento</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {marketData.map((item, index) => (
                            <tr key={index} ref={index === 0 ? highlightedItemRef : null}>
                                <td style={{ padding: '0.75rem 0.5rem', minWidth: "150px" }}>
                                    <Field
                                        data-testid="marketData-symbols"
                                        required
                                        format="Symbol"
                                        name="symbol"
                                        value={item?.symbol}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event,
                                                    true
                                                )
                                            )
                                        }
                                        index={index}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem', minWidth: "150px" }}>
                                    <Field
                                        data-testid="marketData-underlyingSymbol"
                                        format="Symbol"
                                        name="underlyingSymbol"
                                        value={item?.underlyingSymbol}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event,
                                                    true
                                                )
                                            )
                                        }
                                        index={index}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        data-testid="marketData-priceDivisor"
                                        required
                                        format="Decimal"
                                        name="priceDivisor"
                                        value={item?.priceDivisor}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        data-testid="marketData-lastPrice"
                                        required
                                        format="Decimal"
                                        name="lastPrice"
                                        value={item?.lastPrice}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        data-testid="marketData-closingPrice"
                                        required
                                        format="Decimal"
                                        name="closingPrice"
                                        value={item?.closingPrice}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        data-testid="marketData-bidPrice"
                                        required
                                        format="Decimal"
                                        name="bidPrice"
                                        value={item?.bidPrice}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        data-testid="marketData-askPrice"
                                        required
                                        format="Decimal"
                                        name="askPrice"
                                        value={item?.askPrice}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem', minWidth: "150px" }}>
                                    <Select
                                        data-testid="marketData-market"
                                        required
                                        name="market"
                                        value={item?.market}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                    >
                                        <option hidden value="">Selecione</option>
                                        {standardMarkets.map((item, index) => (
                                            <option data-testid="marketData-market-options" key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </Select>
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        required
                                        data-testid="marketData-margin"
                                        format="Decimal"
                                        name="margin"
                                        value={item?.margin}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        required
                                        data-testid="marketData-contractMultiplier"
                                        format="Decimal"
                                        name="contractMultiplier"
                                        value={item?.contractMultiplier ?? 1}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <Field
                                        required
                                        data-testid="marketData-currencyFactor"
                                        format="Decimal"
                                        name="currencyFactor"
                                        value={item?.currencyFactor ?? 1}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                        index={index}
                                        leftAddonStyle={leftAddonStyle}
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <input
                                        data-testid="marketData-maturityDate"
                                        className="int-form-control"
                                        type="date"
                                        name="maturityDate"
                                        value={
                                            item?.maturityDate?.split("T")[0] ||
                                            new Date().toISOString().split('T')[0]
                                        }
                                        min={new Date().toISOString().substr(0, 10)}
                                        onChange={
                                            event => setMarketData(
                                                setArrayOfObjects(
                                                    marketData,
                                                    index,
                                                    event
                                                )
                                            )
                                        }
                                    />
                                </td>
                                <td style={{ padding: '0.75rem 0.5rem' }}>
                                    <BsTrashFill
                                        data-testid="marketData-remove-item"
                                        size={22}
                                        color="var(--chakra-colors-orange-400)"
                                        className="ml-3 flex-shrink-0"
                                        style={{ cursor: "pointer" }}
                                        onClick={
                                            () => [setMarketData(
                                                removeArrayItem(index, marketData)
                                            ),
                                            deleteOldErrors(headerName, index)
                                            ]
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="int-my-4">
                <Button
                    variant="outline"
                    disabled={marketData.length < 2}
                    onClick={() => [removeAllMarketData(), removeAllErrors()]}
                >
                    Remover todos os items
                </Button>
            </div>
        </SectionWrapper>
    )
}
import React from 'react'
import { FiX } from 'react-icons/fi'

import './style.css'

/**
  ```
    Example: <TagsInput
               label="Tags items"
               placeholder="Tags"
               inputValue={inputValue}
               tagsArr={tagsArr}
               onChange={onChange}
               onRemove={onRemove}
               onAdd={onAdd}
               onEnter={onEnter}
             />
  ``` 
*/

export function TagsInput({
  maxLength = 200,
  label,
  placeholder = "",
  inputValue, 
  tagsArr = [], 
  required = false, 
  onChange, 
  onRemove, 
  onAdd, 
  onEnter,
  id = ""
}) {
  const input = React.useRef(null)

  const addTags = (event) => {
    event.preventDefault()

    let tags = [...tagsArr]

    if (event?.key == ' ' || event?.key == ',' || event?.key == 'Enter') {
      input.current.value.split(',').forEach(tag => {
         if (tag && tag.includes(' '))
          return tag.split(' ').forEach(formatTag => {
            if (formatTag)
              tags.push(formatTag.trim())
          })

        if (tag && !tag.includes(' '))
          return tags.push(tag.trim())
      })

      onAdd(tags)
      onEnter('')
    }
  }

  return (
        <div>
            <label data-testid="tag-input-label" className="int-form-label int-m-0">
                {label} {required ? <span style={{ color: 'var(--int-colors-red-500)' }}>*</span> : ''}
            </label>
            
            <div data-testid="tag-input-div" className="int-tags">
              {tagsArr.length > 0 && tagsArr.map((e, i) => ( 
                <div 
                    data-testid="tag-div"
                    key={i}
                    className="int-tag"
                >
                    {e}
                    <FiX onClick={() => onRemove(i)} />
                </div>
              ))}
              
              <input
                  id= {id}
                  maxLength= {maxLength}
                  data-testid="tags-input"
                  className={`int-form-control ${tagsArr.length > 0 ? "int-input" : ""}`}
                  ref={input}
                  placeholder={tagsArr.length > 0 ? "" : placeholder}
                  value={inputValue}
                  onKeyUp={e => addTags(e)}
                  onChange={e => onChange(e)}
              />
            </div>
            <span>Pressione espaço (ou vírgula) para adicionar um novo item.</span>
        </div>     
  )
}

import React from 'react'

import { Field } from '../../components/Forms'
import { SectionWrapper } from '../../components/SectionWrapper'

import { setObject } from '../../common/variables'

type TBalance = {
    available: string;
    initD0: string;
    initD1: string;
    initD2: string;
}

interface BalanceProps {
    balance: TBalance;
    setBalance: (value: TBalance) => void;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
}

export function Balance({ balance, setBalance, allAccordionsOpen }: BalanceProps) {
    return (
        <SectionWrapper
            headerTag="h3"
            header="Financeiro"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
        >
            <table className="int-table">
                <thead>
                    <tr>
                        <th>Disponível</th>
                        <th>Projetado D0</th>
                        <th>Projetado D1</th>
                        <th>Projetado D2</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <Field 
                                data-testid="balance-available"
                                required
                                format="Currency"
                                name="available"
                                value={balance?.available}
                                onChange={
                                    event => setBalance(
                                        setObject(event, balance)
                                    )
                                }
                            />
                        </td>
                        <td>
                            <Field 
                                data-testid="balance-initd0"
                                required
                                format="Currency"
                                name="initD0"
                                value={balance?.initD0}
                                onChange={
                                    event => setBalance(
                                        setObject(event, balance)
                                    )
                                }
                            />
                        </td>
                        <td>
                            <Field 
                                data-testid="balance-initd1"
                                required
                                format="Currency"
                                name="initD1"
                                value={balance?.initD1}
                                onChange={
                                    event => setBalance(
                                        setObject(event, balance)
                                    )
                                }
                            />
                        </td>
                        <td>
                            <Field 
                                data-testid="balance-initd2"
                                required
                                format="Currency"
                                name="initD2"
                                value={balance?.initD2}
                                onChange={
                                    event => setBalance(
                                        setObject(event, balance)
                                    )
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </SectionWrapper>
    )
}

import React, { useState } from 'react'
import { useHistory } from "react-router-dom"

import { SectionWrapper } from '../../components/SectionWrapper'
import { Input, Button } from '../../components/Forms'
import { Alert } from '../../components/Alert'

import { API_HEADER } from '../../constants/api'

import { loginService, isRequiredAcessAdmin, setRequiredAdminAcess, isAdmin, isRouteAdmin, setRouteAdmin } from '../../services/auth'

export function SignIn() {
    const [userName, setUserName] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>();

    const history = useHistory()

    async function AuthenticaionHandle(event) {
        event.preventDefault()
        setIsLoading(true)
        setError(null)

        try {
            const data = {
                userName: userName,
                password: password,
            }

            const options = {
                method: "POST",
                headers: API_HEADER,
                body: JSON.stringify(data),
            };

            const response = await fetch('/api/signIn', options)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const result = await response.json()
            var stateHistory = history.location.state

            loginService(result.token, result.user)
            if (stateHistory!=undefined && stateHistory.hasOwnProperty("from")) {
                var stateFrom = stateHistory["from"]
                if (isRouteAdmin() && !isAdmin())
                    history.push('/')
                else
                    history.push(`${stateFrom.pathname}${stateFrom.search}`)
            }
            else {
                history.push('/')
            }

            setRequiredAdminAcess(!isAdmin())
            setRouteAdmin(false)
            clearData()
        } catch(err) {
            setError(err)
        } finally {
            setIsLoading(false)
        }
    }

    function clearData() {
        setUserName("")
        setPassword("")
    }
    var logOutMessage = "Sessão expirada, realize o login novamente."
    if ((isRequiredAcessAdmin() && isRouteAdmin()) || isRequiredAcessAdmin() == null) {
        logOutMessage = "Usuário sem permissão."
    }

    if (isAdmin())
        setRequiredAdminAcess(false)
    return (
        <SectionWrapper header={(history.action === "REPLACE") ? logOutMessage : "Bem-vindo"}>
            <form onSubmit={AuthenticaionHandle} style={{ maxWidth: '520px'}}>
                    <Input
                        data-testid="login-user"
                        isRequired
                        label="Usuário"
                        name="userName"
                        placeholder="Usuário"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        style={{marginBottom: 16}}
                        type="text"
                    />

                    <Input
                        data-testid="login-password"
                        isRequired
                        label="Senha"
                        name="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        style={{marginBottom: 16}}
                    />

                    <div>
                        <Button 
                            data-testid="btn-clear"
                            variant="outline"
                            onClick={clearData}
                            disabled={isLoading}
                        >
                            Limpar
                        </Button>

                        <Button 
                            data-testid="btn-submit"
                            margin="int-ml-2" 
                            type="submit"
                            disabled={isLoading}
                        >
                            Entrar
                        </Button>
                    </div>

                    {error?.message && (
                        <Alert margin="int-my-4">
                            Ocorreu um erro: {error?.message}
                        </Alert>
                    )}
                </form>
        </SectionWrapper>
    )
}   

import React from "react";
import { ColumnInstance, TableToggleHideAllColumnProps } from "react-table";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { IndeterminateCheckbox } from './Filters'
import { Button, Checkbox } from '../Forms'
import { Modal, ModalBody, ModalFooter } from '../Modal'

interface ReorderColumnsModalProps {
    isOpen: boolean;
    onClose: () => void;
    tableColumnsOrder: ColumnInstance<object>[] | unknown[];
    setTableColumnsOrder: (value: ColumnInstance<object>[] | unknown[]) => void;
    setColumnOrder: (
        updater: string[] | ((columnOrder: string[]) => string[])
    ) => void;
    getToggleHideAllColumnsProps?: (
        props?: Partial<TableToggleHideAllColumnProps>
    ) => TableToggleHideAllColumnProps | void;
    allColumns: ColumnInstance<object>[];
}

export function ReorderColumnsModal({
    isOpen,
    onClose,
    tableColumnsOrder,
    setTableColumnsOrder,
    setColumnOrder,
    getToggleHideAllColumnsProps,
    allColumns
}: ReorderColumnsModalProps) {
    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        // Se for solto fora da lista
        if (!result.destination)
            return;

        const items = reorder(
            tableColumnsOrder,
            result.source.index,
            result.destination.index
        );
        setTableColumnsOrder(items);

        const orderArr = items.map((item: { id : string }) => item.id)
        setColumnOrder(orderArr)
    }

    return(
        <Modal
            size="xlg"
            header="Ocultar e Reordenar colunas"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalBody>
                <p className="int-mb-5">
                    Clique nos checbox para ocultar as colunas e clique e 
                    arraste para ordenar as colunas das tabelas
                </p>
                <IndeterminateCheckbox name="table-inderteminate" {...getToggleHideAllColumnsProps()} />
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Droppable droppableId="colunms" direction="horizontal">
                        {(provided) => (
                            <ul className="int-columns-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
                                {allColumns.map((column, index) => (
                                        <Draggable key={index} draggableId={String(index)} index={index}>
                                            {(provided) => (
                                                <li
                                                    ref={provided.innerRef} 
                                                    {...provided.draggableProps} 
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Checkbox 
                                                        key={index} 
                                                        label={column.Header} 
                                                        {...column.getToggleHiddenProps()} 
                                                    />
                                                </li>
                                            )}
                                        </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}
import React, { useEffect, useState } from 'react';

import { formatDateTime, formatDecimal, formatInteger } from '../../../common/formatters';
import { objToQueryString, dateQueryParse } from '../../../common/api.js';
import { riskOrderSideEnum, riskTypeEnum } from '../../../common/enums.js';
import { sanitizeName } from '../../../common/variables'

import { H4 } from '../../../components/Typography'
import { Button, Input, Field, DateTime } from '../../../components/Forms'
import { Link } from '../../../components/Link'
import { Loader } from '../../../components/Loader'
import { SmartTable } from '../../../components/Table'
import { SelectColumnFilter, NumberRangeColumnFilterVertical } from '../../../components/Table/Filters'
import { SectionWrapper } from '../../../components/SectionWrapper/';
import { Alert } from '../../../components/Alert';

import { useQueryParams } from '../../../hooks/useQueryParams';
import { FiSearch } from 'react-icons/fi';
import { SimpleModal } from '../../../components/Modal/SimpleModal';

type QueryObject = {
    accountStart?: string;
    accountEnd?: string;
    dtStart?: string;
    dtEnd?: string;
    symbols?: string;

}

export function SearchHistory() {
    const { setParam, updateQuery } = useQueryParams()

    const [symbols, setSymbols] = useState(setParam("symbols"));
    const [initialDate, setInitialDate] = useState(setParam("dtStart"));
    const [finalDate, setFinalDate] = useState(setParam("dtEnd"));
    const [initialClient, setInitialClient] = useState(setParam("accountStart"));
    const [finalClient, setFinalClient] = useState(setParam("accountEnd"));
    const [queryObject, setQueryObject] = useState<QueryObject>();
    const [showFilterModal, setShowFilterModal] = useState(true);
    const [results, setResults] = useState<[]>();

    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const today = new Date().toISOString().substr(0, 10);
    const [searched, setSearched] = useState(false)

    useEffect(() => {
        if (queryObject) {
        (async function () {
            await fetchHistoryValidation()
            setLoading(false)
        })();
        }
    }, [queryObject])

    const columns = [
        {
            Header: 'Id',
            accessor: 'id',
            Cell: props => <Link href={`/history/${props.row.values.id}`}>{props.value.split('-', 1)[0]}</Link>
        },
        {
            Header: 'Tipo',
            accessor: ({ type }) => riskTypeEnum(type),
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Ordem Original',
            accessor: ({ origOrderId }) => origOrderId ? origOrderId : '-',
            width: 215
        },
        {
            Header: 'Ordem',
            accessor: ({ orderId }) => orderId ? orderId : '-',
            width: 215
        },
        {
            Header: 'Conta',
            accessor: 'account',
            Cell: props => <Link href={`/client/${props.row.values.account}`}>{props.value}</Link>,
            filter: 'between'
        },
        {
            Header: 'Letra do Operador',
            accessor: ({ enteringTrader }) => enteringTrader ? enteringTrader : '-'
        },
        {
            Header: 'Quantidade',
            accessor: 'quantity',
            Cell: ({ value }) => formatInteger(value),
            filter: 'between'
        },
        {
            Header: 'Preço',
            accessor: 'price',
            Cell: ({ value }) => formatDecimal(value),
            filter: 'between'
        },
        {
            Header: 'Lado',
            accessor: ({ side }) => riskOrderSideEnum(side),
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Ativo',
            accessor: 'symbol',
            Cell: ({ value }) => value.toUpperCase()
        },
        {
            Header: 'Data',
            accessor: ({ dtReceived }) => formatDateTime(dtReceived)
        },
        {
            Header: 'Duração',
            accessor: ({ totalTimeMs }) => totalTimeMs + ' ms',
            filter: 'between'
        },
        {
            Header: 'Ordem aceita',
            accessor: ({ canSendOrder }) => canSendOrder ? 'Sim' : 'Não',
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Origem',
            accessor: 'origin'
        },
        {
            Header: 'Razão da rejeição',
            accessor: ({ rejectReasonMessage }) => rejectReasonMessage ? rejectReasonMessage.substr(0,30) + '...' : '-----',
            width: 300
        }
    ]

    const fetchHistoryValidation = async () => {
        const apiQueryString = objToQueryString({
            ...queryObject,
            accountStart: queryObject?.accountStart,
            accountEnd: queryObject?.accountEnd,
            dtStart: dateQueryParse(queryObject?.dtStart),
            dtEnd: dateQueryParse(queryObject?.dtEnd),
            symbols: symbols
        });
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {
            setFiltering(false);
            setShowFilterModal(false);
            setLoading(true);
            setDisabled(true);

            const response = await fetch(`/api/RiskValuation/?${apiQueryString}`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()

            setResults(data)
        } catch(err) {
            setError(true)
        }
    }

    const cleanFilters = () => {
        setSymbols("");
        setInitialDate("");
        setFinalDate("");
        setInitialClient("");
        setFinalClient("");

        updateQuery(" ")
    }
    const setQueryObjectFilters = () => {

        const queryStringFilters = {
            symbols: symbols,
            accountStart: initialClient,
            accountEnd: finalClient,
            dtStart: initialDate,
            dtEnd: finalDate
        };

        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();
        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const handleHistoryValidation = async event => {
        event.preventDefault()
        setLoading(true)
        setError(false)

        if (!searched)
        setSearched(true)

        setQueryObjectFilters()
    }
    const setNumberValue = (value) => {
        let numberValue = `${value}`;

        numberValue = numberValue.replace(/\D/g, '');

        return numberValue;
    }

    const setFinalDateOnBlur = () => {
        if (finalDate === '' || finalDate === undefined) {
            const date = initialDate.substr(0, 10) + "T" + "23:59";
            setFinalDate(date)
        }
    }
    const renderFilterElements = () => {
        return(
            <form onSubmit={(e) => handleHistoryValidation(e)}>
                    <div className="int-mt-4">
                        <H4>Ativos (separados por vírgula)</H4>
                        <Field
                            data-testid="search-history-symbols"
                            format="Symbols"
                            name="symbols"
                            type="text"
                            value={symbols}
                            onChange={(e) => setSymbols(sanitizeName(e.target.value, true))}
                        />
                    </div>
                    <div className="int-mt-4 int-row">
                        <div className="int-col" style={{flexGrow: 2}}>
                            <H4>Data da Validação</H4>
                            <div className="int-row">
                                <div className="int-col int-pr-0">
                                    <DateTime
                                        label="De"
                                        name="initialDate"
                                        value={initialDate}
                                        setDateTime={setInitialDate}
                                        maxDate={today}
                                        onBlurDate={setFinalDateOnBlur}
                                        timeDefault="00:00"
                                    />
                                </div>

                                <div className="int-col int-pr-0">
                                    <DateTime
                                        label="Para"
                                        name="finalDate"
                                        value={finalDate}
                                        setDateTime={setFinalDate}
                                        minDate={initialDate ? initialDate.substr(0, 10) : ''}
                                        maxDate={today}
                                        timeDefault="23:59"    
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="int-col">
                            <H4>Conta do cliente</H4>
                            <div className="int-row">

                                <div className="int-col int-pr-0">
                                    <Input
                                        data-testid="search-history-initialClient"
                                        label="De"
                                        name="initialClient"
                                        value={initialClient}
                                        onChange={(e) => setInitialClient(setNumberValue(e?.target?.value))}
                                        onBlur={() => (finalClient === '' || finalClient === undefined) && setFinalClient(initialClient)}
                                    />
                                </div>

                                <div className="int-col">
                                    <Input
                                        data-testid="search-history-finalClient"
                                        label="Para"
                                        name="finalClient"
                                        type="text"
                                        value={finalClient || ''}
                                        onChange={(e) => setFinalClient(setNumberValue(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {filtering && ( 
                        <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
                    )}
                </form>
	    )
    }
    return (
        <SectionWrapper header="Buscar Validações de Risco"        
        searchButton={
            <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
        }>
                <SimpleModal 
                    header="Filtros de busca"
                    isOpen={showFilterModal}
                    onClose={() => setShowFilterModal(false)}
                    bodyMessage={renderFilterElements()}
                    footerButtons={[
                        {
                            label: 'Limpar Todos os filtros',
                            onClick:() => cleanFilters(),
                            variant: 'outline'
                        },   
                        {
                            label: 'Buscar',
                            onClick: (e) => {
                                handleHistoryValidation(e)
                            },
                            margin: 'int-ml-2'
                        }
                    ]}
                />                                    
            {loading && <Loader flex />}

            {error && (
                <Alert 
                    margin="int-mt-5"
                >
                    Ocorreu um erro na busca de validações de risco
                </Alert>
            )
            }

            {loading || error || filtering ? '' : results && results.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : results ? (

                <SmartTable 
                    columnFilters
                    columns={columns} 
                    data={results} 
                    gapColumnsFilter={2}
                    lotOfColumnsLockHeader = {true}
                    localStorageKey={'RiskValuationHistorySearch'} 
                    columnsResizable
                />
            ) : ''
            }
        </SectionWrapper>
    )
}

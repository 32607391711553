import React, { useEffect, useState } from "react";

import { Loader } from "../../../components/Loader";
import { SmartTable } from "../../../components/Table";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Alert } from "../../../components/Alert";
import { BsPencil, BsTrashFill } from "react-icons/bs";
import { API_HEADER } from "../../../constants/api";
import { formatDate } from "../../../common/formatters";
import { Modal, ModalBody, ModalFooter } from "../../../components/Modal";
import {
    Button,
    Input,
    DateTime,
} from "../../../components/Forms";

import { sanitizeName } from "../../../common/variables";
import { Tooltip } from "../../../components/Tooltip";

export function IpoRegistryList() {
    const [data, setData] = useState([]);
    const [id, setId] = useState("");
    const [updateId, setUpdateId] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showDeletedParameter, setShowDeletedParameter] = useState(false);

    const [showParameterEdit, setParameterEdit] = useState(false);

    const [isin, setIsin] = useState("");
    const [symbol, setSymbol] = useState("");
    const [offerType, setOfferType] = useState("");
    const [projectionWallet, setProjectionWallet] = useState("");
    const [settlementProjectWallet, setSettlementProjectWallet] = useState("");
    const [settlementDate, setSettlementDate] = useState("");
    const [settlementProjectDate, setSettlementProjectDate] = useState("");
    const [startTradingDate, setStartTradingDate] = useState("");

    const [disabled, setDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const form = React.useRef(null);

    const fetchData = async () => {
        try {
            const response = await fetch("/api/IpoRegistry");

            if (!response.ok) {
                throw new Error();
            }

            const data = await response.json();
            setData(data);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleIpoRegistry = async (event) => {
        event.preventDefault();

        setDisabled(true);
        setError(false);

        try {
            var request = [
                {
                    symbol: symbol.toString(),
                    isin: isin.toString(),
                    offerType: offerType.toString(),
                    projectionWallet: projectionWallet.toString(),
                    settlementProjectWallet: settlementProjectWallet.toString(),
                    startTradingDate: startTradingDate,
                    settlementDate: settlementDate,
                    settlementProjectDate: settlementProjectDate,
                },
            ];
            const options = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(request),
            };

            const response = await fetch(`/api/ipoRegistry/${updateId}`, options);

            if (!response.ok) {
                const error = await response.json();
                console.log(error);
                throw new Error(
                    error.title + " - " + JSON.stringify(error.errors)
                );
            } else {
                await fetchData();
                setParameterEdit(false);
            }

        } catch (err) {
            setErrorMessage(err.message);
            setError(true);

        } finally {
            setDisabled(false);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
       
    }, []);

    const handleDeleteIpo = async (item) => {
        setId(item)
        setShowDeletedParameter(true);
    };

    const handleUpdateIpo = async (item) => {
        setUpdateId(item.id)
        setIsin(item.isin)
        setSymbol(item.symbol)
        setOfferType(item.offerType)
        setProjectionWallet(item.projectionWallet)
        setSettlementProjectWallet(item.settlementProjectWallet)
        setSettlementDate(item.settlementDate.split('T')[0])
        setSettlementProjectDate(item.settlementProjectDate.split('T')[0])
        setStartTradingDate(item.startTradingDate.split('T')[0])
        setParameterEdit(true)
    };

    const submitParamDelete = async event => {
        event.preventDefault()
        try {
            const options = {
                method: "DELETE",
                headers: API_HEADER,
            };

            const response = await fetch(`/api/ipoRegistry/${id}`, options);

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error);
            }
            fetchData();
        } catch (err) {
            console.log(err);
        }
        setShowDeletedParameter(false)
    };

    const DeleteButton = (item) => {
        return (
            <BsTrashFill
                data-testid="param-delete-button"
                size={20}
                color="var(--int-colors-orange-400)"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    handleDeleteIpo(item);
                }}
            />
        );
    };

    const EditButton = (item) => {
        return (
            <BsPencil
                data-testid="param-edit-button"
                size={20}
                color="var(--int-colors-orange-400)"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    handleUpdateIpo(item);
                }}
            />
        );
    };
    const columns = [
        {
            Header: "Símbolo",
            accessor: "symbol",
        },
        {
            Header: "ISIN",
            accessor: "isin",
        },
        {
            Header: "Modalidade",
            accessor: "offerType",
        },
        {
            Header: "Carteira de Projeção",
            accessor: "projectionWallet",
        },
        {
            Header: "Carteira Durante Liquidação",
            accessor: "settlementProjectWallet",
        },
        {
            Header: "Início de Negociação",
            accessor: ({ startTradingDate }) => formatDate(startTradingDate),
        },
        {
            Header: "Liquidação",
            accessor: ({ settlementDate }) => formatDate(settlementDate),
        },
        {
            Header: "Projeção da Liquidação",
            accessor: ({ settlementProjectDate }) =>
                formatDate(settlementProjectDate),
        },
        {
            id: "Edit",
            width: "1%",
            Header: "",
            Cell: ({ row }) => EditButton(row.original),
        },
        {
            id: "Remove",
            width: "1%",
            Header: "",
            Cell: ({ row }) => DeleteButton(row.original.id),
        },
    ];

    return (
        <>
            <SectionWrapper header="Lista de Registros IPO">
                {loading && <Loader flex />}

                {error && (
                    <Alert>
                        Ocorreu um erro ao carregar dados dos Registros de IPO .
                        Tente novamente mais tarde.
                    </Alert>
                )}

                {loading || !!error ? (
                    ""
                ) : data?.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum Registro de IPO encontrado!
                    </p>
                ) : (
                    <SmartTable
                        columnFilters
                        data={data}
                        columns={columns}
                        localStorageKey={"IpoRegistryList"}
                    />
                )}
            </SectionWrapper>

            {/* Modal - Editar Registro de IPO */}
            <Modal
                header="Editar Registro de IPO"
                isOpen={showParameterEdit}
                onClose={() => setParameterEdit(false)}
            >
                <ModalBody>
                    <form onSubmit={handleIpoRegistry} ref={form}>
                        <div className="int-col">
                            <div className="int-row int-mb-6">

                                <div className="int-col">
                                    <Input
                                        data-testid="create-ipo-symbol"
                                        isRequired
                                        label="Símbolo"
                                        name="symbol"
                                        placeholder="Símbolo do IPO"
                                        value={symbol}
                                        onChange={(e) =>
                                            setSymbol(
                                                sanitizeName(e.target.value, true)
                                            )
                                        }
                                        hintIcon
                                        children={<Tooltip label="Código do ativo." />}
                                    />
                                </div>
                                <div className="int-col">
                                    <Input
                                        data-testid="create-ipo-isin"
                                        isRequired
                                        label="Código ISIN"
                                        name="isin"
                                        placeholder="Código ISIN do IPO"
                                        value={isin}
                                        onChange={(e) =>
                                            setIsin(
                                                sanitizeName(e.target.value, true)
                                            )
                                        }
                                        hintIcon
                                        children={<Tooltip label="Código ISIN do ativo." />}
                                    />
                                </div>
                                <div className="int-col">
                                    <Input
                                        data-testid="create-ipo-offerType"
                                        isRequired
                                        label="Modalidade"
                                        name="offerType"
                                        placeholder="Modalidade do IPO"
                                        value={offerType}
                                        onChange={(e) =>
                                            setOfferType(
                                                sanitizeName(e.target.value, true)
                                            )
                                        }
                                        hintIcon
                                        children={<Tooltip label="Número da modalidade do IPO." />}
                                    />
                                </div>
                            </div>

                            <div className="int-row int-mb-6">
                                <div className="int-col">
                                    <Input
                                        data-testid="create-ipo-projection-wallet"
                                        isRequired
                                        label="Carteira de Projeção"
                                        name="projectionWallet"
                                        placeholder="Carteira de projeção do IPO"
                                        value={projectionWallet}
                                        onChange={(e) =>
                                            setProjectionWallet(
                                                sanitizeName(e.target.value, true)
                                            )
                                        }
                                        hintIcon
                                        children={<Tooltip label="Número da carteira onde a posição será projetada durante o primeiro dia de negociação, antes da liquidação acontecer no Sinacor que costuma ocorrer as 16h." />}
                                    />
                                </div>
                                <div className="int-col">
                                    <Input
                                        data-testid="create-ipo-settlement-project-wallet"
                                        isRequired
                                        label="Carteira Durante Liquidação"
                                        name="settlementProjectWallet"
                                        placeholder="Carteira do IPO durante liquidação"
                                        value={settlementProjectWallet}
                                        onChange={(e) =>
                                            setSettlementProjectWallet(
                                                sanitizeName(e.target.value, true)
                                            )
                                        }
                                        hintIcon
                                        children={<Tooltip label="Número da carteira onde a posição será projetada durante o período de reserva e antes do início da negociação." />}
                                    />
                                </div>
                            </div>

                            <div className="int-row int-mb-6">
                                <div className="int-col">
                                    <DateTime
                                        label="Início de Negociação"
                                        name="startTradingDate"
                                        value={startTradingDate}
                                        setDateTime={setStartTradingDate}
                                        maxDate={new Date("9999-12-31")
                                            .toISOString()
                                            .substr(0, 10)}
                                        timeDefault="00:00"
                                        isRequired
                                        notShowTimeInput
                                        hintIcon
                                        children={<Tooltip label="Data do pregão onde iniciará a negociação do papel na B3, independente se haverá lock-up ou não." />}
                                    />
                                </div>
                                <div className="int-col">
                                    <DateTime
                                        label="Liquidação"
                                        name="settlementDate"
                                        value={settlementDate}
                                        setDateTime={setSettlementDate}
                                        maxDate={new Date("9999-12-31")
                                            .toISOString()
                                            .substr(0, 10)}
                                        timeDefault="00:00"
                                        isRequired
                                        notShowTimeInput
                                        hintIcon
                                        children={<Tooltip label="Data em que a posição será liquidada e estará disponível no Sinacor, mesmo que seja apenas as 16h, após a liquidação das operações de compra." />}
                                    />
                                </div>
                                <div className="int-col">
                                    <DateTime
                                        label="Projeção da Liquidação"
                                        name="settlementProjectDate"
                                        value={settlementProjectDate}
                                        setDateTime={setSettlementProjectDate}
                                        maxDate={new Date("9999-12-31")
                                            .toISOString()
                                            .substr(0, 10)}
                                        timeDefault="00:00"
                                        isRequired
                                        notShowTimeInput
                                        hintIcon
                                        children={<Tooltip label="Data em que o pagamento da reserva surgirá como um lançamento futuro no conta corrente do cliente, ou seja, projetado para D2." />}
                                    />
                                </div>
                            </div>
                        </div>

                        <Button
                            data-testid="create-ipo-submit"
                            isFullWidth
                            disabled={disabled}
                            type="submit"
                        >
                            {disabled ? <Loader /> : "Atualizar IPO"}
                        </Button>

                        {error && <Alert margin="int-mt-5">{errorMessage}</Alert>}
                    </form>
                </ModalBody>

                <ModalFooter>

                </ModalFooter>
            </Modal>

            {/* Modal - Mensagem: Apagar Registro de IPO */}
            <Modal
                header="Tem certeza que quer apagar o registro de IPO?"
                isOpen={showDeletedParameter}
                onClose={() => setShowDeletedParameter(false)}
            >
                <ModalBody>
                    <p>
                        Caso você apague esse registro de IPO, não será possível
                        desfazer essa ação.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() => setShowDeletedParameter(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        margin="int-ml-2"
                        onClick={(e) => submitParamDelete(e)}
                    >
                        Apagar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

import React, { TextareaHTMLAttributes } from 'react'

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    label?: string;
    isRequired?: boolean;
    margin?: string;
    rows?: number|undefined;
}

export function Textarea({ 
    name, 
    label, 
    isRequired = false,
    margin, 
    rows,
    ...rest 
}: TextareaProps) {
    return(
        <div className={`${margin}`} id={name}>
            {label && <label className="int-form-label">{label} {isRequired ? <span style={{ color: 'var(--int-colors-red-500)' }}>*</span> : ''}</label>}
            <textarea 
                className="int-form-control"
                id={name} 
                style={rows != null ? {minHeight: 'var(--int-sizes-10)'} : {}}
                rows={rows}
                name={name} 
                required={isRequired} 
                {...rest}
            ></textarea>
        </div>
    )
}

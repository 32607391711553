import React, { useState, useEffect } from 'react'

import { BsTrashFill } from "react-icons/bs"
import { Button, Checkbox, PolicyField} from '../../../components/Forms'
import { Link } from '../../../components/Link'
import { Tooltip } from '../../../components/Tooltip'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable } from '../../../components/Table'
import { NumberRangeColumnFilter } from '../../../components/Table/Filters'
import { DownloadXLSXButton } from '../../../components/Forms/DownloadXLSXButton'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Alert } from '../../../components/Alert'
import { Loader } from "../../../components/Loader"

import { formatParamValue, formatObjectNumberValues, formatDbNumber } from "../../../common/formatters"
import { getPolicyKeyTypeByName } from "../../../common/search"
import { parameterValueHeader } from "../../../common/parameterValueHeader"

import { API_HEADER } from "../../../constants/api";
import { UploadCSVModal } from '../../../components/UploadCSV/UploadCSVModal'

type Param = {
    name: string;
    friendlyName?: string;
    market: string;
    symbol?: string;
    comments?: string;
    value: string;
    description?: string;
}

interface LeverageFactorParametersProps {
    id: string;
    paramsLeverageFactor: any;
    keys: any;
    policyItems: any;
    policy: any;
    setNewParam: (any) => void;
    setSearchParam: (any) => void;
    setUpdatedParam: (value: Param | null) => void;
    setDeletedParam: (value: Param | null) => void;
    setErrorDeletedParam: (value: Error | null) => void;
    setErrorNewParam: (value: Error | null) => void;
    setShowSearchParamsModal: (value: boolean) => void;
    setShowFormUpdatedParameter: (value: boolean) => void;
    setShowFormAddParameter: (value: boolean) => void;
    setShowDeletedParameter: (value: boolean) => void;
    setDisabled: (value: boolean) => void;
    disabled: boolean;
    fetchData: () => void;
}

export function LeverageFactorParameters({
    id, 
    paramsLeverageFactor,
    keys,
    policyItems,
    policy,
    setNewParam,
    setSearchParam,
    setUpdatedParam,
    setDeletedParam,
    setErrorDeletedParam,
    setErrorNewParam,
    setShowSearchParamsModal,
    setShowFormUpdatedParameter,
    setShowFormAddParameter,
    setShowDeletedParameter,
    setDisabled,
    disabled,
    fetchData,
}: LeverageFactorParametersProps) {
    const [errorReplaceParamsCSV, setErrorReplaceParamsCSV] = useState<Error | null>()
    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)
    const [showReplaceParams, setShowReplaceParams] = useState(false)
    const [paramsLeverageFactorExport, setParamsLeverageFactorExport] = useState([])
    const [showLoaderFull, setShowLoaderFull] = useState(false)

    const [showDeleteMultipleParameter, setShowDeleteMultipleParameter] = useState(false)
    const [errorDeleteMultipleParam, setErrorDeleteMultipleParam] = useState<Error | null>()

    const [updateMultipleParamsValue, setUpdateMultipleParamsValue] = useState()
    const [showFormUpdateMultipleParams, setShowFormUpdateMultipleParams] = useState(false)
    const [showUpdateMultipleParamsMessage, setShowUpdateMultipleParamsMessage] = useState(false)
    const [errorUpdateMultipleParams, setErrorUpdateMultipleParams] = useState<Error | null>()

    const [allSymbols, setAllSymbols] = useState<string[]>([])
    const [selectedSymbols, setSelectedSymbols] = useState<string[]>([])


    const ParameterValue = (item) => {
        return ( item.policyId !== policy.id ? (
            <p>{formatParamValue(item)}</p>
        ) : (
            <p
                onClick={() => {
                    const param = { 
                        ...item, 
                        symbol: item.symbol ?? "",
                        comments: item.comments ?? "",
                        value: formatParamValue(item) 
                    }
                    setUpdatedParam(param)
                    setShowFormUpdatedParameter(true)
                }}
                style={{ cursor: "pointer" }}
            >
                <u>{formatParamValue(item)}</u>
            </p>
        ))
    }

    const PolicyName = (item) => {
        if ( item.policyId === policy.id)
            return item.policyName
        
        return (
            <Link href={`/policies/${item.policyId}`}>
                {item.policyName}
            </Link>
        )
    }

    const DeleteButton = (item) => {
        return (item.policyId === policy.id ? (
            <BsTrashFill
                data-testid="param-delete-button"
                size={20}
                color="var(--int-colors-orange-400)"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    setErrorDeletedParam(null);
                    setDeletedParam(item);
                    setShowDeletedParameter(true);
                }} 
            />
        ) : (
            <BsTrashFill
                size={20}
                color="#AAA"
            />
        ))
    }

    const getKeyPameter = (market, symbol) => {
        if (symbol) return `${market},${symbol}`

        return market
    }

    const allChecked = allSymbols.every(x => selectedSymbols.includes(x))

    const checkBoxColumnHandle = ({ target }) => {
        if (target.checked) {
            setSelectedSymbols(selectedSymbols.concat([target.value]))
        } else {
            setSelectedSymbols(selectedSymbols.filter(i => i !== target.value))
        }  
    }
    
    const allCheckBoxesHandle = ({ target }) => {
        if (target.checked) {
            const items = allSymbols.filter(x => !selectedSymbols.includes(x));
            setSelectedSymbols(selectedSymbols.concat(items))
        } else {
            setSelectedSymbols(selectedSymbols.filter(i => !allSymbols.includes(i)))
        }
    }

    const onFiltersChange = (rows) => {
        const symbols = rows.map(item => getKeyPameter(item.original.market, item.original.symbol))
        setAllSymbols(symbols)
        setSelectedSymbols([])
    }

    const columns = [
        {
            id: 'Seleção',
            Header: () => 
                <Checkbox
                    data-testid="table-checkbox-header"
                    onChange={allCheckBoxesHandle} 
                    isChecked={allChecked} 
                />,
            Cell: ({ row }) => 
                <Checkbox 
                    data-testid="table-checkbox-body"
                    value={getKeyPameter(row.original.market, row.original.symbol)} 
                    onChange={checkBoxColumnHandle} 
                    isChecked={selectedSymbols.some(i => i === getKeyPameter(row.original.market, row.original.symbol))} 
                />,
            width: '40px',
            minWidth: 40
        },
        {
            Header: 'Mercado',
            accessor: 'market',
        },
        {
            Header: 'Símbolo',
            accessor: 'symbol',
        },
        {
            id: 'Valor',
            Header: () => parameterValueHeader(keys, "Valor", "LeverageFactor"),
            accessor: props => props.value ? props.value * 100 : 0,
            Cell: ({ row }) => row.original.value ? ParameterValue(row.original): '-',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: 'Política',
            accessor: 'policyName',
            Cell: ({ row }) => PolicyName(row.original) ?? '-',
        },
        {
            Header: 'Comentário',
            accessor: 'comments',
        },
        {
            id: 'Remove',
            width: '1%',
            Header: '',
            Cell: ({ row }) => DeleteButton(row.original)
        },
    ]

    /** Substitui lista de parâmetros API */
    const submitReplaceLeverageFactorParams = (arr) => {
        setDisabled(true);
        setErrorReplaceParamsCSV(null);
        setShowLoaderFull(true);
        
        const parameters = arr.map((item) => ({ 
            ...formatObjectNumberValues(item, ['value']),
            name: 'LeverageFactor'
        }))
        
        const options = {
            method: "PUT",
            headers: API_HEADER,
            body: JSON.stringify(parameters)
        };

        fetch(`/api/Policy/${id}/LeverageFactor`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorReplaceParamsCSV(await response.json());
                    return setDisabled(false);
                }
                setShowReplaceParams(true);
                return setDisabled(false);
            })
            .catch((error) => console.log(error))
            .finally(() => setShowLoaderFull(false));
    };

    /** Apagar múltiplos parâmetros API*/
    const submitDeleteMultipleParameters = () => {
        setDisabled(true);
        setShowLoaderFull(true);
        setShowDeleteMultipleParameter(false);
        setSelectedSymbols([])

        const requestBody = selectedSymbols.map(item => { 
            const data = item.split(',')
            
            return {
                name: "LeverageFactor",
                market: data[0],
                symbol: data.length > 1 ? data[1] : null
            }
        })

        const options = {
            method: "DELETE",
            headers: API_HEADER,
            body: JSON.stringify(requestBody)
        };

        fetch(`/api/policy/${id}/parameters`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorDeleteMultipleParam(await response.json());
                    setShowDeleteMultipleParameter(true);
                    return setDisabled(false);
                }

                fetchData();
                return setDisabled(false);
            })
            .catch((error) => console.log(error))
            .finally(() => setShowLoaderFull(false));
    };

    /** Adiciona e altera vários parâmetros */
    const submitUpdateMultipleParams = (event) => {
        event.preventDefault()
        setShowLoaderFull(true)
        setShowFormUpdateMultipleParams(false)
        setSelectedSymbols([])
        setDisabled(true)

        const value = formatDbNumber(updateMultipleParamsValue)

        const parameters = selectedSymbols.map(item => { 
            const [market, symbol] = item.split(',')
            return {
                symbol: symbol, 
                value: value,
                market: market,
                name: "LeverageFactor",
                comments: paramsLeverageFactor?.find(x => x.symbol === symbol && x.policyId === policy.id)?.comments
            }
        })

        const options = {
            method: "POST",
            headers: API_HEADER,
            body: JSON.stringify(parameters),
        };

        fetch(`/api/policy/${id}/parameters`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorUpdateMultipleParams(await response.json());
                    setShowFormUpdateMultipleParams(true);
                    return setDisabled(false);
                }

                setShowUpdateMultipleParamsMessage(true);
                return setDisabled(false);
            })
            .catch((error) => console.log(error))
            .finally(() => setShowLoaderFull(false))
    };

    /** Baixar modelo de upload de parâmetro */
    async function handleModelDownload() {
        fetch('/assets/Intelirisk-ModeloListaParametros.xlsx', {
            headers: {
                'Content-Type': 'application/xlsx',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Intelirisk-ModeloListaParametros.xlsx`);

                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    useEffect(() => {
        if (paramsLeverageFactor?.length > 0){
            const params = paramsLeverageFactor
                .map(x => ({
                    Mercado: x.market,
                    Ativo: x.symbol,
                    Valor: x.value,
                    Comentario: x.comments
                }))

            setParamsLeverageFactorExport(params)
            setAllSymbols(paramsLeverageFactor?.map(x => getKeyPameter(x.market,x.symbol)))
        }
    }, [paramsLeverageFactor])
    return(
        <>
            <SectionWrapper
                headerTag="h3" 
                header="Fator de Alavancagem (Ações)"
                isAccordion 
            >
                { paramsLeverageFactor?.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum parâmetro foi encontrado
                    </p>
                ) : (
                    <SmartTable 
                        data={paramsLeverageFactor ?? []} 
                        columns={columns} 
                        removeScroll 
                        columnFilters
                        localStorageKey={'PolicyLeverageFactorParams'} 
                        onFiltersChange={onFiltersChange}
                        columnsResizable
                    />
                )}
                    
                <div className="int-d-flex int-align-items-center int-justify-content-between int-mt-4">
                    <div className="int-d-flex">
                        <Button 
                            variant="outline"
                            disabled={selectedSymbols?.length <= 0}
                            onClick={() => {
                                setUpdateMultipleParamsValue(null)
                                setShowFormUpdateMultipleParams(true);
                                setErrorUpdateMultipleParams(null);
                            }}
                        >
                            Editar selecionados
                        </Button>

                        <Button 
                            variant="outline" 
                            disabled={selectedSymbols?.length <= 0}
                            margin="int-ml-3"
                            onClick={() => {
                                setShowDeleteMultipleParameter(true);
                                setErrorDeleteMultipleParam(null);
                            }}
                        >
                            Apagar selecionados
                        </Button>

                        <Button
                            variant="outline"
                            margin="int-ml-3"
                            onClick={() => {
                                setNewParam((s) => ({...s, name: "LeverageFactor", market: "VIS_FRA", isNameDisabled:true, isMarketDisabled: true }))
                                setShowFormAddParameter(true);
                                setErrorNewParam(null);
                            }}
                        >
                            Adicionar
                        </Button>    
 
                        <Button
                            margin="int-ml-3"
                            onClick={() => {
                                setSearchParam((s) => ({...s, name: "LeverageFactor", friendlyName: "Fator de Alavancagem" }))
                                setShowSearchParamsModal(true);
                            }}>
                            Buscar
                        </Button>
                    </div>

                    <div  className="int-d-flex">
                        { paramsLeverageFactor?.length <= 0 ? (
                            <Button variant="outline" onClick={handleModelDownload} >
                                Baixar modelo
                            </Button>
                        ) : (
                            <DownloadXLSXButton
                                dataJs={paramsLeverageFactorExport}
                                fileName={`${policy?.name}_fator_de_alavancagem_${new Date(Date.now()).toLocaleDateString()}`}
                                variant="outline"
                            >
                                Exportar parâmetros
                            </DownloadXLSXButton>
                        )}
                        
                        <Button 
                            margin="int-ml-3" 
                            onClick={() => setIsCSVModalOpen(true)} 
                        >
                            Importar / Substituir parâmetros
                        </Button>
                    </div>
                </div>

                {errorReplaceParamsCSV?.message && (
                    <Alert margin="int-mt-4">
                        <p style={{ whiteSpace: "pre-wrap" }}>{errorReplaceParamsCSV?.message}</p>
                    </Alert>
                )}
            </SectionWrapper>

            {/* Modal - Mensagem Apagar Múltiplos Parâmetros */}
            <Modal 
                header="Tem certeza que quer apagar os parâmetros selecionados?"
                isOpen={showDeleteMultipleParameter} 
                onClose={() => setShowDeleteMultipleParameter(false)}
            >
                <ModalBody>
                    <p>
                        Caso você apague os parâmetros, não será possível desfazer 
                        essa ação.
                    </p>

                    {errorDeleteMultipleParam && (
                        <Alert>
                            Ocorreu um erro ao apagar o parâmetro: {errorDeleteMultipleParam.message}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() => setShowDeleteMultipleParameter(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        disabled={disabled}
                        margin="int-ml-2"
                        onClick={() => submitDeleteMultipleParameters()}
                    >
                        Apagar
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal - Formulário Adicionar / Atualizar parâmetro */}
            <Modal
                header="Editar parâmetros selecionados"
                isOpen={showFormUpdateMultipleParams}
                onClose={() => setShowFormUpdateMultipleParams(false)}
            >
                <form onSubmit={submitUpdateMultipleParams}>
                    <ModalBody>
                        <div className="int-row">
                            <div className="int-col int-pl-0">
                                <PolicyField
                                    isRequired
                                    label="Valor"
                                    keys={keys.flat()}
                                    keyName="LeverageFactor"
                                    name="value"
                                    value={updateMultipleParamsValue ?? ""}
                                    onChange={(e) => setUpdateMultipleParamsValue(e.target.value)}
                                />
                            </div>
                        </div>

                        {errorUpdateMultipleParams?.message && (
                            <Alert>
                                Ocorreu um erro: {errorUpdateMultipleParams?.message}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowFormUpdateMultipleParams(false)}
                        >
                            Cancelar
                        </Button>
                            <Button
                                margin="int-ml-2"
                                type='submit'
                            >
                                Atualizar parâmetros
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>


            {/* Modal - Mensagem Substituir Lista de Parâmetros */}
            <SimpleModal
                header="A lista de parâmetros foi atualizada!" 
                isOpen={showReplaceParams} 
                onClose={() => setShowReplaceParams(false)}
                bodyMessage="A lista de parâmetros que você adicionou foi substituída com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchData();
                        setShowReplaceParams(false);
                    }
                }}
            />

            {/* Modal - Mensagem Adicionar / alterar parâmetros */}
            <SimpleModal
                header="Os parâmetros foram alterados com sucesso!" 
                isOpen={showUpdateMultipleParamsMessage} 
                onClose={() => setShowUpdateMultipleParamsMessage(false)}
                bodyMessage="A lista de parâmetros que você selecionou foi alterada com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchData();
                        setShowUpdateMultipleParamsMessage(false);
                    }
                }}
            />

            <UploadCSVModal
                isModalOpen={isCSVModalOpen}
                onHide={() => setIsCSVModalOpen(false)}
                onAdd={submitReplaceLeverageFactorParams}
                parameterName="Fator de Alavancagem"
                parameterMarket='VIS_FRA'
                policyKeyType={getPolicyKeyTypeByName(keys.flat(), "LeverageFactor")}
                model={"Parametros"}
                policyApplication={true}
                header={"Fator de Alavancagem"}
            />

            {showLoaderFull && <Loader full />}
        </>
    )
}

import React, { useEffect, useState } from 'react'

import { H4 } from '../../components/Typography'
import { Button, Field } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import { SmartTable } from '../../components/Table'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'
import { formatDecimal, formatDate, formatInteger } from '../../common/formatters';
import { objToQueryString, dateQueryParse } from '../../common/api.js';

import { useQueryParams } from '../../hooks/useQueryParams'
import { FiSearch } from 'react-icons/fi'
import { SimpleModal } from '../../components/Modal/SimpleModal'

type QueryObject = {
    accountOrigin?: string;
    accountTarget?: string;
    symbol?: string;
    dtRequestStart?: string;
    dtRequestEnd?: string;
}

export function OrdersRespecifiedHistory() {
    const { setParam, updateQuery } = useQueryParams();
    const [queryObject, setQueryObject] = useState<QueryObject>();
    const [accountOrigin, setAccountOrigin] = useState(setParam("accountOrigin")); 
    const [accountTarget, setAccountTarget] = useState(setParam("accountTarget")); 
    const [symbol, setSymbol] = useState(setParam("symbol")); 
    const [initialRequestDate, setInitialRequestDate] = useState(setParam("dtRequestStart")); 
    const [finalRequestDate, setFinalReleaseDate] = useState(setParam("dtRequestEnd"));
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filtering, setFiltering] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState<[]>();
    const [searched, setSearched] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(true);   
    const today = new Date().toISOString().substr(0, 10);

    useEffect(() => {
        if (queryObject) {
            (async function () {
                await fetchOrdersRespecifiedData()
                setLoading(false)
            })();
        };
    }, [queryObject]);


    const columns = [
        {
            Header: 'Conta Origem',
            accessor: 'originAccount',
        },     
        {
            Header: 'Conta Destino',
            accessor: 'targetAccount'
        },
        {
            Header: 'Ativo',
            accessor: 'symbol'
        },
        {
            Header: 'Motivo',
            accessor: 'reason'
        },
        {
            Header: 'Quantidade',
            accessor: ({ quantity }) => formatDecimal(quantity)
        },
        {
            Header: 'Num Ordem',
            accessor: ({ numSeqOrder }) => formatInteger(numSeqOrder)
        },
        {
            Header: 'Dt. Movimento',
            accessor: ({ movementDate }) => formatDate(movementDate),
            width: 175
        },
    ];

    const fetchOrdersRespecifiedData = async () => {
        const apiQueryString = objToQueryString({
            ...queryObject,
            accountOrigin: queryObject?.accountOrigin,
            accountTarget: queryObject?.accountTarget,
            symbol: queryObject?.symbol,
            dtRequestStart: dateQueryParse(queryObject?.dtRequestStart),
            dtRequestEnd: dateQueryParse(queryObject?.dtRequestEnd)
        });
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {          
            setShowFilterModal(false);
            setFiltering(false);

            const response = await fetch(`/api/RespecifiedOrders?${apiQueryString}`);

            if(!response.ok) {
                const error = await response.json();
                throw error;
            }

            const data = await response.json();
            setResults(data);
        } catch(err) {
            setError(true);
        };      
    }
    
    const cleanFilters = () => {
        setAccountOrigin("");
        setAccountTarget("");
        setSymbol("");
        setInitialRequestDate("");
        setFinalReleaseDate("");
        updateQuery(" ");
    }

    const setQueryObjectFilters = () => {

        const queryStringFilters = {
            accountOrigin: accountOrigin,
            accountTarget: accountTarget,
            symbol: symbol,
            dtRequestStart: initialRequestDate,
            dtRequestEnd: finalRequestDate
        };

        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();

        setQueryObject(queryStringFilters);
        updateQuery(objToQueryString(queryStringFilters));
    }

    const handleOrdersRespecifiedSearch = async event => {
        event.preventDefault();
        setLoading(true);
        setError(false);

        if (!searched)
        setSearched(true);

        setQueryObjectFilters();
    }


    const renderFilterElements = () => {
        return(
            <form onSubmit={e => handleOrdersRespecifiedSearch(e)}>
                <div className="int-mt-4 int-row">
                    <div className="int-col">
                        <H4>Data de Movimentação</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <DateTime
                                    label="De"
                                    name="initialRequestDate"
                                    value={initialRequestDate}
                                    setDateTime={setInitialRequestDate}
                                    maxDate={today}
                                    timeDefault="00:00"
                                    notShowTimeInput
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <DateTime
                                    label="Para"
                                    name="finalRequestDate"
                                    value={finalRequestDate}
                                    setDateTime={setFinalReleaseDate}
                                    minDate={initialRequestDate ? initialRequestDate.substr(0, 10) : ''}
                                    maxDate={today}
                                    timeDefault="23:59"
                                    notShowTimeInput
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="int-mt-4 int-row">
                    <div className="int-col">
                        <H4>Conta Origem</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <Field
                                    data-testid="ordersRespecifiedHistory-originAccount"
                                    format="Number"
                                    value={accountOrigin || ''}
                                    onChange={(e) => setAccountOrigin(e?.target?.value.trimStart())}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="int-mt-4 int-row">
                    <div className="int-col">
                        <H4>Conta Destino</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <Field
                                    data-testid="ordersRespecifiedHistory-targetAccount"
                                    format="Number"
                                    value={accountTarget || ''}
                                    onChange={(e) => setAccountTarget(e?.target?.value.trimStart())}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="int-mt-4 int-row">
                    <div className="int-col">
                        <H4>Ativo</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <Field
                                    data-testid="ordersRespecifiedHistory-symbol"
                                    format="Symbol"
                                    value={symbol || ''}
                                    onChange={(e) => setSymbol(e?.target?.value.trimStart())}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {filtering && ( 
                    <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
                )}
            </form>
	)
    }
    
    return(
        <SectionWrapper header="Histórico de Ordens Reespecificadas"        
        searchButton={
            <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
        }>
                <SimpleModal 
                    header="Filtros de busca"
                    isOpen={showFilterModal}
                    onClose={() => setShowFilterModal(false)}
                    bodyMessage={renderFilterElements()}
                    footerButtons={[
                        {
                            label: 'Limpar Todos os filtros',
                            onClick:() => cleanFilters(),
                            variant: 'outline'
                        },   
                        {
                            label: 'Buscar',
                            onClick: (e) => {
                                handleOrdersRespecifiedSearch(e)
                            },
                            margin: 'int-ml-2'
                        }
                    ]}
                />                        

            {loading && <Loader flex />}

            {error && ( 
                <Alert>Ocorreu um erro na busca</Alert>
            )}
            
            { loading || error || filtering ? '' : results && results.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : results && (
                <SmartTable 
                columnFilters 
                columns={columns} 
                data={results} 
                gapColumnsFilter={2}
                columnsResizable  
                lockHeader = {true}
                localStorageKey={'OrdersRespecifiedHistorySearch'}
                 />              
            )}
        </SectionWrapper>      
    )
}

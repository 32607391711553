import React from 'react'
import { IconContext } from 'react-icons';
import { IconType } from 'react-icons/lib';
import { Link as RouterLink } from 'react-router-dom';

type Color = 'default' | 'gray';

interface LinkProps  {
    className?: string ;
    children: string | number | any ;
    href: string;
    color?: Color;
}

export function Link({ href, children, className, color = 'default' }: LinkProps) {
    const colors = {
        default: 'var(--int-colors-cyan-500)',
        gray: 'var(--int-colors-gray-400)'
    }

    return (
        <RouterLink 
            style={{
                color: colors[color]
            }} 
            to={href}
            className={className}
        >
                {children}
        </RouterLink>
    )
}
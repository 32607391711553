import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import { H3 } from '../../../components/Typography'
import { Button, Select } from '../../../components/Forms';
import { Tabs, TabItem } from '../../../components/Tabs';
import { Dropdown, DropdownItem } from '../../../components/Dropdown'
import { SmartTable } from '../../../components/Table'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Link } from '../../../components/Link';
import { Tooltip } from "../../../components/Tooltip";
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from '../../../components/Alert'
import {
    Breadcrumb, 
    BreadcrumbItem,
} from "../../../components/Breadcrumb"

import { formatParamValue, formatInteger, formatDecimal, formatDateTime } from "../../../common/formatters";
import { parameterValueHeader } from "../../../common/parameterValueHeader";
import { getPolicyParentNames } from "../../../common/search";
import { API_HEADER } from "../../../constants/api";
import {isAdmin} from "../../../services/auth";

export function Policy({ account, id, handlePolicyChange, allAccordionsOpen, updateAllGrids, updatePolicyGrid, autoOpenAccordion }) {
    const history = useHistory();

    const [policy, setPolicy] = useState({ parameters: [] });
    const [policyItems, setPolicyItems] = useState([]);
    const [sortedPolicyItems, setSortedPolicyItems] = useState([]);

    const [associatedPolicy, setAssociatedPolicy] = useState();

    const [paramsCommon, setParamsCommon] = useState([]);
    const [paramsCustomBmf, setParamsCustomBmf] = useState([]);
    const [keys, setKeys] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [error, setError] = useState(false);
    const [associatedError, setAssociatedError] = useState(false);
    const [policyError, setPolicyError] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const ParameterValue= (row, value, paramName, formatter) => {
        if (value == null){
            return "-"
        } else if (row.original.policyIds[paramName] != policy.id) {
            return (
                <Link color="gray" href={`/policies/${row.original.policyIds[paramName]}`}>
                    {formatter(value)}
                </Link>
            )
        } else {
            return formatter(value)
        }  
    }

    const bmfColumns = [
        {
            Header: 'Símbolo',
            accessor: props => props.key.symbol ?? "TODOS",
        },
        {
            Header: () => parameterValueHeader(keys, 'Qt Max. de Exposição - Compra', 'MaxOpenSizeBuy'),
            accessor: 'values.MaxOpenSizeBuy',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOpenSizeBuy', formatInteger),
            columnAlign: 'right',
        },
        {
            Header: () => parameterValueHeader(keys, 'Qt Max. de Exposição - Venda', 'MaxOpenSizeSell'),
            accessor: 'values.MaxOpenSizeSell',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOpenSizeSell', formatInteger),
            columnAlign: 'right',
        },
        {
            Header: () => parameterValueHeader(keys, 'Tam Max. de Ordem', 'MaxOrderSize'),
            accessor: 'values.MaxOrderSize',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOrderSize', formatInteger),
            columnAlign: 'right',
        },
        {
            Header: () => parameterValueHeader(keys, 'Margem por contrato', 'MarginByContract'),
            accessor: 'values.MarginByContract',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MarginByContract', formatDecimal),
            columnAlign: 'right',
        }
    ]

        
    useEffect(() => {
        if(updateAllGrids || updatePolicyGrid){            
            fetchPolicyData()
        }
    }, [updateAllGrids, updatePolicyGrid]);

    useEffect(() => {
        const fetchData = async () => {
            fetch(`/api/client/${account}/policy`)
                .then(async (res) => {
                if (res.status !== 200) {
                    setPolicyError(res);
                } else {
                    const data = await res.json();
                    await handlePolicy(data.id);
                }
                })
                .catch((error) => console.log(error));

            fetch("/api/policy/keys")
                .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    setKeys(data);
                }
                })
                .catch((error) => console.log(error));

            fetch("/api/policy")
                .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    setPolicyItems(data);
                }
            })
                .catch((error) => console.log(error));
        };

        fetchData();
    }, [account]);

    useEffect(() => {
        const paramsCommonFiltered = policy?.parameters?.filter(p => p.category === 'Common')
        setParamsCommon(paramsCommonFiltered)

        const paramsCustomBmfFiltered = policy?.bmfParameters
        setParamsCustomBmf(paramsCustomBmfFiltered)
    }, [keys, policy]);

    useEffect(() => {
        const sortedArray = policyItems?.filter(x => !!x.shared && !x.isInternal && x.policyType === "Client");

        setSortedPolicyItems(sortedArray);
    }, [policyItems]);

    const fetchPolicyData = () => {
        fetch(`/api/client/${account}/policy`)
            .then(async (res) => {
                if (res.status !== 200) {
                    setPolicyError(res);
                } else {
                    const data = await res.json();
                    await handlePolicy(data.id);
                }
            })
            .catch((error) => console.log(error));
    }

    async function handlePolicy(id) {
        fetch(`/api/policy/${id}`)
            .then(async (res) => {
                if (res.status !== 200) {
                    setPolicyError(true);
                } else {
                    const data = await res.json();
                    setPolicy(data);
                }
            })
            .catch((error) => console.log(error));
    }

    /** Customiza a política atual para o cliente */
    const customizeClientPolicy = () => {
        setError(false);
        setDisabled(true);

        const options = {
            method: "POST",
            headers: API_HEADER
        };

        fetch(`/api/client/${account}/policy/`, options)
            .then(async (response) => {
                if (response.status === 200) {
                    const data = await response.json();
                    return history.push(`/policies/${data.id}`);
                }
                return setError(true);
            })
            .catch((error) => console.log(error));

        setDisabled(false)
    };

    const handlePolicyAssociated = (event) => {
        event.preventDefault();
        setDisabled(true)
        setAssociatedError(false);

        if (associatedPolicy === undefined) {
            setDisabled(false);
            return setAssociatedError({message: 'Selecione uma política para associar ao cliente.'})
        }

        const requestBody = {
            account,
            policyId: parseFloat(associatedPolicy)
        }

        const requestOptions = {
            method: "POST",
            headers: API_HEADER,
            body: JSON.stringify(requestBody),
        };

        fetch(`/api/client/`, requestOptions)
            .then(async (response) => {
                if (response.status !== 200) {
                    return setAssociatedError(true)
                }
                setShowModal(false);
                return setShowSuccessModal(true);
            })
            .catch(error => console.log(error));

        setDisabled(false);
    };

    return (
        <>
            <SectionWrapper 
                mt="12" 
                id={id}
                headerTag="h3"
                header={`Política: ${policy.name} ${policy.shared ? "(Compartilhada)" : "(Privada)"}`}
                isAccordion
                allAcordionsOpen={allAccordionsOpen}
                autoOpenAccordion={autoOpenAccordion}
            >
                <div className="int-d-flex int-justify-content-between">
                    <div>
                        <Breadcrumb>
                            {getPolicyParentNames(policyItems, policy).map(data => (
                                <BreadcrumbItem key={data.id}>
                                    <Link href={`/policies/${data.id}`}>
                                        {data.name}
                                    </Link>
                                </BreadcrumbItem>
                            ))}
                            
                            {getPolicyParentNames(policyItems, policy).length > 0 && (
                                <BreadcrumbItem>
                                    <Link href={`/policies/${policy?.id}`}>{policy?.name}</Link>
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                    </div>
                    
                    <Dropdown margin="int-mb-4" align="right" label="Ações" isDisabled={!isAdmin()}>
                        {policy.shared ? (
                            <DropdownItem data-testid="customize-policy-button" disabled={disabled} onClick={customizeClientPolicy}>
                                Customizar
                            </DropdownItem>
                        ) : (
                            <DropdownItem>
                                <Link href={`/policies/${policy.id}`}>
                                    Editar
                                </Link>
                            </DropdownItem>
                        )}

                        <DropdownItem data-testid="change-policy-button" onClick={() => setShowModal(true)}>
                            Alterar
                        </DropdownItem>
                    </Dropdown>
                </div>

                {error && (
                    <Alert margin="int-mt-2">
                        Ocorreu um erro ao customizar política do cliente!
                    </Alert>
                )}

                {policyError && (
                    <p className="int-mt-5 int-text-center">
                        Não foi encontrado nenhuma política vinculada com o cliente!
                    </p>
                )}

                {!policyError && policy?.parameters?.lenght !== 0 && (
                    <>
                        <H3 margin="int-mb-2">Parâmetros Gerais</H3>

                        <Tabs my="4">
                            {keys.map((group, index) => (
                                <TabItem
                                    key={index}
                                    label={group[0].groupName}
                                    as={HashLink} 
                                    route={`/client/${account}#${group[0].groupName}`} 
                                    disabled={!paramsCommon.some(k => k.groupName == group[0].groupName)}
                                />     
                            ))}
                        </Tabs>

                        <table className="int-table">
                            <thead>
                                <tr>
                                    <th style={{ width: "16%" }}>Parâmetro</th>
                                    <th/>
                                    <th style={{ width: "10%" }}>Mercado</th>
                                    <th style={{ width: "10%" }}>Símbolo</th>
                                    <th style={{ width: "16%", textAlign: 'right' }}>Valor</th>
                                    <th style={{ width: "16%" }}>Política</th>
                                    <th style={{ width: "20%" }}>Comentário</th>
                                    <th style={{ width: "12%" }}>Validade</th>
                                </tr>
                            </thead>

                            <tbody>
                                {paramsCommon?.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {(item.groupName !== paramsCommon[index - 1]?.groupName) && (
                                            <tr 
                                                id={item.groupName}
                                                className="int-table-divider"
                                            >
                                                <td colSpan={8}>{item.groupName}</td>
                                            </tr>
                                        )}

                                        <tr key={index}>
                                            <td>{item.friendlyName}</td>
                                            <td><Tooltip label={item.description} /></td>
                                            <td>{item?.market}</td>
                                            <td>{item?.symbol}</td>
                                            <td style={{ textAlign: 'right' }}>{formatParamValue(item)}</td>
                                            
                                                {isAdmin() ? 
                                                (
                                                    <td>
                                                        <Link href={`/policies/${item.policyId}`}>
                                                            {item.policyName}
                                                        </Link>
                                                    </td>
                                                ) :
                                                (
                                                    <td>
                                                        {item.policyName}
                                                    </td>
                                                )}
                                            
                                            <td>{item?.comments}</td>
                                            <td>{formatDateTime(item?.expirationDate)}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>

                        <H3 margin="int-mb-2 int-mt-5">Parâmetros BMF</H3>
                        {paramsCustomBmf?.length <= 0 ? (
                            <p className="int-mt-5 int-text-center">
                                Nenhum paramêtro BMF foi encontrado
                            </p>
                        ) : (
                            <SmartTable 
                                data={paramsCustomBmf ?? []} 
                                columns={bmfColumns} 
                                localStorageKey={'ClientCustomBmfParams'} 
                                removeScroll 
                            />
                        )}
                    </>
                )}
            </SectionWrapper>
  
            <Modal
                isOpen={showModal} 
                header="Alterar Política" 
                onClose={() => setShowModal(false)}
            >
                <form onSubmit={event => handlePolicyAssociated(event)}>
                    <ModalBody>
                        <Select
                            data-testid="change-policy-select"
                            isRequired
                            label="Selecione uma política"
                            name="policyId"
                            value={associatedPolicy}
                            onChange={event => setAssociatedPolicy(event.target.value)}
                        >
                            <option hidden>Selecione um nome</option>
                            {sortedPolicyItems.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                        {associatedError && (
                            <Alert>{associatedError.message ? associatedError.message : 'Ocorreu um erro ao alterar a política do cliente! Tente novamente mais tarde.'}</Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" onClick={() => { setShowModal(false); setAssociatedError(false) }}>
                            Cancelar
                        </Button>
                        <Button data-testid="change-policy-submit" margin="int-ml-2" type="submit" disabled={disabled}>
                            Alterar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Mensagem Adicionar Parâmetro  */}
            <SimpleModal
                data-testid="change-policy-success-modal" 
                header="A política foi atualizada!"
                isOpen={showSuccessModal} 
                onClose={() => { 
                    handlePolicyChange(); 
                    fetchPolicyData(); 
                    setShowSuccessModal(false) 
                }}
                bodyMessage="A política vinculada ao cliente foi atualizada com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        handlePolicyChange();
                        fetchPolicyData();
                        setShowSuccessModal(false);
                    }
                }}
            />
        </>
    );
};

import React, { useEffect, useMemo, useState } from 'react';

import { Link } from '../../../components/Link'
import { SmartTable } from '../../../components/Table'
import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from '../../../components/Alert';
import { isAdmin } from '../../../services/auth';

type Operator = {
    code: string,
    description?: string,
    advisorsString: string,
    clientsString: string,
    policyId: number,
    policyName: string,
}

export function OperatorsList() {
    const [operators, setOperators] = useState<Operator[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error>();

    async function fetchOperators() {
        try {
            setLoading(true)

            const response = await fetch('/api/enteringTrader')

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()

            const operatorsList = data?.map(item => ({
                ...item,

                description: item?.description ? item.description.length > 26
                ? `${item.description.substring(0, 26)}...` 
                : item.description : '-', 
                
                advisorsString: `${item?.advisors.sort().slice(0, 6).join(', ')}${item?.advisors.length > 6 ? '...' : ''}` || '-',
                clientsString: `${item?.clients.sort().slice(0, 6).join(', ')}${item?.clients.length > 6 ? '...' : ''}` || '-'
            }))

            setOperators(operatorsList)
        } catch (err) {
            setError(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => { fetchOperators() }, [])


    const columns = isAdmin() ? 
    useMemo(() => [
        {
            Header: 'Código',
            accessor: 'enteringTraderId',
            Cell: ({ value }) => <Link href={`/operators/${value}`}>{value}</Link>
        },
        {
            Header: 'Descrição',
            accessor: 'description',
        },
        {
            Header: 'Assessores',
            accessor: 'advisorsString',
        },
        {
            Header: 'Clientes',
            accessor: 'clientsString',
        },
        {
            Header: 'Política',
            accessor: 'policyName',
            Cell: ({ row, value }) => <Link href={`/policies/${row.original.policyId}`}>{ value }</Link>
        }
    ], []) :
    useMemo(() => [
        {
            Header: 'Código',
            accessor: 'enteringTraderId',
            Cell: ({ value }) => <Link href={`/operators/${value}`}>{value}</Link>
        },
        {
            Header: 'Descrição',
            accessor: 'description',
        },
        {
            Header: 'Assessores',
            accessor: 'advisorsString',
        },
        {
            Header: 'Clientes',
            accessor: 'clientsString',
        },
        {
            Header: 'Política',
            accessor: 'policyName'
        }
    ], [])

    return (
        <SectionWrapper header="Lista de Operadores">
            { loading && <Loader margin="int-mt-5" /> }

            { error?.message && (
                <Alert 
                    margin="int-mt-5"
                >
                    {error.message}
                </Alert>
            )}

            { loading || !!error ? '' : operators.length <= 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum operador foi encontrado
                </p>
            ) : <SmartTable data={operators} columns={columns} localStorageKey={'OperatorList'} columnFilters /> }
        </SectionWrapper>
    )
}
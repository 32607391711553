export function currency(e) {
  let value = e.currentTarget.value;

  if (value.length == 1) {
    value = value.replace(/[^-0-9]$/g, ""); 
  } else if (value.length == 2) {
    value = value.replace(/[^0-9]$/g, ""); 
  } else if (value.length >= 3) {
    value = value.replace(/[^0-9]$/g, ""); 
    value = value.replace(/\B[^0-9]{1,}\B/g, "");        
    value = value.replace(/\b[^0-9]{1,}\b/g, "");          
  }

  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  e.currentTarget.value = value;

  return e;
}

export function integer(e) {
  let value = e.currentTarget.value;

  if (value.length == 1) {
    value = value.replace(/[^-0-9]$/g, ""); 
  } else if (value.length == 2) {
    value = value.replace(/[^0-9]$/g, ""); 
  } else if (value.length >= 3) {
    value = value.replace(/[^0-9]$/g, ""); 
    value = value.replace(/\B[^0-9]{1,}\B/g, "");        
    value = value.replace(/\b[^0-9]{1,}\b/g, "");          
  }

  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  let int = value.replace(/\D/g, "");

    if (int > 2147483647)
        e.currentTarget.value = "";
    else
        e.currentTarget.value = value;

  return e;
}

export function number(e) {
    let value = e.currentTarget.value;

    value = value.replace(/\D/g, "");

    if (value > 2147483647)
        e.currentTarget.value = "";
    else
        e.currentTarget.value = value;

    return e;
}

export function symbol(e) {
  let value = e.currentTarget.value;

    value = value.replace(/[^a-z0-9]/gi,"");

    e.currentTarget.value = value;
    return e;
}
import React, { ReactNode } from 'react'

import "./styles.css"

interface BreadcrumbProps {
    children: ReactNode,
    margin?: string
}

export function Breadcrumb({ children, margin }: BreadcrumbProps) {
    return(
        <ol className={`int-breadcrumb ${margin}`}>
            {children}
        </ol>
    )
}
import React, { cloneElement, useEffect, useState } from 'react'

import { H4 } from '../../components/Typography'
import { Button, Field } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import { SmartTable } from '../../components/Table'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'

import { objToQueryString, dateQueryParse } from '../../common/api.js';
import { formatDate, formatDecimal, formatInteger } from '../../common/formatters';

import { useQueryParams } from '../../hooks/useQueryParams'
import { FiSearch } from 'react-icons/fi'
import { SimpleModal } from '../../components/Modal/SimpleModal'

type QueryObject = {
    account?: string;
    dueDateStart?: string;
    dueDateEnd?: string;
    tradingDateStart?: string;
    tradingDateEnd?: string;
    settlementDateStart?: string;
    settlementDateEnd?: string;
}

export function TesouroDiretoHistory() {
    const { setParam, updateQuery } = useQueryParams()
    const [queryObject, setQueryObject] = useState<QueryObject>();

    const [tesouroDireto, setTesouroDireto] = useState<[]>()

    const [account, setAccount] = useState(setParam("account"));
    const [dueDateInitial, setDueDateInitial] = useState(setParam("dueDateStart"));
    const [dueDateFinal, setDueDateFinal] = useState(setParam("dueDateEnd"));
    const [tradingDateInitial, setTradingDateInitial] = useState(setParam("tradingDateStart"));
    const [tradingDateFinal, setTradingDateFinal] = useState(setParam("tradingDateEnd"));
    const [settlementDateInitial, setSettlementDateInitial] = useState(setParam("settlementDateStart"));
    const [settlementDateFinal, setSettlementDateFinal] = useState(setParam("settlementDateEnd"));
    
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(true);
    const [searched, setSearched] = useState(false)

    useEffect(() => {
        if (queryObject) {

            (async function () {
                await fetchTesouroDireto()
                setLoading(false)
            })();
        }
    }, [queryObject])


    const columns = [
        {
            Header: 'Conta',
            accessor: 'account'
        },        
        {
            Header: 'Código Título',
            accessor: 'codigoTitulo',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },        
        {
            Header: 'Número Protocolo',
            accessor: 'protocolNumber',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },
        {
            Header: 'Tipo',
            accessor: 'type',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },
        {
            Header: 'C/V',
            accessor: 'side',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },
        {
            Header: 'Tipo de Movimentação',
            accessor: 'description',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },
        {
            Header: 'Quantidade',
            accessor: 'quantity',
            Cell: ({ value }) => formatInteger(value)
        },
        {
            Header: 'Valor IOF',
            accessor: 'iofValue',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Valor IR',
            accessor: 'irValue',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Valor Original',
            accessor: 'originalValue',
            Cell: ({ value }) => formatDecimal(value)
        },      
        {
            Header: 'Valor da Transação',
            accessor: 'transactionValue',
            Cell: ({ value }) => formatDecimal(value)
        },     
        {
            Header: 'Valor Total',
            accessor: 'totalValue',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Data de Operação',
            accessor: ({ tradingDate }) => formatDate(tradingDate)
        },
        {
            Header: 'Data de Liquidação',
            accessor: ({ settlementDate }) => formatDate(settlementDate)
        },
        {
            Header: 'Data de Vencimento',
            accessor: ({ dueDate }) => formatDate(dueDate)
        }
    ]

    const apiQueryString = objToQueryString({
        ...queryObject,
        account: queryObject?.account,
        dueDateStart: dateQueryParse(queryObject?.dueDateStart),
        dueDateEnd: dateQueryParse(queryObject?.dueDateEnd),
        tradingDateStart: dateQueryParse(queryObject?.tradingDateStart),
        tradingDateEnd: dateQueryParse(queryObject?.tradingDateEnd),
        settlementDateStart: dateQueryParse(queryObject?.settlementDateStart),
        settlementDateEnd: dateQueryParse(queryObject?.settlementDateEnd)
    });

    const fetchTesouroDireto = async () => {
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {
            setShowFilterModal(false);
            setFiltering(false);
            const response = await fetch(`/api/tesouroDireto?${apiQueryString}`)

            if (!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()
            setTesouroDireto(data)

        } catch (err) {
            setError(true)
        }
    }

    const cleanFilters = () => {
        setAccount("");
        setDueDateInitial("");
        setDueDateFinal("");
        setTradingDateInitial("");
        setTradingDateFinal("");
        setSettlementDateInitial("");
        setSettlementDateFinal("");

        updateQuery(" ")
    }

    const setQueryObjectFilters = () => {
        const queryStringFilters = {
            account: account,
            dueDateStart: dueDateInitial,
            dueDateEnd: dueDateFinal,
            tradingDateStart: tradingDateInitial,
            tradingDateEnd: tradingDateFinal,
            settlementDateStart: settlementDateInitial,
            settlementDateEnd: settlementDateFinal
        };
        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();

        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const handleSearch = async event => {
        event.preventDefault()
        setLoading(true)
        setError(false)

        if (!searched)
            setSearched(true)

        setQueryObjectFilters()
    }

    const setFinalDueDateOnBlur = () => {
        if (dueDateFinal === '' || dueDateFinal === undefined) {
            const date = new Date().toISOString().substr(0, 10) + "T" + "23:59";
            setDueDateFinal(date)
        }
    }

    const setFinalTradingDateOnBlur = () => {
        if (tradingDateFinal === '' || tradingDateFinal === undefined) {
            const date = new Date().toISOString().substr(0, 10) + "T" + "23:59";
            setTradingDateFinal(date)
        }
    }

    const setFinalSettlementDateOnBlur = () => {
        if (settlementDateFinal === '' || settlementDateFinal === undefined) {
            const date = new Date().toISOString().substr(0, 10) + "T" + "23:59";
            setSettlementDateFinal(date)
        }
    }
    const renderFilterElements = () => {
        return(
            <form onSubmit={e => handleSearch(e)}>
            <div className="int-mt-4 int-row">
                <div className="int-col">
                    <H4>Conta</H4>
                    <div className="int-row">
                        <div className="int-col">
                            <Field
                                data-testid="tesouro-direto-history-client"
                                format="Number"
                                value={account || ''}
                                onChange={(e) => setAccount(e?.target?.value.trimStart())}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="int-mt-4 int-col">
                <div className="int-col">
                    <H4>Data de Operação</H4>
                    <div className="int-row">
                        <div className="int-col">
                            <DateTime
                                label="De"
                                name="tradingDateInitial"
                                value={tradingDateInitial}
                                setDateTime={setTradingDateInitial}
                                onBlurDate={setFinalTradingDateOnBlur}
                                timeDefault="00:00"
                                notShowTimeInput
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <DateTime
                                label="Para"
                                name="tradingDateFinal"
                                value={tradingDateFinal}
                                setDateTime={setTradingDateFinal}
                                timeDefault="23:59"
                                notShowTimeInput
                            />
                        </div>
                    </div>
                </div>

                <div className="int-col int-mt-4">
                    <H4>Data de Liquidação</H4>
                    <div className="int-row">
                        <div className="int-col">
                            <DateTime
                                label="De"
                                name="settlementDateInitial"
                                value={settlementDateInitial}
                                setDateTime={setSettlementDateInitial}
                                onBlurDate={setFinalSettlementDateOnBlur}
                                timeDefault="00:00"
                                notShowTimeInput
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <DateTime
                                label="Para"
                                name="settlementDateFinal"
                                value={settlementDateFinal}
                                setDateTime={setSettlementDateFinal}
                                timeDefault="23:59"
                                notShowTimeInput
                            />
                        </div>
                    </div>
                </div>

                <div className="int-col int-mt-4">
                    <H4>Data de Vencimento</H4>
                    <div className="int-row">
                        <div className="int-col">
                            <DateTime
                                label="De"
                                name="dueDateInitial"
                                value={dueDateInitial}
                                setDateTime={setDueDateInitial}
                                onBlurDate={setFinalDueDateOnBlur}
                                timeDefault="00:00"
                                notShowTimeInput
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <DateTime
                                label="Para"
                                name="dueDateFinal"
                                value={dueDateFinal}
                                setDateTime={setDueDateFinal}
                                timeDefault="23:59"
                                notShowTimeInput
                            />
                        </div>
                    </div>
                </div>
            </div>
            {filtering && ( 
                <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
            )}

        </form>

        )
    }
    return (
        <SectionWrapper 
                header="Histórico de Movimentação de Tesouro Direto"        
                searchButton={
                    <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
                }>
            <SimpleModal 
                    header="Filtros de busca"
                    isOpen={showFilterModal}
                    onClose={() => setShowFilterModal(false)}
                    bodyMessage={renderFilterElements()}
                    footerButtons={[
                        {
                            label: 'Limpar Todos os filtros',
                            onClick:() => cleanFilters(),
                            variant: 'outline'
                        },   
                        {
                            label: 'Buscar',
                            onClick: (e) => {
                                handleSearch(e)
                            },
                            margin: 'int-ml-2'
                        }
                    ]}
                />                        
            {loading && <Loader flex />}

            {error && (
                <Alert>Ocorreu um erro na busca</Alert>
            )}

            {loading || error || filtering ? '' : tesouroDireto && tesouroDireto.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : tesouroDireto && (
                <SmartTable 
                gapColumnsFilter={2}
                columnsResizable  
                lockHeader = {true}
                columnFilters 
                columns={columns} 
                data={tesouroDireto} 
                localStorageKey={'TesouroDiretoHistory'}
                 />
            )}
        </SectionWrapper>
    )
}

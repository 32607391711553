import React, { useEffect, useState } from "react";

import { Balance } from '../Balance'
import { NewOrder } from '../NewOrder'
import { Custody } from '../Custody'
import { TermCustody } from '../TermCustody'
import { BmfCustody } from '../BmfCustody'
import { FutOpcCustody } from '../FutOpcCustody'
import { BtcCustody } from '../BtcCustody'
import { FixedIncomeCustody } from '../FixedIncomeCustody'
import { TesouroDiretoCustody } from '../TesouroDiretoCustody'
import { IntradayPosition } from '../IntradayPosition'
import { MarketData } from '../MarketData'
import { PolicyParameters } from '../PolicyParameters'
import { ClientRegistry } from '../ClientRegistry'

import { Button } from "../../../components/Forms"; 
import { H3 } from "../../../components/Typography"
import { Loader } from "../../../components/Loader";
import { SearchInput } from "../../../components/SearchInput";
import { ListGroup, ListItem } from '../../../components/ListGroup'
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Alert } from "../../../components/Alert";

import { 
    formatDecimal, 
    formatInteger,
    formatBoolean,
    formatObjectNumberValues, 
    formatArrayNumberValues, 
    formatParamValue
} from '../../../common/formatters';
import { objToQueryString } from "../../../common/api.js";
import { checkDuplicateParameters } from "../../../common/checkers";
import { sanitizeName } from '../../../common/variables';

import { API_HEADER } from "../../../constants/api";
import { GoldCustody } from "../GoldCustody";

import * as GridValidationInfo from '../gridValidationInfo';
import { defaultClientParameters } from '../defaultValues';
import { FlexOpcCustody } from "../FlexOpcCustody";

type ValuationResponse = {
    id: string;
    totalTimeMs: string;
    valuationResult: {
        canSendOrder: boolean;
        rejectReasonMessage: string;
    };
    trace: {
        Key: string;
        Value: string;
    }[]
}

type DuplicatesParametersError = {
    existsDuplicates: boolean;
    message: string;
}

type AllAccordions = {
    isAllAccordionOpen: boolean;
    count: number;
}

const balanceObj = {
    available: '0,00',
    initD0: '0,00',
    initD1: '0,00',
    initD2: '0,00',
}

const newOrderObj = {
    symbol: "PETR4",
    ordQty: 100,
    side: "buy",
    ordType: 2,
    timeInForce: 0,
    status: 1,
    price: "10,00",
    enteringTrader: "",
    account: "0"
}

const intradayPositionItem = {
    symbol: "",
    openBuyQty: "",
    execBuyQty: "",
    execSellQty: "",
    openSellQty: "",
    execSellAvgPrice: "0,00",
    execBuyAvgPrice: "0,00",
    openSellAvgPrice: "0,00",
    openBuyAvgPrice: "0,00",
    operatorParameter: false
}

const custodyItem = { 
    symbol: "",
    market: "TODOS",
    wallet: "0",
    available: "0",
    pending: "0",
    projectedD0: "0",
    projectedD1: "0",
    projectedD2: "0",
}

const termCustodyItem = { 
    symbol: "",
    available: "0",
    tradeValue: "0,00",
}

const bmfCustodyItem = { 
    symbol: "",
    available: "0",
    projectedD1: "0",
}

const goldCustodyItem = {
    availableGrams: "0"
}

const futOpcCustodyItem = { 
    symbol: "",
    available: "0",
}

const btcCustodyItem = { 
    symbol: "",
    taker: '0',
    donor: '0',
}

const fixedIncomeCustodyItem = { 
    symbol: "",
    financialCurrentValue: "0",
    guaranteeFactor: "0"
}

const tesouroDiretoCustodyItem = { 
    symbol: "",
    valorBruto: "0",
    guaranteeFactor: "0"
}

const marketDataItem = {
    symbol: "",
    underlyingSymbol: "",
    price: "0,00",
    market: "",
    contractMultiplier: 0,
    maturityDate: new Date('2099-12-31').toISOString().split('T')[0],
    currencyFactor: 0,
    priceDivisor: "0,00",
    closingPrice: "0,00",
    lastPrice: "0,00",
    bidPrice: "0,00",
    askPrice: "0,00",
    margin: "0,00"
}

const flexOpcCustodyItem = {
    symbol: "",
    premio: "0",
    baseSize: "0",
    naturezaOperacao: "sell"
}

interface ParameterType {
    name: string,
    market: string,
    symbol: string,
    value: string,
    operatorParameter: boolean,
    friendlyName?: string
}

const parameterItem:ParameterType = {
    name: "",
    market: "TODOS",
    symbol: "",
    value: "",
    operatorParameter: false,
    friendlyName: ''
}

const clientRegistryObj = {
    isQualified: "Não",
    advisor: ""
}

export function ClientSimulator() {
    const [balance, setBalance] = useState(balanceObj);
    const [newOrder, setNewOrder] = useState(newOrderObj);
    const [custody, setCustody] = useState([]);
    const [termCustody, setTermCustody] = useState([]);
    const [bmfCustody, setBmfCustody] = useState([]);
    const [futOpcCustody, setFutOpcCustody] = useState([]);
    const [flexOpcCustody, setFlexOpcCustody] = useState([]);
    const [btcCustody, setBtcCustody] = useState([]);
    const [fixedIncomeCustody, setFixedIncomeCustody] = useState([]);
    const [tesouroDiretoCustody, setTesouroDiretoCustody] = useState([]);
    const [goldCustody, setGoldCustody] = useState(goldCustodyItem);
    const [intradayPosition, setIntradayPosition] = useState([]);
    const [marketData, setMarketData] = useState([]);
    const [parameters, setParameters] = useState([parameterItem]);
    const [clientRegistry, setClientRegistry] = useState(clientRegistryObj);

    const [keys, setKeys] = useState([]);
    const [clientKeys, setClientKeys] = useState([]);

    const [valuationError, setValuationError] = useState<Error>();
    const [valuationResponse, setValuationResponse] = useState<ValuationResponse>();

    const [isValidated, setIsValidated] = useState(false);
    const [isValuatingRisk, setIsValuatingRisk] = useState(false);

    const [clientAccount, setClientAccount] = useState("");
    const [isLoadingClientData, setIsLoadingClientData] = useState(false);
    const [clientRegisterError, setClientRegisterError] = useState(false);
    const [duplicateParametersError, setDuplicateParametersError] = useState<DuplicatesParametersError>();

    const [allAccordionsOpen, setAllAccordionOpen] = useState<AllAccordions>({isAllAccordionOpen: false, count: 0});

    const [count, setCount] = useState(0);

    const [validationErrors, setValidationErrors] = useState([]);
    function deleteOldErrors (headerName: string, index: number) {
        setValidationErrors(oldState => {
            const keysToDelete = Object.keys(
                oldState).filter(k => k.includes(index + 1 + 'linha_' + headerName)
            );
            keysToDelete.forEach(key => delete oldState[key]);
            
            return oldState;
        })
    }
    let valid = true;
    
    const hasValidationError = () => {
        let hasError = false;
        Object.keys(validationErrors).forEach(key => {
            if (validationErrors[key] !== null) {
                hasError = true;
            }
        })
        return hasError;
    }
    function setDefaultParameters() {
        setParameters([...defaultClientParameters])
    }

    useEffect(() => {
        (async() => {
            try {
                const response = await fetch(`/api/policy/keys`)

                if(!response.ok) {
                    const error = await response.json()
                    throw new Error(error?.message)
                }

                const data = await response.json();

                const clientsKeys = data.map(group => (group.filter(item => !!item.isClient)))

                setKeys(data)
                setClientKeys(clientsKeys)
            } catch (err) {
                console.log(err)
            }
        })()

        setValidationErrors([]);
    }, [])

    useEffect(() => {
        setDefaultParameters();
    }, [clientKeys])

    function clearClientData() {
        setCustody([custodyItem])
        setTermCustody([termCustodyItem])
        setBmfCustody([bmfCustodyItem])
        setFutOpcCustody([futOpcCustodyItem])
        setBtcCustody([btcCustodyItem])
        setFixedIncomeCustody([fixedIncomeCustodyItem])
        setTesouroDiretoCustody([tesouroDiretoCustodyItem])
        setParameters([parameterItem])
        setMarketData([marketDataItem])
        setIntradayPosition([intradayPositionItem])
        setBalance(balanceObj)
        setFlexOpcCustody([flexOpcCustodyItem]);
        setClientRegistry(clientRegistryObj)
    }

    async function fetchClientBalance() {
        const response = await fetch(`/api/client/${clientAccount}/balance`)

        if(!response.ok) {
            return balanceObj
        }

        const data = await response.json()
        
        const clientBalance = {
            available: formatDecimal(data?.available),
            initD0: formatDecimal(data?.initD0),
            initD1: formatDecimal(data?.initD1),
            initD2: formatDecimal(data?.initD2)
        }

        Object.keys(clientBalance).forEach(key => {
            if (clientBalance[key] === '-')
                clientBalance[key] = balanceObj[key];
        })

        return clientBalance
    }

    async function fetchClientBovespaCustody() {
        const response = await fetch(`/api/client/${clientAccount}/variableIncomeCustody`)

        if(!response.ok) {
            return [custodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.items.map(item => ({
            ...item,
            available: formatInteger(item?.available),
            pending: formatInteger(item?.pending),
            projectedD0: formatInteger(item?.projectedD0),
            projectedD1: formatInteger(item?.projectedD1),
            projectedD2: formatInteger(item?.projectedD2)
        }))

        return custodyItems
    }

    async function fetchClientTermCustody() {
        const response = await fetch(`/api/client/${clientAccount}/termCustody`)

        if(!response.ok) {
            return [termCustodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.map(item => ({
            symbol: item?.symbol,
            available: formatInteger(item?.available ?? 0),
            tradeValue: formatDecimal(item?.tradeValue ?? 0)
        }))

        return custodyItems
    }

    async function fetchClientBmfCustody() {
        const response = await fetch(`/api/client/${clientAccount}/bmfCustody`)

        if(!response.ok) {
            return [bmfCustodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.map(item => ({
            symbol: item?.symbol,
            available: formatInteger(item?.available ?? 0),
            projectedD1: formatInteger(item?.projectedD1 ?? 0)
        }))

        return custodyItems
    }

    async function fetchClientGoldCustody() {
        const response = await fetch(`/api/client/${clientAccount}/goldCustody`)

        if(!response.ok) {
            return goldCustodyItem
        }

        const data = await response.json()

        const custodyItems = {
            availableGrams: formatDecimal(data?.custody?.availableGrams ?? 0)
        }

        return custodyItems
    }

    async function fetchClientFutOpcCustody() {
        const response = await fetch(`/api/client/${clientAccount}/futOpc`)

        if(!response.ok) {
            return [futOpcCustodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.map(item => ({
            symbol: item?.symbol,
            available: formatInteger(item?.available ?? 0),
        }))

        return custodyItems
    }

    async function fetchClientFlexOpcCustody() {
        const response = await fetch(`/api/client/${clientAccount}/flexOpcCustody`)

        if(!response.ok) {
            return [futOpcCustodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.map(item => ({
            symbol: item?.symbol,
            premio: formatInteger(item?.premio ?? 0),
            baseSize: formatInteger(item?.baseSize ?? 0),
            naturezaOperacao: item?.naturezaOperacao
        }))

        return custodyItems
    }

    async function fetchClientBtcCustody() {
        const response = await fetch(`/api/btc/${clientAccount}/btcCustody`)

        if(!response.ok) {
            return [btcCustodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.map(item => ({
            symbol: item?.symbol,
            taker: formatInteger(item?.quantityTaker ?? 0),
            donor: formatInteger(item?.quantityDonor ?? 0)
        }))

        return custodyItems
    }

    async function fetchClientFixedIncomeCustody() {
        const response = await fetch(`/api/client/${clientAccount}/fixedIncomeCustody`)

        if(!response.ok) {
            return [fixedIncomeCustodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.map(item => ({
            symbol: item?.symbol,
            financialCurrentValue: formatDecimal(item?.financialCurrentValue ?? 0),
            guaranteeFactor: item?.guaranteeFactor ?? 0
        }))

        return custodyItems
    }

    async function fetchClientTesouroDiretoCustody() {
        const response = await fetch(`/api/client/${clientAccount}/tesouroDiretoCustody`)

        if(!response.ok) {
            return [tesouroDiretoCustodyItem]
        }

        const data = await response.json()

        const custodyItems = data?.map(item => ({
            symbol: item?.nmTitulo?.toUpperCase(),
            valorBruto: formatDecimal(item?.valorBruto ?? 0),
            guaranteeFactor: item?.guaranteeFactor ?? 0
        }))

        return custodyItems
    }

    async function fetchClientBmfIntradayPosition() {
        const response = await fetch(`/api/client/${clientAccount}/bmfPositionD0`)

        if(!response.ok) {
            return [intradayPositionItem]
        }

        const data = await response.json()

        const intradayItems = data?.map(item => ({
            ...item,
            execBuyQty: formatInteger(item.execBuyQty),
            openBuyQty: formatInteger(item.openBuyQty),
            execBuyAvgPrice: formatDecimal(item.execBuyAvgPrice),
            openBuyAvgPrice: formatDecimal(item.openBuyAvgPrice),
            execSellQty: formatInteger(item.execSellQty),
            openSellQty: formatInteger(item.openSellQty),
            execSellAvgPrice: formatDecimal(item.execSellAvgPrice),
            openSellAvgPrice: formatDecimal(item.openSellAvgPrice)
        }))

        return intradayItems
    }

    async function fetchClientBovespaIntradayPosition() {
        const response = await fetch(`/api/client/${clientAccount}/bovespaPositionD0`)

        if(!response.ok) {
            return [intradayPositionItem]
        }

        const data = await response.json()

        const intradayItems = data?.map(item => ({
            ...item,
            execBuyQty: formatInteger(item.execBuyQty),
            openBuyQty: formatInteger(item.openBuyQty),
            execBuyAvgPrice: formatDecimal(item.execBuyAvgPrice),
            openBuyAvgPrice: formatDecimal(item.openBuyAvgPrice),
            execSellQty: formatInteger(item.execSellQty),
            openSellQty: formatInteger(item.openSellQty),
            execSellAvgPrice: formatDecimal(item.execSellAvgPrice),
            openSellAvgPrice: formatDecimal(item.openSellAvgPrice)
        }))

        return intradayItems
    }

    async function fetchClientMarketData(
        custody = [], 
        positionD0 = []
    ) {
        let symbolsList = []

        custody.map(i => symbolsList.push(i.symbol))
        positionD0.map(i => symbolsList.push(i.symbol))

        symbolsList = symbolsList.filter(
            (item, index, arr) => (arr.indexOf(item) === index)
        )

        if (symbolsList.length <= 0)
            return [];

        const querys = []
        const marketDataResponses = []
        for (let i = 0; (symbolsList.length / 100) >= i; i++) {
            querys.push(symbolsList.slice(i * 100, (i * 100) + 100))
            querys[i] = objToQueryString({
                q: querys[i].join(', ')
            })

            const response = await fetch(`/api/MarketData?${querys[i]}`)

            if (!response.ok) {
                return [marketDataItem]
            }
            const data = await response.json();
            marketDataResponses.push(...data)
        }

        const marketDataItems = marketDataResponses.map(item => ({
            ...item,
            price: formatDecimal(item.price),
            contractMultiplier: formatDecimal(item.contractMultiplier),
            currencyFactor: formatDecimal(item.currencyFactor),
            margin: item.margin ? formatDecimal(item.margin) : "0,00",
            priceDivisor: item.priceDivisor ? formatDecimal(item.priceDivisor) : "0,00",
        }))
        return marketDataItems
    }

    async function fetchClientPolicyId() {
        const response = await fetch(`/api/client/${clientAccount}/policy`)

        if(!response.ok) { 
            return 0
        }

        const { id } = await response.json()

        return id
    }

    async function fetchPolicy(id) {
        const response = await fetch(`/api/policy/${id}`)

        if (!response.ok) {
            return [parameterItem]
        }

        const data = await response.json()

        const parametersData = data?.parameters
            .filter(item => item.category !== 'Internal' && item.category !== 'FixedIncome')
            .map(param => ({
                ...param,
                value: formatParamValue(param)
            }))

        return parametersData
    }

    async function fetchClientRegistry() {
        const response = await fetch(`/api/client/${clientAccount}/adapterRegister`)

        if (!response.ok) {
            return clientRegistryObj
        }

        const data = await response.json()

        const clientRegistry = {
            isQualified: formatBoolean(data?.isQualified),
            advisor: data?.advisor ?? ""
        }

        return clientRegistry
    }

    async function handleClient(event) {
        event.preventDefault()

        setIsLoadingClientData(true)
        setDuplicateParametersError(null)
        setClientRegisterError(false)

        clearClientData()

        setNewOrder({ ...newOrder, account: "" })

        try {
            const response = await fetch(`/api/client/${clientAccount}/register`)

            if(!response.ok)
                throw new Error('register')

            setNewOrder({ ...newOrder, account: clientAccount })

            const [balance, custodyBmf, futOpcCustody, flexOpcCustody, btcCustody, custodyBovespa, termCustody, custodyFixedIncome, custodyTesouroDireto, goldCustody, policyId, intradayBmf, intradayBovespa, clientRegistry] = await Promise.all([
                fetchClientBalance(),
                fetchClientBmfCustody(),
                fetchClientFutOpcCustody(),
                fetchClientFlexOpcCustody(),
                fetchClientBtcCustody(),
                fetchClientBovespaCustody(),
                fetchClientTermCustody(),
                fetchClientFixedIncomeCustody(),
                fetchClientTesouroDiretoCustody(),
                fetchClientGoldCustody(),
                fetchClientPolicyId(),
                fetchClientBmfIntradayPosition(),
                fetchClientBovespaIntradayPosition(),
                fetchClientRegistry()
            ])
            const intraday = [...intradayBmf, ...intradayBovespa]
            const custody = [...custodyBmf, ...custodyBovespa, ...futOpcCustody, ...flexOpcCustody, ...btcCustody, ...termCustody, goldCustody]

            setBalance(balance)
            setCustody(custodyBovespa)
            setTermCustody(termCustody)
            setBmfCustody(custodyBmf)
            setFutOpcCustody(futOpcCustody)
            setFlexOpcCustody(flexOpcCustody);
            setBtcCustody(btcCustody)
            setFixedIncomeCustody(custodyFixedIncome)
            setTesouroDiretoCustody(custodyTesouroDireto)
            setGoldCustody(goldCustody)
            setIntradayPosition(intraday)
            setClientRegistry(clientRegistry)

            const [marketData, parameters] = await Promise.all([
                fetchClientMarketData(custody, intraday),
                fetchPolicy(policyId)
            ])

            Object.keys(marketData).forEach(key => {
                if (marketData[key].market === 'NONE') {
                    marketData[key].market = 'VIS';
                }   
            })
            setMarketData(marketData)
            setParameters(parameters)

        } catch (err) {
            if(err.message === 'register') {
                setClientRegisterError(true)
            } else {
                console.log(err)
            }
        } finally {
            setIsLoadingClientData(false)
        }
    }

    function setValidationError(gridName: string, friendlyName:string, index:number) {
        setValidationErrors(oldState => {
            const newState = {...oldState};
            newState[`${index}linha_${gridName}_${friendlyName}`] = new Error(
                `${index}° linha - campo "${friendlyName}" do grid "${gridName}" inválido!`
            );
            return newState;
        })
    }
    function validateFields() {
        setValidationErrors([]);
        valid = true;
        
        Object.keys(balance).forEach((key) => {
            if (!GridValidationInfo.balance[key]) return;
            const gridName = GridValidationInfo.balance.gridName;
            const friendlyName = GridValidationInfo.balance[key].friendlyName;
            if (GridValidationInfo.balance[key].required && balance[key].length === 0) {
                valid = false;
                setValidationError(gridName, friendlyName, 1);
            }
        });
        Object.keys(newOrder).forEach((key) => {
            if (!GridValidationInfo.newOrder[key]) return;
            const gridName = GridValidationInfo.newOrder.gridName;
            const friendlyName = GridValidationInfo.newOrder[key].friendlyName;
            if (GridValidationInfo.newOrder[key].required && newOrder[key].length === 0) {
                valid = false;
                setValidationError(gridName, friendlyName, 1);
            }
        });
        custody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.custody[key]) return;
                const gridName = GridValidationInfo.custody.gridName;
                const friendlyName = GridValidationInfo.custody[key].friendlyName;
                if (GridValidationInfo.custody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        termCustody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.termCustody[key]) return;
                const gridName = GridValidationInfo.termCustody.gridName;
                const friendlyName = GridValidationInfo.termCustody[key].friendlyName;
                if (GridValidationInfo.termCustody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        bmfCustody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.bmfCustody[key]) return;
                const gridName = GridValidationInfo.bmfCustody.gridName;
                const friendlyName = GridValidationInfo.bmfCustody[key].friendlyName;
                if (GridValidationInfo.bmfCustody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        btcCustody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.btcCustody[key]) return;
                const gridName = GridValidationInfo.btcCustody.gridName;
                const friendlyName = GridValidationInfo.btcCustody[key].friendlyName;
                if (GridValidationInfo.btcCustody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        futOpcCustody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.futOpcCustody[key]) return;
                const gridName = GridValidationInfo.futOpcCustody.gridName;
                const friendlyName = GridValidationInfo.futOpcCustody[key].friendlyName;
                if (GridValidationInfo.futOpcCustody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });

        flexOpcCustody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.flexOpcCustody[key]) return;
                const gridName = GridValidationInfo.flexOpcCustody.gridName;
                const friendlyName = GridValidationInfo.flexOpcCustody[key].friendlyName;
                if (GridValidationInfo.flexOpcCustody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        
        fixedIncomeCustody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.fixedIncomeCustody[key]) return;
                const gridName = GridValidationInfo.fixedIncomeCustody.gridName;
                const friendlyName = GridValidationInfo.fixedIncomeCustody[key].friendlyName;
                if (GridValidationInfo.fixedIncomeCustody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        tesouroDiretoCustody.forEach((cust, index) => {
            Object.keys(cust).forEach(key => {
                if (!GridValidationInfo.tesouroDiretoCustody[key]) return;
                const gridName = GridValidationInfo.tesouroDiretoCustody.gridName;
                const friendlyName = GridValidationInfo.tesouroDiretoCustody[key].friendlyName;
                if (GridValidationInfo.tesouroDiretoCustody[key].required && cust[key]?.length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        Object.keys(goldCustody).forEach((key) => {
            if (!GridValidationInfo.goldCustody[key]) return;
            const gridName = GridValidationInfo.goldCustody.gridName;
            const friendlyName = GridValidationInfo.goldCustody[key].friendlyName;
            if (GridValidationInfo.goldCustody[key].required && goldCustody[key].length === 0) {
                valid = false;
                setValidationError(gridName, friendlyName, 1);
            }
        });
        intradayPosition.forEach((ip, index) => {
            Object.keys(ip).forEach(key => {
                if (!GridValidationInfo.intradayPosition[key]) return;
                const gridName = GridValidationInfo.intradayPosition.gridName;
                const friendlyName = GridValidationInfo.intradayPosition[key].friendlyName;
                if (GridValidationInfo.intradayPosition[key].required && ip[key].length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        })
        marketData.forEach((market, index) => {
            Object.keys(market).forEach(key => {
                if (!GridValidationInfo.marketData[key]) return;
                const gridName = GridValidationInfo.marketData.gridName;
                const friendlyName = GridValidationInfo.marketData[key].friendlyName;
                if (GridValidationInfo.marketData[key].required && market[key].length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
        parameters.forEach((param, index) => {
            Object.keys(param).forEach(key => {
                if (!GridValidationInfo.parameters[key]) return;
                const gridName = GridValidationInfo.parameters.gridName;
                const friendlyName = GridValidationInfo.parameters[key].friendlyName;
                if (GridValidationInfo.parameters[key].required && param[key].length === 0) {
                    valid = false;
                    setValidationError(gridName, friendlyName, index + 1);
                }
            })
        });
    }

    async function handleSimulationSubmit(event) {
        event.preventDefault();
        
        validateFields();
        if (!valid) return setIsValidated(true);

        setValuationError(null)
        setValuationResponse(null)

        const form = event.currentTarget
        
        if (!form.checkValidity()) 
            return setIsValidated(true)
        
        setIsValidated(false)

        const duplicates = checkDuplicateParameters(keys, parameters)

        if (duplicates.existsDuplicates) 
            return setDuplicateParametersError(duplicates)      

        setIsValuatingRisk(true)
        setDuplicateParametersError(null)

        const bmfCustodyItems = bmfCustody.map(item => ({
            ...item,
            market: "FUT"
        }))

        const balanceItem = {
            available: balance.available,
            d0: balance.initD0,
            d1: balance.initD1,
            d2: balance.initD2,
        } 
        
        const parametersFixedIncome = fixedIncomeCustody?.map(x => {
            return {
                name: "GuaranteeFactor",
                market: "RF",
                symbol: x.symbol,
                value: x.guaranteeFactor,
                operatorParameter: false,
                comments: ""
            } 
         })

         const parametersTesouroDireto = tesouroDiretoCustody?.map(x => {
            return {
                name: "GuaranteeFactor",
                market: "RF",
                symbol: x.symbol,
                value: x.guaranteeFactor,
                operatorParameter: false,
                comments: ""
            } 
         })

        const parameterItems = parameters.length > 0
            ? parameters.concat(parametersFixedIncome).concat(parametersTesouroDireto)
            : parameters

        const positionD0 = formatArrayNumberValues(intradayPosition, ['openBuyQty', 'execBuyQty', 'execSellQty', 'openSellQty', 'execSellAvgPrice', 'openSellAvgPrice', 'execBuyAvgPrice', 'openBuyAvgPrice'])
            .map(item => { 
                return {
                    ...item, 
                    openBuyOrdersDetails: item.openBuyQty > 0 ? [{ quantity: item.openBuyQty, price: item.openBuyAvgPrice, orderType: 'limit'}] : [], 
                    openSellOrdersDetails: item.openSellQty > 0 ? [{ quantity: item.openSellQty, price: item.openSellAvgPrice, orderType: 'limit'}] : [],
                } 
            })

        const body = {
            balance: formatObjectNumberValues(balanceItem, ['available', 'd0', 'd1', 'd2']),
            newOrder: formatObjectNumberValues(newOrder, ['ordQty', 'price']),
            positionD0: positionD0,
            bovespaCustody: formatArrayNumberValues(custody, ['available', 'wallet', 'projectedD0', 'projectedD1', 'projectedD2', 'pending']),
            termCustody: formatArrayNumberValues(termCustody, ['available', 'tradeValue']),
            bmfCustody: formatArrayNumberValues(bmfCustodyItems, ['available', 'projectedD1']),
            futOpcCustody: formatArrayNumberValues(futOpcCustody, ['available']),
            flexOpcCustody: formatArrayNumberValues(flexOpcCustody, ['premio', 'baseSize']),
            btcCustody: formatArrayNumberValues(btcCustody, ['taker', 'donor']),
            fixedIncomeCustody: formatArrayNumberValues(fixedIncomeCustody, ['financialCurrentValue']),
            tesouroDiretoCustody: formatArrayNumberValues(tesouroDiretoCustody, ['valorBruto']),
            goldCustody: formatObjectNumberValues(goldCustody, ['availableGrams', 'availableOZ1D']),
            marketData: formatArrayNumberValues(marketData, ['price', 'priceDivisor', 'lastPrice', 'closingPrice', 'bidPrice',
                'askPrice', 'contractMultiplier', 'currencyFactor', 'margin']),
            parametersItems: formatArrayNumberValues(parameterItems, ['value']),
            clientRegistry: formatObjectNumberValues(clientRegistry, ['isQualified']),
            origin: "Simulação"
        }

        const options = {
            method: "POST",
            headers: {
                ...API_HEADER,
                "X-Fill-Context": "true",
                "X-Fill-LogTrace": "true",
            },
            body: JSON.stringify(body),
        }

        try {
            const response = await fetch(`/api/RiskValuation/NewOrder/${newOrder.account}`, options)

            if(!response.ok) {
                const { errors } = await response.json()
                const errorMessage = JSON.stringify(errors)
                throw new Error(errorMessage)
            }

            const data = await response.json()

            setTimeout(() => {
                setValuationResponse(data)
                setIsValuatingRisk(false)
            }, 2000);
        } catch (err) {
            setValuationError(err)
        }
    }

    return (
        <>
            <SectionWrapper header="Simulador de Avaliação de Ordem - Cliente">
                <H3>Código do cliente</H3>

                <p>Escreva o código do cliente para preencher dados de custódia, ordens, cotação e outras informações.</p>

                <SearchInput
                    value={clientAccount}
                    required
                    isFullWidth
                    placeholder="Código do cliente"
                    isFetchingData={isLoadingClientData}
                    onChange={(e) => setClientAccount(sanitizeName(e.target.value))}
                    onSubmit={e => handleClient(e)}
                />

                {clientRegisterError && (
                    <Alert 
                        margin="int-mt-5"
                    >
                        Não foram encontrados registros para o código do cliente!
                    </Alert>
                )}

                {isLoadingClientData && (
                    <Alert isLoading>
                        Carregando dados do cliente...
                    </Alert>
                )}

                <div className="int-d-flex int-justify-content-end int-mt-4">
                    <Button
                        variant="outline"
                        onClick={() => {
                            setAllAccordionOpen({isAllAccordionOpen: true, count})
                            setCount(count+1)}}
                    >
                        Expandir Tudo
                    </Button>

                    <Button
                        margin="int-ml-2"
                        variant="outline"
                        onClick={() => {
                            setAllAccordionOpen({isAllAccordionOpen: false, count})
                            setCount(0)}}
                    >
                        Recolher Tudo
                    </Button>
                </div>
            </SectionWrapper>
        
            <form 
                className={`int-mx-auto ${isValidated ? 'was-validated' : ''}`}
                onSubmit={handleSimulationSubmit}
                noValidate
            >
                <ClientRegistry 
                    clientRegistry={clientRegistry} 
                    setClientRegistry={setClientRegistry} 
                    allAccordionsOpen={allAccordionsOpen}
                />

                <Balance 
                    balance={balance} 
                    setBalance={setBalance} 
                    allAccordionsOpen={allAccordionsOpen}
                />

                <NewOrder 
                    newOrder={newOrder} 
                    setNewOrder={setNewOrder} 
                    allAccordionsOpen={allAccordionsOpen}
                />

                <Custody 
                    custody={custody} 
                    setCustody={setCustody} 
                    custodyItem={custodyItem} 
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <TermCustody 
                    custody={termCustody} 
                    setCustody={setTermCustody} 
                    custodyItem={termCustodyItem} 
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <BmfCustody 
                    custody={bmfCustody} 
                    setCustody={setBmfCustody} 
                    custodyItem={bmfCustodyItem} 
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <BtcCustody
                    custody={btcCustody} 
                    setCustody={setBtcCustody} 
                    custodyItem={btcCustodyItem} 
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />


                <FutOpcCustody 
                    custody={futOpcCustody} 
                    setCustody={setFutOpcCustody} 
                    custodyItem={futOpcCustodyItem} 
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <FlexOpcCustody 
                    custody={flexOpcCustody}
                    setCustody={setFlexOpcCustody}
                    custodyItem={flexOpcCustodyItem}
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <FixedIncomeCustody 
                    custody={fixedIncomeCustody} 
                    setCustody={setFixedIncomeCustody} 
                    custodyItem={fixedIncomeCustodyItem} 
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <TesouroDiretoCustody 
                    custody={tesouroDiretoCustody} 
                    setCustody={setTesouroDiretoCustody} 
                    custodyItem={tesouroDiretoCustodyItem} 
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <GoldCustody 
                    custody={goldCustody} 
                    setCustody={setGoldCustody} 
                    allAccordionsOpen={allAccordionsOpen}
                />

                <IntradayPosition
                    intradayPosition={intradayPosition}
                    setIntradayPosition={setIntradayPosition}
                    intradayPositionItem={intradayPositionItem}
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <MarketData 
                    marketData={marketData}
                    setMarketData={setMarketData}
                    marketDataItem={marketDataItem}
                    allAccordionsOpen={allAccordionsOpen}
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <PolicyParameters 
                    parameters={parameters}
                    setParameters={setParameters}
                    parameterItem={parameterItem}
                    keys={clientKeys}
                    allAccordionsOpen={allAccordionsOpen}
                    label="Cliente"
                    deleteOldErrors={deleteOldErrors}
                    setValidationErrors={setValidationErrors}
                />

                <Button 
                    margin="int-my-6"
                    isFullWidth
                    disabled={isValuatingRisk || isLoadingClientData}
                    type="submit"
                >
                    {isValuatingRisk ? <Loader size="sm" /> : "Simular"}
                </Button>

                {isValuatingRisk && (
                    <Alert isLoading>
                       Simulando...
                    </Alert>
                )}

                {duplicateParametersError?.existsDuplicates && ( 
                    <Alert>
                        {duplicateParametersError.message}
                    </Alert>
                )}

                {valuationError && (
                    <Alert>
                        Ocorreu um erro: { valuationError?.message }
                    </Alert>
                )}

                {hasValidationError() && (
                    Object.keys(validationErrors).map((key, index) => (
                        <Alert key={`key${index}`} data-testid="validation-alert">
                            Erro de validação: { validationErrors[key]?.message }
                        </Alert>
                    ))            
                )}
            </form>

            <ListGroup>
                <ListItem>
                    Resultado: { valuationResponse?.valuationResult?.canSendOrder ? 'Válido' : 'Inválido' }
                </ListItem>

                <ListItem>
                    Detalhes: { valuationResponse?.valuationResult?.rejectReasonMessage }
                </ListItem>

                <ListItem>
                    ID da simulação: { valuationResponse?.id }
                </ListItem>
            </ListGroup>

            <ListGroup margin="int-mt-3">
                <ListItem>
                    Tempo total: { valuationResponse?.totalTimeMs }ms
                </ListItem>

                {valuationResponse?.trace?.map((item, index) => (
                    <ListItem key={index}>
                        {`${item.Key}: ${item.Value}`}ms
                    </ListItem>
                ))}
            </ListGroup>
        </>
    );
};

import React, { cloneElement, ReactElement } from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'

import "./styles.css"

interface ActiveLinkProps extends LinkProps {
    children: ReactElement;
    shouldMatchExactHref?: boolean;
}

export function ActiveLink({
    children,
    shouldMatchExactHref = false,
    ...rest
}: ActiveLinkProps) {
    const { pathname } = useLocation()

    let isActive = false

    if (shouldMatchExactHref && (pathname === rest.to)) {
        isActive = true;
    }
    
    if (
        !shouldMatchExactHref &&
        (pathname.startsWith(String(rest.to)))
    ) {
        isActive = true;
    }

    return(
        <Link {...rest} className="int-active-link">
            {cloneElement(children, {
                className: isActive ? "int-navbar-item active" : "int-navbar-item"
            })}
        </Link>
    )
}
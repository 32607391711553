import React, { ReactNode, useState, useRef, useEffect } from 'react'

import "./styles.css"

interface DropdownProps {
    label: string;
    children: ReactNode;
    align?: 'left' | 'right'
}

export function Dropdown({ label, children, align = "left" }: DropdownProps) {
    const node = useRef(null)
    
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => {
            document.removeEventListener("mousedown", handleClick)
        }
    }, [])

    function handleClick(e) {
        if(node.current.contains(e.target)) {
            return
        } 

        setIsDropdownOpen(false)
    }

    return(
        <div 
            ref={node}
            className={`
                int-dropdown 
                int-dropdown-${align}
                ${isDropdownOpen ? 'active' : ''}
            `}
        >
            <button 
                className="int-dropdown-toggle int-active-link"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}    
                style={{
                    background: 'none',
                    fontWeight: 500
                }}
            >
                {label}
            </button>
            <div className="int-navbar-dropdown-list">
                {children}
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";

import { Link } from '../../../components/Link'
import { Alert } from '../../../components/Alert';
import { Loader } from '../../../components/Loader';
import { SmartTable } from '../../../components/Table';
import { getPolicyNameById } from '../../../common/search';
import { SectionWrapper } from '../../../components/SectionWrapper';
import { Button, Checkbox, Select, Input } from '../../../components/Forms';
import { SelectColumnFilter } from '../../../components/Table/Filters';
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal';

import { API_HEADER } from '../../../constants/api';

import { DownloadXLSXButton } from '../../../components/Forms/DownloadXLSXButton';
import { UploadCSVModal } from "../../../components/UploadCSV/UploadCSVModal";

type PolicyParameters = {
    id: string;
    name: string;
    policyType: string;
    shared: boolean;
    isInternal: boolean;
}

type ClientParameters = {
    account: string;
    policyId: number;
    policyName: string;
}

export function ClientsPolicies() {
    const [policies, setPolicies] = useState<PolicyParameters[]>([]);
    const [accounts, setAccounts] = useState<ClientParameters[]>([]);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showAddOrUpdateModal, setShowAddOrUpdateModal] = useState(false);
    const [showLoaderFull, setShowLoaderFull] = useState(false);
    const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
    const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
    const [showSuccessAddOrUpdateModal, setShowSuccessAddOrUpdateModal] = useState(false);
    const [showErrorAddOrUpdateModal, setShowErrorAddOrUpdateModal] = useState(false);

    const [associatedPolicy, setAssociatedPolicy] = useState();
    const [sortedPolicyItems, setSortedPolicyItems] = useState([]);
    const [clientsParamsExport, setClientsParamsExport] = useState([]);

    const [account, setAccount] = useState("");
    const [allClients, setAllClients] = useState<string[]>([]);
    const [selectedClients, setSelectedClients] = useState<string[]>([]);

    const [error, setError] = useState(false);
    const [errorUpdateParam, setErrorUpdateParam] = useState("")
    const [checkName, setCheckName] = useState([])

    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);

    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const allChecked = allClients.every(x => selectedClients.includes(x));

    const onChange = e => setAssociatedPolicy(e.target.value.toUpperCase().trimStart());

    const checkBoxColumnHandle = ({ target }) => {
        if (target.checked) {
            setSelectedClients(selectedClients.concat([target.value]))
        } else {
            setSelectedClients(selectedClients.filter(i => i !== target.value))
        }
    }

    const allCheckBoxesHandle = ({ target }) => {
        if (target.checked) {
            const items = allClients.filter(x => !selectedClients.includes(x));
            setSelectedClients(selectedClients.concat(items))
        } else {
            setSelectedClients(selectedClients.filter(i => !allClients.includes(i)))
        }
    }

    const onFiltersChange = (rows) => {
        const data = rows.map(item => item.original.account)

        setAllClients(data)
        setSelectedClients([])
    }

    const columns = [
        {
            id: 'Seleção',
            Header: () =>
                <Checkbox
                    data-testid="table-checkbox-header"
                    onChange={allCheckBoxesHandle}
                    isChecked={allChecked}
                />,
            Cell: ({ row }) =>
                <Checkbox
                    data-testid="table-checkbox-body"
                    value={row.original.account}
                    onChange={checkBoxColumnHandle}
                    isChecked={selectedClients.some(i => i === row.original.account)}
                />,
            width: '1%'
        },
        {
            Header: 'Conta',
            accessor: 'account',
            columnAlign: 'left',
        },
        {
            Header: 'Política',
            accessor: props => getPolicyNameById(policies, props.policyId),
            Cell: ({ row }) => PolicyName(row.original),
            Filter: SelectColumnFilter,
        }
    ]

    const PolicyName = (item) => {
        if (item.policyId === undefined)
            return "-"

        return (
            <Link href={`/policies/${item.policyId}`}>
                {getPolicyNameById(policies, item.policyId)}
            </Link>
        )
    }

    async function fetchPolicyData() {
        try {
            const response = await fetch(`/api/policy`)

            if (!response.ok) {
                throw new Error("")
            }
            const data = await response.json()
            setPolicies(data)

        } catch (err) {
            console.log(err)
        }
    }

    async function fetchClientData() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/client/searchAll`)

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }
            const data = await response.json()
            setAccounts(data)

        } catch (err) {
            console.log(err)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchPolicyData()
        fetchClientData()
    }, [])

    useEffect(() => {
        if (accounts?.length > 0) {
            const params = accounts
                .map(x => ({
                    "Conta": x.account,
                    "Politica": getPolicyNameById(policies, x.policyId)
                }))

            setClientsParamsExport(params)
            setAllClients(accounts.map(x => x.account))
        }
    }, [accounts])

    useEffect(() => {
        const sortedArray = policies?.filter(x => !!x.shared && !x.isInternal && x.policyType === "Client");
        setSortedPolicyItems(sortedArray);
    }, [policies]);

    /** Substitui as políticas do clientes (API) */
    const handlePolicy = async (arr) => {
        setError(false);
        setDisabled(true);
        setShowLoaderFull(true);

        try {
            const arrNames = arr.map(x => x.policyName)
            const policiesNames = policies.map(x => x.name.toUpperCase())

            const isInArray = arrNames.some(x => !policiesNames.includes(x))

            if (isInArray) {
                const names = arrNames.filter(x => !policiesNames.includes(x))
                setCheckName(names)
                setShowLoaderFull(false)
                setShowErrorUpdateModal(true)
                return
            }

            const requestBody = arr.map((item) => ({
                account: item.account,
                policyId: policies?.find(x => x.name.toUpperCase().trim() === item.policyName)?.id
            }))

            const requestOptions = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(requestBody)
            };

            const response = await fetch(`/api/Policy/updateClientsList`, requestOptions)

            if (!response.ok) {
                setShowErrorUpdateModal(true)
                setError(true)
                setShowLoaderFull(false);
                return setDisabled(false);
            }

            setShowLoaderFull(false);
            setShowSuccessUpdateModal(true)
        } catch (err) {
            console.log(err)
            setShowLoaderFull(false);
            setError(true)
        } finally {
            setDisabled(false)
        }
    };

    /** Substitui a políticas do clientes (API) */
    const changePolicyList = async (event) => {
        event.preventDefault();
        setDisabled(true)
        setError(false);

        try {
            if (associatedPolicy === undefined) {
                setDisabled(false);
                setError(true)
                return setErrorUpdateParam("Selecione uma política para associar ao cliente.")
            }

            const requestBody = {
                accounts: selectedClients.concat([account]),
                policyId: parseFloat(associatedPolicy)
            }

            const requestOptions = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(requestBody),
            };

            const response = await fetch(`/api/policy/updateClients`, requestOptions)

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            setShowLoaderFull(false)

        } catch (err) {
            console.log(err)
            setShowLoaderFull(false);
            setError(true)
        } finally {
            setDisabled(false)
        }

        setDisabled(false);
    };

    return (
        <>
            <SectionWrapper header="Clientes / Políticas">
                <div className="int-d-flex int-align-items-center int-justify-content-between int-mt-4">
                    <div className="int-d-flex">
                        <DownloadXLSXButton
                            disabled={accounts.length <= 0}
                            dataJs={clientsParamsExport}
                            fileName={`lista_de_clientes_e_politicas_${new Date(Date.now()).toLocaleDateString()}`}
                            variant="outline"
                        >
                            Exportar arquivo
                        </DownloadXLSXButton>

                        <Button
                            data-testid="import-arq"
                            variant="outline"
                            margin="int-ml-3"
                            onClick={() => {
                                setIsCSVModalOpen(true)
                                setError(false)
                            }}
                        >
                            Importar arquivo
                        </Button>

                        <Button
                            data-testid="add-or-update-accounts"
                            margin="int-ml-3"
                            variant="outline"
                            onClick={() => {
                                setShowAddOrUpdateModal(true)
                            }}
                        >
                            Adicionar / Editar contas
                        </Button>

                        <Button
                            data-testid="update-item"
                            disabled={selectedClients.length <= 0}
                            margin="int-ml-3"
                            variant="outline"
                            onClick={() => {
                                setShowUpdateModal(true)
                            }}
                        >
                            Editar itens selecionados
                        </Button>
                    </div>
                </div>

                {isLoading && <Loader flex />}
                
                {isLoading ? '' :
                    (
                        <SmartTable
                            data={accounts ?? []}
                            columns={columns}
                            columnFilters
                            columnsResizable
                            localStorageKey={'ClientsPolicy'}
                            onFiltersChange={onFiltersChange}
                        />
                    )}

            </SectionWrapper>

            <UploadCSVModal
                isModalOpen={isCSVModalOpen}
                onHide={() => setIsCSVModalOpen(false)}
                onAdd={handlePolicy}
                header={`Importar / Substituir`}
                model={"Clientes"}
                policyApplication={true}
            />

            {/* Modal - Mensagem de edição com sucesso  */}
            <SimpleModal
                header="Alteração das políticas dos clientes"
                isOpen={showSuccessUpdateModal}
                onClose={() => setShowSuccessUpdateModal(false)}
                bodyMessage="As políticas dos clientes foram atualizadas com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        setSelectedClients([])
                        fetchClientData()
                        setShowSuccessUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Mensagem de erro na edição */}
            <SimpleModal
                header="Erro ao alterar as políticas dos clientes"
                isOpen={showErrorUpdateModal}
                onClose={() => setShowErrorUpdateModal(false)}
                bodyMessage={`Ocorreu um erro ao atualizar as políticas dos cliente,
                não foram encontradas as políticas: ${checkName.filter((este, i) => checkName.indexOf(este) === i)}`}
                footerButton={{
                    label: 'Fechar',
                    onClick: () => {
                        setSelectedClients([])
                        fetchClientData()
                        setShowErrorUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Edição de política  */}
            <Modal
                isOpen={showUpdateModal}
                header="Alterar Política"
                onClose={() => setShowUpdateModal(false)}
            >
                <form onSubmit={event => changePolicyList(event)}>
                    <ModalBody>
                        <Select
                            data-testid="change-policy-select"
                            isRequired
                            label="Selecione uma política"
                            name="policyId"
                            value={associatedPolicy}
                            onChange={onChange}
                        >
                            <option hidden>Selecione um nome</option>
                            {sortedPolicyItems.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>

                        {error && (
                            <Alert margin="int-mt-4">
                                <p>{errorUpdateParam}</p>
                            </Alert>
                        )}

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setShowUpdateModal(false);
                                setError(false)
                            }}>
                            Cancelar
                        </Button>
                        <Button
                            data-testid="change-policy-submit"
                            margin="int-ml-2"
                            type="submit"
                            disabled={disabled}
                            onClick={() => {
                                setShowLoaderFull(true)
                                setShowUpdateModal(false)
                                setShowSuccessUpdateModal(true)
                                setError(false)
                            }}>
                            Alterar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Mensagem adição / edição com sucesso  */}
            <SimpleModal
                header="Adição / edição do cliente"
                isOpen={showSuccessAddOrUpdateModal}
                onClose={() => setShowSuccessAddOrUpdateModal(false)}
                bodyMessage="A política do cliente foi alterada com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        setSelectedClients([])
                        fetchClientData()
                        setShowSuccessAddOrUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Mensagem de erro na atualização  */}
            <SimpleModal
                header="Erro ao alterar a política do cliente"
                isOpen={showErrorAddOrUpdateModal}
                onClose={() => setShowErrorUpdateModal(false)}
                bodyMessage={"Ocorreu um erro ao atualizar a política do cliente."}
                footerButton={{
                    label: 'Fechar',
                    onClick: () => {
                        setSelectedClients([])
                        setShowErrorAddOrUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Edição / Adição de conta e política  */}
            <Modal
                isOpen={showAddOrUpdateModal}
                header="Adicionar / Editar conta e política"
                onClose={() => setShowAddOrUpdateModal(false)}
            >
                <form onSubmit={event => changePolicyList(event)}>
                    <ModalBody>
                        <div className="int-row">
                            <div className="int-col int-pl-0">
                                <Input
                                    isRequired
                                    label="Conta"
                                    name="account"
                                    placeholder="Conta"
                                    value={account}
                                    onChange={(e) => setAccount(e.target.value)}
                                />
                            </div>
                            <div className="int-col int-pl-0">
                                <Select
                                    data-testid="change-policy-select"
                                    isRequired
                                    label="Selecione uma política"
                                    name="policyId"
                                    value={associatedPolicy}
                                    onChange={onChange}
                                >
                                    <option hidden>Selecione um nome</option>
                                    {sortedPolicyItems.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {error && (
                            <Alert margin="int-mt-4">
                                <p>{errorUpdateParam}</p>
                            </Alert>
                        )}

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setShowAddOrUpdateModal(false);
                                setError(false)
                            }}>
                            Cancelar
                        </Button>
                        <Button
                            data-testid="change-policy-submit"
                            margin="int-ml-2"
                            type="submit"
                            onClick={() => {
                                setSelectedClients([])
                                setShowAddOrUpdateModal(false)
                                setShowErrorAddOrUpdateModal(false)
                                setError(false)
                                setShowSuccessAddOrUpdateModal(true);
                            }}>
                            Alterar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {showLoaderFull && <Loader full />}
        </>
    )
}

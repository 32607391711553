import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom";

import { SymbolsList } from '../SymbolsList'

import { H3, H4 } from '../../../components/Typography' 
import { Button, Checkbox, Input, Select, TagsInput } from '../../../components/Forms'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal';
import { Loader } from '../../../components/Loader'
import { SmartTable } from '../../../components/Table'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from '../../../components/Alert';
import { UploadCSVModal } from '../../../components/UploadCSV/UploadCSVModal';

import { removeArrayItem, sanitizeName } from '../../../common/variables'
import { API_HEADER } from '../../../constants/api';

type Params = {
    id: string
}

type Data = {
    symbols: MarketObject[],
    description?: string
}

type MarketObject = {
    symbol: string;
    market: string;
}

export function VirtualMarketDetails() {
    const { id } = useParams<Params>()
    const history = useHistory()
    
    const [symbol, setSymbol] = useState()
    const [symbolsItems, setSymbolsItems] = useState([])
    const [symbolsWithMarketItems, setSymbolsWithMarketItems] = useState<MarketObject[]>([])
    const [allMarkets, setAllMarkets] = useState([]);

    const [data, setData] = useState<Data>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [deleteError, setDeleteError] = useState(false)
    const [disabledDelete, setDisabledDelete] = useState(false)
    const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false)
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    const [showVirtualMarketModal, setShowVirtualMarketModal] = useState(false);

    const [updateError, setUpdateError] = useState(false)
    const [disabledUpdate, setDisabledUpdate] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false)
    const [symbolsError, setSymbolsError] = useState(false);

    const [deleteVirtualMarketError, setDeleteVirtualMarketError] = useState(false)
    const [showDeleteVirtualMarket, setShowDeleteVirtualMarket] = useState(false)
    const [deleteVirtualMarketConfirm, setDeleteVirtualMarketConfirm] = useState("")
    const [deleteVirtualMarketMessageError, setDeleteVirtualMarketMessageError] = useState("")

    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)

    const [count, setCount] = useState("")
    const [dataError, setDataError] = useState("")

    const allChecked = data?.symbols?.map(i => i.symbol).every(x => symbolsItems.includes(x));
    const isIndeterminate = data?.symbols.some(i => symbolsItems.includes(i)) && !allChecked
    
    useEffect(() => {
        allStandardMarketsFetch()
        fetchVirtualMarket()
    }, [])

    async function allStandardMarketsFetch() {
        try {
            const response = await fetch('/api/policy/market/Standard')

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()
            setAllMarkets(data)
        } catch (err) {
            console.log(err)
        }
    }

    const onChange = (e) => {
        setSymbol(e.target.value.toUpperCase().trimStart());
    }

    const onChangeMarket = (name, market) => {
        var index = symbolsWithMarketItems.findIndex(x => x.symbol == name);
        let newArr = [...symbolsWithMarketItems]
        newArr[index].market = market   
        setSymbolsWithMarketItems(newArr)
    }

    const onAdd = arr => {
        const newArr = [...symbolsItems, ...arr].filter((item, index, arr) => { 
        return arr.indexOf(item) === index
        })
        setSymbolsItems(newArr);
    }

    const onAddBySheet = arr => {
        const newArr = [...symbolsItems, ...arr.map(i => i.symbol)].filter((item, index, arr) => {
            return arr.indexOf(item) === index
        })
        setSymbolsWithMarketItems([...arr])
        setSymbolsItems(newArr)
    }
    const onEnter = (s) => {
        setSymbol(s);
    }

    const checkDuplicated = (arr) => {
        var filterArray = [];
        let el = [...document.getElementsByClassName('int-tag')];

        el.forEach( function(element){
            if(data?.symbols.map(i => i.symbol).includes(element.textContent)){
                element.setAttribute("style", "background-color:var(--int-colors-red-500)")
            } else {
                element.setAttribute("style", "background-color:var(--int-colors-orange-400)")
            }           
        });

        arr.filter((item) => {
            if (data?.symbols.includes(item)) {
                filterArray.push("Existe")
            } else {
                filterArray.push("Novo")
            }
        })
        if (filterArray.some(x => x === "Novo")) {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }
    }

    useEffect(() => {
        checkDuplicated(symbolsItems)
    }, [symbolsItems])

    const fetchVirtualMarket = async () => {
        setLoading(true)

        try {
            const response = await fetch(`/api/virtualMarket/${id}`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()
            
            setData(data)
        } catch(err) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }
    const handleVirtualMarketFetch = async (event) => {
        event.preventDefault()
        setDeleteError(false)
        setUpdateError(false)
        setDisabledUpdate(true)

        try {
            const bodyRequest = {
                symbols: symbolsWithMarketItems.map((item) => ({
                    symbol: item.symbol,
                    market: item.market
                }))          
            }

            const options = {
                method: "POST",
                headers: API_HEADER,
                body: JSON.stringify(bodyRequest),
            }

            const response = await fetch(`/api/virtualMarket/${id}/symbols`, options)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const message = await response.json()

            setCount(message)
            setShowVirtualMarketModal(false)
            setShowSuccessUpdateModal(true)
            setSymbolsItems([])
            setSymbolsWithMarketItems([])
        } catch(err) {
            setDataError(err.message)
            setUpdateError(true)
        } finally {
            setDisabledUpdate(false)
        }
    }
    const handleVirtualMarket = async (event) => {
        event.preventDefault()    
        setError(false)
        setSymbolsError(false)

        if (symbolsItems.length === 0) {
            setSymbolsError(true)
            return event.stopPropagation()
        } 

        for(var i = 0; i < symbolsItems.length; i++){
            let symbol = symbolsItems[i]
            var index = symbolsWithMarketItems.findIndex(x => x.symbol == symbol);

            if(index == -1 && !data?.symbols.map(i => i.symbol).includes(symbol)){
                setSymbolsWithMarketItems((oldArray) => [...oldArray, {symbol: symbol, market: "TODOS"}]);
            }
        }

        setShowVirtualMarketModal(true)        
    }
    
    const onRemove = (index, itemsArr) => {
        var symbol = symbolsItems[index];
        setSymbolsItems(removeArrayItem(index,itemsArr))
        setSymbolsWithMarketItems([...symbolsWithMarketItems.filter(i => i.symbol != symbol)]);
    }

    const handleDeleteSymbols = async () => {
        setDeleteError(false)
        setUpdateError(false)
        setDisabledDelete(true)

        try {
            const bodyRequest = {
                symbols: symbolsItems 
            }
        
            const options = {
                method: "DELETE",
                headers: API_HEADER,
                body: JSON.stringify(bodyRequest),
            }

            const response = await fetch(`/api/virtualMarket/${id}/symbols`, options)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const message = await response.json()

            setCount(message)
            setSymbolsItems([])
            setSymbolsWithMarketItems([])
            setShowSuccessDeleteModal(true)
        } catch(err) {
            setDeleteError(true)
        } finally {
            setDisabledDelete(false)
        }
    }

    const handleDeleteVirtualMarket = async () => {
        try {
            const options = {
                method: "DELETE",
                headers: API_HEADER
            }

            const response = await fetch(`/api/virtualMarket/${id}`, options)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            setShowDeleteVirtualMarket(false)

            history.push('/virtual-market')
        } catch(err) {
            setDeleteVirtualMarketMessageError(err.message)
            setDeleteVirtualMarketError(true)
        }
    }

    const checkBoxColumnHandle = ({ target }) => {
        if (target.checked) {
            setSymbolsItems(symbolsItems.concat([target.value]))
        } else {
            setSymbolsItems(symbolsItems.filter(i => i !== target.value))
            setDisabledButton(false)
        }  
    }

    const allCheckBoxesHandle = ({ target }) => {
        if (target.checked) {
        const items = data?.symbols.map(i => i.symbol).filter(x => !symbolsItems.includes(x));
            setSymbolsItems(symbolsItems.concat(items))
        } else {
            setSymbolsItems(symbolsItems
            .filter(i => !data?.symbols.map(i => i.symbol)
            .includes(i)))
            setDisabledButton(false)
        }
    }

    const hideModalDeleteVirtualMarket = () => {
        setShowDeleteVirtualMarket(false)
        setDeleteVirtualMarketError(false)
        setDeleteVirtualMarketMessageError("")
        setDeleteVirtualMarketConfirm("")
    }

    const columns = [
        {
        id: 'Seleção',
        Header: () => 
            <Checkbox 
                data-testid="table-checkbox-header"
                onChange={allCheckBoxesHandle} 
                isChecked={allChecked}
            />,
        Cell: ({ row }) => 
            <Checkbox 
                data-testid="table-checkbox-body"
                value={row.original.symbol} 
                onChange={checkBoxColumnHandle} 
                isChecked={symbolsItems.find(i => i === row.original.symbol)} 
            />,
        width: '40px',
        minWidth: 40
        },
        {
        Header: 'Nome',
        accessor: data => data.symbol
        },
        {
        Header: 'Mercado',
        accessor: data => data.market
        },
    ]

    return (
        <>
            <SectionWrapper header={`Lista de Ativo: ${id.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}`}>
                <div className="int-d-flex int-justify-content-end">
                    <Button
                        data-testid="delete-virtual-market-button"
                        variant="outline"
                        onClick={() => setShowDeleteVirtualMarket(true)}
                    >
                        Apagar Lista de Ativos
                    </Button>
                </div>

                {data?.description && <p>Descrição: {data?.description}</p>}

                <H3>Ativos</H3>

                {loading && <Loader flex />}

                {error && (
                    <Alert 
                        margin="int-mt-5"
                    >
                        Ocorreu um erro ao carregar dados do mercado financeiro. Tente 
                        novamente mais tarde.
                    </Alert>
                )}

                {data && <SmartTable data={data?.symbols} columns={columns} localStorageKey={'VirtualMarketDetails'} columnsResizable />}

                <div className="int-d-flex int-flex-column int-mt-3">
                    <H4>Adicionar / Remover Ativos</H4>
                    <p className="int-mb-2">
                        Escreva os ativos que deseja adicionar ou remover do mercado virtual
                    </p>
                </div>

                <TagsInput 
                    id="symbols"
                    label="Ativos" 
                    required 
                    placeholder="Ativos"
                    inputValue={symbol}
                    tagsArr={symbolsItems}
                    onChange={onChange}
                    onRemove={(index) => onRemove(index,symbolsItems)}

                    onAdd={onAdd}
                    onEnter={onEnter} 
                />

                <Button 
                    margin="int-my-2" 
                    variant="outline" 
                    onClick={() => setIsCSVModalOpen(true)} 
                >
                    Importe os Ativos
                </Button>

                <div className="int-d-flex int-justify-content-end int-mt-2">
                    <Button
                        disabled={disabledUpdate || symbolsItems.length < 1 || disabledButton} 
                            onClick={handleVirtualMarket}
                        >
                        {disabledUpdate ? <Loader /> :  'Adicionar ativos'}
                    </Button>

                    <Button 
                        disabled={disabledDelete || symbolsItems.length < 1} 
                        onClick={() => setShowConfirmDeleteModal(true)} 
                        variant="outline" 
                        margin="int-ml-2"
                    >
                        {disabledDelete ? <Loader /> :  'Remover ativos'}
                    </Button>
                </div>

                {deleteError && ( 
                    <Alert 
                        margin="int-mt-5"
                    >
                        Ocorreu um erro ao remover os ativos do mercado. Tente novamente 
                        mais tarde.
                    </Alert>
                )}

                {updateError && (
                    <Alert 
                        margin="int-mt-5"
                    >
                        {dataError}
                    </Alert>
                )}
            </SectionWrapper>
            
            {/* Modal - Adicionar mercados dos ativos  */}
            <Modal
                isOpen={showVirtualMarketModal}
                header="Adicionar ativos"
                onClose={() => setShowVirtualMarketModal(false)}
            >
                <form onSubmit={handleVirtualMarketFetch}>
                    <ModalBody>
                        <table className="int-table int-mt-3">
                            <tbody>
                                {
                                symbolsWithMarketItems.length  == 0 ? 
                                    <p className="int-mt-5 int-text-center">
                                        Nenhum ativo foi adicionado
                                    </p>
                                :
                                symbolsWithMarketItems.map((row, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{row.symbol}</td>
                                            <td>
                                                <Select
                                                    data-testid="change-virtual-market-item-select"
                                                    name="market"
                                                    isRequired
                                                    onChange={(event) => onChangeMarket(row.symbol, event.target.value)}
                                                    value={row.market}
                                                >
                                                    <option hidden>Selecione um nome</option>
                                                    {allMarkets.map((item, index) => (
                                                        
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setError(false);
                                setShowVirtualMarketModal(false);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            data-testid="change-virtual-market-update-submit"
                            margin="int-ml-2"
                            type="submit"
                            onClick={() => {
                                setError(false);
                            }}
                        >
                            Adicionar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Mesagem de confirmação da remoção */}
            <Modal
                header="Remover itens da lista de ativos"
                isOpen={showConfirmDeleteModal}
                onClose={() => setShowConfirmDeleteModal(false)}
            >
                <ModalBody>
                    <p>
                        Você tem certeza que deseja remover os seguintes dados 
                        da lista de ativos?
                    </p>
                    
                    <SymbolsList symbols={symbolsItems} />
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() =>  setShowConfirmDeleteModal(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        data-testid="confirm-delete-symbols"
                        margin="int-ml-2"
                        variant="outline"
                        onClick={() => {
                            handleDeleteSymbols()
                            setShowConfirmDeleteModal(false)
                        }}
                    >
                        Remover
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal - Mensagem remoção com sucesso  */}
            <SimpleModal 
                header="Os ativos foram removidos"
                isOpen={showSuccessDeleteModal}
                onClose={() => setShowSuccessDeleteModal(false)}
                bodyMessage={count}
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchVirtualMarket()
                        setSymbolsItems([])
                        setShowSuccessDeleteModal(false)
                    }
                }}
            />

            {/* Modal - Mensagem atualização com sucesso  */}
            <SimpleModal 
                header="Os ativos foram atualizados"
                isOpen={showSuccessUpdateModal}
                onClose={() => setShowSuccessUpdateModal(false)}
                bodyMessage={count}
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchVirtualMarket()
                        setSymbolsItems([])
                        setSymbolsWithMarketItems([])
                        setShowSuccessUpdateModal(false)
                    }
                }}
            />
            
            {/* Modal - Confirmação para apagar lista de ativos  */}
            <Modal
                data-testid="delete-virtual-market-modal"
                header="Você tem certeza que quer apagar a lista atual?"
                isOpen={showDeleteVirtualMarket}
                onClose={hideModalDeleteVirtualMarket}
            >
                <ModalBody>
                    <p>
                        Essa ação <b>não pode</b> ser desfeita. Isso fará com 
                        que a lista e todos os seus dados sejam apagados 
                        <b>permanentemente</b>.
                    </p>
                    <p>Escreva <b>{id}</b> para confirmar.</p>

                    <Input
                        data-testid="delete-virtual-market-input"
                        name="deleteVirtualMarket"
                        value={deleteVirtualMarketConfirm}
                        onChange={event => setDeleteVirtualMarketConfirm(event.target.value)}
                    />

                    <Button
                        data-testid="delete-virtual-market-confirm-button"
                        isFullWidth
                        margin="int-mt-3"
                        variant="outline"
                        onClick={handleDeleteVirtualMarket}
                        disabled={ id === deleteVirtualMarketConfirm ? false : true }
                    >
                        Apagar Lista de Ativos
                    </Button>

                    { deleteVirtualMarketError ? 
                        <Alert 
                            margin="int-mt-5"   
                        >
                            {deleteVirtualMarketMessageError}
                        </Alert> : ''}
                </ModalBody>
            </Modal>

            <UploadCSVModal
                isModalOpen={isCSVModalOpen}
                onHide={() => setIsCSVModalOpen(false)}
                onAdd={onAddBySheet}
                header={"Importar Lista de Ativos"}
                model={"Ativos"}
            />
        </>
  )
}

import React, { Fragment, useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatInteger, formatMonthName, formatDecimal } from '../../../common/formatters';


export function CustodyUnknownMarket({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [custody, setCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientCustodyUnknownMarket() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/custodyUnknownMarket`);

            const data = await response.json();

            const custodyData = data?.map(item => ({
                ...item,
                avaliable: formatInteger(item.avaliable),
                maturityMonth: formatMonthName(item.maturityMonth),
                projectedD1: formatInteger(item.projectedD1),
                total: formatInteger(item.total),
                price: formatDecimal(item.price),
                settlementPrice: formatDecimal(item.settlementPrice),
                contractMultiplier: formatDecimal(item.contractMultiplier),
                value: formatDecimal(item.value)
            }))

            setCustody(custodyData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientCustodyUnknownMarket()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientCustodyUnknownMarket();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol,
        },
        {
            Header: 'Vencimento(mês)',
            accessor: data => data.maturityMonth,
        },
        {
            Header: 'Quant. na Abertura',
            accessor: data => data.avaliable,
            columnAlign: 'right',
        },
        {
            Header: 'Quant. no Dia',
            accessor: data => data.projectedD1,
            columnAlign: 'right',
        },
        {
            Header: 'Total',
            accessor: data => data.total,
            columnAlign: 'right',
        },
        {
            Header: 'Cotação',
            accessor: data => data.price,
            columnAlign: 'right',
        },
        {
            Header: 'Preço de Ajuste',
            accessor: data => data.settlementPrice,
            columnAlign: 'right',
        },
        {
            Header: 'Tamanho',
            accessor: data => data.contractMultiplier,
            columnAlign: 'right',
        },
        {
            Header: 'Valor',
            accessor: data => data.value,
        },
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Custódia Outros"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={custody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientCustodyUnknownMarket}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={custody} columns={columns} localStorageKey={'ClientCustodyUnknownMarket'}/>
                )}
        </SectionWrapper>
    );
}


import React, { ReactNode, TdHTMLAttributes } from 'react'
import { Tooltip } from '../Tooltip';

interface TableCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
    value?: string | number;
    children?: ReactNode;
    textAlign?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
    component?: 'th' | 'td';
    hint?: string;
}

export function TableCell({
    value,
    textAlign = 'inherit',
    component = 'td',
    hint,
    children,
    ...rest
}: TableCellProps) {

    const { isNumeric, isNegative } = negativeValidity(`${value}`);

    function negativeValidity(value: string): { isNumeric: boolean, isNegative: boolean } {
        let isNegative = false
        const isNumeric = !isNaN(parseFloat(String(value)))
        
        if(isNumeric){
            const formattedNumber = value.replace(/[^0-9\,-]+/g, "").replace(',', '.')
            const floatNumber = parseFloat(formattedNumber)
            isNegative = floatNumber < 0
        }

        return { isNumeric, isNegative }
    }

    const cellStyles = {
        ...rest.style,
        textAlign,
        color: isNumeric && isNegative ?
            'var(--int-colors-red-500)' :
            'inherit',
    }

    return component === 'td' ? (
        <td
            {...rest}
            style={{ ...cellStyles }}
            title={hint?? null}
        >
            {children ? children : (value ? String(value): null)}
        </td>
    ) : (
        <th
            {...rest}
            style={{ ...cellStyles, position:'relative' }}
        >
            {hint ?  <Tooltip label={hint} shouldShowIcon={false}/> : null}
            {children ? children : (value ? String(value): null)}
        </th>
    );
}
import React, { useRef, useState } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';

import { Button, Field } from '../../components/Forms';
import { SectionWrapper } from '../../components/SectionWrapper';

import { removeArrayItem, setArrayOfObjects } from '../../common/variables';

type TIntradayPosition = {
    symbol: string;
    openBuyQty: string;
    execBuyQty: string;
    execSellQty: string;
    openSellQty: string;
    execSellAvgPrice: string;
    execBuyAvgPrice: string;
    openSellAvgPrice: string;
    openBuyAvgPrice: string;
    operatorParameter: boolean;
}
interface IntradayPositionProps {
    label?: string;
    intradayPosition: TIntradayPosition[];
    setIntradayPosition: (value: TIntradayPosition[]) => void;
    intradayPositionItem: TIntradayPosition;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
    setValidationErrors: React.Dispatch<React.SetStateAction<[]>>;
    deleteOldErrors: (headerName: string, index: number) => void;
}

const headerName = "Posição Intraday"

export function IntradayPosition({
    label,
    intradayPosition,
    setIntradayPosition,
    intradayPositionItem,
    allAccordionsOpen,
    setValidationErrors,
    deleteOldErrors
}: IntradayPositionProps) {

    const highlightedItemRef = useRef(null);

    function addIntradayPosition() {
        if (intradayPosition.length !== 0) resetAnimation();
        setIntradayPosition([ intradayPositionItem, ...intradayPosition]);
        if (intradayPosition.length !== 0) activateAnimation();
    }

    function resetAnimation(): void {
        highlightedItemRef.current.style.animation = '';
        highlightedItemRef.current.style.animationIterationCount = '';
        void highlightedItemRef.current.offsetWidth;
    }

    function activateAnimation(): void {
        const animation = 'smooth-fade-in 3s linear forwards 1';
        highlightedItemRef.current.style.animation = animation;
        highlightedItemRef.current.children[0].children[0].children[0].focus();
    }

    function removeAllIntradayPosition() {
        setIntradayPosition([intradayPositionItem])
    }

    function removeAllErrors() {
        setValidationErrors([]);
    }

    return(
        <SectionWrapper
            headerTag="h3"
            header={`${headerName} ${!!label ? `- ${label}` : ''}`}
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
        >
            <div className="int-d-flex int-justify-content-end int-mb-4">
                <button
                    data-testid="intraday-add-row"
                    type="button"
                    className="int-btn-icon int-btn-icon-outline"
                    onClick={addIntradayPosition}
                >
                    <FiPlus size={20} />
                </button>
            </div>
            <table className="int-table">
                <thead>
                    <tr>
                        <th style={{width: '11%'}}>Ativo</th>
                        <th style={{width: '11%'}}>Compras executadas</th>                
                        <th style={{width: '11%'}}>PM Compras executadas</th>
                        <th style={{width: '11%'}}>Compras em aberto</th>
                        <th style={{width: '11%'}}>PM Compras em aberto</th>
                        <th style={{width: '11%'}}>Vendas executadas</th>
                        <th style={{width: '11%'}}>PM Vendas executadas</th>
                        <th style={{width: '11%'}}>Vendas em aberto</th>
                        <th style={{width: '11%'}}>PM Vendas em aberto</th>
                        <th />
                    </tr>
                </thead>

                <tbody>
                    {intradayPosition.map((item, index) => (
                        <tr key={index} ref={index === 0 ? highlightedItemRef : null}>
                            <td>
                                <Field
                                    data-testid="intraday-symbols"
                                    required
                                    format="Symbol"
                                    name="symbol"
                                    value={item?.symbol}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event, 
                                                true
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-execBuyQty"
                                    required
                                    format="Integer"
                                    name="execBuyQty"
                                    value={item?.execBuyQty}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-execBuyAvgPrice"
                                    required
                                    format="Decimal"
                                    name="execBuyAvgPrice"
                                    value={item?.execBuyAvgPrice}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-openBuyQty"
                                    required
                                    format="Integer"
                                    name="openBuyQty"
                                    value={item?.openBuyQty}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-openBuyAvgPrice"
                                    required
                                    format="Decimal"
                                    name="openBuyAvgPrice"
                                    value={item?.openBuyAvgPrice}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-execSellQty"
                                    required
                                    format="Integer"
                                    name="execSellQty"
                                    value={item?.execSellQty}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-execSellAvgPrice"
                                    required
                                    format="Decimal"
                                    name="execSellAvgPrice"
                                    value={item?.execSellAvgPrice}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-openSellQty"
                                    required
                                    format="Integer"
                                    name="openSellQty"
                                    value={item?.openSellQty}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <Field
                                    data-testid="intraday-openSellAvgPrice"
                                    required
                                    format="Decimal"
                                    name="openSellAvgPrice"
                                    value={item?.openSellAvgPrice}
                                    onChange={
                                        event => setIntradayPosition(
                                            setArrayOfObjects(
                                                intradayPosition, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <BsTrashFill
                                    data-testid="intraday-remove-item"
                                    size={22}
                                    color="var(--chakra-colors-orange-400)"
                                    className="ml-3 flex-shrink-0"
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                        () => [setIntradayPosition(
                                            removeArrayItem(index, intradayPosition)
                                        ), 
                                        deleteOldErrors(headerName, index)
                                    ]
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="int-my-4">
                <Button
                    variant="outline"
                    disabled={intradayPosition.length < 2}
                    onClick={() => [removeAllIntradayPosition(), removeAllErrors()]}
                >
                    Remover todos os items
                </Button>
            </div>
        </SectionWrapper>
    )
}
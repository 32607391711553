import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable } from "../../../components/Table";

import { formatInteger } from '../../../common/formatters';


export function Strategies({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [strategies, setStrategies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientStrategiesData() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/strategies`);

            const data = await response.json();

            const strategyData = data?.items.map(item => ({
                ...item,
                sellSymbol: item.sellSymbol,
                buySymbol: item.buySymbol,
                quantity: formatInteger(item.quantity),
                strategy: item.strategy,
            }))

            setStrategies(strategyData); 
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientStrategiesData()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientStrategiesData();
    }, [account]);

    const columns = [
        {
            Header: 'Estratégia',
            accessor: data => data.strategy,
        },
        {
            Header: 'Posição Comprada',
            accessor: data => data.buySymbol,
        },
        {
            Header: 'Posição Vendida',
            accessor: data => data.sellSymbol,
        },
        {
            Header: 'Quantidade',
            accessor: data => data.quantity,
        },
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Estratégias"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={strategies.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientStrategiesData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={strategies} columns={columns} localStorageKey={'ClientStrategiesData'}/>
                )}
        </SectionWrapper>
    );
}


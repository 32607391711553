import React, { useEffect, useState } from 'react'

import { H4 } from '../../components/Typography'
import { Button, Field } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import { SmartTable } from '../../components/Table'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'
import { formatDecimal, formatDate } from '../../common/formatters';
import { objToQueryString, dateQueryParse } from '../../common/api.js';

import { useQueryParams } from '../../hooks/useQueryParams'
import { fundsTransactionTypeEnum } from '../../common/enums'
import { FiSearch } from 'react-icons/fi'
import { SimpleModal } from '../../components/Modal/SimpleModal'

type QueryObject = {
    account?: string;
    dtRequestStart?: string;
    dtRequestEnd?: string;
    dtSettlementStart?: string;
    dtSettlementEnd?: string;
}

export function FundsTransactionHistory() {
    const { setParam, updateQuery } = useQueryParams();
    const [queryObject, setQueryObject] = useState<QueryObject>();
    const [client, setClient] = useState(setParam("account")); 
    const [initialRequestDate, setInitialRequestDate] = useState(setParam("dtRequestStart")); 
    const [finalRequestDate, setFinalReleaseDate] = useState(setParam("dtRequestEnd"));
    const [initialSettlementDate, setInitialSettlementDate] = useState(setParam("dtSettlementStart")); 
    const [finalSettlementDate, setFinalSettlementDate] = useState(setParam("dtSettlementEnd"));
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filtering, setFiltering] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState<[]>();
    const [searched, setSearched] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(true);   
    const today = new Date().toISOString().substr(0, 10);

    useEffect(() => {
        if (queryObject) {
            (async function () {
                await fetchFundsTransactionData()
                setLoading(false)
            })();
        };
    }, [queryObject]);


    const columns = [
        {
            Header: 'Nome',
            accessor: 'name'
        },
        {
            Header: 'Conta',
            accessor: 'account',
        },     
        {
            Header: 'Tipo',
            accessor: ({ type }) => fundsTransactionTypeEnum(type),
            width: 200
        },
        {
            Header: 'Valor',
            accessor: ({ value }) => formatDecimal(value),
            columnAlign: 'right'
        },
        {
            Header: 'Quantidade',
            accessor: ({ quantity }) => formatDecimal(quantity),
            columnAlign: 'right'

        },
        {
            Header: 'IOF',
            accessor: ({ iof }) => formatDecimal(iof),
            columnAlign: 'right'

        },
        {
            Header: 'Dt. Requisição',
            accessor: ({ requestDate }) => formatDate(requestDate),
            width: 175
        },   
        {
            Header: 'Dt. Liquidação',
            accessor: ({ settlementDate }) => formatDate(settlementDate),
            width: 175
        },
    ];

    const fetchFundsTransactionData = async () => {
        const apiQueryString = objToQueryString({
            ...queryObject,
            account: dateQueryParse(queryObject?.account),
            dtRequestStart: dateQueryParse(queryObject?.dtRequestStart),
            dtRequestEnd: dateQueryParse(queryObject?.dtRequestEnd),
            dtSettlementStart: dateQueryParse(queryObject?.dtSettlementStart),
            dtSettlementEnd: dateQueryParse(queryObject?.dtSettlementEnd)
        });
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {          
            setShowFilterModal(false);
            setFiltering(false);

            const response = await fetch(`/api/FundsTransaction?${apiQueryString}`);

            if(!response.ok) {
                const error = await response.json();
                throw error;
            }

            const data = await response.json();
            setResults(data);
        } catch(err) {
            setError(true);
        };      
    }
    
    const cleanFilters = () => {
        setClient("");
        setInitialRequestDate("");
        setFinalReleaseDate("");
        setInitialSettlementDate("");
        setFinalSettlementDate("");
        updateQuery(" ");
    }

    const setQueryObjectFilters = () => {

        const queryStringFilters = {
            account: client,
            dtRequestStart: initialRequestDate,
            dtRequestEnd: finalRequestDate,
            dtSettlementStart: initialSettlementDate,
            dtSettlementEnd: finalSettlementDate,
        };

        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();

        setQueryObject(queryStringFilters);
        updateQuery(objToQueryString(queryStringFilters));
    }

    const handleFundsTransactionSearch = async event => {
        event.preventDefault();
        setLoading(true);
        setError(false);

        if (!searched)
        setSearched(true);

        setQueryObjectFilters();
    }


    const renderFilterElements = () => {
        return(
            <form onSubmit={e => handleFundsTransactionSearch(e)}>
                <div className="int-mt-4 int-row">
                    <div className="int-col">
                        <H4>Data de Requisição</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <DateTime
                                    label="De"
                                    name="initialRequestDate"
                                    value={initialRequestDate}
                                    setDateTime={setInitialRequestDate}
                                    maxDate={today}
                                    timeDefault="00:00"
                                    notShowTimeInput
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <DateTime
                                    label="Para"
                                    name="finalRequestDate"
                                    value={finalRequestDate}
                                    setDateTime={setFinalReleaseDate}
                                    minDate={initialRequestDate ? initialRequestDate.substr(0, 10) : ''}
                                    maxDate={today}
                                    timeDefault="23:59"
                                    notShowTimeInput
                                />
                            </div>
                        </div>
                    </div>
                    <div className="int-col">
                        <H4>Data de Liquidação</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <DateTime
                                    label="De"
                                    name="initialSettlementDate"
                                    value={initialSettlementDate}
                                    setDateTime={setInitialSettlementDate}
                                    maxDate={today}
                                    timeDefault="00:00"
                                    notShowTimeInput
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <DateTime
                                    label="Para"
                                    name="finalSettlementDate"
                                    value={finalSettlementDate}
                                    setDateTime={setFinalSettlementDate}
                                    minDate={initialSettlementDate ? initialSettlementDate.substr(0, 10) : ''}
                                    maxDate={today}
                                    timeDefault="23:59"
                                    notShowTimeInput
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="int-mt-4 int-row">
                    <div className="int-col">
                        <H4>Conta do cliente</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <Field
                                    data-testid="fundsTransactionHistory-client"
                                    format="Number"
                                    value={client || ''}
                                    onChange={(e) => setClient(e?.target?.value.trimStart())}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {filtering && ( 
                    <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
                )}
            </form>
	)
    }
    
    return(
        <SectionWrapper header="Histórico de Movimentação de Fundos"        
        searchButton={
            <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
        }>
                <SimpleModal 
                    header="Filtros de busca"
                    isOpen={showFilterModal}
                    onClose={() => setShowFilterModal(false)}
                    bodyMessage={renderFilterElements()}
                    footerButtons={[
                        {
                            label: 'Limpar Todos os filtros',
                            onClick:() => cleanFilters(),
                            variant: 'outline'
                        },   
                        {
                            label: 'Buscar',
                            onClick: (e) => {
                                handleFundsTransactionSearch(e)
                            },
                            margin: 'int-ml-2'
                        }
                    ]}
                />                        

            {loading && <Loader flex />}

            {error && ( 
                <Alert>Ocorreu um erro na busca</Alert>
            )}
            
            { loading || error || filtering ? '' : results && results.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : results && (
                <SmartTable 
                columnFilters 
                columns={columns} 
                data={results} 
                gapColumnsFilter={2}
                columnsResizable  
                lockHeader = {true}
                localStorageKey={'FundsTransactionHistorySearch'}
                 />              
            )}
        </SectionWrapper>      
    )
}

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ClientsSection } from './ClientsSection'
import { AdvisorsSection } from './AdvisorsSection'
import { PolicySection } from './PolicySection'

import { H2 } from '../../../components/Typography'
import { Button, Input } from '../../../components/Forms'
import { Loader } from '../../../components/Loader'
import { Link } from '../../../components/Link'
import { Modal, ModalBody } from '../../../components/Modal'
import { Alert } from '../../../components/Alert'

import { API_HEADER } from '../../../constants/api'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { isAdmin } from '../../../services/auth'

type ParamsTypes = {
    code: string
}

type OperatorData = {
    description?: string;
    clients: string[];
    advisors: string[];
    policyId: number;
}

export function OperatorsDetails() {
    const history = useHistory()
    const { code } = useParams<ParamsTypes>()

    const [operatorData, setOperatorData] = useState<OperatorData>()
    const [policyName, setPolicyName] = useState<string>()
    const [error, setError] = useState<Error>()
    const [isFetchingData, setIsFetchingData] = useState(false)

    const [isShowingConfirmDeleteModal, setIsShowingConfirmDeleteModal] = useState(false)
    const [isDeletingOperator, setIsDeletingOperator] = useState(false)
    const [deleteOperatorConfirmation, setDeleteOperatorConfirmation] = useState("")
    const [deleteOperatorError, setDeleteOperatorError] = useState<Error>()

    async function fecthOperatorData() {
        setIsFetchingData(true)

        try {
            const response = await fetch(`/api/enteringTrader/${code}`)
            
            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }
            
            const data = await response.json()
            setOperatorData(data)
        } catch (err) {
            setError(err)
        } finally {
            setIsFetchingData(false)
        }
    }

    useEffect(() => {
        fecthOperatorData()
    }, [])

    async function handleDeleteOperator() {
        setIsDeletingOperator(true)
        try {
            const options = {
                method: 'DELETE',
                headers: API_HEADER,
            }

            const response = await fetch(`/api/enteringTrader/${code}`, options)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            history.push('/operators')
        } catch (err) {
            setDeleteOperatorError(err)
        } finally {
            setIsDeletingOperator(false)
        }
    }

    return(
        <>
            <SectionWrapper header="Detalhes do Operador">
                <div className="int-d-flex int-align-items-center int-justify-content-end">                    
                    <Button
                        data-testid="delete-entering-trader-button"
                        variant="outline"
                        onClick={() => setIsShowingConfirmDeleteModal(true)}
                    >
                        Excluir Operador
                    </Button>
                </div>

                <div className="int-d-flex int-justify-content-between int-mt-3">
                    <div>
                        <H2>Código: {code}</H2>

                        { operatorData?.description && <p>{operatorData.description}</p> }
                    </div>

                    <H2>
                        Política:{" "}
                        {operatorData?.policyId ? (
                            <Link href={`/policies/${operatorData.policyId}`}>{policyName}</Link>
                        ) : (
                                "------"
                        )}
                    </H2>
                </div>

                { isFetchingData && <Loader flex margin="int-mt-5" /> }

                { error?.message && (
                    <Alert
                        margin="int-mt-5"
                    >
                        {error.message}
                    </Alert>
                )}
            </SectionWrapper>

            { isFetchingData || !!error ? '' : (
                <>
                    <ClientsSection 
                        id="clients" 
                        code={code}
                        operatorClients={operatorData?.clients} 
                        fetchOperatorData={fecthOperatorData}
                    />

                    <AdvisorsSection 
                        id="advisors"
                        code={code}
                        operatorAdvisors={operatorData?.advisors}
                        fetchOperatorData={fecthOperatorData}
                    />
                    
                    {isAdmin() ? (
                    <PolicySection
                        id="policy"
                        code={code}
                        description={operatorData?.description}
                        policyId={operatorData?.policyId}
                        setPolicyName={setPolicyName}
                        fetchOperatorData={fecthOperatorData}
                    />) : ''}
                    
                </>
            )}

            {/* Modal de confirmação de deleção do Operador */}
            <Modal
                data-testid="delete-entering-trader-modal"
                isOpen={isShowingConfirmDeleteModal}
                onClose={() => setIsShowingConfirmDeleteModal(false)}
                header="Você tem certeza que quer apagar o operador atual?"
            >
                <ModalBody>
                    <p>Essa ação <b>não pode</b> ser desfeita. Isso fará com que o operador e todos os seus dados sejam apagados <b>permanentemente</b>.</p>
                    <p>Escreva <b>{code}</b> para confirmar.</p>

                    <Input
                        data-testid="delete-entering-trader-input"
                        name="delete-operator"
                        value={deleteOperatorConfirmation}
                        onChange={event => setDeleteOperatorConfirmation(event.target.value)}
                    />

                    <Button
                        data-testid="delete-entering-trader-confirm-button"
                        margin="int-mt-3"
                        variant="outline"
                        isFullWidth
                        onClick={handleDeleteOperator}
                        disabled={code === deleteOperatorConfirmation ? false : true || isDeletingOperator}
                    >
                        { isDeletingOperator ? (
                            <Loader size="sm" />
                        ) :  'Apagar Operador'}
                    </Button>

                    { deleteOperatorError?.message && (
                        <Alert 
                            margin="int-mt-2"
                        >
                            {deleteOperatorError.message}
                        </Alert>
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { Button, Input, Select, Textarea, TagsInput } from '../../../components/Forms'
import { SimpleModal } from '../../../components/Modal'
import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper';
import { Alert } from '../../../components/Alert'

import { sanitizeName } from '../../../common/variables';
import { removeArrayItem } from '../../../common/variables'

import { API_HEADER } from '../../../constants/api'

export function NewOperator() {
    const history = useHistory()

    const form = useRef<HTMLFormElement>(null)

    const [code, setCode] = useState("")
    const [description, setDescription] = useState("")
    const [clientsArr, setClientsArr] = useState<string[]>([])
    const [advisorsArr, setAdvisorsArr] = useState<string[]>([])

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>()
    const [isValidated, setIsValidated] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    
    const [client, setClient] = useState("")
    const [advisor, setAdvisor] = useState("")

    const [isShowingSuccessModal, setIsShowingSuccessModal] = useState(false)

    useEffect(() => {
        if (code) {
            setIsDisabled(false)
        } else {  
            setIsDisabled(true)
        }
    }, [code])

    function onChange(
        { target }: ChangeEvent<HTMLInputElement>, 
        setState: (value: string) => void
    ) {

        let inputValue = target.value

        inputValue = inputValue.replace(/[^0-9,\s]/g, '')

        setState(inputValue)
    } 

    function onEnter(
        value: string,
        setState: (value: string) => void
    ) {
        setState(value)
    }

    function onAdd(
        array: string[],
        stateArr: string[],
        setState: (value: string[]) => void
    ) {
        const newArr = [...stateArr, ...array].filter((item, index, arr) => {
            return arr.indexOf(item) === index;
        })

        setState(newArr)
    }

    function onRemove(
        index: number,
        itemsArr:string[],
        setState: (value: string[]) => void
    ) {
        setState(removeArrayItem(index,itemsArr))
    }

    function onSubmitAddInputInArray(
        value: string,
        stateArr: string[],
        setState: (value: string[]) => void
    ) {
        if(!stateArr.includes(value) && value) setState([...stateArr, value])
    }

    async function handleCreateNewOperator(event: FormEvent) {
        event.preventDefault()
        
        setIsValidated(false)
        setIsLoading(true)
        setError(null)

        try {
            const data = {
                enteringTraderId: code,
                description: description, 
                clients: clientsArr,
                advisors: advisorsArr
            }

            const options = {
                method: "POST",
                headers: API_HEADER,
                body: JSON.stringify(data),
            };

            const response = await fetch('/api/enteringTrader', options)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            setIsShowingSuccessModal(true)
        } catch(err) {
            setError(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <SectionWrapper header="Novo Operador">
            <form onSubmit={handleCreateNewOperator} ref={form}>
                <div className="int-mb-2 int-row">
                    <div className="int-col">
                        <Input 
                            data-testid="create-operator-code"
                            isRequired
                            label="Código"
                            name="code" 
                            placeholder="Código"
                            value={code}
                            onChange={(e) => setCode(sanitizeName(e.target.value))}
                        />
                    </div>
                </div>
                
                <Textarea
                    data-testid="create-operator-description"
                    name="description"
                    label="Descrição"
                    placeholder="Descrição" 
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />

                <div className="int-row int-mt-2">

                    <div className="int-col">
                        <TagsInput 
                            id="clients"
                            maxLength={25}
                            inputValue={client}
                            tagsArr={clientsArr}
                            label="Lista de Clientes"
                            placeholder="Lista de Clientes"
                            onChange={e => {
                                onChange(e, setClient)
                            }}
                            onRemove={(index: number) => {
                                onRemove(index, clientsArr, setClientsArr)
                            }}
                            onAdd={(array: string[]) => {
                                onAdd(array, clientsArr, setClientsArr)
                            }}
                            onEnter={(value: string) => {
                                onEnter(value, setClient)
                            }}
                        />
                    </div>


                    <div className="int-col int-pl-0">
                        <TagsInput
                            maxLength={25}
                            id="entering-trader"
                            inputValue={advisor}
                            tagsArr={advisorsArr}
                            label="Lista de Assessores"
                            placeholder="Lista de Assessores"
                            onChange={e => {
                                onChange(e, setAdvisor)
                            }}
                            onRemove={(index: number) => {
                                onRemove(index, advisorsArr, setAdvisorsArr)
                            }}
                            onAdd={(array: string[]) => {
                                onAdd(array, advisorsArr, setAdvisorsArr)
                            }}
                            onEnter={(value: string) => {
                                onEnter(value, setAdvisor)
                            }}
                        />
                    </div>
                </div>

                <Button 
                    margin="int-mt-4"
                    isFullWidth 
                    disabled={isDisabled}
                    type="submit"
                    onClick={() => {
                        onSubmitAddInputInArray(client, clientsArr, setClientsArr)
                        onSubmitAddInputInArray(advisor, advisorsArr, setAdvisorsArr)
                    }}
                >
                    { isLoading ? (
                        <Loader size="sm" />
                    ) : 'Cadastrar Operador'}
                </Button>
            </form>



            { error?.message && (
                <Alert
                    margin="int-mt-5"
                >
                    {error.message}
                </Alert>
            )}

            {/* Modal - Mensagem cadastro com sucesso  */}
            <SimpleModal 
                header="Operador cadastrado com sucesso!"
                isOpen={isShowingSuccessModal}
                onClose={() => setIsShowingSuccessModal(false)}
                bodyMessage="Um novo operador foi cadastrado com sucesso!"
                footerButtons={[
                    {
                        label: 'Novo Operador',
                        onClick: () => history.go(0),
                        variant: 'outline'
                    },
                    {
                        label: "Lista de Operadores",
                        onClick: () => history.push('/operators'),
                        margin: "int-ml-2"
                    }
                ]}
            />
        </SectionWrapper>
    )
}
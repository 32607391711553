import React, { ReactNode } from 'react' 

import "./styles.css"

interface ButtonGroupProps {
    children: ReactNode;
    margin?: string
}

export function ButtonGroup({ children, margin }: ButtonGroupProps) {
    return(
        <div className={`int-btn-group ${margin}`}>
            {children}
        </div>
    )
}
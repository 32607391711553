import React from 'react'

interface ParameterUpload {
    issuer?: string;
    symbol: string;
    redeemable?: string;
    value: string;
    comments?: string;
}

interface UploadParametersListProps {
    parameters: ParameterUpload[];
}

export function UploadParametersListFixedIncome({ parameters }: UploadParametersListProps) {
    return(
        <table className="int-table">
            <thead>
                <tr>
                    <th>Emissor</th>
                    <th>Tipo</th>
                    <th>Permite Resgate</th>
                    <th>Fator de Garantia</th>
                    <th>Comentário</th>
                </tr>
            </thead>

            <tbody>
                {parameters?.map((item, index) => (
                    <tr key={index}>
                        <td>{item.issuer  ?? '-'}</td>
                        <td>{item.symbol}</td>
                        <td>{item.redeemable  ?? '-'}</td>
                        <td>{item.value}</td>
                        <td>{item.comments ?? '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';

import { Button, Field, PolicyField, Select } from '../../components/Forms';
import { SectionWrapper } from '../../components/SectionWrapper';

import { checksIfParameterIsMarket, disableMarketSelection, disableSymbolSelection } from '../../common/checkers';
import { removeArrayItem, setArrayOfObjects } from '../../common/variables';

type Parameters = {
    name: string;
    market: string;
    symbol: string;
    value: string; 
    operatorParameter: boolean;
}

interface PolicyParametersProps {
    label?: string;
    parameters: Parameters[];
    setParameters: (value: Parameters[]) => void;
    parameterItem: Parameters;
    keys: any[];
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
    setValidationErrors: React.Dispatch<React.SetStateAction<[]>>;
    deleteOldErrors: (headerName: string, index: number) => void;
}

const headerName = "Política";

export function PolicyParameters({ 
    label, 
    parameters, 
    setParameters, 
    parameterItem,
    keys,
    allAccordionsOpen,
    setValidationErrors,
    deleteOldErrors
}: PolicyParametersProps) {
    const [allMarkets, setAllMarkets] = useState<string[]>([])
    const [standardMarkets, setStandardMarkets] = useState<string[]>([])

    const highlightedItemRef = useRef(null);

    useEffect(() => {
        allMarketsFetch()
        standardMarketsFetch();
    }, [])

    async function allMarketsFetch() {
        try {
            const response = await fetch('/api/policy/market')

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()
            setAllMarkets(data)
        } catch (err) {
            console.log(err)
        }
    }

    
    async function standardMarketsFetch() {
        try {
            const response = await fetch('/api/policy/market/standard')

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()
            setStandardMarkets(data)
        } catch (err) {
            console.log(err)
        }
    }

    function addNewParameter() {
        if(parameters.length !== 0) resetAnimation();
        setParameters([parameterItem, ...parameters])
        if(parameters.length !== 0) activateAnimation();
    }

    function resetAnimation(): void {
        highlightedItemRef.current.style.animation = ''
        highlightedItemRef.current.style.animationIterationCount = ''
        void highlightedItemRef.current.offsetWidth;
    }

    function activateAnimation(): void {
        const animation = 'smooth-fade-in 3s linear forwards 1';
        highlightedItemRef.current.style.animation = animation;
        highlightedItemRef.current.children[0].children[0].children[0].focus();
    }

    function removeAllParameters() {
        setParameters([parameterItem])
    }

    function removeAllErrors() {
        setValidationErrors([]);
    }

    const markets = (name: string) => {
        if (checksIfParameterIsMarket(keys, name)) {
            return standardMarkets
        } else {
            return allMarkets
        }   
    }

    return(
        <SectionWrapper
            headerTag="h3"
            header={`${headerName} ${!!label ? `- ${label}` : ''}`}
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isOpenByDefault
        >
            <div className="int-d-flex int-justify-content-end int-mb-4">
                <button
                    data-testid="parameters-add-row"
                    type="button"
                    className="int-btn-icon int-btn-icon-outline"
                    aria-label="icon"
                    onClick={addNewParameter}
                >
                    <FiPlus size={20} />
                </button>
            </div>
            <table className="int-table">
                <thead>
                    <tr>
                        <th style={{width: '24%'}}>Nome</th>
                        <th style={{width: '24%'}}>Mercado</th>
                        <th style={{width: '24%'}}>Símbolo</th>
                        <th style={{width: '28%'}}>Valor</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((parameter, index) => (
                        <tr key={index} data-testid="parameter-item"
                        ref={index === 0 ? highlightedItemRef : null}
                        >
                            <td>
                                <Select
                                    data-testid="parameters-name"
                                    required
                                    name="name"
                                    value={parameter?.name}
                                    onChange={
                                        event => setParameters(
                                            setArrayOfObjects(
                                                parameters, 
                                                index, 
                                                event, 
                                                null,
                                                keys
                                            )
                                        )
                                    }
                                    index={index}
                                >
                                    <option hidden value="">Selecione um nome</option>
                                    {keys.map((group, index) => group.length > 0 ? (
                                            <optgroup label={group[0]?.groupName} key={index}>
                                                {group?.map((item, index) => (
                                                    <option data-testid="parameters-name-options" key={index} value={item.name}>
                                                        {item.friendlyName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ): ''
                                    )}
                                </Select>
                            </td>

                            <td>
                                <Select
                                    data-testid="parameters-market"
                                    required
                                    name="market"
                                    disabled={disableMarketSelection(keys, parameter)}
                                    value={parameter?.market ?? ''}
                                    onChange={
                                        event => setParameters(
                                            setArrayOfObjects(
                                                parameters, 
                                                index, 
                                                event,
                                                null
                                            )
                                        )
                                    }
                                    index={index}
                                >
                                    <option hidden value="">Selecione um mercado</option>
                                    {markets(parameter?.name).map((item, index) => (
                                        <option data-testid="parameters-market-options" key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </Select>
                            </td>

                            <td>
                                <Field
                                    data-testid="parameters-symbol"
                                    format="Symbol"
                                    name="symbol"
                                    disabled={disableSymbolSelection(keys, parameter)}
                                    value={parameter?.symbol ?? ''}
                                    onChange={
                                        event => setParameters(
                                            setArrayOfObjects(
                                                parameters, 
                                                index, 
                                                event,
                                                true
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <PolicyField
                                    data-testid="parameters-value"
                                    required
                                    keys={keys?.flat()}
                                    keyName={parameter?.name}
                                    name="value"
                                    value={parameter?.value ?? ''}
                                    onChange={
                                        event => setParameters(
                                            setArrayOfObjects(
                                                parameters, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <BsTrashFill
                                    data-testid="parameters-remove-item"
                                    size={22}
                                    color="var(--chakra-colors-orange-400)"
                                    className="ml-3 flex-shrink-0"
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                        () => [setParameters(
                                            removeArrayItem(index, parameters)
                                        ),
                                        deleteOldErrors(`${headerName}_${label}`, index)
                                    ]
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="int-my-4">
                <Button
                    variant="outline"
                    disabled={parameters.length < 2}
                    onClick={() => [removeAllParameters(), removeAllErrors()]}
                >
                    Remover todos os items
                </Button>
            </div>
        </SectionWrapper>
    )
}
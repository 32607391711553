import React, { ReactNode,useEffect } from 'react'

interface ModalProps {
    header: string;
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    size?: 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg'
}

export function Modal({ header, children, isOpen, size = 'lg', onClose, ...rest }: ModalProps) {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onClose()
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        }
    }, []);
    return (
        <div className={`int-modal ${isOpen ? 'active' : ''}`} {...rest}>
            <div 
                className={`int-modal-content int-modal-content-${size}`}
                style={{ maxHeight: "85vh", overflowY: "auto" }}
            >
                <div className="int-modal-header">
                    {header}

                    <button 
                        data-testid="close-modal-button"
                        className="int-modal-close-button"
                        onClick={onClose}
                    ></button>
                </div>

                {children}
            </div>
        </div>
    )
}
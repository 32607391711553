type FalsyType = typeof NaN | 0 | '' | null | undefined;

interface ParamItem {
  policyKeyType: string,
  value: boolean | string | number
}

/**
* Formata valor para o modelo do banco de dados.
* @param {string|number|null} value
* 
* @example 
*   const formattedValue = formatDbNumber('1.020,15')
* 
* @returns {boolean|number|string} 1020.15
*/
export const formatDbNumber = (
  value: string | number | FalsyType
): boolean | number | string => {

  if (value == "") return null

  const upperCaseValue = value?.toString().toUpperCase()

  if (upperCaseValue === "SIM" || upperCaseValue === "NÃO")
    return upperCaseValue === "SIM"

  let numberValue = `${value}`;

  numberValue = numberValue.replace(/[^0-9,-]+/g, "");
  numberValue = numberValue.replace(/,/g, ".");

  if (isNaN(parseFloat(numberValue)) || isNaN(parseInt(numberValue)))
    return value

  if (numberValue.includes('.'))
    return parseFloat(numberValue)

  return parseInt(numberValue)
}

/**
 * Formata o valor para inteiro parão pt-br
 * @param {number|string|FalsyType} value
 * 
 * @example 
 *   const formatteValue = formatInteger(1500)
 * 
 * @returns {string} '1.500'
 */
export const formatInteger = (value: number | string | FalsyType): string => {
  if(!value && value !== 0) return '-'

  let integerValue = `${value}`;

  integerValue = integerValue.replace(/[a-zA-Z]+/g, "");

  return parseFloat(integerValue).toLocaleString("pt-br");
};

/**
 * Formata valor para decimal padrão pt-BR
 * @param {number|string|FalsyType} value
 * 
 * @example 
 *   const decimalValue = formatDecimal(2300)
 * 
 * @returns {string} 2.300,00
 */
export const formatDecimal = (value: number | string | FalsyType, fractionDigits = 2, signalInversion = false): string => {
  if(!value && value !== 0) return '-'

  let decimalValue = `${value}`;

  decimalValue = decimalValue.replace(/[a-zA-Z]+/g, "")
  let convertedValue = parseFloat(decimalValue);
  if(signalInversion && convertedValue != 0){
    convertedValue = convertedValue * -1;
  }

  return convertedValue.toLocaleString("pt-br", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });
};

/**
 * Converte um valor boolean para 'Sim' ou 'Não'
 * @param {boolean} value
 * 
 * @example formatBoolean(true)
 * 
 * @returns {string} Sim
 */
export const formatBoolean = (value: boolean): string => {
  return value ? 'Sim' : 'Não'
}

/**
 * Formata valores dos parâmetros
 * @param {ParamItem} item
 * @param {boolean=false} isInputValue
 * 
 * @example
 *  const item = formatParamValue({ policyKeyType: "Bool", value: true })
 * 
 * @returns {string | number | boolean} - Sim
 */
export const formatParamValue = (
  item: ParamItem, 
): string | number | boolean => {

  if (item.policyKeyType === "Bool")
    return item.value.toString().toUpperCase() === "TRUE" ? "Sim" : "Não";

  if(typeof item.value !== 'boolean'){
    if (item.policyKeyType === "Currency" || item.policyKeyType === "Decimal") 
      return formatDecimal(item.value)
      
    if (item.policyKeyType === "Integer") return formatInteger(item.value);
  }

  return item.value;
};

/**
 * Formata valores number para CPF ou CNPF 
 * @param {number} document
 * 
 * @example formatCPFCNPJ(00116190000)
 * 
 * @returns {string} - 001.161.900-00
 */
export const formatCpfCnpj = (document: number):string => {
  let value = `${document}`;

  const CPF_LENGTH = 11;
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === CPF_LENGTH) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");

};

/**
 * Retorna o nome por extenso do mês em questão
 * 
 * @param {string|number} value
 * 
 * @example 
 *   formatMonthName(2)
 * 
 * @returns {string} 'Fev'
 */
export const formatMonthName = (value: string | number | FalsyType): string => {
  const monthNames = [
    "-", "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
    "Jul", "Ago", "Set", "Out", "Nov", "Dez"
  ];

  const monthIndex = parseInt(`${value}`)
  
  if(!value || monthIndex > 12) return '-'
  
  return monthNames[monthIndex];
};

var datesArr = ['0001-01-01', '9999-12-31']
/**
 * Formata valor como Data modelo pt-BR
 * @param {string|FalsyType} value
 * 
 * @example
 *   const dateValue = formatDate('2021-06-29')
 * 
 * @returns {string} 29/06/2021
 */
export const formatDate = (value: string | FalsyType): string => {
  if (value && typeof value !== 'number') {
    let date = value?.split('T')[0];
    if(datesArr.includes(date)){
      return '-';
    }
    let year = date?.split('-')[0];
    let month = date?.split('-')[1];
    let day = date?.split('-')[2];

    return `${day}/${month}/${year}`;
  }

  return '-';
}

/**
 * Formata DateTime ISO 
 * @param {string|FalsyType} value
 * 
 * @example
 *   formatDateTime('2021-06-29T12:00:00-03:00')
 * 
 * @returns {string} - 29/06/2021 12:00:00
 */
export const formatDateTime = (value: string | FalsyType):string => {
  if (value && typeof value !== 'number') {
    let date = value?.split('T')[0];
    let hours = value?.split('T')[1];
    if(datesArr.includes(date)){
      return '-';
    }
    let year = date?.split('-')[0];
    let month = date?.split('-')[1];
    let day = date?.split('-')[2];

    date = `${day}/${month}/${year}`;

    if (hours.includes('.'))
      hours = hours?.split('.')[0];
    else
      hours = hours?.split('-')[0];

    return `${date} ${hours}`;
  }

  return "-";
}

/**
 * Converte valores específicos de um objeto para formato numérico que banco entenda passando o objeto e as suas keys
 * 
 * @param {T} object
 * @param {string[]} accessors
 * 
 * @example 
 *   object = {
 *      keyA: '01',
 *      keyB: '-2',
 *      keyC: true
 *   }
 * 
 *   const newObj = formatObjectNumberValues(object, ['keyA', 'keyB'])
 * 
 * @returns {T} { keyA: 1, keyB: -2, keyC: true }
 */
export function formatObjectNumberValues<T>(
  object: T,
  accessors: string[]
): T {
  let newObject = object

  accessors.map(
    (item) =>
      (newObject = {
        ...newObject,
        [item]: formatDbNumber(object[item])
      })
  );

  return newObject
}

/**
 * Converte valores específicos de um objeto dentro de um array para 
 * formato que banco entenda. É passado o array e keys dos objetos 
 * desejadas 
 * 
 * @param {Array<T>} object
 * @param {string[]} accessors
 * 
 * @example 
 *   const sendedArr = [{
 *     boolean: 'Sim',
 *     string: 'fake-value',
 *     integer: '1.000',
 *     decimal: '320,00'
 *   }]
 * 
 *   const formattedArr = formatArrayNumberValues(sendedArr, ['boolean', 'string', 'integer', 'decimal'])
 * 
 * @returns {Array<T>} [{boolean: true, string: 'fake-value', integer: 1000, decimal: 320.00}]
 */
export const formatArrayNumberValues = <T>(
  object: T[], 
  accessors: string[]
): any[]  => {
  const newArray = object.map(
    item => {
      let itemObject = item

      accessors.map(accessor => 
        itemObject = { ...itemObject, [accessor]: formatDbNumber(item[accessor])})

      return itemObject
    }
  )

  return newArray
}

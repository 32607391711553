import './timePicker.css';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Select } from '../Select';


interface DateTimeProps extends InputHTMLAttributes<HTMLInputElement> {
    value: any;
    name: string;
    label?: string;
    minDate?: string;
    maxDate?: string;
    timeDefault?: string;
    isRequired?: boolean;
    notShowTimeInput?: boolean;
    setDateTime: (value?: string) => void;
    onBlurDate?: () => void;
    hintIcon?: boolean
}

export function DateTime({
    label,
    name,
    value,
    minDate = "",
    maxDate = "",
    timeDefault = "23:59:59",
    setDateTime,
    isRequired = false,
    notShowTimeInput = false,
    onBlurDate = () => { },
    hintIcon,
    children
}: DateTimeProps) {

    const [hora, setHora] = useState("00");
    const [minutos, setMinutos] = useState("00");
    const [segundos, setSegundos] = useState("00");

    var hor = [];
    var min_seg = [];

    useEffect(() => {
        const timeSplit = timeDefault.split(":");
        setHora(timeSplit[0]);
        setMinutos(timeSplit[1]);
        if(timeSplit[2]) {
        setSegundos(timeSplit[2]);
        }
    },[])


    const handleDate = (e) => {
        if (e) {
            const dateTimeValue = e + "T" + (hora + ":" + minutos + ":" + segundos);
            setDateTime(dateTimeValue);
        } else {
            setDateTime()
        }
    }

    const handleTime = () => {
        let time = hora + ":" + minutos + ":" + segundos;

        const date = value?.split("T")[0];

        if (date) {
            const dateTimeValue = date + "T" + time;
            setDateTime(dateTimeValue);
        } else {
            setDateTime("");
        }
    }

    const abrirSelection = () => {
        document.getElementById(name).style.display == "none" ? document.getElementById(name).style.display = "flex" : document.getElementById(name).style.display = "none";
    }
    for (var i = 0; i < 60; i++) {
        if (i <= 9) {
            min_seg.push("0" + i)
        }
        if (i < 24) {
            if (i <= 9) {
                hor.push("0" + i)
            }
            else {
                hor.push(i)
            }
        }
        else {
            min_seg.push(i);
        }
    }

    useEffect(() => {
        handleTime();
    }, [hora, minutos, segundos]);

    return (

        <div style={{ display: "flex", alignItems: "flex-end"}} >
            <div style={{ width: notShowTimeInput ? "100%" : "53%"}} id={name}>
                <div className="label-header">
                    {label && <label className="int-form-label">{label} {isRequired ? <span style={{ color: 'var(--int-colors-red-500)' }}>*</span> : ''}</label>}
                    {hintIcon && <span>{children}</span>}
                </div>
                <input
                    data-testid="date-input"
                    className="int-form-control int-mr-2"
                    style={{ paddingInlineStart: "0.5rem", paddingInlineEnd: "0.5rem" }}
                    type="date"
                    value={value?.split("T")[0] || ''}
                    min={minDate}
                    max={maxDate}
                    onChange={(e) => handleDate(e.currentTarget.value)}
                    onBlur={onBlurDate}
                />
            </div>

            <div className="container-picker-horario" style={{display: notShowTimeInput? 'none' : 'flex'}}>
                <div style={{ width: "80%" }}>
                    <input
                        disabled={value?.split("T")[0] != null ? false : true}
                        data-testid="time-input"
                        className="int-form-control"
                        style={{ paddingInlineStart: "0.5rem", paddingInlineEnd: "0.5rem" }}
                        type="text"
                        value={value?.split("T")[0] ? value?.split("T")[1] : "--:--:--"}
                        onChange={() => handleTime()}
                        onClick={abrirSelection}
                    />
                </div>

                <div id={name} className="box-time-picker">
                    <div className="box-select-time int-mx-1">
                        <Select
                            name={`${name}hora`}
                            value={hora}
                            onChange={(e) => setHora(e.currentTarget.value)}
                            style={{paddingInline: ".1rem"}}
                            >
                            {hor.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}

                        </Select>
                    </div>
                    <div className="box-select-time int-mx-1">
                        <Select
                            name={`${name}minutos`}
                            value={minutos}
                            onChange={(e) => setMinutos(e.currentTarget.value)}
                            style={{paddingInline: ".1rem"}}
                        >
                            {min_seg.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="box-select-time int-mx-1">
                        <Select
                            name={`${name}segundos`}
                            value={segundos}
                            onChange={(e) => setSegundos(e.currentTarget.value)}
                            style={{paddingInline: ".1rem"}}
                        >
                            {min_seg.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react'

interface ParameterUpload {
    id: string;
    clubName: string;
    value: string;
    comments?: string;
}
interface UploadParametersListProps {
    parameters: ParameterUpload[];
}

export function UploadParametersListClubs({ parameters }: UploadParametersListProps) {
    return(
        <table className="int-table">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Nome do Clube</th>
                    <th>Fator de Garantia</th>
                    <th>Comentário</th>
                </tr>
            </thead>

            <tbody>
                {parameters?.map((item, index) => (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.clubName}</td>
                        <td>{item.value}</td>
                        <td>{item.comments ?? '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

import React, { useState } from "react";

import { SearchInput } from "../../components/SearchInput";
import { Loader } from '../../components/Loader'
import { SmartTable } from '../../components/Table'
import { SectionWrapper } from "../../components/SectionWrapper";
import { Alert } from "../../components/Alert";

import { formatDate, 
        formatDateTime, 
        formatDecimal, 
        formatInteger}
        from "../../common/formatters";
import { sanitizeName } from "../../common/variables";
import { objToQueryString } from "../../common/api.js";

import { useQueryParams } from '../../hooks/useQueryParams'

type MarketDataItem = {
    symbol: string;
    lastPrice: string;
    closingPrice: string;
    market: string;
    underlyingSymbol: string;
    contractMultiplier: string;
    positionMargin: string;
    intradayMargin: string;
    updateTime: string;
    settlementPrice: string;
}

export function MarketData() {
    const { setParam, updateQuery } = useQueryParams()

    const [symbols, setSymbols] = useState(setParam("q"));

    const [results, setResults] = useState<MarketDataItem[]>();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const formatPriceUpdateDate = (updateTime: string): string=> {
        var currentDate = new Date();

        if (updateTime == '-')
            return updateTime
        if (updateTime.slice(0,2) == currentDate.getDate().toString())
            return updateTime.slice(11,)
        else
            return updateTime
    }

    async function searchQuotation(e) {
        e.preventDefault();
        setIsLoading(true);
        setError(false);

        let requestSymbols = symbols;

        if (symbols) requestSymbols = requestSymbols.replace(/\s/g, "");

        const queryString = objToQueryString({
        q: requestSymbols,
        });

        updateQuery(queryString)

        try {
            const response = await fetch(`/api/MarketData?${queryString}`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()

            const marketData = data.map(item => ({
                ...item,
                activeDays: formatInteger(item.activeDays),
                price: formatDecimal(item.price),
                currencyFactor: formatDecimal(item.currencyFactor, 3),
                cdiTax: formatDecimal(item.cdiTax, 3),
                lastPrice: formatDecimal(item.lastPrice),
                closingPrice: formatDecimal(item.closingPrice),
                settlementPrice: formatDecimal(item.settlementPrice),
                margin: formatDecimal(item.margin, 4),
                contractMultiplier: formatDecimal(item.contractMultiplier),
                updateTime: formatPriceUpdateDate(formatDateTime(item.updateTime)),
                maturityDate: formatDate(item.maturityDate),
                priceDivisor: formatDecimal(item.priceDivisor, 3)
            }))

            setResults(marketData)
        } catch (err) {
            setError(true)
        }
        
        setIsLoading(false);
    };

    const columns = [
        {
            Header: 'Ativo',
            accessor: 'symbol',
        },
        {
            Header: 'Último Preço',
            accessor: 'price',
        },
        {
            Header: 'Pr. Fechamento',
            accessor: 'closingPrice',
        },
        {
            Header: 'Pr. Ajuste',
            accessor: 'settlementPrice'
        },
        {
            Header: 'Mercado',
            accessor: 'market',
        },
        {
            Header: 'Ativo Obj',
            accessor: 'underlyingSymbol',
        },
        {
            Header: 'Tamanho',
            accessor: 'contractMultiplier',
        },
        {
            Header: 'Margem',
            accessor: 'margin',
        },
        {
            Header: 'Vencimento',
            accessor: 'maturityDate',
        },
        {
            Header: 'Dias úteis',
            accessor: 'activeDays',
        },        
        {
            Header: 'Moeda',
            accessor: 'currencyFactor',
        },
        {
            Header: 'CDI',
            accessor: 'cdiTax',
            Cell: ({ value }) => value == null ? '-' : value
        },
        {
            Header: 'Divisor',
            accessor: 'priceDivisor',
        },
        {
            Header: 'Atualização',
            accessor: 'updateTime',
        }
    ]

    return (
        <SectionWrapper header="Buscar Cotação">
            <p className="int-my-4">
                Escreva os ativos e receba os resultados de cotação (Escreva múltiplos ativos separados por vírgula)
            </p>

            <SearchInput
                value={symbols}
                required
                placeholder="Buscar Cotação"
                isFetchingData={isLoading}
                onChange={e => setSymbols(sanitizeName(e.target.value, true))}
                onSubmit={e => searchQuotation(e)}
            />

            {isLoading && <Loader flex/>}

            {error && (
                <Alert 
                    margin="int-mt-5"
                >
                    Ocorreu um erro na busca
                </Alert>
            )}

            {isLoading || error ? "" : !results ? '' : results.length <= 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : <SmartTable columns={columns} data={results} localStorageKey={'MarketDataSearch'} />}
        </SectionWrapper>
    );
};

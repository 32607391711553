import React, { ReactNode, useState, HTMLAttributes, useEffect } from 'react'

import { H1, H2, H3, H4 } from '../Typography'

interface SectionWrapperProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    header: string;
    searchButton?: JSX.Element;
    buttonGroup?: JSX.Element;
    headerTag?: "h1" | "h2" | "h3" | "h4";
    isAccordion?: boolean;
    autoOpenAccordion?: boolean;
    isEmpty?: boolean;
    allAcordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
    isOpenByDefault?: boolean;
}

export function SectionWrapper({ 
    children,
    header,
    headerTag = "h1", 
    searchButton,
    buttonGroup,
    isAccordion = false,
    autoOpenAccordion = false,
    allAcordionsOpen,
    isEmpty = false,
    isOpenByDefault,
    ...rest 
}: SectionWrapperProps) {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    let headerRenderData;

    switch(headerTag) {
        case "h1" :
            headerRenderData = (<H1>{header}</H1>)
            break;
        case "h2" :
            headerRenderData = (<H2>{header}</H2>)
            break;
        case "h3" :
            headerRenderData = (<H3>{header}</H3>)
            break;
        case "h4" :
            headerRenderData = (<H4>{header}</H4>)
            break;
    }

    function handleOpenAccordion() {
        setIsAccordionOpen(oldState => !oldState);
    }

    var styles = {
        maxWidth: "100%"
    }

    useEffect(() => {
        if (!isAccordionOpen && isOpenByDefault) {
            setIsAccordionOpen(isOpenByDefault);
        } 
        else if (allAcordionsOpen?.isAllAccordionOpen 
            && (!isEmpty || allAcordionsOpen.count > 0)) {
                setIsAccordionOpen(true);
        }
        else {
            setIsAccordionOpen(false);
        }
    }, [allAcordionsOpen]); 

    useEffect(() => {
        if(autoOpenAccordion) setIsAccordionOpen(true)
    })

    if(isAccordion) {
        return(
            <section 
                className={`int-accordion-item ${isAccordionOpen ? 'active' : ''} ${autoOpenAccordion ? 'int-m-0-force' : ""}`}
                data-testid="accordion-item" 
                style={styles}
                {...rest}
            >
                <div className={`${!autoOpenAccordion ? 'int-show-tab int-accordion-label' : 'int-hide-tab'}`}  onClick={handleOpenAccordion}>
                    {headerRenderData}
                </div>
                
                <div className="int-accordion-content">
                    {children}
                </div>
            </section>
        )
    }

    return(
        <section 
            className="int-section-item"
            style={styles}
            {...rest}
        >
            <div className="int-section-label" style={{display: 'flex',flexDirection:'row', justifyContent:'space-between'}}>
                {headerRenderData}
                {searchButton != null && ( 
                    searchButton
                )}
                {buttonGroup != null && (
                    buttonGroup
                )}
            </div>
            <div className="int-section-content int-px-6 int-py-4">
                {children}
            </div>

        </section>
    )
}
import React, { useContext } from 'react';

import IntelitraderLogo from '../../../assets/intelitrader.svg'
import { ThemeContext } from '../../../contexts/ThemeContext';

export function Footer() {
    const year = new Date().getFullYear()

    const context = useContext(ThemeContext)

    return (
        <footer
            className="int-px-4 int-py-8 int-d-flex int-flex-column int-align-items-start"
            style={{    
                boxShadow: `${context.theme === "dark" ? "8px 0px 12px rgba(51, 56, 62, 0.8)" : "1px 0px 4px rgba(51, 56, 62, 0.4)"}`
            }}
        >
            <img src={IntelitraderLogo} alt="Intelitrader" />

            <p className="int-mt-20">
                @ {year} - Gerenciador de Risco
            </p>
        </footer>
    );
};

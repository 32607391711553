import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
    Button,
    Input
} from "../../../components/Forms";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { SimpleModal } from "../../../components/Modal";
import { Loader } from "../../../components/Loader";
import { Alert } from "../../../components/Alert";

import { sanitizeName } from "../../../common/variables";
import { formatDbNumber, formatDecimal } from "../../../common/formatters";

export function NewMtmRegistry() {

    const history = useHistory();

    const [instrument, setInstrument] = useState("");
    const [instrumentOrigin, setInstrumentOrigin] = useState("");
    const [instrumentId, setInstrumentId] = useState("");
    const [exchange, setExchange] = useState("");
    const [referencePrice, setReferencePrice] = useState("");
    const [holdingDay, setHoldingDay] = useState("");
    const [maxMarginTheoreticalBuy, setMaxMarginTheoreticalBuy] = useState("");
    const [maxMarginTheoreticalSell, setMaxMarginTheoreticalSell] = useState("");
    const [minMarginCreditAmountPhi1, setMinMarginCreditAmountPhi1] = useState("");
    const [minMarginCreditAmountPhi2, setMinMarginCreditAmountPhi2] = useState("");

    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const form = React.useRef(null);

    const handleMtmRegistry = async (event) => {
        event.preventDefault();
        setLoading(true)

        try {
            var request = [{
                instrument: instrument,
                instrumentOrigin: instrumentOrigin,
                instrumentId: instrumentId,
                exchange: exchange,
                referencePrice: formatDbNumber(referencePrice),
                holdingDay: Number(holdingDay),
                maxMarginTheoreticalBuy: formatDbNumber(maxMarginTheoreticalBuy),
                maxMarginTheoreticalSell: formatDbNumber(maxMarginTheoreticalSell),
                minMarginCreditAmountPhi1:  formatDbNumber(minMarginCreditAmountPhi1),
                minMarginCreditAmountPhi2: formatDbNumber(minMarginCreditAmountPhi2)
            },];
            const options = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(request)
            }

            const response = await fetch(`api/MaxTheoricalMargin`, options);
            setLoading(false)

            if (!response.ok) {
                const error = await response.json();
                throw error;
            }

            setShowSuccessModal(true);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
        } finally {
            setDisabled(false)
        }
    }

    return (
        <>
            <SectionWrapper header="Nova Margem Teórica Máxima">
                <form onSubmit={handleMtmRegistry} ref={form}>
                    <div className="int-col">
                        <div className="int-row int-mb-6">
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-instrument"
                                    isRequired
                                    label="Instrumento"
                                    name="instrument"
                                    placeholder="Instrumento"
                                    value={instrument}
                                    onChange={(e) =>
                                        setInstrument(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-instrument-origin"
                                    isRequired
                                    label="Origem do Instrumento"
                                    name="instrumentOrigin"
                                    placeholder="Origem do Instrumento"
                                    value={instrumentOrigin}
                                    onChange={(e) =>
                                        setInstrumentOrigin(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-instrument-id"
                                    isRequired
                                    label="ID do Instrumento"
                                    name="instrumentId"
                                    placeholder="ID do Instrumento"
                                    value={instrumentId}
                                    onChange={(e) =>
                                        setInstrumentId(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-exchange"
                                    isRequired
                                    label="Intercâmbio"
                                    name="exchange"
                                    placeholder="Intercâmbio"
                                    value={exchange}
                                    onChange={(e) =>
                                        setExchange(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-reference-price"
                                    isRequired
                                    label="Preço de Referência"
                                    name="referencePrice"
                                    placeholder="Preço de Referência"
                                    value={referencePrice}
                                    onChange={(e) =>
                                        setReferencePrice(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="int-row int-mb-6">
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-holding-day"
                                    isRequired
                                    label="Dia de Espera"
                                    name="holdingDay"
                                    placeholder="Dia de Espera"
                                    value={holdingDay}
                                    onChange={(e) =>
                                        setHoldingDay(e.target.value)
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-max-margin-theoretical-buy"
                                    isRequired
                                    label="Compra de Marg. T. Máx"
                                    name="maxTheoreticalMarginBuy"
                                    placeholder="Compra de Marg. T. Máx"
                                    value={maxMarginTheoreticalBuy}
                                    onChange={(e) =>
                                        setMaxMarginTheoreticalBuy(e.target.value)
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-max-margin-theoretical-sell"
                                    isRequired
                                    label="Venda de Marg. T. Máx"
                                    name="maxTheoreticalMarginSell"
                                    placeholder="Venda de Marg. T. Máx"
                                    value={maxMarginTheoreticalSell}
                                    onChange={(e) =>
                                        setMaxMarginTheoreticalSell(e.target.value)
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-min-margin-amount-phil-1"
                                    isRequired
                                    label="Crédito de Marg. Min. Phi1"
                                    name="minMarginCreditAmountPhi1"
                                    placeholder="Crédito de Marg. Min. Phi1"
                                    value={minMarginCreditAmountPhi1}
                                    onChange={(e) =>
                                        setMinMarginCreditAmountPhi1(e.target.value)                                       
                                    }
                                />
                            </div>
                            <div className="int-col">
                                <Input
                                    data-testid="create-mtm-min-margin-amount-phil-2"
                                    isRequired
                                    label="Crédito de Marg. Min. Phi2"
                                    name="minMarginCreditAmountPhi2"
                                    placeholder="Crédito de Marg. Min. Phi2"
                                    value={minMarginCreditAmountPhi2}
                                    onChange={(e) =>
                                        setMinMarginCreditAmountPhi2(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <Button
                        isFullWidth
                        disabled={disabled}
                        type="submit"
                    >
                        {loading ? <Loader flex/> : "Cadastrar Marg. T. Máx"}
                    </Button>
                    

                    {error && <Alert margin="int-mt-5">{errorMessage}</Alert>}

                </form>
            </SectionWrapper>

            {/* Modal - Mensagem cadastro com sucesso  */}
            <SimpleModal
                header="Registro de Marg. T. Máx cadastrado com sucesso!"
                isOpen={showSuccessModal}
                onClose={() => setShowSuccessModal(false)}
                bodyMessage="O novo registro de Marg. T. Máx foi cadastrado com sucesso!"
                footerButtons={[
                    {
                        label: "Novo Registro de Marg. T. Máx",
                        onClick: () => setShowSuccessModal(false),
                        variant: "outline",
                    },
                ]}
            />

        </>
    )
}
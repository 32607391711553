import React from 'react'

import { Button } from '../Forms'
import { Modal, ModalBody, ModalFooter } from './'

type FooterButton = {
    label: string;
    variant?: 'solid' | 'outline';
    onClick: (any) => void;
    margin?: string;
}

interface SimpleModalProps {
    header: string;
    isOpen: boolean;
    bodyMessage: string | JSX.Element;
    onClose: () => void;
    footerButton?: FooterButton;
    footerButtons?: FooterButton[];
}
export function SimpleModal({ 
    header, 
    isOpen, 
    bodyMessage, 
    onClose,
    footerButton,
    footerButtons
}: SimpleModalProps) {

    return(
        <Modal
            header={header}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalBody>
                {typeof bodyMessage === 'string' ? <p>{bodyMessage}</p> : bodyMessage}
            </ModalBody>
            <ModalFooter>
                {!footerButtons ? (
                    <Button 
                        onClick={footerButton.onClick}
                        variant={footerButton.variant || 'solid'}
                    >
                        {footerButton.label}
                    </Button>
                ) : (
                    footerButtons.map(({ 
                        label, 
                        onClick, 
                        variant = "solid", 
                        margin 
                    }, index) => (
                        <Button
                            key={index}
                            margin={margin}
                            onClick={onClick}
                            variant={variant}
                        >
                            {label}
                        </Button>
                    ))
                )}
            </ModalFooter>
        </Modal>
    )
}
import React, { Fragment, useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatInteger, formatDateTime, formatDecimal, formatDate, formatBoolean } from "../../../common/formatters"

export function FixedIncomeCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [fixedIncomeCustody, setFixedIncomeCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientFixedIncomeCustody() {
        setIsLoading(true);
        
        try {
            const response = await fetch(`/api/client/${account}/fixedIncomeCustody`);
            const data = await response.json();
            setFixedIncomeCustody(data);
        
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientFixedIncomeCustody()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientFixedIncomeCustody();
    }, [account]);

    const columns = [
        {
            Header: 'Título',
            accessor: data => data.symbolName,
        },
        {
            Header: 'Emissor',
            accessor: data => data.issuer,
            minWidth: 190
        },
        {
            Header: 'Tipo',
            accessor: data => data.type,
        },
        {
            Header: 'Resg. Antecipado',
            accessor: data => formatBoolean(data.availableRescue),
            singleLine: false,
            hint: 'Resgate Antecipado',
        },
        {
            Header: 'Qtd. Total',
            accessor: data => formatInteger(data.quantity),
            singleLine: false,
            hint: 'Quantidade Total'
        },
        {
            Header: 'Qtd. Bloq.',
            accessor: data => formatInteger(data.blockedQuantity),
            singleLine: false,
            hint: 'Quantidade Bloqueada'
        },
        {
            Header: 'Índice',
            accessor: data => data.indexName ?? '-'
        },
        {
            Header: '%',
            accessor: data => formatDecimal(data.indexPercent),
        },
        {
            Header: 'Taxa Pré',
            accessor: data => formatDecimal(data.fixedPercent),
        },
        {
            Header: 'Val. Aplicado',
            accessor: data => formatDecimal(data.financialAppliedValue),
            singleLine: false,
            hint: 'Valor Aplicado'
        },
        {
            Header: 'Val. Atual',
            accessor: data => formatDecimal(data.financialCurrentValue),
            hint: 'Valor Atual'
        },
        {
            Header: 'Fat. Garantia',
            accessor: data => formatDecimal(data.guaranteeFactor),
            singleLine: false,
            hint: 'Fator de Garantia'
        },
        {
            Header: 'Garantia',
            accessor: data => formatDecimal(data.guaranteeValue),
        },
        {
            Header: 'Dt. Operação',
            accessor: data => formatDate(data.operationDateTime),
            hint: 'Data Operação'
        },        
        {
            Header: 'Dt. Vencimento',
            accessor: data => formatDate(data.symbolMaturityDateTime),
            hint: 'Data Vencimento'
        }
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Renda Fixa"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={fixedIncomeCustody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientFixedIncomeCustody}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={fixedIncomeCustody} columns={columns} localStorageKey={'ClientFixedIncomeCustodyParams'} />
                )}
        </SectionWrapper>
    )
}

export const orderSideValues = {
    ORDER_SIDE_INVALID: 'Inválido',
    ORDER_SIDE_BUY: 'Compra',
    ORDER_SIDE_SELL: 'Venda'
}

export const policyTypeValues = {
    Client: 'Cliente',
    EnteringTrader: 'Operador'
}

export const contractTypeBtcValues = {
    Invalid: 'Inválido',
    Taker: 'Tomador',
    Donor: 'Doador'
}

export const orderTypeValues = {
    ORDER_TYPE_INVALID: 'Inválido',
    ORDER_TYPE_MARKET: 'Mercado',
    ORDER_TYPE_LIMIT: 'Limitada',
    ORDER_TYPE_STOP_LOSS: 'Stop Loss',
    ORDER_TYPE_STOP_LIMIT: 'Stop Limit',
    ORDER_TYPE_STOP_GAIN: 'Stop Gain',
    ORDER_TYPE_STOP_LOSS_GAIN: 'Stop Loss Gain',
    ORDER_TYPE_DAYTRADE: 'Daytrade',
    ORDER_TYPE_START: 'Início',
    ORDER_TYPE_ALERTA_LOSS: 'Alerta de Perda',
    ORDER_TYPE_ALERTA_GAIN: 'Alerta de Ganho',
    ORDER_TYPE_MARKET_WITH_LEFT_OVER_AS_LIMIT: 'Mercado + Limitada',
    ORDER_TYPE_RLP: 'RLP'
}

export const orderStatusValues = {
    ORDER_STATUS_INVALID: 'Inválida',
    ORDER_STATUS_NEW: 'Aberta',
    ORDER_STATUS_CANCELED: 'Cancelada',
    ORDER_STATUS_FILLED: 'Total. Exec.',
    ORDER_STATUS_PENDING_NEW: 'Pendente',
    ORDER_STATUS_PARTIALLY_FILLED: 'Parc. Exec.',
    ORDER_STATUS_REPLACED: 'Alterada',
    ORDER_STATUS_PENDING_CANCEL: 'Can. Pendente',
    ORDER_STATUS_PENDING_REPLACE: 'Alt. Pendente',
    ORDER_STATUS_REJECTED: 'Rejeitada',
    ORDER_STATUS_SUSPENDED: 'Suspensa',
    ORDER_STATUS_EXPIRED: 'Expirada',
    ORDER_STATUS_WAIT_MARKET: 'Aguar. Mercado',
    ORDER_STATUS_WAIT_TRIGGER: 'Aguar. Disparo',
    ORDER_STATUS_WAIT_TRIGGED: 'Disparado',
}

export const orderTimeInForceValues = {
    ORDER_TIME_IN_FORCE_INVALID: 'Inválido',
    ORDER_TIME_IN_FORCE_DAY: 'Dia',
    ORDER_TIME_IN_FORCE_GOOD_TILL_CANCEL: 'Até Cancelar',
    ORDER_TIME_IN_FORCE_GOOD_TILL_DATE: 'Até Data',
    ORDER_TIME_IN_FORCE_FILL_OR_KILL: 'Executa ou Cancela',
    ORDER_TIME_IN_FORCE_IMMEDIATE_OR_CANCEL: 'Imediato ou Cancela',
    ORDER_TIME_IN_FORCE_GOOD_FOR_AUCTION: 'Leilão',
    ORDER_TIME_IN_FORCE_GOOD_AT_CLOSE: 'Leilão Fechamento',
    ORDER_TIME_IN_AT_OPENING: 'Abertura',
    ORDER_TIME_IN_FORCE_GOOD_FOR_CROSSING: 'Crossing'
}

export const riskTypeValues = {
    NewOrder: 'Nova Ordem',
    CancelOrder: 'Cancelamento de Ordem',
    OrderReplacement: 'Alteração de Ordem',
    invalid: 'Inválido'
}

export const orderOperationValues = {
    POSITION: "Posição",
    DAYTRADE: "DayTrade"
}

export const riskOrderTypeValues = {
    invalid: 'Inválido',
    market: 'Mercado',
    limit: 'Limitada',
    stop_loss: 'Stop Loss',
    stop_limit: 'Stop Limit',

}

export const riskOrderSideValues = {
    sell: 'Venda',
    buy: 'Compra',
    invalid: 'Inválido'
}

export const riskOrderStatusValues = {
    invalid: 'Inválida',
    new_order: 'Aberta',
    pending_new: 'Pendente',
    partially_filled: 'Parc. Exec.',
    filled: 'Total Exec.',
    canceled: 'Cancelada',
    replaced: 'Alterada',
    pending_cancel: 'Can. Pendente',
    pending_replace: 'Alt. Pendente',
    rejected: 'Rejeitada',
    suspended: 'Suspensa',
    expired: 'Expirada'
}

export const riskTimeInForceValues = {
    invalid: '-',
    day: 'Dia',
    good_till_cancel: 'Até Cancelar',
    good_till_date: 'Até Data',
    fill_or_kill: 'Executa ou Cancela',
    immediate_or_cancel: 'Imediato ou Cancela',
    good_for_auction: 'Leilão',
    good_at_close: 'Leilão Fechamento',
}

export const fundsTransactionTypeValues = {
    redemption: 'Resgate',
    investment: 'Aplicação'
}

export const fundsSecurityListValues = {
    very_low: 'Muito Baixo',
    very_high: 'Muito Alto',
    high: 'Alto',
    low: 'Baixo',
    medium: 'Médio'
}
export const marketsEnum = {
    'OPC': 'Call',
    'OPV': 'Put',
    'FUT': 'Futuro',
    'VIS,FRA': 'À Vista',
    'TER': 'À Termo',
    'VIS': 'À Vista',
    'FRA': 'Fracionário',
    'DIS': 'Ouro'
}
export const orderSideEnum = value => {
    if (orderSideValues[value]) return orderSideValues[value]

    return value
}

export const contractTypeBtcEnum = value => {
    if (contractTypeBtcValues[value]) return contractTypeBtcValues[value]

    return value
}

export const orderTypeEnum = value => {
    if (orderTypeValues[value]) return orderTypeValues[value]

    return value
}

export const orderStatusEnum = value => {
    if (orderStatusValues[value]) return orderStatusValues[value]

    return value
}

export const orderTimeInForceEnum = value => {
    if (orderTimeInForceValues[value]) return orderTimeInForceValues[value]

    return value
}

export const riskTypeEnum = value => {
    if (riskTypeValues[value]) return riskTypeValues[value]

    return value
}

export const orderOperationEnum = value => {
    if (orderOperationValues[value]) return orderOperationValues[value]

    return value
}

export const riskOrderTypeEnum = value => {
    if (riskOrderTypeValues[value]) return riskOrderTypeValues[value]

    return value
}

export const riskOrderSideEnum = value => {
    if (riskOrderSideValues[value]) return riskOrderSideValues[value]

    return value
}

export const riskOrderStatusEnum = value => {
    if (riskOrderStatusValues[value]) return riskOrderStatusValues[value]

    return value
}

export const riskTimeInForceEnum = value => {
    if (riskTimeInForceValues[value]) return riskTimeInForceValues[value]

    return value
}

export const fundsTransactionTypeEnum = value => {
    if (fundsTransactionTypeValues[value]) return fundsTransactionTypeValues[value]

    return value
}

export const fundsSecurityListEnum = value => {
    if (fundsSecurityListValues[value]) return fundsSecurityListValues[value]

    return value
}
import React, { Fragment } from 'react'
import { BsTrashFill } from "react-icons/bs"

import { Button } from '../../../components/Forms'
import { Link } from '../../../components/Link'
import { Tooltip } from '../../../components/Tooltip'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Tabs, TabItem } from '../../../components/Tabs'

import { formatParamValue, formatDateTime } from "../../../common/formatters"

type Param = {
    name: string;
    friendlyName?: string;
    market: string;
    symbol?: string;
    comments?: string;
    expirationDate?: string;
    value: string;
    description?: string;
}

interface CommonParametersProps {
    id: string;
    paramsCommon: any;
    keys: any;
    policyItems: any;
    policy: any;
    setUpdatedParam: (value: Param | null) => void;
    setDeletedParam: (value: Param | null) => void;
    setErrorDeletedParam: (value: Error | null) => void;
    setErrorNewParam: (value: Error | null) => void;
    setShowSearchParamsModal: (value: boolean) => void;
    setShowFormUpdatedParameter: (value: boolean) => void;
    setShowFormAddParameter: (value: boolean) => void;
    setShowDeletedParameter: (value: boolean) => void;
}

export function CommonParameters({
    id, 
    paramsCommon,
    keys,
    policyItems,
    policy,
    setUpdatedParam,
    setDeletedParam,
    setErrorDeletedParam,
    setErrorNewParam,
    setShowSearchParamsModal,
    setShowFormUpdatedParameter,
    setShowFormAddParameter,
    setShowDeletedParameter,   
}: CommonParametersProps) {
    return(
        <SectionWrapper
            headerTag="h3" 
            header="Parâmetros Gerais"
            isAccordion 
        >                 
            <Tabs>
                {keys.map((group, index) => (
                    <TabItem 
                        key={index}
                        label={group[0].groupName} 
                        route={`/policies/${id}#${group[0].groupName}`} 
                    />
                ))}
            </Tabs>

            <table className="int-table">
                <thead>
                    <tr>
                        <th style={{ width: "15%" }}>Parâmetro</th>
                        <th />
                        <th style={{ width: "10%" }}>Mercado</th>
                        <th style={{ width: "10%" }}>Símbolo</th>
                        <th style={{ width: "15%" }}>Valor</th>
                        <th style={{ width: "15%" }}>Política</th>
                        <th style={{ width: "20%" }}>Comentário</th>
                        <th style={{ width: "10%" }}>Validade</th>
                        <th style={{ width: "5%" }}></th>
                    </tr>
                </thead>

                <tbody>
                    {paramsCommon?.map((item, index) => (
                        <Fragment key={index}>
                            {(item.groupName !== paramsCommon[index - 1]?.groupName) && (
                                <tr id={item.groupName} className="bg-secondary">
                                    <td colSpan={9} className="int-table-divider">{item.groupName}</td>
                                </tr>
                            )}

                            <tr>
                                <td>
                                    {item.policyId !== policy.id ? (
                                        <p>{item.friendlyName}</p>
                                    ) : (
                                        <p
                                            onClick={() => {
                                                const param = { 
                                                    ...item, 
                                                    symbol: item.symbol ?? "",
                                                    comments: item.comments ?? "",
                                                    value: formatParamValue(item) 
                                                }
                                                setUpdatedParam(param)
                                                setShowFormUpdatedParameter(true)
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <u>{item.friendlyName}</u>
                                        </p>
                                    )}
                                </td>
                                <td><Tooltip label={item.description} /></td>
                                <td>
                                    {item?.market}
                                </td>
                                <td>{item?.symbol}</td>
                                <td>{formatParamValue(item)}</td>
                                <td>
                                    {item.policyId === policy.id ? item.policyName : (
                                        <Link href={`/policies/${item.policyId}`}>
                                            {item.policyName}
                                        </Link>
                                    )}
                                </td>
                                <td>{item?.comments}</td>
                                <td>
                                    {formatDateTime(item.expirationDate)}
                                </td>
                                <td>
                                    {item.policyId === policy.id ? (
                                        <BsTrashFill
                                            data-testid="param-delete-button"
                                            size={20}
                                            color="var(--int-colors-orange-400)"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setErrorDeletedParam(null);
                                                setDeletedParam(item);
                                                setShowDeletedParameter(true);
                                            }} 
                                        />
                                    ) : (
                                        <BsTrashFill
                                            size={20}
                                            color="#AAA"
                                        />
                                    )}
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                </tbody>
            </table>
                
            <div className="int-d-flex int-align-items-center int-mt-4">
                <Button
                    variant="outline"
                    onClick={() => {
                        setShowFormAddParameter(true);
                        setErrorNewParam(null);
                    }}
                    data-testid="addButton"
                >
                    Adicionar novo parâmetro
                </Button>

                <Button
                    margin="int-ml-3"
                    onClick={() => setShowSearchParamsModal(true)}
                    data-testid="searchParam"
                >
                    Buscar parâmetro
                </Button>
            </div>
        </SectionWrapper>
    )
}

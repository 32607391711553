
type Parameters = {
    name: string;
    market: string;
    symbol?: string;
    value: number | string | boolean;
}

type checkDuplicateParametersResponse = {
    existsDuplicates: boolean;
    message: string;
}

export function checkDuplicateParameters(
    keys: any[],
    parameters: Parameters[],
): checkDuplicateParametersResponse {
    const duplicates = parameters.filter((parameter, indexParameter) => {
        const compare = parameters.filter((parameterCompare, indexParameterCompare) => {
            return (
                parameter.name === parameterCompare.name && 
                parameter.market === parameterCompare.market && 
                (parameter.symbol?.toUpperCase() ?? "") == (parameterCompare.symbol?.toUpperCase() ?? "") &&
                indexParameter !== indexParameterCompare
            )
        })
            return compare.length > 0
    })

    const dupeArrayName = duplicates.map(e => e.name);
    const newDupeArrayName = [...new Set(dupeArrayName)];

    const arrayNames = keys.flat().filter(item => newDupeArrayName.includes(item.name));

    let message = '';

    if(duplicates.length > 0 && arrayNames.length === 1) {
        message = `O parâmetro "${arrayNames[0].friendlyName}" está duplicado!`;

        return {
            existsDuplicates: true,
            message
        }
    }
    else if(duplicates.length > 0 && arrayNames.length > 1) {
        message = `Os parâmetros "${arrayNames.map(x => x.friendlyName).join(', ')}" estão duplicados!`;

        return {
            existsDuplicates: true,
            message
        }
    }

    return {
        existsDuplicates: false,
        message
    }
}

Object.defineProperty(Array.prototype, 'flat', {
    value: function(depth = 1) {
      return this.reduce(function (flat, toFlatten) {
        return flat.concat((Array.isArray(toFlatten) && (depth>1)) ? toFlatten.flat(depth-1) : toFlatten);
      }, []);
    }
});

export function disableMarketSelection(keys: any[], param: any) {
    const market = getMarketForParameter(keys, param?.name);
    const key = keys.flat().find(k => k.name === param?.name);

    var params = ["AllowTradeCoveredCallOptions", "AllowBullCallSpread",
     "AllowBearCallSpread", "AllowBullPutSpread", "AllowBearPutSpread",
     "UseMaximumTheoreticalMarginForUncoveredOptionSell", "AllowIncreaseMaturingOptionPosition",
     "MarginByContract", "MaxIntradayMovementQuantity", "ApplyGuaranteeDiscountOnShortPositions"]

    if(params.includes(param?.name)) return true

    if (key) {
        if (key.isGlobal) return true
        if (key.isMarketParameter) return false
    }
    
    return market != ""
}

export function disableSymbolSelection(keys: any[], param: any) {
    const key = keys.flat().find(k => k.name === param?.name);
    
    if (!key) return false

    if(key.isGlobal) return true
    if (key.isMarketParameter) return true

    var params = ["BlockedAccount"];

    if (params.includes(param?.name)) return true

    return false
}

export function getMarketForParameter(keys: any[], name: string) {

    if(name == undefined || name == null ) return ""

    const key = keys.flat().find(k => k.name === name);

    if(key == null || key == undefined)  {
        return ""
    }

    var opc = ["AllowTradeCoveredCallOptions", "AllowBullCallSpread", "AllowBearCallSpread"]
    if (opc.includes(name)) return "OPC"  

    var opv = ["AllowBullPutSpread", "AllowBearPutSpread"]
    if (opv.includes(name)) return "OPV"    
    
    var opc_opv = ["UseMaximumTheoreticalMarginForUncoveredOptionSell", "AllowIncreaseMaturingOptionPosition"]
    if(opc_opv.includes(name)) return "OPC_OPV";

    var fut = ["MarginByContract", "MaxIntradayMovementQuantity"]
    if(fut.includes(name)) return "FUT";

    var ter = ["TermIntradayTradeShouldConsumeLimit"]
    if(ter.includes(name)) return "TER";

    var vis_fra = ["ApplyGuaranteeDiscountOnShortPositions"]
    if(vis_fra.includes(name)) return "VIS_FRA";

    if(key.isGlobal) return "TODOS";
    if(key.isMarketParameter) return "TODOS"; 

    return ""
}

export function checksIfParameterIsMarket(keys: any[], name: string) {
    const key = keys.flat().find(k => k.name === name);
    return key ? key.isMarketParameter : false;
}
import React, { useEffect, useState } from 'react';

import { SectionWrapper } from '../../components/SectionWrapper';
import { Alert } from '../../components/Alert';

export function Connections() {
    const [adapters, setAdapters] = useState();
    const [database, setDatabase] = useState();
    const [inteliData, setInteliData] = useState();
    const [inteliOrder, setInteliOrder] = useState();

    const checkIntervalMs = 5 * 1000;
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(false)

    const statusList = [
        {
            url: '/api/health/adapters',
            type: adapters
        },
        {
            url: '/api/health/database',
            type: database
        },
        {
            url: '/api/health/inteliData',
            type: inteliData
        },
        {
            url: '/api/health/inteliOrder',
            type: inteliOrder
        }
    ];

    useEffect(() => {
        const newIntervalId = setInterval(fetchData, checkIntervalMs);

        return () => {
            clearInterval(newIntervalId);
        };
    }, []);

    const fetchData = async () => {
        setIsFetching(true)

        try {
            const response = await fetch(`/api/health`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()

            setAdapters(data.adaptersEndPointsStatus.every(item => item.sucess === true));
            setDatabase(data.dataBaseStatus.sucess)
            setInteliData(data.InteliData.sucess)
            setInteliOrder(data.InteliOrder.every(item => item.sucess === true)) 
            
            setError(false)
        }
        catch(err) {
            setError(true)
        } finally {
            setIsFetching(false)
        }
    }

    const getColorStatus = (status) => {
        if (status === true)
            return "var(--int-colors-green-400)";
        else if (status === false)
            return "var(--int-colors-red-400)";  
        else
            return "var(--int-colors-gray-500)";    
    } 


    return (
        <SectionWrapper header="Conexões">
            {error ? (
                <Alert
                    margin="int-mt-5"
                >
                    Erro ao coletar informações do ambiente
                </Alert>
            ) : (
                <table className="int-table">
                    <thead>
                        <tr>
                            <th style={{ width: "90%" }}>Serviço</th>
                            <th style={{ width: "10%" }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statusList.map((item, index) => ( 
                            <tr key={index}>
                                <td>
                                    <a href={item.url} target="_blank">{item.url.slice(1)}</a>
                                </td>
                                <td>
                                    <div 
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "50%",
                                            margin: "0 0.75rem",
                                            opacity: isFetching ? 0.5 : 1,
                                            backgroundColor: getColorStatus(item.type),
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )} 
        </SectionWrapper>
    );
};

import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronsLeft, FiChevronRight, FiChevronsRight } from 'react-icons/fi'

import { NumberInput, Select } from '../Forms';
import { Loader } from "../Loader";

interface PaginationProps {
    pageOptions: number[];
    pageIndex: number;
    pageSize: number;
    pageCount: number;
    count?: number;
    canPreviousPage: boolean;
    canNextPage: boolean;
    isRefreshing?: boolean;
    autoRefresh?: boolean;
    previousPage: () => void;
    nextPage: () => void;
    gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
    setPageSize: (value: number) => void;
    fetchData?: () => void;
    paginationToSmartTable?:  (value: any) => void;
    onUpdate?: () => void;
}

export function Pagination({
    pageOptions,
    pageIndex,
    pageSize,
    pageCount,
    count,
    canPreviousPage,
    canNextPage,
    isRefreshing,
    autoRefresh,
    previousPage,
    nextPage,
    gotoPage,
    setPageSize,
    fetchData,
    paginationToSmartTable,
    onUpdate
}: PaginationProps) {
    const [pageIndexValue, setPageIndexValue] = useState(0);

    const updateCallBack = (callback: () => void) => {
        callback()
        onUpdate?.()
    }

    useEffect(()  => { 
        setPageIndexValue(0);
        paginationToSmartTable({items: pageSize, page: (pageIndexValue + 1)})
    }, [pageSize])

    useEffect(()  => { 
        paginationToSmartTable({items: pageSize, page: (pageIndexValue + 1)})
    }, [pageSize, pageIndex, pageCount, pageIndexValue])

    useEffect(() => {
        setPageIndexValue(0);
    },[autoRefresh])

    var pageLength = count != 0 && count != undefined && count != null ? Math.ceil(count / pageSize) : pageOptions.length;

    if(fetchData != null){
        pageIndexValue + 1 >= pageLength ? canNextPage = false : canNextPage = true;
        pageIndexValue + 1 > pageLength || pageIndexValue == 0 ? canPreviousPage = false : canPreviousPage = true;
    }

    function goToFirstPage() {
        if(isRefreshing) return
        if(fetchData == null){ 
            updateCallBack(() => gotoPage(0))
        }else{
            setPageIndexValue(0);
        }
    }

    function goToPreviousPage() {
        if(isRefreshing) return
        if(fetchData == null){
            updateCallBack(previousPage)
        }else{
            updateCallBack(previousPage)
            setPageIndexValue(pageIndexValue - 1);      
        }
    }

    function goToNextPage() {
        if(isRefreshing) return
        if(fetchData == null){
            updateCallBack(nextPage)
        }else{
            updateCallBack(nextPage)
            setPageIndexValue(pageIndexValue + 1);
        }
    }

    function goToLastPage() {
        if(isRefreshing) return
        if(fetchData == null){
            updateCallBack(() => gotoPage(pageCount - 1))
        }else{            
            setPageIndexValue(pageLength - 1);
        }
    }
    function goToSpecificPage(page) {
        if(isRefreshing) return
        if(fetchData == null){
            updateCallBack(() => gotoPage(page))
        }else{            
            if(page  < pageLength){
                setPageIndexValue(page);
            }
        }
    }


    var pageSizeValues =  [20, 30, 40, 50, 100, 'todos os'];
    if(count > 500){
        pageSizeValues = [20, 30, 40, 50, 100]
    }

    return(
        <div className="int-d-flex int-align-items-center int-justify-content-between int-mt-4">
            <div className="int-d-flex">
                <button
                    className="int-btn-icon int-btn-icon-outline int-mr-4"
                    onClick={() => goToFirstPage()}
                    disabled={autoRefresh || !canPreviousPage}
                >   
                    <FiChevronsLeft />
                </button>
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    onClick={() => goToPreviousPage()}
                    disabled={autoRefresh || !canPreviousPage}
                >
                    <FiChevronLeft />
                </button>
            </div>
            <div className="int-d-flex int-align-items-center">
                <p className="int-mr-8">
                    Página{" "}
                    <b>{fetchData != null ? pageIndexValue + 1 : pageIndex + 1}</b>{" "}
                    de{" "}
                    <b>{pageLength}</b>
                </p>
                <p>Ir para página:</p>{" "}
                <NumberInput
                    margin="int-ml-2 int-mr-4"
                    style={{ maxWidth: "9rem" }}
                    min={1}
                    max={pageLength}
                    disabled={autoRefresh}
                    onChange={e => {
                        const value = e.target.value
                        const page = value ? Number(value) - 1 : 0;
                        goToSpecificPage(page);                        
                    }}
                        defaultValue={pageIndex + 1}
                />
                <Select
                    name="items-size"
                    style={{ minWidth: "14rem" }}
                    value={pageSize}
                    onChange={(e) => {
                        updateCallBack(() => setPageSize(Number(e.target.value)));
                    }}
                >
                    {pageSizeValues.map((pageSize) => (
                        <option key={pageSize} value={pageSize === 'todos os' ? 10000 : pageSize}>
                            Mostrar {pageSize} items
                        </option>
                    ))}
                </Select>

                {isRefreshing 
                    ? 
                    <div style={{marginLeft: "10px"}}>
                        <Loader flex/>
                    </div> 
                    :  
                    <div style={{opacity: 0, marginLeft: "10px"}}> 
                        <Loader flex/> 
                    </div>
                }

            </div>

            <div className="int-d-flex">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    onClick={() =>{goToNextPage()}}
                    disabled={autoRefresh || !canNextPage}
                >
                    <FiChevronRight />
                </button>

                <button
                    className="int-btn-icon int-btn-icon-outline int-ml-4"
                    onClick={() => goToLastPage()}
                    disabled={autoRefresh || !canNextPage}
                >
                    <FiChevronsRight />
                </button>
            </div>
        </div>
    )
}
import React, { InputHTMLAttributes } from 'react'
import { ForwardRefRenderFunction } from 'react'
import { forwardRef } from 'react'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    isChecked: boolean;
}

const CheckboxImp: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = ({ label, isChecked, ...rest }, ref) => {
    return(
        <div className="int-form-check">
            <input 
                type="checkbox" 
                className="int-form-check-input" 
                checked={isChecked} 
                ref={ref}
                {...rest} 
            />
            {!!label && (
                <label className="int-form-check-label">{label} {rest.required ? <span style={{ color: 'var(--int-colors-red-500)' }}>*</span> : ''}</label>
            )}
        </div>
    )
}

export const Checkbox = forwardRef(CheckboxImp)
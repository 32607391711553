import React from 'react'

interface ParameterUpload {
    id: string;
    fundName: string;
    settlementCycle: string;
    riskClassification: string;
    value: string;
    comments?: string;
}

interface UploadParametersListProps {
    parameters: ParameterUpload[];
}

export function UploadParametersListFunds({ parameters }: UploadParametersListProps) {
    return(
        <table className="int-table">
            <thead>
                <tr>
                    <th>CNPJ</th>
                    <th>Nome do Fundo</th>
                    <th>Prazo Liquidação</th>
                    <th>Risco do Fundo</th>
                    <th>Fator de Garantia</th>
                    <th>Comentário</th>
                </tr>
            </thead>

            <tbody>
                {parameters?.map((item, index) => (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.fundName}</td>
                        <td>{item.settlementCycle}</td>
                        <td>{item.riskClassification}</td>
                        <td>{item.value}</td>
                        <td>{item.comments ?? '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

import React, { Fragment, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Button, Select } from '../../../components/Forms'
import { Dropdown, DropdownItem } from '../../../components/Dropdown'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Tabs, TabItem } from '../../../components/Tabs'
import { Link } from '../../../components/Link'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Tooltip } from '../../../components/Tooltip'
import { Alert } from '../../../components/Alert'
import { Breadcrumb, BreadcrumbItem } from "../../../components/Breadcrumb"
import { getPolicyParentNames } from '../../../common/search'
import { formatParamValue, formatInteger } from "../../../common/formatters"
import { API_HEADER } from '../../../constants/api'
import { H3 } from '../../../components/Typography'
import { SmartTable } from '../../../components/Table'
import { parameterValueHeader } from '../../../common/parameterValueHeader'

interface PolicySectionProps {
    id: string;
    code: string;
    description: string;
    policyId?: number;
    fetchOperatorData: () => void;
    setPolicyName: (name: string) => void;
}

type Parameter = {
    friendlyName: string;
    category: string;
    value: string | number | boolean;
    symbol?: string;
    market: string;
    groupName: string;
    description: string;
    policyId: number;
    policyName?: string;
    comments?: string;
}

type ParentPolicy = {
    id: number;
    name: string;
}

type OperatorPolicy = {
    name: string;
    id: number;
    parameters: Parameter[];
    shared: boolean;
    parentPolicies: ParentPolicy[]
}

export function PolicySection({ 
    id, 
    code,
    description, 
    policyId = 0,
    setPolicyName,
    fetchOperatorData
}: PolicySectionProps) {
    const history = useHistory();

    const [operatorPolicy, setOperatorPolicy] = useState<OperatorPolicy>()
    const [commonParameters, setCommonParameters] = useState<Parameter[]>()
    const [policies, setPolicies] = useState([])
    const [paramsCustomBmf, setParamsCustomBmf] = useState([]);
    const [keys, setKeys] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [isShowingSuccessUpdateModal, setIsShowingSuccessUpdateModal] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [associatedPolicy, setAssociatedPolicy] = useState<string>()
    const [error, setError] = useState<Error>()

    const ParameterValue= (row, value, paramName, formatter) => {
        if (value == null){
            return "-"
        } else if (row.original.policyIds[paramName] != operatorPolicy.id) {
            return (
                <Link color="gray" href={`/policies/${row.original.policyIds[paramName]}`}>
                    {formatter(value)}
                </Link>
            )
        } else {
            return formatter(value)
        }  
    }

    const bmfColumns = [
        {
            Header: 'Símbolo',
            accessor: props => props.key.symbol ?? "TODOS",
        },
        {
            Header:  () => parameterValueHeader(keys, 'Qt Max. de Exposição - Compra', 'MaxOpenSizeBuy'),
            accessor: 'values.MaxOpenSizeBuy',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOpenSizeBuy', formatInteger)
        },
        {
            Header: () => parameterValueHeader(keys, 'Qt Max. de Exposição - Venda', 'MaxOpenSizeSell'),
            accessor: 'values.MaxOpenSizeSell',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOpenSizeSell', formatInteger)
        },
        {
            Header: () => parameterValueHeader(keys, 'Tam Max. de Ordem', 'MaxOrderSize'),
            accessor: 'values.MaxOrderSize',
            Cell: ({ row, value }) => ParameterValue(row, value, 'MaxOrderSize', formatInteger)
        }
    ]

    async function fetchPoliciesData(signal) {
        try {
            const response = await fetch('/api/policy', {signal})

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()
            const sortedArray = data?.filter(x => !x.isInternal && x.policyType === "EnteringTrader")

            setPolicies(sortedArray)
            return sortedArray
        } catch (err) {
            console.log(err)
        }
    }

    async function fetchKeysData(signal) {
        try {
            const response = await fetch('/api/policy/keys', {signal})

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()

            const operatorKeys = data.map(g => g.filter(x => x.isEnteringTrader)).filter(x => x.length > 0)

            setKeys(operatorKeys)
            return operatorKeys
        } catch (err) {
            console.log(err)
        }
    }

    async function fetchOperatorPolicy(policies, keys) {
        try {
            const response = await fetch(`/api/policy/${policyId}`)

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()

            const parentPolicies = getPolicyParentNames(policies, data)

            const parameters = data?.parameters
                .filter(item => item.category !== 'Internal')
                .map(param => ({
                    ...param,
                    value: formatParamValue(param)
                }))

            const policyData = {
                ...data,
                parameters,
                parentPolicies
            }
        
            setOperatorPolicy(policyData)
            setPolicyName(data.name)

            const paramsCommonFiltered = policyData?.parameters?.filter(p => p.category == 'Common')
            setCommonParameters(paramsCommonFiltered);

            const paramsCustomBmfFiltered = policyData?.bmfParameters
            setParamsCustomBmf(paramsCustomBmfFiltered)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const ac = new AbortController()

        Promise.all([fetchPoliciesData(ac.signal), fetchKeysData(ac.signal)])
            .then(response => {
                const policies = response[0];
                const keys = response[1];

                fetchOperatorPolicy(policies, keys)
            })

        return () => ac.abort()
    }, [policyId])

    async function handlePolicyAssociated(event) {
        event.preventDefault();
        setDisabled(true)
        setError(null)

        try {
            const data = {
                enteringTraderId: code,
                description: description,
                policyId: parseFloat(associatedPolicy)
            }

            const options = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(data)
            }

            const response = await fetch(`/api/enteringTrader/${code}/policyId`, options)
            
            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            setShowModal(false)
            setIsShowingSuccessUpdateModal(true)
        } catch (err) {
            setError(err)
        } finally {
            setDisabled(false);
        }
    }

    async function customizeClientPolicy() {
        setError(null)
        setDisabled(true)

        try {
            const options = {
                method: "POST",
                headers: API_HEADER
            }
    
            const response = await fetch(`/api/enteringTrader/${code}/policyId`, options)

            if(response.ok) {
                const data = await response.json();
                return history.push(`/policies/${data.id}`)
            }

            const error = await response.json()
            throw new Error(error?.message)
        } catch (err) {
            setError(err)
        } finally {
            setDisabled(false);
        }
    };

    return (
        <>
            <SectionWrapper
                id={id}
                headerTag="h3"
                header={
                    `Política: ${operatorPolicy?.name 
                    ? `${operatorPolicy.name} ${operatorPolicy.shared ? '(Compartilhada)' : '(Privada)'}` 
                    : 'Não encontrada'}`
                }
                isAccordion
            >
                <div className="int-d-flex int-justify-content-between int-mb-3">
                    <div>                        
                        <Breadcrumb>
                            { operatorPolicy?.parentPolicies.map(({ id, name }) => (
                                <BreadcrumbItem key={id}>
                                    <Link href={`/policies/${id}`}>{name}</Link>
                                </BreadcrumbItem>
                            ))}

                            { operatorPolicy?.parentPolicies.length > 0 && (
                                <BreadcrumbItem>
                                    <Link href={`/policies/${operatorPolicy?.id}`}>
                                        {operatorPolicy?.name}
                                    </Link>
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                    </div>

                    <Dropdown align="right" label="Ações">
                        {operatorPolicy?.shared ? (
                            <DropdownItem onClick={customizeClientPolicy} data-testid="customize-policy-button">
                                Customizar
                            </DropdownItem>
                        ) : (
                            <DropdownItem>
                                <Link href={`/policies/${operatorPolicy?.id}`}>
                                    Editar
                                </Link>
                            </DropdownItem>
                        )}

                        <DropdownItem data-testid="change-policy-button" onClick={() => setShowModal(true)}>
                            Alterar
                        </DropdownItem>
                    </Dropdown>
                </div>

                <H3 margin="int-mb-2">Parâmetros Gerais</H3>

                <Tabs>
                    {keys.map((group, index) => (
                        <TabItem 
                            key={index}
                            label={group[0].groupName} 
                            route={`/operators/${code}#${group[0].groupName}`}
                            disabled={
                                !operatorPolicy?.parameters.some(
                                    i => i.groupName === group[0].groupName
                                )
                            }
                        />
                    ))}
                </Tabs>

                <table className="int-table">
                    <thead>
                        <tr>
                            <th style={{ width: "16%" }}>Parâmetro</th>
                            <th />
                            <th style={{ width: "16%" }}>Mercado</th>
                            <th style={{ width: "16%" }}>Símbolo</th>
                            <th style={{ width: "16%" }}>Valor</th>
                            <th style={{ width: "16%" }}>Política</th>
                            <th style={{ width: "20%" }}>Comentário</th>
                        </tr>
                    </thead>

                    <tbody>
                        {commonParameters?.map((param, index, arr) => (
                            <Fragment key={index}>
                                {param.groupName !== arr[index - 1]?.groupName && (
                                    <tr id={param.groupName} className="int-table-divider">
                                        <td colSpan={7}>{param.groupName}</td>
                                    </tr>
                                )}

                                <tr>
                                    <td>{param.friendlyName}</td>
                                    <td>
                                        <Tooltip 
                                            label={param.description} 
                                        />
                                    </td>
                                    <td>{param.market}</td>
                                    <td>{param?.symbol}</td>
                                    <td>{param.value}</td>
                                    <td>
                                        <Link href={`/policies/${param.policyId}`}>
                                            {param.policyName}
                                        </Link>
                                    </td>
                                    <td>{param?.comments}</td>
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </table>

                <H3 margin="int-mb-2 int-mt-5">Paramêtros BMF</H3>
                {paramsCustomBmf?.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum paramêtro BMF foi encontrado
                    </p>
                ) : (
                    <SmartTable data={paramsCustomBmf ?? []} columns={bmfColumns} localStorageKey={'OperatorPolicySection'} removeScroll />
                )}
            </SectionWrapper>

            {/** Modal Alterar política */}
            <Modal 
                header="Alterar Política"
                isOpen={showModal} 
                onClose={() => setShowModal(false)}
            >
                <form onSubmit={event => handlePolicyAssociated(event)}>
                    <ModalBody>
                        <Select
                            data-testid="change-policy-select"
                            isRequired
                            label="Selecione uma política"
                            name="policyId"
                            value={associatedPolicy}
                            onChange={event => setAssociatedPolicy(event.target.value)}
                        >
                            <option hidden value="">Selecione um nome</option>
                            {policies.filter(x => x.shared).map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                        {error && (           
                            <Alert>{error?.message ? error?.message : 'Ocorreu um erro ao alterar a política do cliente! Tente novamente mais tarde.'}</Alert>
                        )}
                    </ModalBody>
                    
                    <ModalFooter>
                        <Button variant="outline" onClick={() => { setShowModal(false); setError(null) }}> Cancelar</Button>
                        <Button data-testid="change-policy-submit" margin="int-ml-2" type="submit" disabled={disabled}>Alterar</Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal: Sucesso alterar política */}
            <SimpleModal 
                data-testid="change-policy-success-modal"
                header="Alteração de Política"
                isOpen={isShowingSuccessUpdateModal}
                onClose={() => setIsShowingSuccessUpdateModal(false)}
                bodyMessage="A política foi alterada com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchOperatorData()
                        setIsShowingSuccessUpdateModal(false)
                    }
                }}
            />
        </>
    )
}
import React from "react"
import { Tooltip } from '../components/Tooltip'

export const parameterValueHeader = (keys: any[], friendlyName, paramName) => {
    return (
        <div className="int-d-flex">
            <span  className="int-mr-3">{friendlyName}</span>
            <Tooltip label={getDescription(keys, paramName)} />
        </div>
        )
    }

    const getDescription = (keys, paramName) => {
        const key = keys?.flat().filter((item) => item.name == paramName)
        return key[0]?.description
    }
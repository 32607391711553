import React, { useEffect, useState } from 'react';
import { BsArrowClockwise } from 'react-icons/bs';

import { SmartTable } from '../../../components/Table';
import { Loader } from '../../../components/Loader';
import { Link } from "../../../components/Link";
import { SectionWrapper } from '../../../components/SectionWrapper'

import { formatDecimal, formatInteger, formatDateTime } from '../../../common/formatters';
import {
    riskOrderTypeEnum,
    riskOrderSideEnum,
    riskOrderStatusEnum,
    riskOrderStatusValues,
    riskTimeInForceEnum
} from '../../../common/enums';
import { ordersRowStyles } from '../../../common/variables';
 
const columns = [
    {
        Header: 'ID',
        accessor: 'id',
        width: 225
    },
    {
        Header: 'Data',
        accessor: ({ date }) => formatDateTime(date),
        width: 180
    },
    {
        Header: 'Conta',
        accessor: 'account'
    },
    {
        Header: 'Nome',
        accessor: 'name',
        Cell: ({ value }) => value ?? '-',
        width: 200
    },
    {
        Header: 'Status',
        accessor: ({ status }) => riskOrderStatusEnum(status),
        filter: 'includes'
    },
    {
        Header: 'Ativo',
        accessor: 'symbol',
        Cell: ({ value }) => value?.toUpperCase() ?? '-'
    },
    {
        Header: 'Preço',
        accessor: 'price',
        Cell: ({ value }) => formatDecimal(value),
        columnAlign: 'right',
        width: 130
    },
    {
        Header: 'Tipo',
        accessor: ({ ordType }) => riskOrderTypeEnum(ordType),
        filter: 'includes'
    },
    {
        Header: 'Lado',
        accessor: ({ side }) => riskOrderSideEnum(side),
        filter: 'includes'
    },
    {
        Header: 'Qtd. Total',
        accessor: 'ordQty',
        Cell: ({ value }) => formatInteger(value),
        columnAlign: 'right',
        width: 150
    },
    {
        Header: 'Preço. Perda',
        accessor: 'stopLossPrice',
        Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
        columnAlign: 'right',
        width: 150
    },
    {
        Header: 'Preço. Ganho',
        accessor: 'stopGainPrice',
        Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
        columnAlign: 'right',
        width: 150
    },
    {
        Header: 'Preço Disp. Início',
        accessor: 'startTriggerPrice',
        Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
        columnAlign: 'right',
        width: 150
    },
    {
        Header: 'Preço Inicial',
        accessor: 'startPrice',
        Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
        columnAlign: 'right'
    },
    {
        Header: 'Preço Disp. Perda',
        accessor: 'stopLossTriggerPrice',
        Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
        columnAlign: 'right',
        width: 180
    },
    {
        Header: 'Preço Disp. Ganho',
        accessor: 'stopGainTriggerPrice',
        Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
        columnAlign: 'right',
        width: 180
    },
];

export function ConditionalOrdersAccount({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientesConditionalOrdersData() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/conditionalorders?account=${account}`)

            if(!response.ok) {
                throw new Error("Ocorreu um erro ao buscar dados das Ordens condicionais do cliente")
            }

            const data = await response.json()
            setOrders(data.orders)
        } catch (err) {

        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
      if(updateAllGrids){
          fetchClientesConditionalOrdersData()
      }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientesConditionalOrdersData()
    }, [account]);

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            header="Ordens Condicionais"
            headerTag="h3"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={orders.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-align-items-center">
                <Link
                    href={`/conditional-orders-history?account=${account}`}
                >
                    Ver todas as ordens condicionais
                </Link>

                <button
                    className="int-btn-icon int-btn-icon-outline int-ml-auto"
                    disabled={isLoading}
                    onClick={fetchClientesConditionalOrdersData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex/>}

            {isLoading ? '' : 
            (
                <SmartTable 
                    columns={columns} 
                    data={orders} 
                    localStorageKey={'ClientConditionalOrders'} 
                    bodyRowStyles={ordersRowStyles}
                />
            )}
        </SectionWrapper>
    );
}

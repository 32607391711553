import React from 'react'

interface ParameterUpload {
    symbol: string;
    maxOpenSizeBuy?:  string | number;
    maxOpenSizeSell?:  string | number;
    maxOrderSize?:  string | number;
    marginByContract?:  string | number;
}

interface UploadParametersListProps {
    parameters: ParameterUpload[];
    parameterName: string;
    isClient: boolean;
}

export function UploadParametersListBMF({ parameters, parameterName, isClient }: UploadParametersListProps) {
    return(
        <table className="int-table">
            <thead>
                <tr>
                    <th>Símbolo</th>
                    <th>Qt Max. De Exposição Comprada</th>
                    <th>Qt Max. De Exposição Vendida</th>
                    <th>Tam Max. De Ordem</th>
                    {isClient? <th>Margem Por Contrato</th> : null}
                </tr>
            </thead>

            <tbody>
                {parameters?.map((item, index) => (
                    <tr key={index}>
                        <td>{item.symbol}</td>
                        <td>{item.maxOpenSizeBuy ?? '-'}</td>
                        <td>{item.maxOpenSizeSell ?? '-'}</td>
                        <td>{item.maxOrderSize ?? '-'}</td>
                        {isClient ? <td>{item.marginByContract ?? '-'}</td> : null}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

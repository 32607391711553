import React, { ReactNode } from 'react'

interface H2Props {
    children: ReactNode;
    margin?: string;
    padding?: string;
}

export function H2({
    children, 
    margin = "", 
    padding = "", 
    ...rest 
}: H2Props) {
    return(
        <h2 className={`int-h2 ${margin} ${padding}`} {...rest}>
            {children}
        </h2>
    )
}
import React from 'react';

import { Field, Input, Select } from '../../components/Forms';
import { SectionWrapper } from '../../components/SectionWrapper';

import { setObject } from '../../common/variables';

type TNewOrder = {
    symbol: string;
    ordQty: number;
    price: string;
    side: string,
    ordType: number,
    timeInForce: number,
    status: number,
    account: string,
    enteringTrader: string
}

interface NewOrderProps {
    newOrder: TNewOrder;
    setNewOrder: (value: TNewOrder) => void;
    isOperatorCheck?: boolean;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
}

const headerName = "Nova Ordem";

export function NewOrder({ 
    newOrder, 
    setNewOrder, 
    isOperatorCheck = false, 
    allAccordionsOpen,
 }: NewOrderProps) {
    return(
        <SectionWrapper 
            headerTag="h3"
            header={headerName}
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isOpenByDefault
        >
            <table className="int-table">
                <thead>
                    <tr>
                        <th style={{width: '12.5%'}}>Ativo</th>
                        <th style={{width: '12.5%'}}>Quantidade</th>
                        <th style={{width: '12.5%'}}>Tipo</th>
                        <th style={{width: '12.5%'}}>C/V</th>
                        <th style={{width: '12.5%'}}>Preço</th>
                        <th style={{width: '12.5%'}}>Validade</th>
                        <th style={{width: '12.5%'}}>Conta do Cliente</th>
                        <th style={{width: '12.5%'}}>Letra do Operador</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <Field 
                                data-testid="newOrder-symbols"
                                required
                                format="Symbol"
                                name="symbol"
                                value={newOrder?.symbol}
                                onChange={
                                    event => setNewOrder(
                                        setObject(event, newOrder, true)
                                    )
                                }
                            />
                        </td>
                        <td>
                            <Field
                                data-testid="newOrder-ordQty"
                                required
                                format="Integer"
                                name="ordQty"
                                value={newOrder?.ordQty ?? 0}
                                onChange={
                                    event => setNewOrder(
                                        setObject(event, newOrder)
                                    )
                                }
                            />
                        </td>
                        <td>
                            <Select
                                data-testid="newOrder-side"
                                name="side"
                                value={newOrder?.side}
                                onChange={
                                    event => setNewOrder(
                                        setObject(event, newOrder)
                                    )
                                }
                            >
                                <option value="sell">Venda</option>
                                <option value="buy">Compra</option>
                            </Select>
                        </td>
                        <td>
                            <Select
                                data-testid="newOrder-ordType"
                                name="ordType"
                                value={newOrder?.ordType.toString()}
                                onChange={
                                    event => setNewOrder(
                                        setObject(event, newOrder)
                                    )
                                }
                            >
                                <option value="1">market</option>
                                <option value="2">limit</option>
                                <option value="5">market_with_left_over_as_limit</option>
                            </Select>
                        </td>
                        <td>
                            <Field
                                data-testid="newOrder-price"
                                required
                                format="Decimal"
                                name="price"
                                value={newOrder?.price}
                                onChange={
                                    event => setNewOrder(
                                        setObject(event, newOrder)
                                    )
                                }
                                disabled={newOrder.ordType == 1 ? true : false}
                            />
                        </td>
                        <td>
                            <Input 
                                disabled 
                                name="day"
                                placeholder="day" 
                            />
                        </td>
                        <td>
                            <Field
                                data-testid="newOrder-account"
                                required
                                format="Number"
                                name="account"
                                value={newOrder?.account}
                                onChange={
                                    event => setNewOrder(
                                        setObject(event, newOrder)
                                    )
                                }
                            />
                        </td>
                        <td>
                            <Input
                                data-testid="newOrder-enteringTrader"
                                required={isOperatorCheck}
                                name="enteringTrader"
                                value={newOrder?.enteringTrader}
                                onChange={
                                    event => setNewOrder(
                                        setObject(event, newOrder, true)
                                    )
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </SectionWrapper>
    )
}
import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs";

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatDecimal, formatInteger } from '../../../common/formatters';

export function GoldCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [custody, setCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientCustody() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/client/${account}/goldCustody`);

            const data = await response.json();

            const custodyItemsData = [
                {
                    symbol: 'OZ1D',
                    availableContracts: formatInteger(data?.custody?.availableOZ1DContracts),
                    intradayContracts: formatInteger(data?.custody?.intradayOZ1DContracts),
                    total: formatInteger(data?.custody?.availableOZ1DContracts + data?.custody?.intradayOZ1DContracts),
                    grams: formatDecimal(data?.custody?.availableOZ1DGrams + data?.custody?.intradayOZ1DGrams, 4),
                    price: formatDecimal(data?.custody?.priceOZ1D),
                    gramsTotal: formatDecimal((data?.custody?.availableOZ1DGrams + data?.custody?.intradayOZ1DGrams) * data?.custody?.priceOZ1D)
                },
                {
                    symbol: 'OZ2D',
                    availableContracts: formatInteger(data?.custody?.availableOZ2DContracts),
                    intradayContracts: formatInteger(data?.custody?.intradayOZ2DContracts),
                    total: formatInteger(data?.custody?.availableOZ2DContracts + data?.custody?.intradayOZ2DContracts),
                    grams: formatDecimal(data?.custody?.availableOZ2DGrams + data?.custody?.intradayOZ2DGrams, 4),
                    price: formatDecimal(data?.custody?.priceOZ2D),
                    gramsTotal: formatDecimal((data?.custody?.availableOZ2DGrams + data?.custody?.intradayOZ2DGrams) * data?.custody?.priceOZ2D)
                },
                {
                    symbol: 'OZ3D',
                    availableContracts: formatInteger(data?.custody?.availableOZ3DContracts),
                    intradayContracts: formatInteger(data?.custody?.intradayOZ3DContracts),
                    total: formatInteger(data?.custody?.availableOZ3DContracts + data?.custody?.intradayOZ3DContracts),
                    grams: formatDecimal(data?.custody?.availableOZ3DGrams + data?.custody?.intradayOZ3DGrams, 4),
                    price: formatDecimal(data?.custody?.priceOZ3D),
                    gramsTotal: formatDecimal((data?.custody?.availableOZ3DGrams + data?.custody?.intradayOZ3DGrams) * data?.custody?.priceOZ3D)
                }
            ];

            setCustody(custodyItemsData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if (updateAllGrids) {
            fetchClientCustody()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientCustody();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol,
        },
        {
            Header: 'Disponível',
            accessor: data => data.availableContracts,
            columnAlign: 'right',
        },
        {
            Header: 'Intraday',
            accessor: data => data.intradayContracts,
            columnAlign: 'right',
        },
        {
            Header: 'Total',
            accessor: data => data.total,
            columnAlign: 'right',
        },
        {
            Header: 'Gramas',
            accessor: data => data.grams,
            columnAlign: 'right',
        },
        {
            Header: 'Preço',
            accessor: data => data.price,
            columnAlign: 'right'
        },
        {
            Header: 'Valor Total',
            accessor: data => data.gramsTotal,
            columnAlign: 'right',
        },
    ]

    return (
        <SectionWrapper
            id={id}
            headerTag="h3"
            header="Custódia de Ouro"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={custody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button 
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading} 
                    onClick={fetchClientCustody}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex/>}

            {isLoading ? "" :
                (
                    <SmartTable data={custody} columns={columns} localStorageKey={'ClientGoldCustody'}/>
                )}
        </SectionWrapper>
    );
}

import React, { useEffect, useState } from "react";
import { BsCone, BsTrashFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";

import { H3 } from '../../../components/Typography'
import { Button, Input, Select, PolicyField, DateTime } from '../../../components/Forms'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Tooltip } from "../../../components/Tooltip";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Alert } from "../../../components/Alert";

import { formatArrayNumberValues } from '../../../common/formatters';
import { getPolicyKeyTypeByName } from "../../../common/search";
import { policyTypeValues } from '../../../common/enums.js';
import { sanitizeName } from '../../../common/variables';

import { getMarketForParameter, disableMarketSelection, disableSymbolSelection, checksIfParameterIsMarket} from '../../../common/checkers';

import { API_HEADER } from "../../../constants/api";

const today = new Date().toISOString().substr(0, 10);

type Parameters = {
    name: string;
    market: string;
    symbol?: string;
    value: string;
    comments?: string;
    expirationDate?: any;
    key: any
}

const parameterObj = {
    name: "",
    market: "",
    symbol: "",
    value: "",
    comments: "",
    expirationDate: null,
    key: {}
}

export function NewPolicy() {
    const history = useHistory();

    const [name, setName] = useState("");
    const [policyType, setPolicyType] = useState("");
    const [parentId, setParentId] = useState<number>();
    const [parametersItems, setParametersItems] = useState<Parameters[]>([parameterObj]);

    const [allMarkets, setAllMarkets] = useState([]);
    const [standardMarkets, setStandardMarkets] = useState([]);
    const [clientKeys, setClientKeys] = useState([]);
    const [enteringTraderKeys, setEnteringTraderKeys] = useState([]);
    const [keys, setKeys] = useState([]);
    const [policyClientItems, setPolicyClientIItems] = useState([]);
    const [policyEnteringTraderItems, setPolicyEnteringTraderItems] = useState([]);
    const [policyItems, setPolicyItems] = useState([]);

    const [showParametersTable, setShowParametersTable] = useState(false);
    const [showMessageNewPolicy, setShowMessageNewPolicy] = useState(false);

    const [validated, setValidated] = useState(false);
    const [error, setError] = useState<Error>();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => { 
        if (policyType === "Client") {
            setKeys(clientKeys)
            setPolicyItems(policyClientItems)
        } else if (policyType === "EnteringTrader") {
            setKeys(enteringTraderKeys)
            setPolicyItems(policyEnteringTraderItems)
        }
    }, [policyType])

    /** Listagem dos dados API */
    const fetchData = async () => {
        const policyFetch = await fetch('/api/policy');
        policyFetch
        .json()
        .then(res => {
            const policiesClient = res.filter(item => item.shared === true && item.isInternal === false && item.policyType === "Client")
            const policiesEnteringTrader = res.filter(item => item.shared === true && item.isInternal === false && item.policyType === "EnteringTrader")
            setPolicyClientIItems(policiesClient)
            setPolicyEnteringTraderItems(policiesEnteringTrader)
        })
        .catch((error) => console.log(error));

        const keysFetch = await fetch('/api/policy/keys');
        keysFetch
        .json()
        .then(res => {
            setClientKeys(res.map(g => g.filter(x => x.isClient)).filter(x => x.length > 0))
            setEnteringTraderKeys(res.map(g => g.filter(x => x.isEnteringTrader)).filter(x => x.length > 0))
        })
        .catch((error) => console.log(error));

        const allMarketsFetch = await fetch(`/api/policy/market`)
        allMarketsFetch
        .json()
        .then(res => setAllMarkets(res))
        .catch((error) => console.log(error));

        const standardMarketsFetch = await fetch(`/api/policy/market/standard`)
        standardMarketsFetch
        .json()
        .then(res => setStandardMarkets(res))
        .catch((error) => console.log(error));
    };

    /** Cadastro da Política API */
    const submitNewPolicy = (event) => {
        event.preventDefault();

        setError(null);
        setDisabled(true);

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setValidated(true);
            setDisabled(false);
            return event.stopPropagation();
        }

        if (checkDuplicateParameters()) {
            setDisabled(false);
            setError(new Error("Há parâmetros duplicados na nova política!"));
            return event.stopPropagation();
        }

        const isValidPolicyName = checkPolicyName();
            if (!isValidPolicyName.isValid) {
            setDisabled(false);
            setError(new Error(isValidPolicyName.message));
            return event.stopPropagation();
        }

        const parameters = formatArrayNumberValues(parametersItems, ['value'])

        const bodyRequest = {
            name,
            policyType,
            parentId,
            parameters
        }

        const options = {
            method: "POST",
            headers: API_HEADER,
            body: JSON.stringify(bodyRequest),
        };

        fetch(`/api/policy/`, options)
        .then(async (response) => {
            if (response.status !== 200) {
                setError(await response.json());
                return setDisabled(false);
            }

            setShowMessageNewPolicy(true);
            return setDisabled(false);
        })
        .catch((error) => console.log(error))
    };

    /** Atualiza os dados dos parâmetros */
    const handleParametersItems = (position, field, value, event) => {
        const upadatedParametersItems = parametersItems.map(
            (parameterItem, index) => {
                if (event == null)
                {
                    if (index === position)
                    {
                        if (field === "expirationDate" && value === "") {
                            return { ...parameterItem, [field]: null };
                        }
                        return { ...parameterItem, [field]: value };
                    }
                }
                else
                {
                    const { type } = event?.target;

                    if (index === position) {
                        if (type === "number")
                            return { ...parameterItem, [field]: parseFloat(value) };

                        if (field != "name")
                            return { ...parameterItem, [field]: value };

                        return { ...parameterItem, [field]: value, market: getMarketForParameter(keys, value), symbol: "" };
                    }
                }

                return parameterItem;
            }
        );

        return setParametersItems(upadatedParametersItems);
    };

    /** Adiciona novo parâmetro na tabela */
    const addNewParameterItem = () => {
        setParametersItems([ ...parametersItems, parameterObj]);
    };

    /** Deleta o parâmetro da linha selecionada na tabela */
    const handleParametersDeleteRow = (position) => {
        const parametersArray = [...parametersItems];
        parametersArray.splice(position, 1);
        setParametersItems(parametersArray);
    };

    /** Verifica se há parâmetros duplicados na lista */
    const checkDuplicateParameters = () => {
        const allParameter = [...parametersItems];

        const duplicates = allParameter.filter((parameter, indexParameter) => {
            const compare = allParameter.filter((parameterCompare, indexParameterCompare) => {
                return (
                    parameter.name === parameterCompare.name && 
                    parameter.market === parameterCompare.market && 
                    (parameter.symbol?.toUpperCase() ?? "") == (parameterCompare.symbol?.toUpperCase() ?? "") &&
                    indexParameter !== indexParameterCompare
                )
            })
            return compare.length > 0;
        })

        return duplicates.length > 0;
    }

    /** Verifica se o nome da política é válido */
    const checkPolicyName = () => {
        if (Number.isInteger(Number(name))) {
            return {
                isValid: false,
                message: `O nome da política não pode ser um número.`
            };
        }

        return {
            isValid: true,
            message: `O nome da política é válido.`
        };
    }

    const markets = (name) => {
        if (checksIfParameterIsMarket(keys, name)) {
            return standardMarkets
        } else {
            return allMarkets
        }   
    }

    return (
        <>
            <SectionWrapper header="Nova Política">                
                <form onSubmit={submitNewPolicy}>
                    <div className="int-row">
                        <div className="int-col">
                            <Input 
                                data-testid="policy-name"
                                isRequired
                                label="Nome"
                                name="name"
                                placeholder="Nome da política"
                                value={name}
                                onChange={(e) => setName(sanitizeName(e.target.value))}
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <Select
                                data-testid="policy-policyType"
                                isRequired
                                label="Cliente / Operador"
                                name="policyType"
                                value={policyType}
                                onChange={(e) => setPolicyType(e.target.value)}
                            >
                                <option hidden value="">Selecione o tipo de política</option>
                                    {Object.entries(policyTypeValues).map((item, index) => (
                                        <option key={index} value={item[0]}>
                                            {item[1]}
                                        </option>
                                    ))}
                            </Select>
                        </div>

                        <div className="int-col int-pl-0">
                            <Select
                                data-testid="policy-parentPolicy"
                                isRequired
                                disabled={!policyType}
                                label="Política Pai"
                                name="parentId"
                                value={parentId}
                                onChange={(e) => setParentId(parseFloat(e.target.value))}
                            >
                                <option hidden value="">Selecione uma política pai</option>
                                    {policyItems.map((item) => (
                                        <option data-testid="policy-parentPolicy-options" key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </Select>
                        </div>
                    </div>
                
                    <H3 margin="int-mt-6">Parâmetros</H3>
                    <table className="int-table">
                        <thead>
                            <tr>
                                <th className="int-px-1">Nome <sup style={{color: "var(--int-colors-red-500)"}}>*</sup></th>
                                <th className="int-px-1">Mercado <sup style={{color: "var(--int-colors-red-500)"}}>*</sup></th>
                                <th className="int-px-1">Símbolo</th>
                                <th className="int-px-1">Valor <sup style={{color: "var(--int-colors-red-500)"}}>*</sup></th>
                                <th className="int-px-1">Comentário</th>
                                <th className="int-px-1">Validade</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {parametersItems?.map((parameter, index) => (
                                <tr key={index}>
                                    <td className="int-px-1">
                                        <div className="int-d-flex int-align-items-center">
                                            <Select
                                                data-testid="parameter-name"
                                                isRequired
                                                disabled={!policyType}
                                                name="name"
                                                value={parameter?.name}
                                                onChange={(e) => handleParametersItems(index, "name", e.target.value, e)}
                                            >
                                                <option hidden value="">Selecione um nome</option>
                                                {keys.map((group, index) => (
                                                    <optgroup label={group[0].groupName} key={index}>
                                                        {group.map((item, index) => (
                                                            <option data-testid="parameter-name-options" key={index} value={item.name}>
                                                                {item.friendlyName}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                            </Select>
                                            
                                            <div className="int-ml-2" onClick={() => setShowParametersTable(true)}>
                                                <Tooltip label="Clique para abrir a tabela de descrição dos parâmetros" />
                                            </div>
                                        </div>
                                    </td>

                                    <td className="int-px-1">
                                        <Select
                                            data-testid="parameter-market"
                                            isRequired
                                            disabled={ disableMarketSelection(keys, parameter)}
                                            name="market"
                                            value={parameter?.market}
                                            onChange={(e) => handleParametersItems(index, "market", e.target.value, e)}
                                        >
                                            <option hidden value="">Selecione um mercado</option>
                                            {markets(parameter?.name).map((item, index) => (
                                                <option data-testid="parameter-market-options" key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Select>
                                    </td>

                                    <td className="int-px-1">
                                        <Input
                                            data-testid="parameter-symbol"
                                            name="symbol"
                                            value={parameter?.symbol}
                                            disabled={disableSymbolSelection(keys, parameter)}
                                            onChange={(e) =>
                                                handleParametersItems(index, "symbol", e?.target?.value.trim().toUpperCase(), e)
                                            }
                                        />
                                    </td>

                                    <td className="int-px-1">
                                        <PolicyField
                                            data-testid="parameter-value"
                                            isRequired
                                            keys={keys.flat()}
                                            keyName={parameter?.name}
                                            name="value"
                                            value={parameter?.value || ''}
                                            onChange={(e) =>
                                                handleParametersItems(index, "value", e.target.value, e)
                                            }
                                        />
                                    </td>

                                    <td className="int-px-1">
                                        <Input
                                            data-testid="parameter-comment"
                                            name="comments"
                                            value={parameter?.comments || ''}
                                            onChange={(e) =>
                                                handleParametersItems(index, "comments", e?.target?.value, e)
                                            }
                                        />
                                    </td>

                                    <td className="int-px-1">
                                        <DateTime
                                            data-testid="parameter-validity"
                                            name = "validity"
                                            value = {parameter?.expirationDate}
                                            minDate={today}
                                            maxDate={"9999-12-31"}
                                            setDateTime={(e) =>
                                            {
                                                handleParametersItems(index, "expirationDate", e , null)
                                            }}
                                        >
                                        </DateTime>
                                    </td>

                                    <td className="int-px-1">
                                        <BsTrashFill
                                            data-testid="parameter-remove-item"
                                            size={22}
                                            color="var(--chakra-colors-orange-400)"
                                            className="ml-3"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleParametersDeleteRow(index)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Button margin="int-mt-4" onClick={addNewParameterItem} variant="outline">
                        Adicionar novo parâmetro
                    </Button>

                    <Button
                        isFullWidth 
                        disabled={disabled
                            || name === ""
                            || parametersItems[0]?.name === ""
                            || parametersItems[0]?.market === ""
                            || parametersItems[0]?.value === ""
                            || (getPolicyKeyTypeByName(keys.flat(), parametersItems[0]?.name) === "Bool"
                                    && (parametersItems[0]?.value != "Sim" && parametersItems[0]?.value != "Não")) }
                        margin="int-my-4"
                        type="submit"
                    >
                        Cadastrar política
                    </Button>

                    {error && (
                        <Alert>
                            Ocorreu um erro: {error?.message || 'Verifique se todos os parâmetros estão corretos'}
                        </Alert>
                    )}
                </form>
            </SectionWrapper>

            {/* Modal - Tabela de Parâmetros */}
            <Modal
                size="xlg"
                header="Tabela de parâmetros"
                isOpen={showParametersTable}
                onClose={() => setShowParametersTable(false)}
            >
                <ModalBody>
                    <table className="int-table">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                            {keys.flat().map((item, index) => (
                                <tr key={index}>
                                    <td>{item.friendlyName}</td>
                                    <td>{item.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setShowParametersTable(false)}>
                        Fechar
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal - Mensagem Cadastro com sucesso */}
            <SimpleModal 
                header="Política cadastrada com sucesso"
                isOpen={showMessageNewPolicy}
                onClose={() => setShowMessageNewPolicy(false)}
                bodyMessage="A política foi cadastrada com sucesso!"
                footerButtons={[
                    {
                        label: 'Nova Política',
                        onClick: () => history.go(0),
                        variant: 'outline'
                    },
                    {
                        label: 'Lista de Políticas',
                        onClick: () => history.push('/policies'),
                        margin: "int-ml-2"
                    }
                ]}
            />
        </>
    );
}

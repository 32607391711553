import React, { useEffect, useState, } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Button, Checkbox } from '../../../components/Forms'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Loader } from '../../../components/Loader'
import { Link } from '../../../components/Link'
import { SmartTable } from '../../../components/Table'
import { NumberRangeColumnFilter } from '../../../components/Table/Filters'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from "../../../components/Alert";

import { formatCpfCnpj } from "../../../common/formatters";

import { API_HEADER } from "../../../constants/api";

type Params = {
    id: string
}

type Policy = {
    id: number;
    name: string;
    policyType: "Client" | "EnteringTrader";
    shared: boolean;
    isMaster: boolean
}

export function SectionClientsPage() {
    let { id } = useParams<Params>();

    const history = useHistory();

    const [enteringTraderCheckBox, setEnteringTraderCheckBox] = useState([]);
    const [allEnteringTraders, setAllEnteringTraders] = useState([]);
    const [clientsCheckBox, setClientsCheckBox] = useState([]);
    const [allClients, setAllClients] = useState([]);

    const [policy, setPolicy] = useState<Policy>();
    const [policyClients, setPolicyClients] = useState([]);
    const [policyEnteringTraders, setPolicyEnteringTraders] = useState([]);

    const [deleteError, setDeleteError] = useState(false)
    const [disabledDelete, setDisabledDelete] = useState(false)
    const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false)
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)

    const columnsClients = [
        {
            id: 'Seleção',
            Header: () =>
                <Checkbox
                    onChange={allCheckBoxesHandle}
                    isChecked={allClientsChecked}
                />,
            Cell: ({ row }) =>
                <Checkbox
                    value={row.original.id}
                    onChange={checkBoxColumnHandle}
                    isChecked={clientsCheckBox.find(i => i == row.original.id)}
                />,
            width: '40px',
            minWidth: 40
        },
        {
            Header: 'Cliente',
            accessor: 'name',
            Cell: ({ value, row }) => <Link href={`/client/${row?.values?.id}`}>{value}</Link>,
        },
        {
            Header: 'Conta',
            accessor: 'id',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: 'CPF/CNPJ',
            accessor: 'document',
            Cell: ({ value }) => formatCpfCnpj(value)
        },
        {
            Header: 'Status',
            accessor: 'status'
        }
    ]

    const columnsEnteringTraders = [
        {
            id: 'Seleção',
            Header: () =>
                <Checkbox
                    onChange={allCheckBoxesHandle}
                    isChecked={allEnteringTradersChecked}
                />,
            Cell: ({ row }) =>
                <Checkbox
                    value={row.original.enteringTraderId}
                    onChange={checkBoxColumnHandle}
                    isChecked={enteringTraderCheckBox.find(i => i == row.original.enteringTraderId)}
                />,
            width: '40px',
            minWidth: 40
        },
        {
            Header: 'Letra do Operador',
            accessor: 'enteringTraderId',
            Cell: ({ value, row }) => <Link href={`/operators/${row?.values?.enteringTraderId}`}>{value}</Link>,
        },
        {
            Header: 'Descrição',
            accessor: 'description',
        },
        {
            Header: 'Clientes',
            accessor: 'clients',
            Cell: ({ value }) => `${value?.sort().slice(0, 6).join(', ')}${value?.length > 6 ? '...' : ''}` || '-',
        },
        {
            Header: 'Assessores',
            accessor: 'advisors',
            Cell: ({ value }) => `${value?.sort().slice(0, 6).join(', ')}${value?.length > 6 ? '...' : ''}` || '-',
        }
    ]

    const fetchData = async () => {
        const policyFetch = await fetch(`/api/policy/${id}`)
        if (policyFetch.status !== 200) {
            if (policy.policyType === "Client")
                history.push(`/client/${policy.name}`);
            else
                history.push(`/operators/${policy.name}`);
        } else {
            policyFetch
                .json()
                .then(res => setPolicy(res))
                .catch((error) => console.log(error));
        }
        const policyClientsFetch = await fetch(`/api/client/register?policyId=${id}`)
        policyClientsFetch
            .json()
            .then(res => { 
                setPolicyClients(res)
                setAllClients(res.map(item => `${item.id}`))
            })
            .catch((error) => console.log(error));

        const policyEnteringTradersFetch = await fetch(`/api/enteringTrader?policyId=${id}`)
        policyEnteringTradersFetch
            .json()
            .then(res => { 
                setPolicyEnteringTraders(res)
                setAllEnteringTraders(res.map(item => `${item.enteringTraderId}`))
            })
            .catch((error) => console.log(error));
    }

    const allClientsChecked = allClients?.every(x => clientsCheckBox.includes(x))
    const allEnteringTradersChecked = allEnteringTraders?.every(x => enteringTraderCheckBox.includes(x))

    const checkBoxColumnHandle = ({ target }) => {
        if (policy?.policyType === "Client") {
            if (target.checked) {
                setClientsCheckBox(clientsCheckBox.concat([target.value]))
            } else {
                setClientsCheckBox(clientsCheckBox.filter(i => i !== target.value))
            }
        }
        else {
            if (target.checked) {
                setEnteringTraderCheckBox(enteringTraderCheckBox.concat([target.value]))
            } else {
                setEnteringTraderCheckBox(enteringTraderCheckBox.filter(i => i !== target.value))
            }
        }
    }

    const allCheckBoxesHandle = ({ target }) => {
        if (policy?.policyType === "Client") {
            if (target.checked) {
                setClientsCheckBox(allClients)
            } else {
                setClientsCheckBox([])
            }
        }
        else {
            if (target.checked) {
                setEnteringTraderCheckBox(allEnteringTraders)
            } else {
                setEnteringTraderCheckBox([])
            }
        }
    }

    const onFiltersChange = (rows) => {
        if (policy?.policyType === "Client") {
            const clients = rows.map(item => `${item.original.id}`)
            setAllClients(clients)
            setClientsCheckBox([])
        }
        else {
            const operators = rows.map(item => item.original.enteringTraderId)
            setAllEnteringTraders(operators)
            setEnteringTraderCheckBox([])
        }
    }

    /** Move um ou mais clientes para a política Master API*/
    const handleChangeClients = async () => {
        setDeleteError(false)
        setDisabledDelete(true)

        try {
            const bodyRequest = {
                policyId: 1,
                accounts: clientsCheckBox
            }

            const options = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(bodyRequest),
            }

            const response = await fetch(`/api/policy/updatePolicyClient`, options)

            if (!response.ok) {
                setDeleteError(true)
                const error = await response.json()
                throw new Error(error?.message)
            }

            setShowConfirmDeleteModal(false)
            setShowSuccessDeleteModal(true)

        } catch (err) {
            setDeleteError(true)
        } finally {
            setDisabledDelete(false)
        }
    }

    /** Move um ou mais operadores para a política Master API*/
    const handleChangeOperators = async () => {
        setDeleteError(false)
        setDisabledDelete(true)

        try {
            const bodyRequest = {
                policyId: 2,
                enteringTraderIds: enteringTraderCheckBox
            }

            const options = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(bodyRequest),
            }

            const response = await fetch(`/api/policy/updatePolicyOperator`, options)

            if (!response.ok) {
                setDeleteError(true)
                const error = await response.json()
                throw new Error(error?.message)
            }

            setShowConfirmDeleteModal(false)
            setShowSuccessDeleteModal(true)

        } catch (err) {
            setDeleteError(true)
        } finally {
            setDisabledDelete(false)
        }
    }

    useEffect(() => {
        const handleData = async () => {
            const policyFetch = await fetch(`/api/policy/${id}`)
            policyFetch
                .json()
                .then(res => setPolicy(res))
                .catch((error) => console.log(error))

            const policyClientsFetch = await fetch(`/api/client/register?policyId=${id}`)
            policyClientsFetch
                .json()
                .then(res => { 
                    setPolicyClients(res)
                    setAllClients(res.map(item => `${item.id}`))
                })
                .catch((error) => console.log(error));

            const policyEnteringTradersFetch = await fetch(`/api/enteringTrader?policyId=${id}`)
            policyEnteringTradersFetch
                .json()
                .then(res => { 
                    setPolicyEnteringTraders(res)
                    setAllEnteringTraders(res.map(item => `${item.enteringTraderId}`))
                })
                .catch((error) => console.log(error));
        }

        handleData();
    }, [id]);

    return (
        <>
            {policy?.isMaster || policy?.shared === false ? null : policy?.policyType === "Client" ? (
                <SectionWrapper
                    headerTag="h3"
                    header={"Clientes atrelados à política"}
                    isAccordion
                >
                    {policyClients?.length <= 0 ? (
                        <p className="int-mt-5 int-text-center">
                            Não existem clientes atrelados à essa política
                        </p>
                    ) : (
                        <SmartTable
                            columnFilters
                            columns={columnsClients}
                            data={policyClients}
                            localStorageKey={'PolicyClientSection'}
                            onFiltersChange={onFiltersChange}
                            columnsResizable
                        />
                    )}
                    <div className="int-d-flex int-justify-content-end int-mt-2">
                        <Button
                            disabled={
                                disabledDelete || 
                                policyClients?.length < 1 ||
                                clientsCheckBox?.length < 1}
                            onClick={() => setShowConfirmDeleteModal(true)}
                            variant="outline"
                            margin="int-ml-2"
                        >
                            {disabledDelete ? <Loader /> : 'Mover para política Master'}
                        </Button>
                    </div>
                </SectionWrapper>
            ) : (
                <SectionWrapper
                    headerTag="h3"
                    header={"Operadores atrelados à política"}
                    isAccordion
                >
                    {policyEnteringTraders?.length <= 0 ? (
                        <p className="int-mt-5 int-text-center">
                            Não existem operadores atrelados à essa política
                        </p>
                    ) : (
                        <SmartTable
                            columnFilters
                            columns={columnsEnteringTraders}
                            data={policyEnteringTraders}
                            localStorageKey={'PolicyOperatorSection'}
                            onFiltersChange={onFiltersChange}
                            columnsResizable
                        />
                    )}

                    <div className="int-d-flex int-justify-content-end int-mt-2">
                        <Button
                            disabled={
                                disabledDelete || 
                                policyEnteringTraders?.length < 1 ||
                                enteringTraderCheckBox.length < 1}
                            onClick={() => setShowConfirmDeleteModal(true)}
                            variant="outline"
                            margin="int-ml-2"
                        >
                            {disabledDelete ? <Loader /> : 'Mover para política Operador Master'}
                        </Button>
                    </div>
                </SectionWrapper>
            )}

            {/* Modal - Mesagem de confirmação da remoção */}
            {policy?.policyType === 'Client' ? (
                <Modal
                    header="Mover os clientes selecionados para política Master"
                    isOpen={showConfirmDeleteModal}
                    onClose={() => setShowConfirmDeleteModal(false)}
                >
                    <ModalBody>
                        <p>
                            Você tem certeza que deseja alterar os dados da lista?
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowConfirmDeleteModal(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            margin="int-ml-2"
                            onClick={() => {
                                handleChangeClients()
                                fetchData()
                            }}
                        >
                            Alterar
                        </Button>

                        {deleteError && (
                            <Alert margin="int-mt-3">
                                Ocorreu um erro na alteração de política.
                            </Alert>
                        )}
                    </ModalFooter>
                </Modal>
            ) : (
                <SimpleModal 
                    header="Mover os operadores selecionados para política Operador Master"
                    isOpen={showConfirmDeleteModal}
                    onClose={() => setShowConfirmDeleteModal(false)}
                    bodyMessage="Você tem certeza que deseja alterar os dados da lista?"
                    footerButtons={[
                        {
                            label: 'Cancelar',
                            variant: "outline",
                            onClick: () => setShowConfirmDeleteModal(false)
                        },
                        {
                            label: "Alterar",
                            margin: "int-ml-2",
                            onClick: () => {
                                handleChangeOperators()
                                fetchData()
                            }
                        }
                    ]}
                />
            )}

            {/* Modal - Mensagem remoção com sucesso  */}
            <SimpleModal 
                header="Os dados foram alterados com sucesso"
                isOpen={showSuccessDeleteModal}
                onClose={() => setShowSuccessDeleteModal(false)}
                bodyMessage=""
                footerButton={{
                    label: "Concluir",
                    onClick: () => { 
                        setShowSuccessDeleteModal(false)
                        fetchData()
                    }
                }}
            />
        </>
    );
}
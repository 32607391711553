import React, { ReactNode } from "react";

interface ModalProps {
    children: any | JSX.Element;
    onClose: () => void;
}

export function ToastNotification({ children, onClose }: ModalProps) {
    return (
        <div
            style={{
                position: "fixed",
                top: "10px",
                right: "10px",
                width: "400px",
                zIndex: 2,
                padding: 10,
                backgroundColor: "var(--int-colors-gray-800)",
                borderTop: " solid 5px",
                borderColor: "var(--int-colors-orange-400)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
            }}
        >
            {children}
            <button
                data-testid="close-modal-button"
                className="int-modal-close-button"
                onClick={onClose}
            ></button>
        </div>
    );
}

export function objToQueryString(obj) {
    const keyValuePairs = [];
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
        if (Object.values(obj)[i] !== undefined)
            keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`);
    }
    return keyValuePairs.join('&');
}

export const dateQueryParse = (value) => {
    if (value !== undefined) {
        let date = value;

        date = date.replace("T", " ");
        date = date.replace("%3A", ":");

        return date
    };

    return '';
};
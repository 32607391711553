import { useRef, useEffect } from 'react'

export const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
    const targetRef = useRef();

    useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};
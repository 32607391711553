import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { formatInteger, formatDateTime, formatDecimal } from '../../common/formatters';
import { Loader } from '../../components/Loader'
import { SectionWrapper } from '../../components/SectionWrapper'
import { SmartTable } from '../../components/Table'
import { Alert } from '../../components/Alert'
import { orderStatusEnum } from '../../common/enums.js';

type Params = {
    orderID: string
}

export function EventHistory() {
    let params = useParams<Params>();

    const [loadingTable, setLoadingTable] = useState(false);
    const [error, setError] = useState(false)
    const [eventsHistory, setEventsHistory] = useState(null)

    useEffect(() => {
        setError(false)
        if( params ) {
           fetchEventHistory()
        }
    }, [])


    const columns = [
        {
        Header: 'Data',
        accessor: ({ transactTime }) => formatDateTime(transactTime)
        },
        {
        Header: 'Status',
        accessor: ({ status }) => orderStatusEnum(status)
        },
        {
        Header: 'Quantidade da execução',
        accessor: 'lastQuantity',
        Cell: ({ value }) => formatInteger(value)
        },
        {
        Header: 'Preço de execução',
        accessor: 'lastPrice',
        Cell: ({ value }) => formatDecimal(value)
        },
        {
        Header: 'Preço da ordem',
        accessor: 'price',
        Cell: ({ value }) => formatDecimal(value)
        },
        {
        Header: 'Quantidade da ordem',
        accessor: 'orderQuantity',
        Cell: ({ value }) => formatInteger(value)
        },
    ]

    const fetchEventHistory = async () => {
        try {
            setLoadingTable(true)
            const response = await fetch(`/api/orders/executions/${params.orderID}`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()
            
            setEventsHistory(data)
            setLoadingTable(false)
        } catch(err) {
            setLoadingTable(false)
            setError(true)
        }
    }
    
    return (
        <SectionWrapper header="Historico de eventos">

            {loadingTable && <Loader flex/>}

            { error || eventsHistory?.length === 0  ? <Alert>Ocorreu um erro na busca</Alert> : eventsHistory && (
                    <SmartTable 
                        columns={columns} 
                        data={eventsHistory} 
                        localStorageKey= {'OrderTrackingEventHistoryPage'} 
                />
            )}
                     
        </SectionWrapper>
    );
};

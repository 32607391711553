import React, { useEffect, useState } from "react"
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table"

import { formatInteger, formatDecimal } from '../../../common/formatters'

export function BtcCustodyTaker({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [custody, setCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientCustody() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/btc/${account}/btcCustodyTaker`);

            const data = await response.json();

            const custodyItemsData = data?.map(item => ({
                ...item,
                symbol: item.symbol ?? "-",
                indOpcEarlyReturn: item.indOpcEarlyReturn ? "Sim" : "Não",
                quantity: formatInteger(item.quantity),
                price: formatDecimal(item.price),
                amount: formatDecimal(item.amount),
            }))

            setCustody(custodyItemsData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchClientCustody();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol,
        },
        {
            Header: 'Liq. Antecip',
            accessor: data => data.indOpcEarlyReturn,
        },
        {
            Header: 'Qtd.',
            accessor: data => data.quantity,
            columnAlign: 'right',
            hint: 'Quantidade'
        },
        {
            Header: 'Último Preço',
            accessor: data => data.price,
            columnAlign: 'right',
        },
        {
            Header: 'Valor',
            accessor: data => data.amount,
            columnAlign: 'right',
        }
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="BTC Tomador"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={custody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-align-items-center">
                <button
                    className="int-btn-icon int-btn-icon-outline int-ml-auto"
                    disabled={isLoading}
                    onClick={fetchClientCustody}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? "" :
                (
                    <SmartTable data={custody} columns={columns} localStorageKey={'ClientBtcCustodyTaker'}/>
                )}
        </SectionWrapper>
    );
}

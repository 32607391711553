import React, { useEffect, useState } from 'react'

import { Button, Input, Field, ButtonGroup, Checkbox } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'
import { FiSearch } from "react-icons/fi"
import MultipleCheckboxFilter from '../../components/MultipleCheckboxFilter'



import { objToQueryString } from '../../common/api.js';

import { useQueryParams } from '../../hooks/useQueryParams'
import { SimpleModal } from '../../components/Modal'
type QueryObject = {
    accounts?: string;
    allClients?: boolean; 
}



export function LimitReportsHistory() {

    const [refresh, setRefresh] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [timeLeft, setTimeLeft] = useState<number>()
    const [intervalId, setIntervalId] = useState(null)
    const isStarted = intervalId !== null

    const { setParam, updateQuery } = useQueryParams()
    const [queryObject, setQueryObject] = useState<QueryObject>();

    const [clients, setClient] = useState(setParam("account")); 
    
    const [allClients, setAllClients] = useState(false);

    const [loadingTable, setLoadingTable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [notFound, setNotFound] = useState(false);


    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [results, setResults] = useState([])
    const [searched, setSearched] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(true);
    const today = new Date().toISOString().substring(0, 10);
    const [smartTableData, setSmartTableData] = useState({top: "", skip: ""});
    const [smartTableDataCheck, setSmartTableDataCheck] = useState({top: "", skip: ""});

    useEffect(() => {
        if (queryObject) {
        (async function () {
            await fetchReportsHistory()
            setTimeLeft(2);
            setLoadingTable(false)
            setIsLoading(false)
            return null
        })();
        }
    }, [queryObject])

    useEffect(() => {
        if ((smartTableData.top != smartTableDataCheck.top || smartTableData.skip != smartTableDataCheck.skip)
            && smartTableData?.top != "10" 
            && smartTableData?.top != "" 
            && smartTableDataCheck?.top != "10" 
            && !loadingTable) {
        (async function () {
            await fetchReportsHistory()
            setTimeLeft(2);
            setLoadingTable(false)
            setIsLoading(false)
            return null
        })();
        }
    }, [smartTableData])
    

    const smartTableToParent = (childdata) => {
        setSmartTableData(childdata);
        if(smartTableData?.top == "10" || smartTableData?.top == "")
        {
            setSmartTableData({top: "20", skip: "0"})
            setSmartTableDataCheck({top: "20", skip: "0"});
        }
    }
    
    useEffect(() => {
        smartTableToParent(smartTableToParent)
    }, [smartTableData])

    async function fetchReportsHistory()
    {
        setIsRefreshing(true)
        setIsLoading(true)
        smartTableToParent(smartTableData)
        setSmartTableDataCheck({top: smartTableData?.top, skip: smartTableData?.skip})

        const apiQueryString = objToQueryString({
        ...queryObject,
        });

        if (isQueryEmpty){
            setFiltering(true);
            return;
        }else if(!refresh){
            setShowFilterModal(false);
        }
        try {
            setNotFound(false)
            setFiltering(false);
            const data = await fetch(`/api/client/limitReport?${apiQueryString}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
              })
            .then(json => {
                if (json.count == 0) {
                    setNotFound(true)
                }
                return true
            })

        } catch (err) {
            setError(true)
        }
        setIsRefreshing(false)
    }


    const setQueryObjectFilters = () => {

        const queryStringFilters = {
            accounts: clients,
            allClients: allClients
        };

        checkIsEmpty(queryStringFilters);
        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const checkIsEmpty = (queryStringFilters) => {
 
        let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
        setIsQueryEmpty(true);
        propertyNames.forEach(item => {
            if(queryStringFilters[item] !== '' && item != "top" && item != "skip"){
                setIsQueryEmpty(false);
            };
        });
    };

    const handleAllClientsChange = () => {
        setClient("");
        setAllClients(!allClients);
    };
    const handleSearch = async event => {
        window.open('/files/reports', '_blank');
    }

    const handleGeneration = async event => {
        event.preventDefault()
        setLoadingTable(true)
        setIsLoading(true)
        setError(false)

        if (!searched)
            setSearched(true)

        setQueryObjectFilters()
        if (!isQueryEmpty) {
            setShowFilterModal(false);
        }
    }
    
    const handleSearchClick = () => {
        if (isStarted) {
            clearInterval(intervalId);
            setIntervalId(null);
        } else {
            const newIntervalId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
            }, 2000);
            setIntervalId(newIntervalId);
        }
    };

    useEffect(() => {
        if (refresh && searched) {
            setTimeLeft(2);
            handleSearchClick();
        } else setTimeLeft(null);
    }, [refresh, searched]);

    useEffect(() => {
        if (timeLeft === 0 && searched && !isLoading) {
            (async function () {
                await fetchReportsHistory();
                setIsLoading(false);
                setTimeLeft(2);
            })();
        }
    }, [timeLeft]);

    const renderFilterElements = () => {
        return(
        <form onSubmit={e => handleGeneration(e)}>
            <div className="int-mt-4 ">
                <div className="int-col">
                    <Input
                        data-testid="client-ids"
                        name="clients"
                        label="Código do(s) cliente(s) (Escreva múltiplos clientes separadas por vírgula)"
                        value={clients}
                        onChange={(e) => setClient(e?.target?.value)}
                        disabled = {allClients}
                    />
                </div>
            </div>
            <div className="int-mt-4">
                    <Checkbox
                        name="search-all-clients"
                        label="Buscar Todos os Clientes"
                        isChecked={allClients}
                        onClick={handleAllClientsChange}
                />
            </div>



            {filtering && ( 
                    <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido.</Alert>
            )}
        </form>

	)
}

    return (
        <SectionWrapper 
            header="Relatório de Limite"         
            searchButton={
                <div className="int-d-flex int-align-items-center int-justify-content-end int-mt-6">
                    {results?.length > 0 ? (
                        <div className="int-d-flex int-align-items-center int-justify-content-end int-mr-5">
                            <p>Auto refresh</p>

                            <ButtonGroup margin="int-ml-4">
                                <Button
                                    variant={!refresh ? "outline" : "solid"}
                                    onClick={() => {
                                        setRefresh(true);
                                        smartTableToParent({skip: "0"});
                                    }}
                                >
                                    {isRefreshing && refresh ? (
                                        <Loader
                                            color="var(--int-colors-white)"
                                            flex
                                        />
                                    ) : (
                                        "ON"
                                    )}
                                </Button>

                                <Button
                                    variant={refresh ? "outline" : "solid"}
                                    onClick={() => {
                                        setRefresh(false);
                                        setIsLoading(false);
                                        setNotFound(false);
                                        handleSearchClick();
                                    }}
                                >
                                    OFF
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : (
                        ""
                    )}

                    <Button onClick={() => setShowFilterModal(true)}>
                        <FiSearch />
                    </Button>
                </div>
            }
        >
            <SimpleModal
                header="Filtros de busca"
                isOpen={showFilterModal}
                onClose={() => setShowFilterModal(false)}
                bodyMessage={renderFilterElements()}
                footerButtons={[
                    {
                        label: "Ver relatórios",
                        onClick: (e) => {
                            handleSearch(e);
                        },
                        margin: "int-ml-0",
                        variant: "outline",
                    },
                    {
                        label: "Buscar",
                        onClick: (e) => {
                            handleGeneration(e);
                        },
                        margin: "int-ml-2",
                    },
                ]}
            />

            {allClients && loadingTable && (
                <div className="int-mb-3">
                    <Alert isLoading={true}>
                        O relatório está sendo gerado em segundo plano. Esse processo pode demorar mais de 30 minutos.
                    </Alert>
                </div>
            )}

            {loadingTable && <Loader flex />}

            {error && (
                <div className="int-mb-3">
                    <Alert>Ocorreu um erro na busca</Alert>
                </div>
            )}

            {notFound && (
                <div className="int-mb-3">
                    <Alert>Não foi possível encontrar o(s) cliente(s) solicitado(s)</Alert>
                </div>
            )}

            {!loadingTable && !error && !notFound && (
                <div className="int-text-center">
                    <p className="int-mt-5 int-mb-3">Relatório gerado com sucesso</p>
                </div>
            )}

            {!loadingTable && (
                <div className="int-text-center">
                    <ButtonGroup margin="int-ml-4">
                        <Button
                            onClick={handleSearch}>
                            Ver relatórios
                        </Button>
                    </ButtonGroup>
                </div>
            )}
            

        </SectionWrapper>   
    )
}

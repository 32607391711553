import React, { useState, useEffect, useCallback } from 'react'
import { BsTrashFill } from "react-icons/bs"

import { Button, Checkbox, PolicyField, Input } from '../../../components/Forms'
import { Link } from '../../../components/Link'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable } from '../../../components/Table'
import { SelectColumnFilter } from '../../../components/Table/Filters'
import { DownloadXLSXButton } from '../../../components/Forms/DownloadXLSXButton'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Alert } from '../../../components/Alert'
import { Loader } from "../../../components/Loader"
import { formatObjectNumberValues, formatDecimal, formatDbNumber, formatInteger } from "../../../common/formatters"
import { getPolicyNameById } from "../../../common/search"
import { parameterValueHeader } from "../../../common/parameterValueHeader"

import { API_HEADER } from "../../../constants/api";
import { UploadCSVModal } from '../../../components/UploadCSV/UploadCSVModal'

type Param = {
    name: string;
    friendlyName?: string;
    market: string;
    symbol?: string;
    comments?: string;
    value: string;
    description?: string;
}

type ClubsParameter = {
    clubName: string;
    id: string;
    value?: string;
    policyId?: number;
    comments?: string;
}

interface ClubsParametersProps {
    id: string;
    keys: any;
    policyItems: any;
    policy: any;    
    setSearchParam: (any) => void;
    setDeletedParam: (value: Param | null) => void;
    setErrorDeletedParam: (value: Error | null) => void;
    setShowDeletedParameter: (value: boolean) => void;
    setShowSearchParamsModal: (value: boolean) => void;
    setDisabled: (value: boolean) => void;
    disabled: boolean;
}

export function ClubsParameters({
    id,     
    keys,
    policyItems,
    policy,
    setSearchParam,
    setDeletedParam,
    setErrorDeletedParam,
    setShowDeletedParameter,
    setShowSearchParamsModal,
    setDisabled,
    disabled
}: ClubsParametersProps) {
    const [errorReplaceParamsCSV, setErrorReplaceParamsCSV] = useState<Error | null>()
    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)
    const [showReplaceParams, setShowReplaceParams] = useState(false)
    const [paramsClubsExport, setParamsClubsExport] = useState([])
    const [showLoaderFull, setShowLoaderFull] = useState(false)

    const [updateMultipleParamsValue, setUpdateMultipleParamsValue] = useState()
    const [showFormUpdateMultipleParams, setShowFormUpdateMultipleParams] = useState(false)
    const [showUpdateMultipleParamsMessage, setShowUpdateMultipleParamsMessage] = useState(false)
    const [errorUpdateMultipleParams, setErrorUpdateMultipleParams] = useState<Error | null>()

    const [updateParam, setUpdateParam] = useState<any>()
    const [showFormUpdateParam, setShowFormUpdateParam] = useState(false)
    const [showUpdateParamMessage, setShowUpdateParamMessage] = useState(false)
    const [errorUpdateParam, setErrorUpdateParam] = useState<Error | null>()

    const [showDeleteMultipleParameter, setShowDeleteMultipleParameter] = useState(false)
    const [errorDeleteMultipleParam, setErrorDeleteMultipleParam] = useState<Error | null>()

    const [allSymbols, setAllSymbols] = useState<string[]>([])
    const [selectedSymbols, setSelectedSymbols] = useState<string[]>([])
    const [paramsClubs, setParamsClubs] = useState<ClubsParameter[]>([]);

    async function fetchData() {
        try {
            const response = await fetch(`/api/policy/${id}/clubsParams`)

            if(!response.ok) {
                throw new Error("")
            }

            const data = await response.json()
            setParamsClubs(data)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => { fetchData() }, [])

    const ParameterValue = (item) => {
        return ( item.policyId !== policy.id ? (
            <p>{formatDecimal(item.value)}</p>
        ) : (
            <p
                onClick={() => {
                    const param = { 
                        ...item, 
                        symbol: item.symbol ?? "",
                        comments: item.comments ?? "",
                        value: formatDecimal(item.value) 
                    }
                    setUpdateParam(param)
                    setShowFormUpdateParam(true)
                }}
                style={{ cursor: "pointer" }}
            >
                <u>{formatDecimal(item.value)}</u>
            </p>
        ))
    }

    const PolicyName = (item) => {
        if (item.policyId === undefined) 
            return "-"
        
        if (item.policyId === policy.id)
            return getPolicyNameById(policyItems, item.policyId)

        return (
            <Link href={`/policies/${item.policyId}`}>
                {getPolicyNameById(policyItems, item.policyId)}
            </Link>
        )
    }

    const DeleteButton = (item) => {
        return (item.policyId === policy.id ? (
            <BsTrashFill
                data-testid="param-delete-button"
                size={20}
                color="var(--int-colors-orange-400)"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    setErrorDeletedParam(null);
                    setDeletedParam({name: "GuaranteeFactor", market: "CLUB", symbol: item.id, value: item.value});
                    setShowDeletedParameter(true);
                    fetchData();
                }} 
            />
        ) : (
            <BsTrashFill
                size={20}
                color="#AAA"
            />
        ))
    }

    const allChecked = allSymbols.every(x => selectedSymbols.includes(x))

    const checkBoxColumnHandle = ({ target }) => {
        if (target.checked) {
            setSelectedSymbols(selectedSymbols.concat([target.value]))
        } else {
            setSelectedSymbols(selectedSymbols.filter(i => i !== target.value))
        }  
    }
    
    const allCheckBoxesHandle = ({ target }) => {
        if (target.checked) {
            const items = allSymbols.filter(x => !selectedSymbols.includes(x));
            setSelectedSymbols(selectedSymbols.concat(items))
        } else {
            setSelectedSymbols(selectedSymbols.filter(i => !allSymbols.includes(i)))
        }
    }

    const onFiltersChange = (rows) => {
        const symbols = rows.map(item => item.original.clubName)
        setAllSymbols(symbols)
        setSelectedSymbols([])
    }

    const columns = [
        {
            id: 'Seleção',
            Header: () => 
                <Checkbox
                    data-testid="table-checkbox-header"
                    onChange={allCheckBoxesHandle} 
                    isChecked={allChecked} 
                />,
            Cell: ({ row }) => 
                <Checkbox 
                    data-testid="table-checkbox-body"
                    value={row.original.clubName} 
                    onChange={checkBoxColumnHandle} 
                    isChecked={selectedSymbols.some(i => i === row.original.clubName)} 
                />,
            width: '1%',
        },
        {
            Header: 'Id',
            accessor: 'id'
        },
        {
            Header: 'Nome do Clube',
            accessor: 'clubName'
        },
        {
            id: 'Valor',
            Header: () => parameterValueHeader(keys, "Valor", "LeverageFactor"),
            accessor: props => props.value ? props.value : '-',
            Cell: ({ row }) => row.original.value ? ParameterValue(row.original): '-',
            Filter: SelectColumnFilter,
        },
        {
            Header: 'Política',
            accessor: 'policyName',
            Cell: ({ row }) => PolicyName(row.original) ?? '-',
        },
        {
            Header: 'Comentário',
            accessor: 'comments',
        },
        {
            id: 'Remove',
            width: '1%',
            Header: '',
            Cell: ({ row }) => DeleteButton(row.original)
        },
    ]

    /** Adicina e altera vários parâmetros */
    const submitUpdateMultipleParams = (event) => {
        event.preventDefault()
        setSelectedSymbols([])
        setDisabled(true)
        setShowLoaderFull(true)
        setShowFormUpdateMultipleParams(false);

        const value = formatDbNumber(updateMultipleParamsValue)

        const paramaters = selectedSymbols.map(item => { 
            return {
                symbol: paramsClubs?.find(x => x.clubName === item)?.id, 
                value: value,
                market: "CLUB",
                name: "GuaranteeFactor",
                comments: paramsClubs?.find(x => x.clubName === item && x.policyId === policy.id)?.comments
            }
        })

        const options = {
            method: "POST",
            headers: API_HEADER,
            body: JSON.stringify(paramaters),
        };

        fetch(`/api/policy/${id}/parameters`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorUpdateMultipleParams(await response.json());
                    setShowFormUpdateMultipleParams(true);
                    return setDisabled(false);
                }
                setShowUpdateMultipleParamsMessage(true);
                fetchData();
                return setDisabled(false);
            })
            .catch((error) => console.log(error))
            .finally(() => setShowLoaderFull(false))
    };

    /** Edita um parâmetro */
    const submitUpdateParam = (event) => {
        event.preventDefault()
        setDisabled(true)

        const parameter = {
            name: "GuaranteeFactor",
            market: "CLUB",
            symbol: updateParam.id,
            value: formatDbNumber(updateParam.value),
            comments: updateParam.comments
        }

        const options = {
            method: "PUT",
            headers: API_HEADER,
            body: JSON.stringify(parameter)
        };


        fetch(`/api/policy/${id}/parameter`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorUpdateParam(await response.json());
                    return setDisabled(false);
                }
                setShowFormUpdateParam(false);
                setShowUpdateParamMessage(true);
                fetchData();
                return setDisabled(false);
            })
            .catch((error) => console.log(error));
    };

    /** Apagar múltiplos parâmetros API*/
    const submitDeleteMultipleParameters = () => {
        setDisabled(true);
        setSelectedSymbols([])
        setShowLoaderFull(true)
        setShowDeleteMultipleParameter(false)

        const requestBody = selectedSymbols.map(symbol => { 
            return {
                name: "GuaranteeFactor",
                market: "CLUB",
                symbol: paramsClubs?.find(x => x.clubName === symbol && x.policyId === policy.id)?.id
            }
        })

        const options = {
            method: "DELETE",
            headers: API_HEADER,
            body: JSON.stringify(requestBody)
        };

        fetch(`/api/policy/${id}/parameters`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorDeleteMultipleParam(await response.json());
                    setShowDeleteMultipleParameter(true)
                    return setDisabled(false);
                }
                fetchData();
                return setDisabled(false);
            })
            .catch((error) => console.log(error))
            .finally(() => setShowLoaderFull(false))
    };

    /** Substitui lista de parâmetros API */
    const submitClubsFactorParams = (arr) => {
        setDisabled(true);
        setErrorReplaceParamsCSV(null);
        setShowLoaderFull(true);

        const parameters = arr.map((item) => ({ 
            ...formatObjectNumberValues(item, ['value']),
            symbol: item.id,
            market: "CLUB",
            name: "GuaranteeFactor"
        }))

        const options = {
            method: "PUT",
            headers: API_HEADER,
            body: JSON.stringify(parameters)
        };

        fetch(`/api/Policy/${id}/GuaranteeFactor`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorReplaceParamsCSV(await response.json());
                    return setDisabled(false);
                }

                setShowReplaceParams(true);
                return setDisabled(false);
            })
            .catch((error) => console.log(error))
            .finally(() => setShowLoaderFull(false));
    };

    /** Baixar modelo de upload de parâmetro */
    async function handleModelDownload() {
        fetch('/assets/Intelirisk-ModeloListaParametrosClubes.xlsx', {
            headers: {
                'Content-Type': 'application/xlsx',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Intelirisk-ModeloListaParametrosClubes.xlsx`);

                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

      /** Atualiza dados do parêmetro de edição */
      const handleUpdatedParam = useCallback(
        (event) => {
            setUpdateParam({
                ...updateParam,
                [event.currentTarget.name]: event.currentTarget.value
            });
        },
        [updateParam]
    );

    useEffect(() => {
        if (paramsClubs?.length > 0){
            const params = paramsClubs
                .map(x => ({
                    "Id": x.id,
                    "Nome do Clube": x.clubName,
                    "Fator de Garantia": x.value,
                    "Comentario": x.comments
                }))

            setParamsClubsExport(params)
            setAllSymbols(paramsClubs.map(x => x.clubName))
        }
    }, [paramsClubs])

    return(
        <>
            <SectionWrapper
                headerTag="h3" 
                header="Fator de Garantia (Clubes de Investimentos)"
                isAccordion 
            >
                { paramsClubs?.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum parâmetro foi encontrado
                    </p>
                ) : (
                    <SmartTable 
                        data={paramsClubs ?? []} 
                        columns={columns} 
                        columnFilters 
                        removeScroll 
                        localStorageKey={'PolicyClubsParams'} 
                        onFiltersChange={onFiltersChange}
                    />
                )}
                    
                <div className="int-d-flex int-align-items-center int-justify-content-between int-mt-4">
                    <div className="int-d-flex">
                        <Button
                            disabled={selectedSymbols.length <= 0}
                            variant="outline"
                            onClick={() => {
                                setUpdateMultipleParamsValue(null)
                                setShowFormUpdateMultipleParams(true);
                                setErrorUpdateMultipleParams(null);
                                fetchData();
                            }}
                        >
                            Editar parâmetros selecionados
                        </Button>

                        <Button
                            disabled={selectedSymbols.length <= 0}
                            margin="int-ml-3"
                            onClick={() => {
                                setShowDeleteMultipleParameter(true);
                                setErrorDeleteMultipleParam(null);
                                fetchData();
                            }}
                        >
                            Excluir parâmetros selecionados
                        </Button>

                    </div>

                    <div  className="int-d-flex">
                        { paramsClubs?.length <= 0 ? (
                            <Button variant="outline" onClick={handleModelDownload} >
                                Baixar modelo
                            </Button>
                        ) : (
                            <DownloadXLSXButton
                                dataJs={paramsClubsExport}
                                fileName={`${policy?.name}_fator_de_garantia_clubes_${new Date(Date.now()).toLocaleDateString()}`}
                                variant="outline"
                            >
                                Exportar parâmetros
                            </DownloadXLSXButton>
                        )}
                        
                        <Button 
                            margin="int-ml-3" 
                            onClick={() => setIsCSVModalOpen(true)} 
                        >
                            Importar / Substituir parâmetros
                        </Button>
                    </div>
                </div>

                {errorReplaceParamsCSV?.message && (
                    <Alert margin="int-mt-4">
                        <p style={{ whiteSpace: "pre-wrap" }}>{errorReplaceParamsCSV?.message}</p>
                    </Alert>
                )}
            </SectionWrapper>

            {/* Modal - Formulário Adicionar / Atualizar parâmetro */}
            <Modal
                header="Editar parâmetros selecionados"
                isOpen={showFormUpdateMultipleParams}
                onClose={() => setShowFormUpdateMultipleParams(false)}
            >
                <form onSubmit={submitUpdateMultipleParams}>
                    <ModalBody>
                        <div className="int-row">
                            <div className="int-col int-pl-0">
                                <PolicyField
                                    isRequired
                                    label="Valor"
                                    keys={keys.flat()}
                                    keyName="GuaranteeFactor"
                                    name="value"
                                    value={updateMultipleParamsValue ?? ""}
                                    onChange={(e) => setUpdateMultipleParamsValue(e.target.value)}
                                />
                            </div>
                        </div>

                        {errorUpdateMultipleParams?.message && (
                            <Alert>
                                Ocorreu um erro: {errorUpdateMultipleParams?.message}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowFormUpdateMultipleParams(false)}
                        >
                            Cancelar
                        </Button>
                            <Button
                                margin="int-ml-2"
                                type='submit'
                            >
                                Atualizar parâmetros
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Formulário Atualizar parâmetro */}
            <Modal
                size="xlg"
                header="Editar Parâmetro"
                isOpen={showFormUpdateParam}
                onClose={() => setShowFormUpdateParam(false)}
            >
                <form onSubmit={submitUpdateParam}>
                    <ModalBody>
                        <div className="int-row">
                            <div className="int-col int-pl-0">
                                <Input 
                                    isRequired
                                    disabled
                                    label="Id do Clube"
                                    name="id"
                                    placeholder="Id do Clube"
                                    value={updateParam?.id}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <Input 
                                    isRequired
                                    disabled
                                    label="Nome do Clube"
                                    name="clubName"
                                    placeholder="Nome do Clube"
                                    value={updateParam?.clubName}
                                />
                            </div>
                            
                            <div className="int-col int-pl-0">
                                <PolicyField
                                    isRequired
                                    label="Valor"
                                    keys={keys.flat()}
                                    keyName="GuaranteeFactor"
                                    name="value"
                                    value={updateParam?.value}
                                    onChange={handleUpdatedParam}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <Input
                                    label="Comentário"
                                    name="comments"
                                    placeholder="Comentário"
                                    value={updateParam?.comments}
                                    onChange={handleUpdatedParam}
                                />
                            </div>
                        </div>

                        {errorUpdateParam && (
                            <Alert>
                                Ocorreu um erro ao editar o parâmetro: {errorUpdateParam.message}
                            </Alert>
                        )}            
                    </ModalBody>    
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowFormUpdateParam(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            margin="int-ml-2"
                            type='submit'
                        >
                            Atualizar parâmetro
                        </Button>
                    </ModalFooter>
                </form>

            </Modal>

            {/* Modal - Mensagem Apagar Múltiplos Parâmetros */}
            <Modal 
                header="Tem certeza que quer apagar os parâmetros selecionados?"
                isOpen={showDeleteMultipleParameter} 
                onClose={() => setShowDeleteMultipleParameter(false)}
            >
                <ModalBody>
                    <p>
                        Caso você apague os parâmetros, não será possível desfazer 
                        essa ação.
                    </p>

                    {errorDeleteMultipleParam && (
                        <Alert>
                            Ocorreu um erro ao apagar o parâmetro: {errorDeleteMultipleParam.message}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() => setShowDeleteMultipleParameter(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        disabled={disabled}
                        margin="int-ml-2"
                        onClick={() => submitDeleteMultipleParameters()}
                    >
                        Apagar
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal - Mensagem Atualizar Parâmetro */}
            <SimpleModal
                header="O parâmetro foi atualizado!" 
                isOpen={showUpdateParamMessage} 
                onClose={() => setShowUpdateParamMessage(false)}
                bodyMessage="O parâmetro que você editou foi atualizado com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        setShowUpdateParamMessage(false);
                        setUpdateParam(null);
                        fetchData();
                    }
                }}
            />

            {/* Modal - Mensagem Adicionar / alterar parâmetros */}
            <SimpleModal
                header="Os parâmetros foram alterados com sucesso!" 
                isOpen={showUpdateMultipleParamsMessage} 
                onClose={() => setShowUpdateMultipleParamsMessage(false)}
                bodyMessage="A lista de parâmetros que você selecionou foi alterada com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        setShowUpdateMultipleParamsMessage(false);
                        fetchData();
                    }
                }}
            />

            {/* Modal - Mensagem Substituir Lista de Parâmetros */}
            <SimpleModal
                header="A lista de parâmetros foi atualizada!" 
                isOpen={showReplaceParams} 
                onClose={() => setShowReplaceParams(false)}
                bodyMessage="A lista de parâmetros que você adicionou foi substituída com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {                        
                        setShowReplaceParams(false);
                        fetchData();
                    }
                }}
            />

            <UploadCSVModal
                isModalOpen={isCSVModalOpen}
                onHide={() => setIsCSVModalOpen(false)}
                onAdd={submitClubsFactorParams}
                header={`Importar / Substituir Lista de Parâmetros: Fator de Garantia (Clubes de Investimentos)`}
                model={"ParametrosClubes"}
                policyApplication={true}
            />

            {showLoaderFull && <Loader full />}
        </>
    )
}

import React, { ReactNode } from 'react'
import { HTMLAttributes } from 'react'

interface DropdownItemProps extends HTMLAttributes<HTMLLIElement> {
    children: ReactNode;
}

export function DropdownItem({ children, ...rest }: DropdownItemProps) {
    return (
        <li {...rest}>
            {children}
        </li>
    )
}
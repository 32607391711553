import React, { forwardRef, useEffect, useState, useMemo } from 'react'
import { useAsyncDebounce } from 'react-table'

import { Button, Input, NumberInput, Select, Checkbox } from '../../Forms'
import { sanitizeName } from '../../../common/variables';
import { useCombinedRefs } from '../../../hooks/useCombinedRefs';

// Checkbox para ocultar todas as colunas das tabelas
interface IndeterminateCheckboxProps {
    indeterminate?: boolean;
    name?: string;
}
  
export const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
        const defaultRef = React.useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);

        useEffect(() => {
            if (combinedRef?.current) {
                combinedRef.current.indeterminate = indeterminate ?? false;
            }
        }, [combinedRef, indeterminate]);

        return (
            <Checkbox 
                {...rest} 
                isChecked={combinedRef?.current?.checked} 
                ref={combinedRef} 
                label="Ocultar todas as colunas" 
            />
        )
    }
);

// Filtro global da tabela
interface GlobalFilterProps {
    globalFilter: string;
    setGlobalFilter: (value?: string) => void;
}

export function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}: GlobalFilterProps) {
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="int-d-flex int-align-items-center">
            <label className="int-form-label int-mr-3">
                Pesquisar:
            </label>
            <Input
                name="global"
                value={value || ""}
                onChange={e => {
                    setValue(sanitizeName(e?.target?.value));
                    onChange(e.target.value);
                }}
            />
        </div>
    )
}

// Filtro de coluna
interface DefaultColumnFilterProps {
    column: {
        filterValue: string;
        setFilter: (value: string) => void;
    }
}

export function DefaultColumnFilter({
    column: { filterValue, setFilter },
}: DefaultColumnFilterProps) {
    return (
        <Input 
            name="default-filter"
            value={filterValue || ''}
            style={{textAlign: 'inherit'}}
            onChange={(e) => setFilter(e?.target?.value)}
        />
    )
}

// Filtro Select de apenas uma única opção
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calcula as opçõeses para filtrar
    const options = useMemo<string[]>(() => {
        const options = new Set<string>()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    return (
        <Select
            name="select"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Tudo</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </Select>
    )
}

// Filtrar entre valores de número
export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div className="int-d-flex int-align-items-center">
            <NumberInput
                margin="int-mr-2"
                min={min}
                max={max}
                value={filterValue[0] || ''}
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder="Min"
                style={{ maxWidth:"70px" }}
            />
      e

            <NumberInput 
                margin="int-ml-2"
                min={min}
                max={max}
                value={filterValue[1] || ''}
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val && Number(val) > 0  ? parseInt(val, 10) : undefined])
                }}
                placeholder="Máx"
                style={{ maxWidth:"70px" }}
            />
        </div>
    )
}

// Filtrar entre valores de número
export function NumberRangeColumnFilterVertical({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div className="int-d-flex int-align-items-center int-flex-column">
            <NumberInput 
                margin="int-mb-2"
                min={min}
                max={max}
                value={filterValue[0] || ''}
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder="Min"
                style={{ maxWidth:"70px" }}
            />
      e

            <NumberInput 
                margin="int-mt-2"
                min={min}
                max={max}
                value={filterValue[1] || ''}
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val && Number(val) > 0  ? parseInt(val, 10) : undefined])
                }}
                placeholder="Máx"
                style={{ maxWidth:"70px" }}    
            />
        </div>
    )
}

// Filtro de slider
export function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input 
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            
            <Button margin="int-ml-auto int-mt-3" variant="outline" size="sm" onClick={() => setFilter(undefined)}>Reset</Button>
        </>
    )
}

// Filtro Indisponível
export function UnavailableFilter() {
    return <p>Filtro Indisponível</p>
}

// Funçãoo para filtrar valor menor que o valor do input
export function filterMinorThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue <= filterValue
    })
}

// Remove a função caso o valor passado não for um número
filterMinorThan.autoRemove = val => typeof val !== 'number'
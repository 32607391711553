import { orderStatusValues } from '../common/enums';
import { getMarketForParameter } from './checkers';
  
export const setArrayOfObjects = (state, position, event, isSanitizable, keys) => {
  const upadatedArrayItems = state.map(
    (item, index) => {
      const { name, value, type } = event.target;

      if (index === position) {
        if (type === "number")
         return{ ...item, [name]: parseFloat(value.trim()) };

        if (isSanitizable)
          return { ...item, [name]: sanitizeName(value, false) };

        if (name === "name")
          return { ...item, [name]: value.trim(), market: getMarketForParameter(keys, value), symbol: "" };

        return { ...item, [name]: value.trim() };
      }

      return item;
    }
  );

  return upadatedArrayItems;
}

export const setObject = (event, state, isSanitizable, mapValue = (e) => e) => {
  const { name, value, type } = event.target;

  if (type === "number")
    return { ...state, [name]: parseFloat(mapValue(value).trim()) };

  if (isSanitizable)
      return { ...state, [name]: mapValue(sanitizeName(value, false)) };

  return { ...state, [name]: mapValue(value.trim()) };
}

export const removeArrayItem = (position, state) => {
  const stateItems = [...state]

  stateItems.splice(position, 1)
      
  return stateItems
}

export const removeKeyObject = (object, keys) => {
  const newObject = {...object}
  
  keys.map(item => delete newObject[item])

  return newObject
}

//remove caracteres especiais do valor, deixa apenas números, letras e espaço
//caso allowComma = true, permite também virgulas
export const sanitizeName = (value, allowComma = false) => {

  let newValue = value;
  
  if(!allowComma) {
    newValue = newValue.replace(/[^a-zA-Z0-9 ]/g, '');
    newValue = newValue.toUpperCase().trimStart();
  }
  else {
    newValue = newValue.replace(/[^a-zA-Z0-9 ,]/g, '');
    newValue = newValue.toUpperCase().trimStart();
  }

  return newValue;
}

export const ordersRowStyles = (row) => {
    let statusCellValue;
    let statusFontColorObj;
    let statusBgColorObj;

    row.cells.map(cell => {
        if(String(cell.column.id).toLocaleLowerCase() === 'status') {
            statusCellValue = cell.value
            return { backgroundColor: 'none'}
        }
    })

    if(!statusCellValue) return { backgroundColor: 'none'}

    Object.entries(orderStatusValues).forEach(([key, value]) => {
        const orderStatusBG = {[value]: 'var(--int-colors-gray-600)'};
        let orderStatusFont = {[value]: 'var(--int-colors-white)'};
        
        if (value === "Aberta" || value === "Pendente" || value === "Can. Pendente" || value === "Alt. Pendente"){
            orderStatusFont[value] = 'var(--int-colors-gray-900)';
        }

        statusBgColorObj = { 
            ...statusBgColorObj,
            ...orderStatusBG
        }

        statusFontColorObj = { 
            ...statusFontColorObj,
            ...orderStatusFont
        }
    });

    statusBgColorObj = { 
        ...statusBgColorObj,
        'Aberta': 'var(--int-colors-white)',
        'Rejeitada': 'var(--int-colors-red-600)',
        'Expirada': 'var(--int-colors-orange-600)',
        'Inválida': 'var(--int-colors-orange-600)',
        'Cancelada': 'var(--int-colors-orange-600)',
        'Total. Executada': 'var(--int-colors-cyan-600)',
        'Alterada': 'var(--int-colors-green-600)',
        'Suspensa': 'var(--int-colors-green-600)',
        'Parc. Executada': 'var(--int-colors-green-600)',
        'Pendente': 'var(--int-colors-yellow-500)',
        'Can. Pendente': 'var(--int-colors-yellow-500)',
        'Alt. Pendente': 'var(--int-colors-yellow-500)'
    }

    return { 
        color: statusFontColorObj[statusCellValue],
        backgroundColor: statusBgColorObj[statusCellValue] 
    }
}
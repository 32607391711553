import React from 'react';

import { Field } from '../../components/Forms';
import { SectionWrapper } from '../../components/SectionWrapper';

import { setObject } from '../../common/variables';

type TCustody = {
    availableGrams: string;
}

interface CustodyProps {
    custody: TCustody;
    setCustody: (value: TCustody) => void;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
}

export function GoldCustody({ custody, setCustody, allAccordionsOpen }: CustodyProps) {
      
    return(
        <SectionWrapper
            headerTag="h3"
            header="Custódia de Ouro"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
        >
            <table className="int-table">
                <thead>
                    <tr>
                        <th style={{ width: "20%" }}>Gramas Disponíveis</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                        <tr key={0}>
                            <td>
                                <Field
                                    data-testid="gold-custody-availableGrams"
                                    required
                                    format="Decimal"
                                    name="availableGrams"
                                    value={custody?.availableGrams}
                                    onChange={
                                        event => setCustody(
                                            setObject(event, custody)
                                        )
                                    }
                                />
                            </td>
                        </tr>
                </tbody>
            </table>
        </SectionWrapper>
    )
}
import React from 'react'

interface Symbols {
  symbol?: string;
  market?: string;
}

interface SymbolsListProps {
  symbols: Symbols[];
}

export function SymbolsList({ symbols, ...rest }: SymbolsListProps) {  

  if(symbols?.map(i => i.market).filter(i => i != undefined).length == 0){
    return(
      <div className="int-columns-wrapper" {...rest}>
      { symbols.map((symbol, index) => (
        <div 
            key={index}
            className="int-d-flex int-align-items-center 
            int-justify-content-center int-p-2 int-rounded"
            style={{ background: 'var(--int-colors-orange-400)' }}
        >
            <p>{symbol}</p>
        </div>
      )) }
      </div>
    )
  }else{
    return(
        <table className="int-table">
          <thead>
              <tr>
                  <th>Ativo</th>
                  <th>Mercado</th>
              </tr>
          </thead>

          <tbody>
              {symbols?.map((item, index) => (
                  <tr key={index}>
                      <td>{item.symbol ?? '-'}</td>
                      <td>{item.market ?? '-'}</td>
                  </tr>
              ))}
          </tbody>
      </table>
    )
  }
}
import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs";

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable } from "../../../components/Table";

import {formatDecimal, formatInteger} from '../../../common/formatters';

export function FlexOpcCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [custody, setCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientFlexOpcCustody() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/client/${account}/flexOpcCustody`);

            const data = await response.json();

            setCustody(data);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if(updateAllGrids){
            fetchClientFlexOpcCustody()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientFlexOpcCustody();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol.toUpperCase(),
        },
        {
            Header: 'Mercado',
            accessor: data => data.commodityCode,
        },
        {
            Header: 'C/V',
            accessor: data => data.naturezaOperacao.toUpperCase() == "BUY" ? "C" :
                              data.naturezaOperacao.toUpperCase() == "SELL" ? "V" : "",
            columnAlign: 'right',
        },
        {
            Header: 'Qtd. Base',
            accessor: data => formatInteger(data.baseSize),
            columnAlign: 'right',
            hint: 'Quantidade Base'
        },
        {
            Header: 'Qtd. Reversão',
            accessor: data => formatInteger(data.baseSizeReversion),
            columnAlign: 'right',
            hint: 'Quantidade Reversão'
        },
        {
            Header: 'Exercício',
            accessor: data => formatDecimal(data.price),
            columnAlign: 'right',
        },
        {
            Header: 'Pr. Deferido',
            accessor: data => formatDecimal(data.premioDeferido),
            columnAlign: 'right',
        },
        {
            Header: 'Premio',
            accessor: data => formatDecimal(data.premio),
            columnAlign: 'right',
        },
        {
            Header: 'Preço Barreira',
            accessor: data => formatDecimal(data.precoBarreira),
            columnAlign: 'right',
        },
        {
            Header: '%',
            accessor: data => formatDecimal(data.percentage),
            columnAlign: 'right',
        },
        {
            Header: 'Knk In Down',
            accessor: data => formatDecimal(data.knockInAndDown),
            columnAlign: 'right',
        },
        {
            Header: 'Knk In Up',
            accessor: data => formatDecimal(data.knockInAndUp),
            columnAlign: 'right',
        },
        {
            Header: 'Knk Out Down',
            accessor: data => formatDecimal(data.knockOutAndDown),
            columnAlign: 'right',
        },
        {
            Header: 'Knk Out Up',
            accessor: data => formatDecimal(data.knockOutAndUp),
            columnAlign: 'right',
        }
    ]

    return (
        <SectionWrapper 
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Opções Flexíveis"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={custody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientFlexOpcCustody}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' : 
            (
                    <SmartTable data={custody} columns={columns} localStorageKey={'ClientFlexOpcCustody'} /> 
            )}
        </SectionWrapper>
    );
}

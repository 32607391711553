import React, { ReactNode } from 'react';

interface H4Props {
    children: ReactNode;
    margin?: string;
    padding?: string;
}

export function H4({
    children, 
    margin = "", 
    padding = "", 
    ...rest 
}: H4Props) {
    return(
        <h4 className={`int-h4 ${margin} ${padding}`} {...rest}>
            {children}
        </h4>
    )
}
import React, { useState, useEffect } from "react";
import { BsArrowClockwise } from "react-icons/bs";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Loader } from "../../../components/Loader";
import { formatInteger, formatDecimal, formatDate } from "../../../common/formatters";
import { SmartTable } from '../../../components/Table'

export function ClubsCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion })
{
    const [clubsCustody, setClubsCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientClubsCustody()
    {
        setIsLoading(true);

        try
        {
            const response = await fetch(`/api/client/${account}/clubsCustody`);
            
            if (!response.ok)
            {
                throw new Error("Ocorreu um erro ao buscar garantias do cliente");
            }

            const data = await response.json();
            setClubsCustody(data);
        }
        catch (err)
        {
            console.log(err);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if(updateAllGrids){
            fetchClientClubsCustody()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientClubsCustody();
    }, [account]);

    const columns = [
        {
            Header: 'ID',
            accessor: data => data.clubId,
        },
        {
            Header: 'Nome',
            accessor: data => data.clubName ?? '-',
        },
        {
            Header: 'Quantidade',
            accessor: data => formatDecimal(data.quantity),
        },
        {
            Header: 'Valor Bruto',
            accessor: data => formatDecimal(data.grossValue),
        },
        {
            Header: 'IR',
            accessor: data => formatDecimal(data.ir),
        },
        {
            Header: 'IOF',
            accessor: data => formatDecimal(data.iof),
        },
        {
            Header: 'Valor Líquido',
            accessor: data => formatDecimal(data.netValue),
        },            
        {
            Header: 'Fat. Garantia',
            accessor: data => formatDecimal(data.guaranteeFactor),
            hint: 'Fator de Garantia'
        },
        {
            Header: 'Garantia',
            accessor: data => formatDecimal(data.guaranteeValue),
        }
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Clubes de Investimento"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={clubsCustody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientClubsCustody}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={clubsCustody} columns={columns} localStorageKey={'ClientClubsCustodyFactorParams'} />
                )}
        </SectionWrapper>
    )
}

import React, { useCallback } from 'react'

import { Input, Select } from '..';

import { currency, integer, number, symbol } from '../../../common/inputMasks';

export function Field({ 
    value, 
    label = "",
    onChange, 
    format,
    index = 0,
    leftAddonStyle = {},
    ...rest 
}) {
    let renderData;

    const handleKeyUp = useCallback((event, mask) => {
        if (mask === "currency" || mask === "decimal")
            return currency(event);

        if (mask === "integer")
            return integer(event);

        if (mask === "number")
            return number(event);

        if (mask === "symbol")
            return symbol(event);
    }, [])

    if (format === "Currency") {
        renderData = (
            <div data-testid="curretcy-field-div" className="int-input-group">
                <span className="int-group-left-addon" style={leftAddonStyle}>R$</span>
                <Input
                    {...rest}
                    name={rest.name}
                    value={value}
                    onChange={onChange}
                    onKeyUp={(e) => { handleKeyUp(e, 'decimal'); onChange(e); }}
                    index={index}
                />
            </div>
        )
    }

    else if (format === "Decimal") {
        renderData = (
            <Input
                {...rest}
                name={rest.name}
                value={value}
                onChange={onChange}
                onKeyUp={(e) => { handleKeyUp(e, 'currency'); onChange(e); }}
                index={index}
            />
        )
    }

    else if (format === "Integer") {
        renderData = (
            <Input
                {...rest}
                name={rest.name}
                value={value}
                onChange={onChange}
                onKeyUp={(e) => { handleKeyUp(e, 'integer'); onChange(e); }}
                index={index}
            />
        )
    }

    else if (format === "Number") {
        renderData = (
            <Input
                {...rest}
                name={rest.name}
                value={value}
                onChange={onChange}
                onKeyUp={(e) => { handleKeyUp(e, 'number'); onChange(e); }}
                index={index}
            />
        )
    }

    else if (format === "Bool") {
        renderData = (
            <Select
                {...rest}
                name={rest.name}
                value={value}
                onChange={onChange}
                index={index}
            >
                <option hidden>Selecione um nome</option>
                <option value='Sim'>Sim</option>
                <option value="Não">Não</option>
            </Select>
        )
    }

    else if (format === "Symbol") {
        renderData = (
            <Input
                {...rest}
                name={rest.name}
                value={value}
                onChange={onChange}
                onKeyUp={(e) => { handleKeyUp(e, 'symbol'); onChange(e); }}
                index={index}
            />
        )
    }

    else {
        renderData = (
            <Input
                {...rest}
                name={rest.name}
                value={value}
                onChange={onChange}
                index={index}
            />
        ) 
    }

    return (
        <div>
            {!!label && <label className="int-form-label">{label}</label>}
            {renderData}
        </div>
    );
}

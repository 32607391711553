import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
    Button,
    Input,
    DateTime,
} from "../../../components/Forms";
import { SimpleModal } from "../../../components/Modal";
import { Loader } from "../../../components/Loader";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Alert } from "../../../components/Alert";

import { sanitizeName } from "../../../common/variables";

import { Tooltip } from "../../../components/Tooltip";

export function NewIpoRegistry() {
    const history = useHistory();

    const [isin, setIsin] = useState("");
    const [symbol, setSymbol] = useState("");
    const [offerType, setOfferType] = useState("");
    const [projectionWallet, setProjectionWallet] = useState("");
    const [settlementProjectWallet, setSettlementProjectWallet] = useState("");
    const [settlementDate, setSettlementDate] = useState("");
    const [settlementProjectDate, setSettlementProjectDate] = useState("");
    const [startTradingDate, setStartTradingDate] = useState("");

    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const form = React.useRef(null);

    const handleIpoRegistry = async (event) => {
        event.preventDefault();

        setDisabled(true);
        setError(false);

        try {
            var request = [
                {
                    symbol: symbol,
                    isin: isin,
                    offerType: offerType,
                    projectionWallet: projectionWallet,
                    settlementProjectWallet: settlementProjectWallet,
                    startTradingDate: startTradingDate,
                    settlementDate: settlementDate,
                    settlementProjectDate: settlementProjectDate,
                },
            ];
            const options = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(request),
            };

            const response = await fetch(`/api/ipoRegistry`, options);

            if (!response.ok) {
                const error = await response.json();
                console.log(error);
                throw new Error(
                    error.title + " - " + JSON.stringify(error.errors)
                );
            }

            setShowSuccessModal(true);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
        } finally {
            setDisabled(false);
        }
    };

    return (
        <>
            <SectionWrapper header="Novo Registro de IPO">
                <form onSubmit={handleIpoRegistry} ref={form}>
                    <div className="int-col">
                        <div className="int-row int-mb-6">
                            <div className="int-col input-box">
                                <Input
                                    data-testid="create-ipo-symbol"
                                    isRequired
                                    label="Símbolo"
                                    name="symbol"
                                    placeholder="Símbolo do IPO"
                                    value={symbol}
                                    onChange={(e) =>
                                        setSymbol(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                    hintIcon
                                    children={<Tooltip label="Código do ativo." />}
                                />
                            </div>
                            <div className="int-col input-box">
                                <Input
                                    data-testid="create-ipo-isin"
                                    isRequired
                                    label="Código ISIN"
                                    name="isin"
                                    placeholder="Código ISIN do IPO"
                                    value={isin}
                                    onChange={(e) =>
                                        setIsin(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                    hintIcon
                                    children={<Tooltip label="Código ISIN do ativo." />}
                                />
                            </div>
                            <div className="int-col input-box">
                                <Input
                                    data-testid="create-ipo-projection-wallet"
                                    isRequired
                                    label="Carteira de Projeção"
                                    name="projectionWallet"
                                    placeholder="Carteira de projeção do IPO"
                                    value={projectionWallet}
                                    onChange={(e) =>
                                        setProjectionWallet(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                    hintIcon
                                    children={<Tooltip label="Número da carteira onde a posição será projetada durante o primeiro dia de negociação, antes da liquidação acontecer no Sinacor que costuma ocorrer as 16h." />}
                                />
                            </div>
                            <div className="int-col input-box">
                                <Input
                                    data-testid="create-ipo-settlement-project-wallet"
                                    isRequired
                                    label="Carteira Durante Liquidação"
                                    name="settlementProjectWallet"
                                    placeholder="Carteira do IPO durante liquidação"
                                    value={settlementProjectWallet}
                                    onChange={(e) =>
                                        setSettlementProjectWallet(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                    hintIcon
                                    children={<Tooltip label="Número da carteira onde a posição será projetada durante o período de reserva e antes do início da negociação." />}
                                />
                            </div>
                            <div className="int-col input-box">
                                <Input
                                    data-testid="create-ipo-offerType"
                                    isRequired
                                    label="Modalidade"
                                    name="offerType"
                                    placeholder="Modalidade do IPO"
                                    value={offerType}
                                    onChange={(e) =>
                                        setOfferType(
                                            sanitizeName(e.target.value, true)
                                        )
                                    }
                                    hintIcon
                                    children={<Tooltip label="Número da modalidade do IPO." />}
                                />
                            </div>
                        </div>
                        <div className="int-row int-mb-6">
                            <div className="int-col input-box">
                                <DateTime
                                    label="Data de Início de Negociação"
                                    name="startTradingDate"
                                    value={startTradingDate}
                                    setDateTime={setStartTradingDate}
                                    maxDate={new Date("9999-12-31")
                                        .toISOString()
                                        .substr(0, 10)}
                                    timeDefault="00:00"
                                    isRequired
                                    notShowTimeInput
                                    hintIcon
                                    children={<Tooltip label="Data do pregão onde iniciará a negociação do papel na B3, independente se haverá lock-up ou não." />}
                                />
                            </div>
                            <div className="int-col input-box">
                                <DateTime
                                    label="Data de Liquidação"
                                    name="settlementDate"
                                    value={settlementDate}
                                    setDateTime={setSettlementDate}
                                    maxDate={new Date("9999-12-31")
                                        .toISOString()
                                        .substr(0, 10)}
                                    timeDefault="00:00"
                                    isRequired
                                    notShowTimeInput
                                    hintIcon
                                    children={<Tooltip label="Data em que a posição será liquidada e estará disponível no Sinacor, mesmo que seja apenas as 16h, após a liquidação das operações de compra." />}
                                />
                            </div>
                            <div className="int-col input-box">
                                <DateTime
                                    label="Data de Projeção da Liquidação"
                                    name="settlementProjectDate"
                                    value={settlementProjectDate}
                                    setDateTime={setSettlementProjectDate}
                                    maxDate={new Date("9999-12-31")
                                        .toISOString()
                                        .substr(0, 10)}
                                    timeDefault="00:00"
                                    isRequired
                                    notShowTimeInput
                                    hintIcon
                                    children={<Tooltip label="Data em que o pagamento da reserva surgirá como um lançamento futuro no conta corrente do cliente, ou seja, projetado para D2." />}
                                />
                            </div>
                        </div>
                    </div>

                    <Button
                        data-testid="create-ipo-submit"
                        isFullWidth
                        disabled={disabled}
                        type="submit"
                    >
                        {disabled ? <Loader /> : "Cadastrar IPO"}
                    </Button>

                    {error && <Alert margin="int-mt-5">{errorMessage}</Alert>}
                </form>
            </SectionWrapper>

            {/* Modal - Mensagem cadastro com sucesso  */}
            <SimpleModal
                header="Registro de IPO cadastrado com sucesso!"
                isOpen={showSuccessModal}
                onClose={() => setShowSuccessModal(false)}
                bodyMessage="O novo registro de IPO foi cadastrado com sucesso!"
                footerButtons={[
                    {
                        label: "Novo Registro de IPO",
                        onClick: () => setShowSuccessModal(false),
                        variant: "outline",
                    },
                    {
                        label: "Lista de IPOs",
                        onClick: () => history.push("/ipo-registries"),
                        margin: "int-ml-2",
                    },
                ]}
            />
        </>
    );
}

import React from 'react'

interface ClientParameters {
    account?: string | number;
    policyName?: string | number;
}

interface UploadClientParametersProps {
    parameters: ClientParameters[];
}

export function UploadClientsPolicies({ parameters }: UploadClientParametersProps) {
    return (
        <table className="int-table">
            <thead>
                <tr>
                    <th>Conta</th>
                    <th>Política</th>
                </tr>
            </thead>

            <tbody>
                {parameters?.map((item, index) => (
                    <tr key={index}>
                        <td>{item.account ?? '-'}</td>
                        <td>{item.policyName ?? '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

import React, { InputHTMLAttributes } from 'react'

interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name?: string;
    label?: string;
    margin?: string;
}

export function NumberInput({ name, label, margin, ...rest }: NumberInputProps) {
    return(
        <div className={`${margin}`}>
            {!!label && <label className="int-form-label">{label}</label>}
            <input type="number" className="int-form-control" {...rest}/>
        </div>
    )
}
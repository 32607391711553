import React from 'react'

interface ParameterUpload {
    market: string;
    symbol?: string;
    value: string;
    comments?: string;  
}

interface UploadParametersListProps {
    parameters: ParameterUpload[];
    parameterName: string;
}

export function UploadParametersList({ parameters, parameterName }: UploadParametersListProps) {
    return(
        <table className="int-table">
            <thead>
                <tr>
                    <th>Parâmetro</th>
                    <th>Mercado</th>
                    <th>Símbolo</th>
                    <th>Valor</th>
                    <th>Comentário</th>
                </tr>
            </thead>

            <tbody>
                {parameters?.map((item, index) => (
                    <tr key={index}>
                        <td>{parameterName}</td>
                        <td>{item.market}</td>
                        <td>{item.symbol ?? '-'}</td>
                        <td>{item.value}</td>
                        <td>{item.comments ?? '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

import React, { useRef, useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs'
import "./styles.css"

interface TooltipProps {
    label: string;
    shouldShowIcon?: boolean;
}

export function Tooltip({ label, shouldShowIcon = true }: TooltipProps) {
    const tooltipContainer = useRef(null)
    const [translate, setTranslate] = useState('100%')
    const [translateArrow, setTranslateArrow] = useState('50%')
    const GetPosition = () => {
        const position = tooltipContainer.current.getBoundingClientRect()
        const halfWindowWidth = innerWidth / 2
        if (position.x <= halfWindowWidth) {
            setTranslate('20%');
            setTranslateArrow('20%')
        } else {
            setTranslate('80%');
            setTranslateArrow('80%')
        }
    }
    if (shouldShowIcon ){
        return (
            <div ref={tooltipContainer}
                className="int-tooltip"
                onMouseOver={() => GetPosition()}
                onMouseOut={() => {
                    setTranslate('100%')
                }}>
                <BsQuestionCircle 
                    size={22}
                    color="var(--int-colors-orange-400)"
                    style={{ cursor: "pointer" }}
                />
                <div  style={{ transform: `translate(-${translate})` }} className="int-p">
                    <span className="int-tooltip-arrow" style={{ left: translateArrow }} />
                    <p className="int-tooltip-label" >{label}</p>       
                </div>
            </div>
        )     
    }
    else{
        return (
                <span className="int-no-icon-tooltip int-p">{label}</span>
        ) 
    }
}
import React, { ReactNode } from 'react'

interface ModalBodyProps {
    children: ReactNode;
}

export function ModalBody({ children, ...rest }: ModalBodyProps) {
    return(
        <div className="int-modal-body" {...rest}>
            {children}
        </div>
    )
}
import React, { useEffect, useState } from 'react'

import { H4 } from '../../components/Typography'
import { Button, Input, Field, ButtonGroup } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import MultipleCheckboxFilter from '../../components/MultipleCheckboxFilter'
import { SmartTable } from '../../components/Table'
import { SelectColumnFilter } from '../../components/Table/Filters'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'
import { FiExternalLink, FiSearch } from "react-icons/fi"
import { Link } from '../../components/Link'

import { objToQueryString, dateQueryParse } from '../../common/api.js';
import { formatInteger, formatDateTime, formatDecimal } from '../../common/formatters';
import { ordersRowStyles, sanitizeName, removeKeyObject } from '../../common/variables'
import {
  orderSideValues,
  orderTypeValues,
  orderStatusEnum,
  orderStatusValues,
  riskOrderTypeEnum,
  riskOrderSideEnum,
  riskOrderStatusEnum,
} from '../../common/enums.js';

import { useQueryParams } from '../../hooks/useQueryParams'
import { Modal, ModalBody, SimpleModal } from '../../components/Modal'

type QueryObject = {
    account?: string;
    top?: string;
    skip?: string;
    dtStart?: string;
    dtEnd?: string;
    symbols?: string;
    ids?: string;
    type?: string;
    side?: string;
    status?: string;
    timeInForce: string;
}

export function ConditionalOrdersHistory() {
    const [refresh, setRefresh] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [timeLeft, setTimeLeft] = useState<number>()
    const [intervalId, setIntervalId] = useState(null)
    const isStarted = intervalId !== null

    const { setParam, updateQuery } = useQueryParams()
    const [queryObject, setQueryObject] = useState<QueryObject>();

    const [ids, setIds] = useState(setParam("ids"));
    const [client, setClient] = useState(setParam("account")); 
    const [symbols, setSymbols] = useState(setParam("symbols")); 
    const [initialDate, setInitialDate] = useState(setParam("dtStart")); 
    const [finalDate, setFinalDate] = useState(setParam("dtEnd")); 

    const [type, setType] = useState(setParam("type"));
    const [side, setSide] = useState(setParam("side"));
    const [status, setStatus] = useState(setParam("status"));
    const [timeInForce, setTimeInForce] = useState(setParam("timeInForce"));

    const [loadingModalTable, setLoadingModalTable] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [modalError, setModalError] = useState(false);

    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [results, setResults] = useState([])
    const [count, setCount] = useState<number>()
    const [showEventHistory, setShowEventHistory] = useState(false);
    const [eventHistoryParams, setEventHistoryParams] = useState(null)
    const [eventsHistory, setEventsHistory] = useState(null)
    const [searched, setSearched] = useState(false)
    const [ctrlKeyIsPressed, setCtrlKeyIsPressed] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(true);
    const today = new Date().toISOString().substring(0, 10);
    const [smartTableData, setSmartTableData] = useState({top: "", skip: ""});
    const [smartTableDataCheck, setSmartTableDataCheck] = useState({top: "", skip: ""});

    useEffect(() => {
        if (queryObject) {
        (async function () {
            await fetchConditionalOrdersData()
            setTimeLeft(2);
            setLoadingTable(false)
            setIsLoading(false)
            return null
        })();
        }
    }, [queryObject])

    useEffect(() => {
        if ((smartTableData.top != smartTableDataCheck.top || smartTableData.skip != smartTableDataCheck.skip)
            && smartTableData?.top != "10" 
            && smartTableData?.top != "" 
            && smartTableDataCheck?.top != "10" 
            && !loadingTable) {
        (async function () {
            await fetchConditionalOrdersData()
            setTimeLeft(2);
            setLoadingTable(false)
            setIsLoading(false)
            return null
        })();
        }
    }, [smartTableData])
    

    const smartTableToParent = (childdata) => {
        setSmartTableData(childdata);
        if(smartTableData?.top == "10" || smartTableData?.top == "")
        {
            setSmartTableData({top: "20", skip: "0"})
            setSmartTableDataCheck({top: "20", skip: "0"});
        }
    }
    
    useEffect(() => {
        smartTableToParent(smartTableToParent)
    }, [smartTableData])

    const modalTableColumns = [
        {
        Header: 'Data',
        accessor: ({ transactTime }) => formatDateTime(transactTime)
        },
        {
        Header: 'Status',
        accessor: ({ status }) => orderStatusEnum(status)
        },
        {
        Header: 'Quantidade da execução',
        accessor: 'lastQuantity',
        Cell: ({ value }) => formatInteger(value)
        },
        {
        Header: 'Preço de execução',
        accessor: 'lastPrice',
        Cell: ({ value }) => formatDecimal(value),
        columnAlign: 'right',
        },
        {
        Header: 'Preço da ordem',
        accessor: 'price',
        Cell: ({ value }) => formatDecimal(value),
        columnAlign: 'right',
        },
        {
        Header: 'Quantidade da ordem',
        accessor: 'orderQuantity',
        Cell: ({ value }) => formatInteger(value),
        columnAlign: 'right',
        },
    ]


    const tableColumns = [
        {
            Header: 'ID',
            accessor: 'id',
            width: 225
        },
        {
            Header: 'Data',
            accessor: ({ date }) => formatDateTime(date),
            width: 180
        },
        {
            Header: 'Conta',
            accessor: 'account'
        },
        {
            Header: 'Nome',
            accessor: 'name',
            Cell: ({ value }) => value ?? '-',
            width: 200
        },
        {
            Header: 'Status',
            accessor: ({ status }) => riskOrderStatusEnum(status),
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Ativo',
            accessor: 'symbol',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },
        {
            Header: 'Preço',
            accessor: 'price',
            Cell: ({ value }) => formatDecimal(value),
            columnAlign: 'right',
            width: 130
        },
        {
            Header: 'Tipo',
            accessor: ({ ordType }) => riskOrderTypeEnum(ordType),
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Lado',
            accessor: ({ side }) => riskOrderSideEnum(side),
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Qtd. Total',
            accessor: 'ordQty',
            Cell: ({ value }) => formatInteger(value),
            columnAlign: 'right',
            width: 150
        },
        {
            Header: 'Preço. Perda',
            accessor: 'stopLossPrice',
            Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
            columnAlign: 'right',
            width: 150
        },
        {
            Header: 'Preço. Ganho',
            accessor: 'stopGainPrice',
            Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
            columnAlign: 'right',
            width: 150
        },
        {
            Header: 'Preço Disp. Início',
            accessor: 'startTriggerPrice',
            Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
            columnAlign: 'right',
            width: 150
        },
        {
            Header: 'Preço Inicial',
            accessor: 'startPrice',
            Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
            columnAlign: 'right'
        },
        {
            Header: 'Preço Disp. Perda',
            accessor: 'stopLossTriggerPrice',
            Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
            columnAlign: 'right',
            width: 180
        },
        {
            Header: 'Preço Disp. Ganho',
            accessor: 'stopGainTriggerPrice',
            Cell: ({ value }) => value === 0 ? '-' : formatDecimal(value),
            columnAlign: 'right',
            width: 180
        },
    ]
    async function fetchConditionalOrdersData()
    {
        setIsRefreshing(true)
        setIsLoading(true)
        smartTableToParent(smartTableData)
        setSmartTableDataCheck({top: smartTableData?.top, skip: smartTableData?.skip})

        const apiQueryString = objToQueryString({
        ...queryObject,        
        top: smartTableData?.top, 
        skip: smartTableData?.skip,
        dtStart: dateQueryParse(queryObject?.dtStart),
        dtEnd: dateQueryParse(queryObject?.dtEnd)
        });

        if (isQueryEmpty){
            setFiltering(true);
            return;
        }else if(!refresh){
            setShowFilterModal(false);
        }
        try {
            setFiltering(false);
            
            const data = await fetch(`/api/conditionalorders?${apiQueryString}`)
            .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                throw new Error('Something went wrong');
              })
            .then(json => {
                return json
            })

            if(!refresh && results.length >= 0 || 
                refresh && smartTableData.top != smartTableDataCheck.top || 
                results[0].id !== data.orders[0].id) 
            {
                setResults(data.orders);
                setCount(data.count);
                setShowFilterModal(false);
            } 

        } catch(err) {
            setError(true)
        }
        setIsRefreshing(false)
    }

    const cleanFilters = () => {
        setIds("");
        setSymbols("");
        setInitialDate("");
        setFinalDate("");
        setClient("");
        setSmartTableData({top:"20", skip: "0"})
        setSmartTableDataCheck({top:"20", skip: "0"})
        setClient("");
        setType("");
        setSide("");
        setStatus("");
        setTimeInForce("");

        updateQuery(" ")
    }

    const setQueryObjectFilters = () => {
        let requestSymbols = symbols

        if (symbols) requestSymbols = requestSymbols.replace(/\s/g, "")
        const queryStringFilters = {
        account: client,
        top: smartTableData?.top,
        skip: smartTableData?.skip,
        dtStart: initialDate,
        dtEnd: finalDate,
        symbols: requestSymbols,
        ids: ids?.replace(/\s/g, ''),
        type: type?.replace(/\s/g, ''),
        side: side?.replace(/\s/g, ''),
        status: status?.replace(/\s/g, ''),
        timeInForce: timeInForce?.replace(/\s/g, '')
        };

        checkIsEmpty(queryStringFilters);
        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const checkIsEmpty = (queryStringFilters) => {
 
        let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
        setIsQueryEmpty(true);
        propertyNames.forEach(item => {
            if(queryStringFilters[item] !== '' && item != "top" && item != "skip"){
                setIsQueryEmpty(false);
            };
        });
    };

    const handleOrderSearch = async event => {
        event.preventDefault()
        setLoadingTable(true)
        setIsLoading(true)
        setError(false)
        
        if (!searched)
        setSearched(true)

        setQueryObjectFilters()
        if(!isQueryEmpty){
            setShowFilterModal(false);
        }
    }

    const setFinalDateOnBlur = () => {
        if (finalDate === '' || finalDate === undefined) {
            const date = initialDate.substring(0, 10) + "T" + "23:59:59";
            setFinalDate(date)
        }
    }
    
    const handleStartStopClick = () => {
        if (isStarted) {
            clearInterval(intervalId);
            setIntervalId(null);
        } else {
            const newIntervalId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
            }, 2000);
            setIntervalId(newIntervalId);
        }
    };

    useEffect(() => {
        if (refresh && searched) {
            setTimeLeft(2);
            handleStartStopClick();
        } else setTimeLeft(null);
    }, [refresh, searched]);

    useEffect(() => {
        if (timeLeft === 0 && searched && !isLoading) {
            (async function () {
                await fetchConditionalOrdersData();
                setIsLoading(false);
                setTimeLeft(2);
            })();
        }
    }, [timeLeft]);

    const renderFilterElements = () => {
        return(
        <form onSubmit={e => handleOrderSearch(e)}>
            <div className="int-mt-4 ">
                <div className="int-col">
                    <Input
                        data-testid="order-ids"
                        name="ids"
                        label="ID (Escreva múltiplos ids separadas por vírgula)"
                        value={ids}
                        onChange={(e) => setIds(e?.target?.value)}
                    />
                </div>

                <div className="int-col int-pl-0 int-mt-4 ">
                    <Input
                        data-testid="order-symbols"
                        name="symbols"
                        label="Ativos (Escreva múltiplos ativos separados por vírgula)"
                        type="text"
                        value={symbols}
                        onChange={(e) => setSymbols(sanitizeName(e?.target?.value, true))}
                    />
                </div>
            </div>
            <div className="int-mt-4 int-row">
                <div className="int-col">
                    <div className="int-mt-4 int-row">
                        <H4>Data da Ordem</H4>

                        <div className="int-col">
                            <DateTime
                                data-testid="order-initialDate"
                                label="De"
                                name="initialDate"
                                value={initialDate}
                                setDateTime={setInitialDate}
                                maxDate={today}
                                onBlurDate={setFinalDateOnBlur}
                                timeDefault="00:00:00"
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <DateTime
                                data-testid="order-finalDate"
                                label="Para"
                                name="finalDate"
                                value={finalDate}
                                setDateTime={setFinalDate}
                                minDate={initialDate ? initialDate.substring(0, 10) : ''}
                                maxDate={today}
                                timeDefault="23:59:59"
                            />
                        </div>
                        <div className="int-col int-pl-0">
                            <Field
                                data-testid="order-account"
                                label="Conta do Cliente"
                                format="Number"
                                value={client || ''}
                                onChange={(e) => setClient(e?.target?.value.trimStart())}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <SectionWrapper 
                header="Status"  
                headerTag='h4'
                isAccordion       
                >
                <div className="int-mt-4">
                    <MultipleCheckboxFilter 
                        value={{
                            ORDER_STATUS_NEW: orderStatusValues.ORDER_STATUS_NEW,
                            ORDER_STATUS_CANCELED: orderStatusValues.ORDER_STATUS_CANCELED,
                            ORDER_STATUS_PENDING_NEW: orderStatusValues.ORDER_STATUS_PENDING_NEW,
                            ORDER_STATUS_REPLACED: orderStatusValues.ORDER_STATUS_REPLACED,
                            ORDER_STATUS_REJECTED: orderStatusValues.ORDER_STATUS_REJECTED,
                            ORDER_STATUS_PARTIALLY_FILLED: orderStatusValues.ORDER_STATUS_PARTIALLY_FILLED,
                            ORDER_STATUS_FILLED: orderStatusValues.ORDER_STATUS_FILLED

                        }} setParentQueryState={s => setStatus(s)} 
                        columns={2}
                    />
                </div>
            </SectionWrapper>

            <SectionWrapper 
                header="Lado"  
                headerTag='h4'
                isAccordion       
                >
                <div className="int-mt-4">
                    <MultipleCheckboxFilter 
                        value={removeKeyObject(orderSideValues, ['ORDER_SIDE_INVALID', 'sell', 'buy', 'invalid'])} 
                        parentQueryState={side} 
                        setParentQueryState={s => setSide(s)} 
                    />
                </div>
            </SectionWrapper>



            {filtering && ( 
                    <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido.</Alert>
            )}
        </form>

	)
}

    return (
        <SectionWrapper 
            header="Acompanhamento de ordens condicionais"         
            searchButton={
                <div className="int-d-flex int-align-items-center int-justify-content-end int-mt-6">
                    {results?.length > 0 ? (
                        <div className="int-d-flex int-align-items-center int-justify-content-end int-mr-5">
                            <p>Auto refresh</p>

                            <ButtonGroup margin="int-ml-4">
                                <Button
                                    variant={!refresh ? "outline" : "solid"}
                                    onClick={() => {
                                        setRefresh(true);
                                        smartTableToParent({skip: "0"});
                                    }}
                                >
                                    {isRefreshing && refresh ? (
                                        <Loader
                                            color="var(--int-colors-white)"
                                            flex
                                        />
                                    ) : (
                                        "ON"
                                    )}
                                </Button>

                                <Button
                                    variant={refresh ? "outline" : "solid"}
                                    onClick={() => {
                                        setRefresh(false);
                                        setIsLoading(false);
                                        handleStartStopClick();
                                    }}
                                >
                                    OFF
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : (
                        ""
                    )}

                    <Button onClick={() => setShowFilterModal(true)}>
                        <FiSearch />
                    </Button>
                </div>
            }
        >
            <SimpleModal
                header="Filtros de busca"
                isOpen={showFilterModal}
                onClose={() => setShowFilterModal(false)}
                bodyMessage={renderFilterElements()}
                footerButtons={[
                    {
                        label: "Limpar Todos os filtros",
                        onClick: () => cleanFilters(),
                        variant: "outline",
                    },
                    {
                        label: "Buscar",
                        onClick: (e) => {
                            handleOrderSearch(e);
                        },
                        margin: "int-ml-2",
                    },
                ]}
            />
            {loadingTable && <Loader flex />}

            {error && <Alert>Ocorreu um erro na busca</Alert>}

            { loadingTable || error || filtering ? '' : results && results.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : results && (
                <>
                    <SmartTable
                        columnFilters
                        data={results}
                        columns={tableColumns}
                        gapColumnsFilter={2}
                        count={count}
                        localStorageKey={"OrderTrackingSearch"}
                        bodyRowStyles={ordersRowStyles}
                        lotOfColumnsLockHeader={true}
                        columnsResizable
                        smartTableToParent={smartTableToParent}
                        fetchData={fetchConditionalOrdersData}
                        isRefreshing={isRefreshing}
                        autoRefresh={refresh}
                    />
                </>                
            )}

        </SectionWrapper>   
    )
}

import React, { useState } from "react";

import { Button } from "../../components/Forms";
import { Modal, ModalBody, ModalFooter } from "../../components/Modal";
import { UploadCSVInput } from "../../components/Forms";
import { SymbolsList } from "../../pages/VirtualMarket/SymbolsList";
import { formatDecimal, formatInteger, formatParamValue } from "../../common/formatters";
import { UploadClientsPolicies } from "../../pages/Policy/ClientsPolicies/UploadClientsList";
import { UploadParametersList } from "../../pages/Policy/Details/UploadParametersList";
import { UploadParametersListBMF } from "../../pages/Policy/Details/UploadParametersListBMF";
import { UploadParametersListClubs } from "../../pages/Policy/Details/UploadParametersListClubs";
import { UploadParametersListFixedIncome } from "../../pages/Policy/Details/UploadParametersListFixedIncome";
import { UploadParametersListFunds } from "../../pages/Policy/Details/UploadParametersListFunds";
import { UploadOperatorsPolicies } from "../../pages/Policy/OperatorsPolicies/UploadOperatorsList";
import { UploadAdvisorsPolicies } from "../../pages/Policy/AdvisorsPolicies/UploadAdvisorsList";

interface UploadCSVModalProps {
    isModalOpen: boolean;
    onHide: () => void;
    onAdd: (parameters?: any[]) => void;
    header: string;
    model: string;
    policyApplication?: boolean;
    isClient?: boolean;
    parameterName?: string;
    parameterMarket?: string;
    policyKeyType?: string;
}

interface ExcelData {
    "CNPJ"?: string;
    "Nome do Fundo"?: string;
    "Prazo Liquidacao"?: number;
    "Fator de Garantia"?: number;
    "Comentario"?: string;
    "Codigo"?: string;
    "Emissor"?: string;
    "Tipo"?: string;
    "Permite Resgate"?: string;
    "Id"?: string;
    "Nome do Clube"?: string;
    "Símbolo"?: string;
    "Qt Max. De Exposição Comprada"?: string | number;
    "Qt Max. De Exposição Vendida"?: string | number;
    "Tam Max. De Ordem"?: string | number;
    "Margem Por Contrato"?: string | number;
    "Conta": string | number;
    "Politica": string | number;
    Mercado?: string;
    Valor?: object;
    Ativo?: string;
    Ativos?: string
}

interface ParameterClients {
    account: string | number;
    policyName: string | number;
}

interface ParameterAdvisors {
    id: string | number;
    policyName: string | number;
}

interface ParameterBMF {
    symbol: string;
    maxOpenSizeBuy?: string | number;
    maxOpenSizeSell?: string | number;
    maxOrderSize?: string | number;
    marginByContract?: string | number;
}

interface ParameterClubs {
    id: string;
    clubName: string;
    value: string;
    comments?: string;
}

interface ParameterFixedIncome {
    id: string;
    issuer?: string;
    symbol: string;
    redeemable?: string;
    value: string;
    comments?: string;
}

interface ParameterFunds {
    id: string;
    fundName: string;
    settlementCycle: string;
    riskClassification: string;
    value: string;
    comments?: string;
}

interface Parameter {
    market: string;
    symbol?: string;
    value: string;
    comments?: string;
}

interface ParameterMarket {
    symbol: string;
    market: string;
}

export function UploadCSVModal({
    isModalOpen,
    onHide,
    onAdd,
    header,
    model,
    policyApplication,
    isClient,
    parameterName,
    parameterMarket,
    policyKeyType
}: UploadCSVModalProps) {
    const [importedParametersList, setImportedParametersList] = useState<Parameter[]>([]);
    const [importedParametersListClient, setImportedParametersListClient] = useState<ParameterClients[]>([]);
    const [importedParametersListAtivos, setImportedParametersListAtivos] = useState<ParameterMarket[]>([]);
    const [importedParametersListOperators, setImportedParametersListOperators] = useState<ParameterClients[]>([]);
    const [importedParametersListAdvisors, setImportedParametersListAdvisors] = useState<ParameterAdvisors[]>([]);
    const [importedParametersListBMF, setImportedParametersListBMF] = useState<ParameterBMF[]>([]);
    const [importedParametersListFunds, setImportedParametersListFunds] = useState<ParameterFunds[]>([]);
    const [importedParametersListFixedIncome, setImportedParametersListFixedIncome] = useState<ParameterFixedIncome[]>([]);
    const [importedParametersListClubs, setImportedParametersListClubs] = useState<ParameterClubs[]>([]);
    const [stateFilled, setStateFilled] = useState(true);

    function closeModal() {
        onHide()
        setImportedParametersList([])
        setImportedParametersListClient([])
        setImportedParametersListAtivos([])
        setImportedParametersListOperators([])
        setImportedParametersListAdvisors([])
        setImportedParametersListBMF([])
        setImportedParametersListFunds([])
        setImportedParametersListFixedIncome([])
        setImportedParametersListClubs([])
        setStateFilled(true)
    }

    function onFileRead(data: ExcelData[]) {
        var parameters;
        switch (model) {
            case "Ativos":
                const symbols = data
                    .map((item) => ({
                        symbol: item["Ativos"].toString().toUpperCase().trim() ?? "",
                        market: item["Mercado"].toString().toUpperCase().trim() ?? "",
                    }))
                setImportedParametersListAtivos(symbols);
                if (symbols != null || "") {
                    setStateFilled(false);
                }
                break;

            case "Clientes":
                const parametersClientes = data
                    .map((item) => ({
                        account: item["Conta"].toString().toUpperCase().trim() ?? "",
                        policyName: item["Politica"].toString().toUpperCase().trim() ?? "",
                    }))

                setImportedParametersListClient(parametersClientes);
                setStateFilled(false);
                break;

            case "Operadores":
                const parametersOperators = data
                    .map((item) => ({
                        account: item["Conta"].toString().toUpperCase().trim() ?? "",
                        policyName: item["Politica"].toString().toUpperCase().trim() ?? "",
                    }))

                setImportedParametersListOperators(parametersOperators);
                setStateFilled(false);
                break;
            case "Assessores":
                const parametersAdvisors = data
                    .map((item) => ({
                        id: item["Conta"].toString().toUpperCase().trim() ?? "",
                        policyName: item["Politica"].toString().toUpperCase().trim() ?? "",
                    }))

                setImportedParametersListAdvisors(parametersAdvisors);
                setStateFilled(false);
                break;
            case "Parametros":
                parameters = data
                    .map((item) => ({
                        market: parameterMarket != null && parameterMarket != "" ? parameterMarket : item.Mercado.toUpperCase().trim(),
                        symbol: item.Ativo?.toUpperCase().trim() ?? '',
                        value: item.Valor.toString().trim(),
                        comments: item.Comentario?.trim() ?? '',
                        policyKeyType: policyKeyType
                    }))
                    .map(param => ({
                        ...param,
                        value: formatParamValue(param)
                    }))

                setImportedParametersList(parameters);
                setStateFilled(false);
                break;
            case "ParametrosBmf":
                parameters = data
                    .map((item) => ({
                        symbol: item["Símbolo"].toUpperCase().trim(),
                        maxOpenSizeBuy: item["Qt Max. De Exposição Comprada"]?.toString().trim() ?? '',
                        maxOpenSizeSell: item["Qt Max. De Exposição Vendida"]?.toString().trim() ?? '',
                        maxOrderSize: item["Tam Max. De Ordem"]?.toString().trim() ?? '',
                        marginByContract: item["Margem Por Contrato"]?.toString().trim() ?? '',
                    }))
                    .map(param => ({
                        ...param,
                        maxOpenSizeBuy: param.maxOpenSizeBuy == "" ? "" : formatInteger(param.maxOpenSizeBuy),
                        maxOpenSizeSell: param.maxOpenSizeSell == "" ? "" : formatInteger(param.maxOpenSizeSell),
                        maxOrderSize: param.maxOrderSize == "" ? "" : formatInteger(param.maxOrderSize),
                        marginByContract: param.marginByContract == "" ? "" : formatDecimal(param.marginByContract),
                    }))
                    .map(param => ({
                        ...param,
                        marginByContract: isClient ? param.marginByContract : null
                    }))

                setImportedParametersListBMF(parameters);
                setStateFilled(false);
                break;
            case "ParametrosClubes":
                parameters = data
                    .filter(item => item["Fator de Garantia"] !== null && item["Fator de Garantia"] !== undefined)
                    .map((item) => ({
                        id: item["Id"].toString(),
                        clubName: item["Nome do Clube"].toUpperCase().trim(),
                        value: item["Fator de Garantia"].toString().trim(),
                        comments: item.Comentario?.trim() ?? '',
                    }))
                    .map(param => ({
                        ...param,
                        value: formatDecimal(param.value)
                    }))

                setImportedParametersListClubs(parameters);
                setStateFilled(false);
                break;
            case "ParametrosFundos":
                const parametersFundos = data
                    .filter(item => item["Fator de Garantia"] !== null && item["Fator de Garantia"] !== undefined)
                    .map((item) => ({
                        id: item["CNPJ"].trim(),
                        fundName: item["Nome do Fundo"].toUpperCase().trim(),
                        settlementCycle: item["Prazo Liquidacao"].toString().trim(),
                        riskClassification: item["Risco do Fundo"].toString().trim(),
                        value: item["Fator de Garantia"].toString().trim(),
                        comments: item.Comentario?.trim() ?? '',
                    }))
                    .map(param => ({
                        ...param,
                        value: formatDecimal(param.value)
                    }))

                setImportedParametersListFunds(parametersFundos);
                setStateFilled(false);
                break;
            case "ParametrosRendaFixa":
                const parametersFixed = data
                    .filter(item => item["Fator de Garantia"] !== null && item["Fator de Garantia"] !== undefined)
                    .map((item) => ({
                        id: item["Codigo"].toUpperCase().trim(),
                        issuer: item["Emissor"].toUpperCase().trim(),
                        symbol: item["Tipo"].toUpperCase().trim(),
                        redeemable: item["Permite Resgate"].toUpperCase().trim(),
                        value: item["Fator de Garantia"].toString().trim(),
                        comments: item.Comentario?.trim() ?? '',
                    }))
                    .map(param => ({
                        ...param,
                        value: formatDecimal(param.value)
                    }))

                setImportedParametersListFixedIncome(parametersFixed);
                setStateFilled(false);
                break;
            default:
                console.log(data)
                break;
        }
    }

    async function handleModelDownload() {
        fetch(`/assets/Intelirisk-ModeloLista${model}.xlsx`, {
            headers: {
                'Content-Type': 'application/xlsx',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Intelirisk-ModeloLista${model}.xlsx`);

                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    function returnValues(model) {
        switch (model) {
            case "Ativos":
                return (
                    <SymbolsList symbols={importedParametersListAtivos} />
                )
                break;
            case "Clientes":
                return (
                    <UploadClientsPolicies
                        parameters={importedParametersListClient}
                    />
                )
                break;
            case "Operadores":
                return (
                    <UploadOperatorsPolicies
                        parameters={importedParametersListOperators}
                    />
                )
                break;
            case "Assessores":
                return (
                    <UploadAdvisorsPolicies
                        parameters={importedParametersListAdvisors}
                    />
                )
                break;
            case "Parametros":
                return (
                    <UploadParametersList
                        parameters={importedParametersList}
                        parameterName={parameterName}
                    />
                )
                break;
            case "ParametrosBmf":
                return (
                    <UploadParametersListBMF
                        parameters={importedParametersListBMF}
                        parameterName={parameterName}
                        isClient={isClient}
                    />
                )
                break;
            case "ParametrosClubes":
                return (
                    <UploadParametersListClubs
                        parameters={importedParametersListClubs}
                    />
                )
                break;
            case "ParametrosRendaFixa":
                return (
                    <UploadParametersListFixedIncome
                        parameters={importedParametersListFixedIncome}
                    />
                )
                break;
            default:
                return (
                    <UploadParametersListFunds
                        parameters={importedParametersListFunds}
                    />
                )
                break;
        }
    }

    function checkIsFilled() {
        if (importedParametersList.length > 0) {
            return (importedParametersList)
        }
        if (importedParametersListAtivos.length > 0) {
            return (importedParametersListAtivos)
        }
        if (importedParametersListBMF.length > 0) {
            return (importedParametersListBMF)
        }
        if (importedParametersListClient.length > 0) {
            return (importedParametersListClient)
        }
        if (importedParametersListClubs.length > 0) {
            return (importedParametersListClubs)
        }
        if (importedParametersListFixedIncome.length > 0) {
            return (importedParametersListFixedIncome)
        }
        if (importedParametersListFunds.length > 0) {
            return (importedParametersListFunds)
        }
        if (importedParametersListOperators.length > 0) {
            return (importedParametersListOperators)
        }
        if (importedParametersListAdvisors.length > 0) {
            return (importedParametersListAdvisors)
        }
    }

    return (
        <Modal
            size="xlg"
            header={header}
            isOpen={isModalOpen}
            onClose={closeModal}
        >
            <ModalBody>
                {policyApplication && (
                    <div>
                        <p className="int-mb-2">
                            <strong>Atenção:</strong> Os parâmetros importados substituirão os parâmetros atuais da política!
                        </p>
                    </div>)}

                <UploadCSVInput
                    onFileRead={onFileRead}
                    onCancelUploadedFile={() => setImportedParametersList([])}
                />

                <div className="int-mt-4">
                    <p>Caso não tenha, baixe nosso modelo</p>

                    <Button margin="int-mt-4" onClick={handleModelDownload} >
                        Baixar modelo
                    </Button>
                </div>

                {stateFilled == false &&
                    <div className="int-mt-3">
                        <p className="int-mb-2">
                            Esses foram os dados que extraímos do seu arquivo:
                        </p>

                        {returnValues(model)}

                    </div>
                }
            </ModalBody>

            <ModalFooter>
                <Button variant="outline" onClick={closeModal}>
                    Cancelar
                </Button>
                <Button
                    margin="int-ml-2"
                    disabled={stateFilled}
                    onClick={() => {
                        onAdd(checkIsFilled());
                        closeModal();
                    }}
                >
                    Confirmar
                </Button>
            </ModalFooter>
        </Modal>
    );
}
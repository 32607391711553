import React, { ReactNode } from 'react';
import { ButtonHTMLAttributes } from 'react';

type ButtonVariant = 
    | 'solid' 
    | 'outline';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    variant?: 'solid' | 'outline';
    size?: 'sm' | 'md' | 'lg';
    margin?: string;
    padding?: string;
    isFullWidth?: boolean;
}

export function Button({ 
    children, 
    variant = "solid",
    size = "md",
    margin = "",
    padding = "",
    isFullWidth = false,
    ...rest
}: ButtonProps) {
    return(
        <button 
            data-testid="button-modal"
            type="button"
            className={`
                int-btn 
                int-btn-${variant}  
                int-btn-${size} 
                ${margin} 
                ${padding}
                ${isFullWidth ? 'int-btn-block': ''} 
            `} 
            {...rest}
        >
            {children}
        </button>
    )
}
import React, { useEffect, useState } from 'react';
import { BsArrowClockwise } from 'react-icons/bs';

import { SmartTable } from '../../../components/Table';
import { Loader } from '../../../components/Loader';
import { Link } from "../../../components/Link";
import { SectionWrapper } from '../../../components/SectionWrapper'

import { formatDecimal, formatInteger, formatDateTime } from '../../../common/formatters';
import {
    riskOrderTypeEnum,
    riskOrderSideEnum,
    riskOrderStatusEnum,
    riskOrderStatusValues,
    riskTimeInForceEnum
} from '../../../common/enums';
import { ordersRowStyles } from '../../../common/variables';
 
const columns = [
    {
        Header: 'ID',
        accessor: 'id',
        width: 225
    },
    {
        Header: 'Data',
        accessor: ({ date }) => formatDateTime(date),
        width: 180
    },
    {
        Header: 'Conta',
        accessor: 'account'
    },
    {
        Header: 'Nome',
        accessor: 'name',
        Cell: ({ value }) => value ?? '-',
        width: 200
    },
    {
        Header: 'Status',
        accessor: ({ status }) => riskOrderStatusEnum(status),
        filter: 'includes'
    },
    {
        Header: 'Ativo',
        accessor: 'symbol',
        Cell: ({ value }) => value?.toUpperCase() ?? '-'
    },
    {
        Header: 'Preço',
        accessor: 'price',
        Cell: ({ value }) => formatDecimal(value),
        columnAlign: 'right',
        width: 130
    },
    {
        Header: 'Tipo',
        accessor: ({ ordType }) => riskOrderTypeEnum(ordType),
        filter: 'includes'
    },
    {
        Header: 'Lado',
        accessor: ({ side }) => riskOrderSideEnum(side),
        filter: 'includes'
    },
    {
        Header: 'Horário de alteração',
        accessor: 'lastUpdateTime',
        Cell: ({ value, row }) =>
            formatCancelReplaceDateTime(value, row),
        columnAlign: 'right',
        width: 150
    },
    {
        Header: 'Qtd. Total',
        accessor: 'ordQty',
        Cell: ({ value }) => formatInteger(value),
        columnAlign: 'right',
        width: 150
    }
];

export function ScheduledOrdersAccount({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientsScheduledOrdersData() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/scheduledorders?account=${account}`)

            if(!response.ok) {
                throw new Error("Ocorreu um erro ao buscar dados das Ordens agendadas do cliente")
            }

            const data = await response.json()
            setOrders(data.orders)
        } catch (err) {

        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
      if(updateAllGrids){
          fetchClientsScheduledOrdersData()
      }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientsScheduledOrdersData()
    }, [account]);

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            header="Ordens Agendadas"
            headerTag="h3"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={orders.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-align-items-center">
                <Link
                    href={`/scheduled-orders-history?account=${account}`}
                >
                    Ver todas as ordens agendadas
                </Link>

                <button
                    className="int-btn-icon int-btn-icon-outline int-ml-auto"
                    disabled={isLoading}
                    onClick={fetchClientsScheduledOrdersData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex/>}

            {isLoading ? '' : 
            (
                <SmartTable 
                    columns={columns} 
                    data={orders} 
                    localStorageKey={'ClientScheduledOrders'} 
                    bodyRowStyles={ordersRowStyles}
                />
            )}
        </SectionWrapper>
    );
};

function formatCancelReplaceDateTime(value: any, row: any) {
    const status = riskOrderStatusValues[row.original.status];

    switch (status) {
        case riskOrderStatusValues.canceled:
        case riskOrderStatusValues.replaced:
            return formatDateTime(value);
        default:
            return '-';
    }
}
import React from "react"
import { Route, Redirect } from "react-router-dom";

import { Home } from "../pages/Home";
import { ClientSimulator } from "../pages/Simulator/Client";
import { OperatorSimulator } from "../pages/Simulator/Operator";

import { PoliciesList } from "../pages/Policy/List";
import { NewPolicy } from "../pages/Policy/New";
import { PolicyDetails } from "../pages/Policy/Details";
import { ClientsPolicies } from "../pages/Policy/ClientsPolicies";
import { OperatorsPolicies } from "../pages/Policy/OperatorsPolicies";
import { AdvisorsPolicies } from "../pages/Policy/AdvisorsPolicies";

import { SearchClients } from '../pages/Clients/Search';
import { ClientDetails } from '../pages/Clients/Details';

import { OperatorsList } from '../pages/Operators/List';
import { NewOperator } from '../pages/Operators/New';
import { OperatorsDetails } from '../pages/Operators/Details';

import { MarketData } from '../pages/MarketData';
import { NewMtmRegistry } from "../pages/MarketData/NewMaxTheoreticalMargin";

import { SearchHistory } from '../pages/History/Search';
import { HistoryDetails } from '../pages/History/Details';

import { OrderTracking } from "../pages/OrderTracking";
import { ConditionalOrdersHistory } from "../pages/ConditionalOrdersHistory";
import { LimitReportsHistory } from "../pages/LimitReportsHistory";
import { ScheduledOrdersHistory } from "../pages/ScheduledOrdersHistory"
import { EventHistory } from "../pages/OrderTracking/EventHistory"
import { OrdersRespecifiedHistory } from "../pages/OrdersRespecified"

import { BtcHistory } from "../pages/BtcHistory";
import { CheckingAccountHistory } from "../pages/CheckingAccountHistory";
import { FundsTransactionHistory } from "../pages/FundsTransactionHistory";
import { FixedIncomeTransactionHistory } from "../pages/FixedIncomeTransactionHistory";
import { MarginGuranteeHistory } from "../pages/MarginGuaranteeHistory";
import { TesouroDiretoHistory } from "../pages/TesouroDiretoHistory";

import { VirtualMarketList } from "../pages/VirtualMarket/List";
import { VirtualMarketDetails } from "../pages/VirtualMarket/Details";
import { NewManualVirtualMarket } from "../pages/VirtualMarket/NewManualVirtualMarket";
import { NewAutoVirtualMarket } from "../pages/VirtualMarket/NewAutoVirtualMarket";

import { NewIpoRegistry } from "../pages/IPO/New";
import { IpoRegistryList } from "../pages/IPO/List";

import { ControlPainel } from '../pages/ClosingPosition/ControlPainel'

import { Connections } from '../pages/Connections/';

import { Configurations } from '../pages/Configurations/';

import { Holidays } from '../pages/Holidays';

import { SignIn } from '../pages/SignIn';

import { isAdmin, isAuthenticated, isDisabledAuthentication, logoutService, isRequiredAcessAdmin, setRouteAdmin } from "../services/auth";
import {RiskMonitor} from "../pages/RiskMonitor";

const AdminRoute = ({ component: Component, ...rest }) => {
    var userIsAdmin = isAdmin()
    if (userIsAdmin || isDisabledAuthentication())
        return (
            <Route
                {...rest}
                render={props => (<Component {...props} />)}
            />
        );
    else {
        if (rest.path == window.location.pathname) {
            //verica se o usuário está acessando uma rota com privilégios de admin
            logoutService()
            setRouteAdmin(true)
            return (
                <Route
                    {...rest}
                    render={props => (<Redirect to={{ pathname: "/signIn", state: { from: props.location } }} />)
                    }
                />
            )
        }

        if (!isRequiredAcessAdmin()) {
            return (
                <Route
                    {...rest}
                    render={props => (<Component {...props} />)}
                />
            );
        }

        if (isRequiredAcessAdmin()) {
            return (
                <Route
                    {...rest}
                    render={props => (<Redirect to={{ pathname: "/", state: { from: props.location } }} />)
                    }
                />
            )
        }
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() || isDisabledAuthentication() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/signIn", state: { from: props.location } }} />
            )
        }
    />
);

const LoggedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() || isDisabledAuthentication() ? (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            ) : (
                <Component {...props} />
            )
        }
    />
);

const Routes = () => {
    return (
        <>
            <Route path="/" exact component={Home} />
            <LoggedRoute path="/signIn" exact component={SignIn} />

            <AdminRoute path="/simulator" exact component={ClientSimulator} />
            <AdminRoute path="/simulator/operator" exact component={OperatorSimulator} />

            <AdminRoute path="/policies" exact component={PoliciesList} />
            <AdminRoute path="/new-policy" component={NewPolicy} />
            <AdminRoute path="/policies/:id" component={PolicyDetails} />
            <PrivateRoute path="/clients-policies" component={ClientsPolicies} />
            <PrivateRoute path="/operators-policies" component={OperatorsPolicies} />
            <PrivateRoute path="/advisors-policies" component={AdvisorsPolicies} />

            <PrivateRoute path="/client" exact component={SearchClients} />
            <PrivateRoute path="/client/:account" exact component={ClientDetails} />

            <PrivateRoute path="/operators" exact component={OperatorsList} />
            <PrivateRoute path="/new-operator" exact component={NewOperator} />
            <PrivateRoute path="/operators/:code" exact component={OperatorsDetails} />

            <AdminRoute path="/market-data" exact component={MarketData} />
            <AdminRoute path="/new-mtm-registry" component={NewMtmRegistry} />

            <PrivateRoute path="/history" exact component={SearchHistory} />
            <PrivateRoute path="/history/:id" exact component={HistoryDetails} />

            <PrivateRoute path="/order-tracking" exact component={OrderTracking} />
            <PrivateRoute path="/order-tracking/executions/:orderID" exact component={EventHistory} />
            <AdminRoute path="/order-respecified-tracking" exact component={OrdersRespecifiedHistory} />
            <AdminRoute path="/conditional-orders-history" exact component={ConditionalOrdersHistory} />
            <AdminRoute path="/limit-reports-history" exact component={LimitReportsHistory} />
            <AdminRoute path="/scheduled-orders-history" exact component={ScheduledOrdersHistory} />

            <AdminRoute path="/btc-history" exact component={BtcHistory} />
            <AdminRoute path="/checking-account-history" exact component={CheckingAccountHistory} />
            <AdminRoute path="/funds-transaction-history" exact component={FundsTransactionHistory} />
            <AdminRoute path="/fixed-income-transaction-history" exact component={FixedIncomeTransactionHistory} />
            <AdminRoute path="/margin-guarantee-history" exact component={MarginGuranteeHistory} />
            <AdminRoute path="/tesouro-direto-history" exact component={TesouroDiretoHistory} />
            <AdminRoute path="/risk-monitor" exact component={RiskMonitor} />

            <AdminRoute path="/virtual-market" exact component={VirtualMarketList} />
            <AdminRoute path="/new-manual-virtual-market" component={NewManualVirtualMarket} />
            <AdminRoute path="/new-auto-virtual-market" component={NewAutoVirtualMarket} />
            <AdminRoute path="/virtual-market/:id" component={VirtualMarketDetails} />

            <AdminRoute path="/ipo-registries" exact component={IpoRegistryList} />
            <AdminRoute path="/new-ipo-registry" exact component={NewIpoRegistry} />

            <AdminRoute path="/holidays" exact component={Holidays} />

            <AdminRoute path="/closing-position/control-painel" exact component={ControlPainel} />

            <AdminRoute path="/connections" exact component={Connections} />

            <AdminRoute path="/configurations" exact component={Configurations} />
        </>
    );
};

export default Routes;

import React, { useRef } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';

import { Button, Field } from '../../components/Forms';
import { SectionWrapper } from '../../components/SectionWrapper';

import { removeArrayItem, setArrayOfObjects } from '../../common/variables';

type TCustody = {
    symbol: string;
    valorBruto: string;
    guaranteeFactor: string;
}

interface CustodyProps {
    custody: TCustody[];
    setCustody: (value: TCustody[]) => void;
    custodyItem: TCustody;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
    setValidationErrors: React.Dispatch<React.SetStateAction<[]>>;
    deleteOldErrors: (headerName: string, index: number) => void;
}

const headerName = "Custódia de Tesouro Direto"

export function TesouroDiretoCustody({ custody, 
    setCustody, 
    custodyItem, 
    allAccordionsOpen,
    setValidationErrors,
    deleteOldErrors
}: CustodyProps) {

    const highlightedItemRef = useRef(null);

    function addNewCustody() {
        if (custody.length !== 0) resetAnimation();
        setCustody([ custodyItem, ...custody ]);
        if (custody.length !== 0) activateAnimation();
    }

    function resetAnimation(): void {
        highlightedItemRef.current.style.animation = '';
        highlightedItemRef.current.style.animationIterationCount = '';
        void highlightedItemRef.current.offsetWidth;
    }

    function activateAnimation(): void {
        const animation = 'smooth-fade-in 3s linear forwards 1';
        highlightedItemRef.current.style.animation = animation;
        highlightedItemRef.current.children[0].children[0].children[0].focus();
    }

    function removeAllCustody() {
        setCustody([custodyItem])
    }

    function removeAllErrors() {
        setValidationErrors([]);
    }

    return(
        <SectionWrapper
            headerTag="h3"
            header={headerName}
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
        >
            <div className="int-d-flex int-justify-content-end int-mb-4">
                <button
                    data-testid="custody-add-row"
                    type="button"
                    className="int-btn-icon int-btn-icon-outline"
                    onClick={addNewCustody}
                >
                    <FiPlus size={20} />
                </button>
            </div>
            <table className="int-table">
                <thead>
                    <tr>
                        <th style={{ width: "33.3%" }}>Título</th>
                        <th style={{ width: "33.3%" }}>Valor Disponível</th>
                        <th style={{ width: "33.3%" }}>Fator de Garantia</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {custody.map((item, index) => (
                        <tr key={index} ref={index === 0 ? highlightedItemRef : null}>
                            <td>
                                <Field
                                    data-testid="tesouro-direto-custody-symbol"
                                    required
                                    format=""
                                    name="symbol"
                                    value={item?.symbol}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event,
                                                true
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Field
                                    data-testid="tesouro-direto-custody-value"
                                    required
                                    format="Decimal"
                                    name="valorBruto"
                                    value={item?.valorBruto}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Field
                                    data-testid="tesouro-direto-custody-parameter"
                                    required
                                    format="Decimal"
                                    name="guaranteeFactor"
                                    value={item?.guaranteeFactor}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <BsTrashFill
                                    data-testid="custody-remove-item"
                                    size={22}
                                    color="var(--chakra-colors-orange-400)"
                                    className="ml-3 flex-shrink-0"
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                        () => [setCustody(
                                            removeArrayItem(index, custody)
                                        ),
                                        deleteOldErrors(headerName, index)
                                    ]
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="int-my-4">
                <Button
                    variant="outline"
                    disabled={custody.length < 2}
                    onClick={() => [removeAllCustody(), removeAllErrors()]}
                >
                    Remover todos os items
                </Button>
            </div>
        </SectionWrapper>
    )
}
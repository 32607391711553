import React, { useEffect, useState } from 'react'

import { H2, H3 } from '../../../components/Typography'
import { Button, Input, ButtonGroup } from '../../../components/Forms'
import { Modal, ModalBody, ModalFooter } from '../../../components/Modal'
import { Loader } from '../../../components/Loader'
import { SmartTable } from '../../../components/Table'
import { NumberRangeColumnFilterVertical } from '../../../components/Table/Filters'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from '../../../components/Alert'

import { objToQueryString } from '../../../common/api.js'
import { formatInteger, formatDecimal } from '../../../common/formatters'
import { sanitizeName } from '../../../common/variables';

import { useQueryParams } from '../../../hooks/useQueryParams'
import { API_HEADER } from '../../../constants/api'

export function ControlPainel() {
    const { setParam, updateQuery } = useQueryParams()

    const [results, setResults] = useState<[]>()

    const [accounts, setAccounts] = useState(setParam("accounts"))
    const [accountsInput, setAccountsInput] = useState("")

    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(false)

    const [refreshIntervalId, setRefreshIntervalId] = useState(null)
    const [wasSearched, setWasSearched] = useState(false)

    const [zeroPosDisabled, setZeroPosDisabled] = useState(false)
    const [showModalZeroPos, setShowModalZeroPos] = useState(false)
    const [zeroPosResultMessage, setZeroPostResultMessage] = useState("")
    const [isZeroPosResultError, setIsZeroPosResultError] = useState(false)

    const columns = [
        {
           Header: 'Cliente',
           accessor: 'account',
           Cell: ({ value }) => <b>{value}</b>,
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Ativo',
           accessor: 'symbol',
           Cell: ({ value }) => value.toUpperCase()
        },
        {
           Header: 'Compra exec',
           accessor:'execBuyQty',
           Cell: ({ value }) => formatInteger(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Ven exec',
           accessor: 'execSellQty',
           Cell: ({ value }) => formatInteger(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Comp abert',
           accessor: 'openBuyQty',
           Cell: ({ value }) => formatInteger(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Ven abert',
           accessor: 'openSellQty',
           Cell: ({ value }) => formatInteger(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'PM Compra',
           accessor: 'execBuyAvgPrice',
           Cell: ({ value }) => formatDecimal(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'PM Venda',
           accessor: 'execSellAvgPrice',
           Cell: ({ value }) => formatDecimal(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Posic exec',
           accessor: 'execPosition',
           Cell: ({ value }) => formatInteger(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Posic atual',
           accessor: 'currentPosition',
           Cell: ({ value }) => formatInteger(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Prec. merc',
           accessor: 'marketPrice',
           Cell: ({ value }) => formatDecimal(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'Limite',
           accessor: 'limit',
           Cell: ({ value }) => formatDecimal(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
           Header: 'L/P',
           accessor: 'profit',
           Cell: ({ value }) => formatDecimal(value),
           Filter: NumberRangeColumnFilterVertical,
           filter: 'between'
        },
        {
            Header: '-',
            Cell: ({ row }) => (
                <Button
                    data-testid="zero-position-button"
                    isFullWidth
                    disabled={zeroPosDisabled}
                    onClick={() => zeroPosition(row?.values?.account, row?.values?.symbol)}
                >
                    Zerar Posição
                </Button>    
            )
        }
    ]



    useEffect(() => {
        if (accounts)
        {
            fetchIntradayPosition()
            setAccountsInput(accounts)
            updateQuery(objToQueryString({ accounts }))
        }
    }, [accounts])

    const clearFilters = () => {
        setAccountsInput("")

        updateQuery(" ")
    }

    const fetchIntradayPosition = async () => {
        if (!wasSearched) {
            setLoading(true)
        }

        setDisabled(true)
        setError(false)

        const queryString = objToQueryString({ accounts });

        try {
            const response = await fetch(`/api/IntradayPosition?${queryString}`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()

            setResults(data)
        } catch(err) {
            setError(true)
        } finally {
            setLoading(false)
            setDisabled(false)

            if (!wasSearched) {
                setWasSearched(true)
            }

        }
    }

    const handleD0PositionFetch = (event) => {
        event.preventDefault()

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setDisabled(false)
            return event.stopPropagation()
        }

        setLoading(true)
        setError(false)
        setAccounts(accountsInput)
    }

    const zeroPosition = async (account, symbol) => {
        setZeroPosDisabled(true)

        try {
            const bodyRequest = {
                account,
                symbol
            }
    
            const options = {
                method: "POST",
                headers: API_HEADER,
                body: JSON.stringify(bodyRequest),
            };

            const response = await fetch('/api/PosTrading/closeClientPosition', options)

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            setZeroPostResultMessage("Zeragem executada com sucesso!");
            setIsZeroPosResultError(false);
        } catch(err) {
            setZeroPostResultMessage(err.message);
            setIsZeroPosResultError(true);
        } finally {
            setZeroPosDisabled(false)
            setShowModalZeroPos(true)
        }
    }

    return (
        <>
            <SectionWrapper header="Painel de Controle">
                <H2 margin="int-mt-4">Posição Intraday dos Clientes</H2>

                <SectionWrapper
                    headerTag="h2"
                    header="Filtros de busca"
                    isAccordion
                    >  
                    <form 
                        className="int-mb-4"
                        onSubmit={event => handleD0PositionFetch(event)}
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}
                    >
                        <div className="int-flex-grow-1">
                            <Input 
                                data-testid="intraday-accounts"
                                label="Contas dos clientes (Escreva multíplas contas separadas por vírgula)"
                                name="accounts"
                                isRequired
                                value={accountsInput}
                                onChange={(e) => setAccountsInput(sanitizeName(e?.target?.value, true))}
                            />
                        </div>
                        <Button
                            margin="int-ml-2"
                            disabled={disabled}
                            variant="outline"
                            onClick={clearFilters}
                        >
                            Limpar todos os filtros
                        </Button>
                    </form>
                </SectionWrapper>
                { loading && <Loader flex/>}

                { error && <Alert>Ocorreu um erro na busca</Alert> }

                { loading || error ? '' : results && results.length === 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum resultado foi encontrado
                    </p>
                ) : results && (
                    <>
                        <SmartTable columnFilters columns={columns} data={results} localStorageKey={'ClosingPostionSearch'} />
                    </>
                )}
            </SectionWrapper>

            {/* Modal - Mensagem Zeragem da posição com sucesso */}
            <Modal
                header={isZeroPosResultError ? "Erro!" : "Sucesso!"}
                isOpen={showModalZeroPos}
                onClose={() => {
                    setShowModalZeroPos(false)
                    fetchIntradayPosition()
                }}
            >
                <ModalBody>
                    <p>{zeroPosResultMessage}</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant={isZeroPosResultError ? "outline" : "solid"}
                        onClick={() => {
                            setShowModalZeroPos(false)
                            fetchIntradayPosition()
                        }}
                    >
                        Fechar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

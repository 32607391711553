import React, { ReactNode, useEffect, useState } from 'react'

interface TabContentProps {
    children: ReactNode;
    tabSelected: string;
    tabIndex: string;
};

export function TabContent({ children, tabSelected, tabIndex }: TabContentProps) {
    const [isEnable, setIsEnable] = useState(false);
    
    useEffect(() => {
        if(tabSelected === tabIndex) {
            setIsEnable(true);
        } else {
            setIsEnable(false);
        };
    },[tabSelected])


    return (
        <div className={`${isEnable ? 'int-show-tab int-tab-content-border' : 'int-hide-tab'}`}>
            {children}
        </div>
    );
};
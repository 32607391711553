import React, { useState, useEffect } from "react";
import { BsArrowClockwise } from "react-icons/bs";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Loader } from "../../../components/Loader";
import { formatInteger, formatDecimal, formatDate } from "../../../common/formatters";
import { SmartTable } from '../../../components/Table'

export function IpoReserve({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion })
{
    const [IpoReserves, setIpoReserves] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientIpoReserve()
    {
        setIsLoading(true);

        try
        {
            const response = await fetch(`/api/client/${account}/IpoReserve`);

            if (!response.ok)
            {
                throw new Error("Ocorreu um erro ao buscar reservas IPO do cliente");
            }

            const data = await response.json();

            setIpoReserves(data);
        }
        catch (err)
        {
            console.log(err);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if(updateAllGrids){
            fetchClientIpoReserve()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientIpoReserve();
    }, [account]);

    const columns = [
        {
            Header: 'Código ISIN',
            accessor: data => data.isin,
        },
        {
            Header: 'Modalidade',
            accessor: data => formatInteger(data.offerType),
        },
        {
            Header: 'Quantidade',
            accessor: data => formatInteger(data.quantity),
        },
        {
            Header: 'Preço Máximo',
            accessor: data => formatDecimal(data.maxPrice),
        },
        {
            Header: 'Valor Reservado',
            accessor: data => formatDecimal(data.reservedValue),
        },
        {
            Header: 'Valor Atual',
            accessor: data => formatDecimal(data.actualValue),
        }

    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Reservas de IPO"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={IpoReserves.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientIpoReserve}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={IpoReserves} columns={columns} localStorageKey={'ClientIpoReserveParams'} />
                )}
        </SectionWrapper>
    )
}

import React, { ReactNode } from 'react'

interface H1Props {
    children: ReactNode;
    margin?: string;
    padding?: string;
}

export function H1({ 
    children, 
    margin = "", 
    padding = "", 
    ...rest 
}: H1Props) {
    return(
        <h1 className={`int-h1 ${margin} ${padding}`} {...rest}>
            {children}
        </h1>
    )
}
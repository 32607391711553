import React, { useEffect, useState } from 'react';
import { BsArrowClockwise } from 'react-icons/bs';

import { SmartTable } from '../../../components/Table'
import { Loader } from '../../../components/Loader'; 
import { SectionWrapper } from '../../../components/SectionWrapper'

import { formatInteger, formatDecimal } from '../../../common/formatters'

export function BmfPositionD0({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [bmfPositionD0, setBmfPositionD0] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientBmfPositionD0Data () {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/bmfPositionD0`)
            
            if(!response.ok) {
                throw new Error("Ocorreu um erro ao buscar a Posição Intraday - BMF do cliente")
            }

            const data = await response.json()
            setBmfPositionD0(data)
        } catch (err) {
            console.log(err)
        }

        setIsLoading(false)
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientBmfPositionD0Data()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientBmfPositionD0Data()
    }, [account])

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol.toUpperCase(),
        },
        {
            Header: 'Qtd. Inicial',
            accessor: data => formatInteger(data.initQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Quantidade Inicial'
        },
        {
            Header: 'Compra exec',
            accessor: data => formatInteger(data.execBuyQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Compra executada'
        },
        {
            Header: 'Ven exec',
            accessor: data => formatInteger(data.execSellQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Venda executada'
        },
        {
            Header: 'Comp abert',
            accessor: data => formatInteger(data.openBuyQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Compra abertura'
        },
        {
            Header: 'Ven abert',
            accessor: data => formatInteger(data.openSellQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Venda abertura'
        },
        {
            Header: 'PM Compra exec.',
            accessor: data => formatDecimal(data.execBuyAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Compra executada'
        },
        {
            Header: 'PM Venda exec.',
            accessor: data => formatDecimal(data.execSellAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Venda executada'
        },
        {
            Header: 'PM Compra abert',
            accessor: data => formatDecimal(data.openBuyAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Compra abertura'
        },
        {
            Header: 'PM Venda abert',
            accessor: data => formatDecimal(data.openSellAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Venda Abertura'
        },
        {
            Header: 'Posic exec',
            accessor: data => formatInteger(data.execPosition),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Posição executada'
        },
        {
            Header: 'Posic atual',
            accessor: data => formatInteger(data.currentPosition),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Posição atual'
        },
        {
            Header: 'Cotação',
            accessor: data => formatDecimal(data.currentPrice),
            columnAlign: 'right',
        },
        {
            Header: 'Margem',
            accessor: data => formatDecimal(data.marginByContract),
            columnAlign: 'right',
        },
        {
            Header: 'Limite',
            accessor: data => formatDecimal(data.limit),
            columnAlign: 'right',
        },
        {
            Header: 'L/P',
            accessor: data => formatDecimal(data.profit),
            columnAlign: 'right',
        }
    ]

    return (
        <SectionWrapper 
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Posição Intraday - BMF"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={bmfPositionD0.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientBmfPositionD0Data}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' : 
            (
                    <SmartTable data={bmfPositionD0} columns={columns} localStorageKey={'ClientBmfD0Position'} /> 
            )}
        </SectionWrapper>
    )
}
import React from 'react'

interface OperatorParameters {
    account?: string | number;
    policyName?: string | number;
}

interface UploadOperatorParametersProps {
    parameters: OperatorParameters[];
}

export function UploadOperatorsPolicies({ parameters }: UploadOperatorParametersProps) {
    return (
        <table className="int-table">
            <thead>
                <tr>
                    <th>Conta</th>
                    <th>Política</th>
                </tr>
            </thead>

            <tbody>
                {parameters?.map((item, index) => (
                    <tr key={index}>
                        <td>{item.account ?? '-'}</td>
                        <td>{item.policyName ?? '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

import React, { ReactNode } from 'react'
import { Button } from '..'
import * as XLSX from 'xlsx'
import { ButtonHTMLAttributes } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    variant?: 'solid' | 'outline';
    size?: 'sm' | 'md' | 'lg';
    margin?: string;
    padding?: string;
    isFullWidth?: boolean;
    dataJs: any;
    fileName: string;
}

export function DownloadXLSXButton({ 
    dataJs, 
    fileName, 
    children, 
    variant = "solid",
    size = "md",
    margin = "",
    padding = "",
    isFullWidth = false,
    ...rest
}: ButtonProps) {
    const fileExtension = '.xlsx';

    const exportFile = (dataJs, fileName) => {

        const ws = XLSX.utils.json_to_sheet(dataJs);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, fileName + fileExtension);
    }
    
    return (
        <Button 
            variant={variant} 
            margin={margin}
            padding={padding}
            size={size}
            isFullWidth={isFullWidth}
            onClick={() => exportFile(dataJs, fileName)}
            {...rest}
        >
            {children}
        </Button>
    )
}
const commonParameters = [
    {
        friendlyName: "Qtd. máx. de exposição comprada (individual)",
        name: "MaxOpenSizeBuy",
        market: "TODOS",
        symbol: "",
        value: "1.000.000.000",
        operatorParameter: false,
    },
    {
        friendlyName: "Qtd. máx. de exposição vendida (individual)",
        name: "MaxOpenSizeSell",
        market: "TODOS",
        symbol: "",
        value: "1.000.000.000",
        operatorParameter: false,
    },
    {
        friendlyName: "Vol. máx. de exposição comprada (individual)",
        name: "MaxOpenVolumeBuy",
        market: "TODOS",
        symbol: "",
        value: "1.000.000.000",
        operatorParameter: false,
    },
    {
        friendlyName: "Vol. máx. de exposição vendida (individual)",
        name: "MaxOpenVolumeSell",
        market: "TODOS",
        symbol: "",
        value: "1.000.000.000",
        operatorParameter: false,
    },
    {
        friendlyName: "Tamanho Máximo de ordem",
        name: "MaxOrderSize",
        market: "TODOS",
        symbol: "",
        value: "1.000.000.000",
        operatorParameter: false,
    },
    {
        friendlyName: "Volume máximo de ordem",
        name: "MaxOrderVolume",
        market: "TODOS",
        symbol: "",
        value: "1.000.000.000",
        operatorParameter: false,
    },  
    {
        friendlyName: "Pode aumentar posição",
        name: "AllowIncreasePosition",
        market: "TODOS",
        symbol: "",
        value: "Sim",
        operatorParameter: false,
    },
    {
        friendlyName: "Pode negociar",
        name: "AllowTrade",
        market: "TODOS",
        symbol: "",
        value: "Sim",
        operatorParameter: false,
    },
    {
        friendlyName: "Pode operar a descoberto",
        name: "AllowUncoveredSell",
        market: "TODOS",
        symbol: "",
        value: "Sim",
        operatorParameter: false,
    },
]

const defaultClientParameters = [...commonParameters];

const defaultOperatorParameters = [...commonParameters, 
    {
        friendlyName: "Validar risco de Cliente",
        name: "ValidateClient",
        market: "TODOS",
        symbol: "",
        value: "Não",
        operatorParameter: false,
    }
]

export { defaultClientParameters, defaultOperatorParameters }
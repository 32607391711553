import React, { useState } from 'react'

import { Tabs } from '../../../components/Tabs';
import { Tab } from '../../../components/Tabs/Tab';
import { TabContent } from '../../../components/Tabs/TabContent';

import { Balance } from './Balance';
import { BmfCustody } from './BmfCustody';
import { BmfPositionD0 } from './BmfPositionD0';
import { BovespaPositionD0 } from './BovespaPositionD0';
import { BtcCustodyDonor } from './BtcCustodyDonor';
import { BtcCustodyTaker } from './BtcCustodyTaker';
import { ClubsCustody } from './ClubsCustody';
import { Custody } from './Custody';
import { CustodyUnknownMarket } from './CustodyUnknownMarket';
import { Strategies } from './Strategies';
import { FixedIncomeCustody } from './FixedIncomeCustody';
import { FlexOpcCustody } from './FlexOpcCustody';
import { FundsCustody } from './FundsCustody';
import { FutOpc } from './FutOpc';
import { GoldCustody } from './GoldCustody';
import { Guarantee } from './Guarantee';
import { IpoReserve } from './IpoReserve';
import { LimitsByCategory } from './LimitsByCategory';
import { Margin } from './Margin';
import { OrdersHistory } from './OrdersHistory';
import { PatrimonyLimits } from './PatrimonyLimits';
import { Policy } from './Policy';
import { TermCustody } from './TermCustody';
import { TesouroDiretoCustody } from './TesouroDiretoCustody';
import { ScheduledOrdersAccount } from './ScheduledOrdersAccount';
import { ConditionalOrdersAccount } from './ConditionalOrdersAccount';

interface TabsPageProps {
    account: string;
    allAccordionsOpen?: {
        isAllAccordionOpen: boolean;
        count: number;
    };
    updateAllGrids: boolean;
    updatePolicyGrid: boolean;
    handlePolicyChange?: Function;
    clientLimit: any;
};

const TabsPage = ({ account, allAccordionsOpen, updateAllGrids, updatePolicyGrid, handlePolicyChange, clientLimit }: TabsPageProps) => {
    const [tabSelectedRowLimitPatrimony, setTabSelectedRowLimitPatrimony] = useState("1");
    const [tabSelectedRowCustody, setTabSelectedRowCustody] = useState("1");
    const [tabSelectedRowIntraday, setTabSelectedRowIntraday] = useState("1");
    const [tabSelectedRowTermAndBtc, setTabSelectedRowTermAndBtc] = useState("1");
    const [tabSelectedRowMarginAndGuarantee, setTabSelectedRowMarginAndGuarantee] = useState("1");
    const [tabSelectedRowFinAndTd, setTabSelectedRowFinAndTd] = useState("1");
    const [tabSelectedRowFundsAndClubs, setTabSelectedRowFundsAndClubs] = useState("1");
    const [tabSelectedRowOrders, setTabSelectedRowOrders] = useState("1");


    return (
        <div>
            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Financeiro (Bovespa)"
                        tabIndex={"1"}
                    tabSelectedRow={"1"}/>
                </Tabs>

                <TabContent tabSelected={"1"} tabIndex={"1"}>
                    <Balance
                        account={account}
                        id="balance-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Patrimônio e Limites"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowLimitPatrimony}
                        tabSelectedRow={tabSelectedRowLimitPatrimony}
                        zIndex={2}
                    />
                    <Tab
                        label="Limite em Uso por Categoria"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowLimitPatrimony}
                        tabSelectedRow={tabSelectedRowLimitPatrimony}
                        zIndex={1}
                    />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowLimitPatrimony} tabIndex={"1"}>
                    <PatrimonyLimits
                        account={account}
                        clientLimit={clientLimit}
                        id="patrimonyLimits-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowLimitPatrimony} tabIndex={"2"}>
                    <LimitsByCategory
                        account={account}
                        clientLimit={clientLimit}
                        id="limitsByCategory-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Ações e Opções"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={8}
                    />
                    <Tab
                        label="Custódia BMF"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={7}
                    />
                    <Tab
                        label="Custódia de Ouro"
                        tabIndex={"3"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={6}
                    />
                    <Tab
                        label="Opções BMF"
                        tabIndex={"4"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={5}
                    />
                    <Tab
                        label="Opções Flexíveis"
                        tabIndex={"5"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={4}
                    />
                    <Tab
                        label="Reservas IPO"
                        tabIndex={"6"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={3}
                    />
                    <Tab
                        label="Custódia Outros"
                        tabIndex={"7"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={2}
                    />
                    <Tab
                        label="Estratégias"
                        tabIndex={"8"}
                        setTabSelectedRow={setTabSelectedRowCustody}
                        tabSelectedRow={tabSelectedRowCustody}
                        zIndex={1}
                    />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"1"}>
                    <Custody
                        account={account}
                        id="custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"2"}>
                    <BmfCustody
                        account={account}
                        id="bmf-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"3"}>
                    <GoldCustody
                        account={account}
                        id="gold-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"4"}>
                    <FutOpc
                        account={account}
                        id="futOpc-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"5"}>
                    <FlexOpcCustody
                        account={account}
                        id="flexOpc-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"6"}>
                    <IpoReserve
                        account={account}
                        id="ipoReserve-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"7"}>
                    <CustodyUnknownMarket
                        account={account}
                        id="custody-unknown-market"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowCustody} tabIndex={"8"}>
                    <Strategies
                        account={account}
                        id="strategies-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Posição Intraday BMF"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowIntraday}
                        tabSelectedRow={tabSelectedRowIntraday}
                        zIndex={2}
                    />
                    <Tab
                        label="Posição Intraday Bovespa"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowIntraday}
                        tabSelectedRow={tabSelectedRowIntraday}
                        zIndex={1}
                    />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowIntraday} tabIndex={"1"}>
                    <BmfPositionD0
                        account={account}
                        id="bmf-position-d0-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowIntraday} tabIndex={"2"}>
                    <BovespaPositionD0
                        account={account}
                        id="bovespa-position-d0-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Termo"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowTermAndBtc}
                        tabSelectedRow={tabSelectedRowTermAndBtc}
                        zIndex={3}
                    />
                    <Tab
                        label="BTC Tomador"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowTermAndBtc}
                        tabSelectedRow={tabSelectedRowTermAndBtc}
                        zIndex={2}
                    />
                    <Tab
                        label="BTC Doador"
                        tabIndex={"3"}
                        setTabSelectedRow={setTabSelectedRowTermAndBtc}
                        tabSelectedRow={tabSelectedRowTermAndBtc}
                        zIndex={1}
                    />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowTermAndBtc} tabIndex={"1"}>
                    <TermCustody
                        account={account}
                        id="term-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowTermAndBtc} tabIndex={"2"}>
                    <BtcCustodyTaker
                        account={account}
                        id="btc-custody-tomador-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowTermAndBtc} tabIndex={"3"}>
                    <BtcCustodyDonor
                        account={account}
                        id="btc-custody-doador-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Margens"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowMarginAndGuarantee}
                        tabSelectedRow={tabSelectedRowMarginAndGuarantee}
                        zIndex={2}
                    />
                    <Tab
                        label="Garantias"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowMarginAndGuarantee}
                        tabSelectedRow={tabSelectedRowMarginAndGuarantee}
                        zIndex={1}
                    />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowMarginAndGuarantee} tabIndex={"1"}>
                    <Margin
                        account={account}
                        id="margin-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowMarginAndGuarantee} tabIndex={"2"}>
                    <Guarantee
                        account={account}
                        id="guarantee-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Renda Fixa"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowFinAndTd}
                        tabSelectedRow={tabSelectedRowFinAndTd}
                        zIndex={2}
                    />
                    <Tab
                        label="Tesouro Direto"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowFinAndTd}
                        tabSelectedRow={tabSelectedRowFinAndTd}
                        zIndex={1}
                    />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowFinAndTd} tabIndex={"1"}>
                    <FixedIncomeCustody
                        account={account}
                        id="fixed-income-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowFinAndTd} tabIndex={"2"}>
                    <TesouroDiretoCustody
                        account={account}
                        id="tesouro-direto-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Fundos de Investimento"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowFundsAndClubs}
                        tabSelectedRow={tabSelectedRowFundsAndClubs}
                        zIndex={2}
                    />
                    <Tab
                        label="Clubes de Investimento"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowFundsAndClubs}
                        tabSelectedRow={tabSelectedRowFundsAndClubs}
                        zIndex={1}
                    />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowFundsAndClubs} tabIndex={"1"}>
                    <FundsCustody
                        account={account}
                        id="funds-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowFundsAndClubs} tabIndex={"2"}>
                    <ClubsCustody
                        account={account}
                        id="clubsCustody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Política: Master (Compartilhada)"
                        tabIndex={"1"}
                        tabSelectedRow={"1"} />
                </Tabs>

                <TabContent tabSelected={"1"} tabIndex={"1"}>
                    <Policy
                        account={account}
                        id="policy"
                        handlePolicyChange={handlePolicyChange}
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        updatePolicyGrid={updatePolicyGrid}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>

            <section className="int-my-6">
                <Tabs>
                    <Tab
                        label="Ordens Ativas"
                        tabIndex={"1"}
                        setTabSelectedRow={setTabSelectedRowOrders}
                        tabSelectedRow={tabSelectedRowOrders}
                        zIndex={1} />

                    <Tab
                        label="Ordens Agendadas"
                        tabIndex={"2"}
                        setTabSelectedRow={setTabSelectedRowOrders}
                        tabSelectedRow={tabSelectedRowOrders}
                        zIndex={2} />

                    <Tab
                        label="Ordens Condicionais"
                        tabIndex={"3"}
                        setTabSelectedRow={setTabSelectedRowOrders}
                        tabSelectedRow={tabSelectedRowOrders}
                        zIndex={3} />
                </Tabs>

                <TabContent tabSelected={tabSelectedRowOrders} tabIndex={"1"}>
                    <OrdersHistory
                        account={account}
                        id="active-orders"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowOrders} tabIndex={"2"}>
                    <ScheduledOrdersAccount
                        account={account}
                        id="scheduled-orders"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>

                <TabContent tabSelected={tabSelectedRowOrders} tabIndex={"3"}>
                    <ConditionalOrdersAccount
                        account={account}
                        id="conditional-orders"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={true}
                    />
                </TabContent>
            </section>
        </div>
    )
}

export default TabsPage
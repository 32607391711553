import React, { useEffect, useCallback } from 'react';

import { Input, Select } from '../';

import { currency, integer } from '../../../common/inputMasks';
import { getPolicyKeyTypeByName } from '../../../common/search';

export function PolicyField({ 
    value, 
    label = "", 
    onChange, 
    keys, 
    keyName,
    isRequired = false, 
    ...rest 
}) {
    useEffect(() => {
        value = ''
    }, [value])

    let renderData;

    const handleKeyUp = useCallback((e, mask) => {
        if (mask === "currency" || mask === "decimal")
            currency(e);

        if (mask === "integer")
            integer(e);
    }, [])

    if (keys === "Currency" || getPolicyKeyTypeByName(keys, keyName) === "Currency") {
        renderData = (
            <div data-testid="currency-div" className="int-input-group">
                <span className="int-group-left-addon">R$</span>
                <Input
                    {...rest}
                    isRequired={isRequired}
                    name={rest.name}
                    value={value}
                    onChange={onChange}
                    onKeyUp={(e) => { handleKeyUp(e, 'decimal'); onChange(e); }}
                />
            </div>
        )
    }

    else if (getPolicyKeyTypeByName(keys, keyName) === "Decimal") {
        renderData = (
            <Input
                {...rest}
                isRequired={isRequired}
                name={rest.name}
                value={value}
                onChange={onChange}
                onKeyUp={(e) => { handleKeyUp(e, 'currency'); onChange(e); }}
            />
        )
    }

    else if (getPolicyKeyTypeByName(keys, keyName) === "Integer") {
        renderData = (
            <Input
                {...rest}
                isRequired={isRequired}
                name={rest.name}
                value={value}
                onChange={onChange}
                onKeyUp={(e) => { handleKeyUp(e, 'integer'); onChange(e); }}
            />
        )
    }

    else if (getPolicyKeyTypeByName(keys, keyName) === "Bool") {
        renderData = (
            <Select
                {...rest}
                isRequired={isRequired}
                name={rest.name}
                value={value}
                onChange={onChange}
            >
                <option hidden value="">Selecione um nome</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
            </Select>
        )
    }
    
    else {
        renderData = (
            <Input
                {...rest}
                readOnly
                name={rest.name}
                disabled={rest.disabled}
            />
        )
    }

    return (
        <div>
            {!!label && <label className="int-form-label">{label}</label>}
            {renderData}
        </div>
    );
};
import React, { ChangeEvent, useState } from 'react'

import { Button, Checkbox, TagsInput } from '../../../components/Forms'
import { Loader } from '../../../components/Loader'
import { SimpleModal } from '../../../components/Modal'
import { SmartTable } from '../../../components/Table'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from '../../../components/Alert'

import { removeArrayItem } from '../../../common/variables'

import { API_HEADER } from '../../../constants/api'

interface AdvisorsSectionProps {
    id: string;
    code: string;
    operatorAdvisors?: string[];
    fetchOperatorData: () => void;
}

export function AdvisorsSection({ id, code, operatorAdvisors = [], fetchOperatorData }: AdvisorsSectionProps) {
    const [inputValue, setInputValue] = useState('');
    const [advisorsArr, setAdvisorsArr] = useState<string[]>([])

    const [isUpdatingOperatorData, setIsUpdatingOperatorData] = useState(false)
    const [isShowingSuccessDeleteModal, setIsShowingSuccessDeleteModal] = useState(false)
    const [isShowingSuccessUpdateModal, setIsShowingSuccessUpdateModal] = useState(false)
    
    const [successUpdateOperatorDataMessage, setSuccessUpdateOperatorDataMessage] = useState('')
    const [error, setError] = useState<Error>()

    const allChecked = operatorAdvisors.every(x => advisorsArr.includes(x))
    const isIndeterminate = operatorAdvisors.some(i => advisorsArr.includes(i)) && !allChecked
    
    function onAdd(array: string[]) {
        const newArr = [...advisorsArr, ...array].filter((item, index, arr) => {
            return arr.indexOf(item) === index;
        })

        setAdvisorsArr(newArr)
    }

    function onChange({ target }: ChangeEvent<HTMLInputElement>) {
        let inputValue = target.value

        inputValue = inputValue.replace(/[^0-9,\s]/g, '')

        setInputValue(inputValue)
    }
    
    async function handleUpdateAdvisors() {
        setIsUpdatingOperatorData(true)
        setError(null)

        try {
            const data = {
                advisors: advisorsArr
            }

            const options = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(data)
            }

            const response = await fetch(`/api/enteringTrader/${code}/advisors`, options)
            
            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const message = await response.json()

            setSuccessUpdateOperatorDataMessage(message)
            setIsShowingSuccessUpdateModal(true)
        } catch (err) {
            setError(err)
        } finally {
            setIsUpdatingOperatorData(false)
        }
    }

    async function handleDeleteAdvisors() {
        setIsUpdatingOperatorData(true)
        setError(null)

        try {
            const data = {
                advisors: advisorsArr
            }

            const options = {
                method: "DELETE",
                headers: API_HEADER,
                body: JSON.stringify(data)
            }

            const response = await fetch(`/api/enteringTrader/${code}/advisors`, options)
            
            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }
            
            const message = await response.json()

            setSuccessUpdateOperatorDataMessage(message)
            setIsShowingSuccessDeleteModal(true)
        } catch (err) {
            setError(err)
        } finally {
            setIsUpdatingOperatorData(false)
        }
    }

    const checkBoxColumnHandle = ({ target }) => {
        if (target.checked) {
            setAdvisorsArr(advisorsArr.concat([target.value]))
        } else {
            setAdvisorsArr(advisorsArr.filter(i => i !== target.value))
        }  
    }
    
    const allCheckBoxesHandle = ({ target }) => {
        if (target.checked) {
            const items = operatorAdvisors.filter(x => !advisorsArr.includes(x));
            setAdvisorsArr(advisorsArr.concat(items))
        } else {
            setAdvisorsArr(advisorsArr.filter(i => !operatorAdvisors.includes(i)))
        }
    }

    const columns = [
        {
            id: 'Seleção',
            Header: () => 
                <Checkbox
                    data-testid="table-checkbox-header"
                    onChange={allCheckBoxesHandle} 
                    isChecked={allChecked} 
                />,
            Cell: ({ row }) => 
                <Checkbox 
                    data-testid="table-checkbox-body"
                    value={row.original} 
                    onChange={checkBoxColumnHandle} 
                    isChecked={advisorsArr.some(i => i === row.original)} 
                />,
            width: '40px',
            minWidth: 40
        },
        {
            Header: 'Código',
            Cell: ({ row }) => row.original
        }
    ]

    return(
        <>
            <SectionWrapper 
                id={id}
                headerTag="h3"
                header="Assessores"
                isAccordion
            >
                { operatorAdvisors.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum assessor foi encontrado
                    </p>
                ) : (
                    <SmartTable 
                        data={operatorAdvisors} 
                        columns={columns} 
                        localStorageKey={'OperatorAdvisorsSection'} 
                        columnsResizable
                    />
                )}

                { isUpdatingOperatorData && <Loader flex margin="int-mt-5" /> }

                <TagsInput
                    maxLength={25}
                    id="entering-trader"
                    label="Assessores"
                    placeholder="Assessores"
                    inputValue={inputValue}
                    tagsArr={advisorsArr}
                    onEnter={val => setInputValue(val)}
                    onChange={onChange}
                    onRemove={index => setAdvisorsArr(
                        removeArrayItem(index, advisorsArr)
                    )}
                    onAdd={onAdd}
                />

                <div className="int-d-flex int-justify-content-end int-mt-1">
                    <Button
                        disabled={advisorsArr.length <= 0 || isUpdatingOperatorData}
                        onClick={handleUpdateAdvisors}
                    >
                        Adicionar Assessores
                    </Button>

                    <Button
                        disabled={advisorsArr.length <= 0 || isUpdatingOperatorData}
                        variant="outline"
                        margin="int-ml-2"
                        onClick={handleDeleteAdvisors}
                    >
                        Remover Assessores
                    </Button>
                </div>

                { error?.message && (
                    <Alert
                        margin="int-mt-5"
                    >
                        {error.message}
                    </Alert>
                )}
            </SectionWrapper>
        
            {/* Modal: Sucesso Atualizar Assessores */}
            <SimpleModal 
                header="Os assessores foram adicionados"
                isOpen={isShowingSuccessUpdateModal}
                onClose={() => setIsShowingSuccessUpdateModal(false)}
                bodyMessage={successUpdateOperatorDataMessage}
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchOperatorData()
                        setIsShowingSuccessUpdateModal(false)
                    }
                }}
            />

            {/* Modal: Sucesso Remover Assessores */}
            <SimpleModal 
                header="Os assessores foram removidos"
                isOpen={isShowingSuccessDeleteModal}
                onClose={() => setIsShowingSuccessDeleteModal(false)}
                bodyMessage={successUpdateOperatorDataMessage}
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchOperatorData();
                        setIsShowingSuccessDeleteModal(false)
                    }
                }}
            />
        </>
    )
}
import jwt_decode from "jwt-decode";

export const TOKEN_KEY = "TOKEN_RISK";
export const TOKEN_USER_KEY = "USER_RISK";
export const AUTH_DISABLED = "AUTH_DISABLED";
export const REQUIRED_ADMIN = "REQUIRED_ADMIN";
export const ROUTE_ADMIN = 'ROUTE_ADMIN'
export const isDisabledAuthentication = () => JSON.parse(localStorage.getItem(AUTH_DISABLED));

export const isAuthenticated = () => isDisabledAuthentication() || localStorage.getItem(TOKEN_KEY) !== null;

export const isRequiredAcessAdmin = () => null || JSON.parse(localStorage.getItem(REQUIRED_ADMIN));

export const isRouteAdmin = () => JSON.parse(localStorage.getItem(ROUTE_ADMIN))

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getUser= () => localStorage.getItem(TOKEN_USER_KEY);

export const isAdmin = () => {
    try {
        const decodedJwt = jwt_decode<jwt_model>(getToken());

        return decodedJwt.role === "admin";
    } catch (e){
        // Geralmente vai cair aqui pelo token ser inválido ou null;
        return false;
    }
}

export const loginService = (token, user) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(TOKEN_USER_KEY, user);
};

export const logoutService = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_USER_KEY);
};

export const setDisabledAuthentication = (isDisabledAuthentication) => {
  localStorage.setItem(AUTH_DISABLED, isDisabledAuthentication);
};

export const setRequiredAdminAcess = (requiredAdmin) => {
    localStorage.setItem(REQUIRED_ADMIN, requiredAdmin);
};

export const setRouteAdmin = (isRouteAdmin) => {
    localStorage.setItem(ROUTE_ADMIN, isRouteAdmin)
}

export interface jwt_model {
    role: string
}
import React, { ReactNode } from 'react'

interface ModalFooterProps {
    children: ReactNode;
}

export function ModalFooter({ children, ...rest }: ModalFooterProps) {
    return(
        <div data-testid="modalfooter-div" className="int-modal-footer" {...rest}>
            {children}
        </div>
    )
}
import React, { useEffect, useState } from 'react'

import { H2, H4 } from '../../components/Typography'
import { Button, Input, Field } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import MultipleCheckboxFilter from '../../components/MultipleCheckboxFilter'
import { SmartTable } from '../../components/Table'
import { SelectColumnFilter, NumberRangeColumnFilter } from '../../components/Table/Filters'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'
import { FiSearch } from 'react-icons/fi'

import { objToQueryString, dateQueryParse } from '../../common/api.js';
import { formatInteger, formatDate } from '../../common/formatters';
import { sanitizeName } from '../../common/variables'
import { contractTypeBtcValues, contractTypeBtcEnum } from '../../common/enums.js';
import { removeKeyObject } from '../../common/variables.js'

import { useQueryParams } from '../../hooks/useQueryParams'
import { SimpleModal } from '../../components/Modal/SimpleModal'

type QueryObject = {
    accountStart?: string;
    accountEnd?: string;
    dtOpeningStart?: string;
    dtOpeningEnd?: string;
    dtDuoStart?: string;
    dtDuoEnd?: string;
    symbols?: string;
    contractNumbers?: string;
    type?: string;
}

export function BtcHistory() {
    const { setParam, updateQuery } = useQueryParams()
    const [queryObject, setQueryObject] = useState<QueryObject>();

    const [initialClient, setInitialClient] = useState(setParam("accountStart")); 
    const [finalClient, setFinalClient] = useState(setParam("accountEnd"));
    const [initialOpeningDate, setInitialOpeningDate] = useState(setParam("dtOpeningStart")); 
    const [finalOpeningDate, setFinalOpeningDate] = useState(setParam("dtOpeningEnd"));
    const [initialDuoDate, setInitialDuoDate] = useState(setParam("dtDuoStart"));
    const [finalDuoDate, setFinalDuoDate] = useState(setParam("dtDuoEnd"));
    const [contractNumbers, setContractNumbers] = useState(setParam("contractNumbers"));
    const [symbols, setSymbols] = useState(setParam("symbols")); 
    const [type, setType] = useState(setParam("type"));

    const [showFilterModal, setShowFilterModal] = useState(true);
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [results, setResults] = useState<[]>()
    
    const [searched, setSearched] = useState(false)
    
    const today = new Date().toISOString().substr(0, 10);

    useEffect(() => {
        if (queryObject) {
        (async function () {
            await fetchOrderData()
            setLoading(false)
        })();
        }
    }, [queryObject])

    const columns = [
        {
            Header: 'Conta',
            accessor: 'account',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            width:150
        },
        {
            Header: 'Tipo',
            accessor: ({ type }) => contractTypeBtcEnum(type),
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Ativo',
            accessor: 'symbol',
            Cell: ({ value }) => value.toUpperCase(),
            width:150
        },
        {
            Header: 'Contrato',
            accessor: 'numContract',
            width:300
        },
        {
            Header: 'Registro',
            accessor: ({ originDate }) => formatDate(originDate)
        },
        {
            Header: 'Vencimento',
            accessor: ({ dueDate }) => formatDate(dueDate)
        },
        {
            Header: 'Data Registro',
            accessor: ({ openingDate }) => formatDate(openingDate)
        },
        {
            Header: 'Liq. Antecip',
            accessor: ({ indOpcEarlyReturn }) => indOpcEarlyReturn ? "Sim": "Não",
            Filter: SelectColumnFilter,
            filter: 'includes',
            width:100
        },
        {
            Header: 'Quantidade',
            accessor: 'quantity',
            Cell: ({ value }) => formatInteger(value),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        }
    ]

    const fetchOrderData = async () => {
        const apiQueryString = objToQueryString({
            ...queryObject,
            dtOpeningStart: dateQueryParse(queryObject?.dtOpeningStart),
            dtOpeningEnd: dateQueryParse(queryObject?.dtOpeningEnd),
            dtDuoStart: dateQueryParse(queryObject?.dtDuoStart),
            dtDuoEnd: dateQueryParse(queryObject?.dtDuoEnd)
        });
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {
            setShowFilterModal(false)
            setFiltering(false);
            const response = await fetch(`/api/btc?${apiQueryString}`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()

            setResults(data)
        } catch(err) {
            setError(true)
        }
    }

    const cleanFilters = () => {
        setInitialClient("");
        setFinalClient("");
        setInitialOpeningDate("");
        setFinalOpeningDate("");
        setInitialDuoDate("");
        setFinalDuoDate("");
        setContractNumbers("");
        setSymbols("");
        setType("");

        updateQuery(" ")
    }

    const setQueryObjectFilters = () => {
        let requestSymbols = symbols

        if (symbols) requestSymbols = requestSymbols.replace(/\s/g, "")

        const queryStringFilters = {
            accountStart: initialClient,
            accountEnd: finalClient,
            dtOpeningStart: initialOpeningDate,
            dtOpeningEnd: finalOpeningDate,
            dtDuoStart: initialDuoDate,
            dtDuoEnd: finalDuoDate,
            symbols: requestSymbols,
            contractNumbers: contractNumbers?.replace(/\s/g, ''),
            type: type?.replace(/\s/g, '')
        };
        
        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();

        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const handleBTCSearch = async event => {
        event.preventDefault()
        setLoading(true)
        setError(false)

        if (!searched)
        setSearched(true)

        setQueryObjectFilters()
    }

    const setFinalOpeningDateOnBlur = () => {
        if (finalOpeningDate === '' || finalOpeningDate === undefined) {
            const date = initialOpeningDate.substr(0, 10) + "T" + "23:59";
            setFinalOpeningDate(date)
        }
    }

    const setFinalDuoDateOnBlur = () => {
        if (finalDuoDate === '' || finalDuoDate === undefined) {
            const date = initialDuoDate.substr(0, 10) + "T" + "23:59";
            setFinalDuoDate(date)
        }
    }
    const renderFilterElements = () => {
        return(
        <form onSubmit={e => handleBTCSearch(e)}>
            <div className="int-mt-4 int-row">
                <div className="int-col">
                    <Input
                        data-testid="btcHistory-contracts"
                        name="contractNumbers"
                        label="Contratos (Escreva múltiplos contratos separadas por vírgula)"
                        value={contractNumbers}
                        onChange={(e) => setContractNumbers(e?.target?.value)}
                    />
                </div>

                <div className="int-col int-pl-0">
                    <Input
                        data-testid="btcHistory-symbols"
                        name="symbols"
                        label="Ativos (Escreva múltiplos ativos separados por vírgula)"
                        type="text"
                        value={symbols}
                        onChange={(e) => setSymbols(sanitizeName(e?.target?.value, true))}
                    />
                </div>
            </div>
            <div className="int-mt-4 int-row">
                <div className="int-col">
                    <H4>Data de Registro</H4>
                    <div className="int-row">
                        <div className="int-col">
                            <DateTime
                                label="De"
                                name="initialOpeningDate"
                                value={initialOpeningDate}
                                setDateTime={setInitialOpeningDate}
                                maxDate={today}
                                onBlurDate={setFinalOpeningDateOnBlur}
                                timeDefault="00:00"
                                notShowTimeInput
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <DateTime
                                label="Para"
                                name="finalOpeningDate"
                                value={finalOpeningDate}
                                setDateTime={setFinalOpeningDate}
                                minDate={initialOpeningDate ? initialOpeningDate.substr(0, 10) : ''}
                                maxDate={today}
                                timeDefault="23:59"
                                notShowTimeInput
                            />
                        </div>
                    </div>
                </div>

                <div className="int-col">
                    <H4>Data de Vencimento</H4>
                    <div className="int-row">
                        <div className="int-col">
                            <DateTime
                                label="De"
                                name="initialDuoDate"
                                value={initialDuoDate}
                                setDateTime={setInitialDuoDate}
                                maxDate={new Date("9999-12-31").toISOString().substr(0, 10)}
                                onBlurDate={setFinalDuoDateOnBlur}
                                timeDefault="00:00"
                                notShowTimeInput
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <DateTime
                                label="Para"
                                name="finalDuoDate"
                                value={finalDuoDate}
                                setDateTime={setFinalDuoDate}
                                minDate={initialDuoDate ? initialDuoDate.substr(0, 10) : ''}
                                maxDate={new Date("9999-12-31").toISOString().substr(0, 10)}
                                timeDefault="23:59"
                                notShowTimeInput
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="int-mt-4 int-row">
                <div className="int-col">
                    <H4>Conta do cliente</H4>
                    <div className="int-row">
                        <div className="int-col">
                            <Field
                                data-testid="btcHistory-intialClient"
                                label="De"
                                format="Number"
                                value={initialClient || ''}
                                onChange={(e) => setInitialClient(e?.target?.value.trimStart())}
                            />
                        </div>

                        <div className="int-col int-pl-0">
                            <Field
                                data-testid="btcHistory-finalClient"
                                label="Para"
                                format="Number"
                                value={finalClient || ''}
                                onChange={(e) => setFinalClient(e?.target?.value.trimStart())}
                            />
                        </div>
                    </div>
                </div>
                <div className="int-col-4">
                    <H4>Tipo de Contrato</H4>
                    <MultipleCheckboxFilter 
                        value={removeKeyObject(contractTypeBtcValues, ['Invalid'])} 
                        parentQueryState={type} 
                        setParentQueryState={s => setType(s)} 
                    />
                </div>
            </div>
            {filtering && ( 
                <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
            )}
        </form>
        )                 
    }
    return(
        <SectionWrapper 
        header="Histórico de Contratos BTC" 
        searchButton={
            <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
        }>
            <SimpleModal 
                header="Filtros de busca"
                isOpen={showFilterModal}
                onClose={() => setShowFilterModal(false)}
                bodyMessage={renderFilterElements()}
                footerButtons={[
                    {
                        label: 'Limpar Todos os filtros',
                        onClick:() => cleanFilters(),
                        variant: 'outline'
                    },   
                    {
                        label: 'Buscar',
                        onClick: (e) => {
                            handleBTCSearch(e)
                        },
                        margin: 'int-ml-2'
                    }
                ]}
            />
            {loading && <Loader flex/>}
            
            {error && ( 
            <Alert>Ocorreu um erro na busca</Alert>
            )}

            { loading || error || filtering ? '' : results && results.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : results && (
                <SmartTable 
                columnFilters 
                columns={columns} 
                data={results} 
                gapColumnsFilter={2}
                lockHeader
                columnsResizable  
                localStorageKey={'BtcHistorySearch'} />              
            )}
        </SectionWrapper>      
    )
}

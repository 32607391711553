import React, { useEffect, useState } from 'react'

import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'

export function Configurations() {
    const [configItems, setConfigItems] = useState([]);
    
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(false)

    const fetchData = async () => {
        setIsFetching(true)
        setError(false)

        try {
            const response = await fetch(`/api/Settings`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()
            setConfigItems(Object.entries(data)) 
        }
        catch(err) {
            setError(true)
        } finally {
            setIsFetching(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <SectionWrapper header="Configurações">
            {error ? (
                <Alert margin="int-mt-5">
                    Erro ao coletar informações do ambiente
                </Alert>
            ) : (
                <table className="int-table">
                    <thead>
                        <tr>
                            <th>Chave</th>
                            <th>Valor</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {configItems.map(([key, data]) =>(
                            <>
                                <tr>
                                    <td colSpan={2} className="int-table-divider">{key}</td>
                                </tr>
                                {data.map((item) => (
                                    <tr key={item.key+item.value}>
                                        <td>{item.key}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))}
                            </>                            
                        ))}
                    </tbody>
                </table>
            )} 
        </SectionWrapper>
    );
};

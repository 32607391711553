import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Balance } from './Balance'
import { Custody } from './Custody'
import { BmfCustody } from './BmfCustody'
import { GoldCustody } from './GoldCustody'
import { Policy } from './Policy'
import { BmfPositionD0 } from './BmfPositionD0'
import { BovespaPositionD0 } from './BovespaPositionD0'
import { OrdersHistory } from './OrdersHistory'
import { PatrimonyLimits } from './PatrimonyLimits'
import { FixedIncomeCustody } from './FixedIncomeCustody'
import { BtcCustodyTaker } from "./BtcCustodyTaker"
import { BtcCustodyDonor } from "./BtcCustodyDonor"
import { TesouroDiretoCustody } from './TesouroDiretoCustody'
import { FundsCustody } from './FundsCustody'
import { TermCustody } from "./TermCustody"
import { FutOpc } from "./FutOpc"
import { CustodyUnknownMarket } from "./CustodyUnknownMarket"
import { Guarantee } from "./Guarantee"
import { Margin } from "./Margin"

import { H2 } from "../../../components/Typography";
import { Link } from "../../../components/Link";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Button, ButtonGroup } from "../../../components/Forms";

import { formatCpfCnpj, formatBoolean } from "../../../common/formatters";
import { BodyElementsToClipboard } from "../../../components/Table/UtilsHeader";
import { FiClipboard } from "react-icons/fi";
import { IpoReserve } from "./IpoReserve";
import { ClubsCustody } from "./ClubsCustody";

import { Loader } from "../../../components/Loader";
import { ToastNotification } from "../../../components/ToastNotification";

import {isAdmin} from "../../../services/auth";
import { FlexOpcCustody } from "./FlexOpcCustody";
import { Modal, ModalBody, ModalFooter } from "../../../components/Modal";
import TabsPage from "./TabsPage";
import { LimitsByCategory } from "./LimitsByCategory";
import { Strategies } from "./Strategies";

type Params = {
    account: string;
};

type ClientRegister = {
    account: number;
    document: string;
    name: string;
    policyId: number;
    status: string;
    advisorName: string;
    advisor: string;
    qualified: boolean;
};

type ClientPolicy = {
    id: number;
    name: string;
    parameters: {
        name: string;
        value: boolean;
    }[];
    tradeOnlyBalance: boolean;
};

type ClientNegotiationStatus = {
    isBlocked: boolean;
}

type AllAccordions = {
    isAllAccordionOpen: boolean;
    count: number;
};

export function ClientDetails() {
    let { account } = useParams<Params>();
    const [clientLimit, setClientLimit] = useState(null);

    const [policy, setPolicy] = useState<ClientPolicy>();
    const [register, setRegister] = useState<ClientRegister>();
    const [negotiationStatus, setNegotiationStatus] = useState<ClientNegotiationStatus>();
    const [allAccordionsOpen, setAllAccordionOpen] = useState<AllAccordions>({isAllAccordionOpen: false, count: 0});

    const [count, setCount] = useState(0);
    const [copySuccess, setCopySuccess] = useState<string | null>('');
    
    const [updateAllGrids, setUpdateAllGrids] = useState(false);
    const [toastElements, setToastElements] = useState<any | JSX.Element>('');
    const [isToast, setIsToast] = useState(false);

    const [updatePolicyGrid, setUpdatePolicyGrid] = useState(false);
    const [showChangePolicyModal, setShowChangePolicyModal] = useState(false);

    const [tabsPage, setTabsPage] = useState("");

    const sleep = (milli) => {
        return new Promise((res) => setTimeout(res, milli));
    };

    async function fetchLimits() {
        try {
            const response = await fetch(`/api/client/${account}/limits`)

            if (!response.ok) {
                throw new Error("Ocorreu um erro ao buscar dados de limites do cliente")
            }

            const data = await response.json()
            setClientLimit(data)

        } catch (err) {
            console.log(err)
        }
    };

    useEffect(() => {
        fetchLimits()
    }, [account])

    async function handleCopyTableToClipboard() {
        let data = "";
        var registers =
        "Conta:\t"+ account +"\n"               +
        "Nome:\t"+ register?.name +"\n"         +
        "Política:\t"+ policy?.name +"\n"       +
        "Status de negociação:\t" + (negotiationStatus?.isBlocked ? "Bloqueado" : "Desbloqueado") + "\n" +
        "CPF/CNPJ:\t"+ register?.document +"\n\n\n";

        var elements = document.getElementsByClassName("int-accordion-item");
        if (elements !== null) {
            for (var i = 0; i < elements.length; i++) {
                var itemId = elements[i]?.getAttribute("id");
                if (!itemId?.includes("copyToClipboard")) {
                    continue;
                }
                const elTableDataRows = elements[i].querySelectorAll("h3, tr");
                let rowsData = BodyElementsToClipboard(elTableDataRows);

                let dataClipboard = rowsData.join("\n");
                data += dataClipboard.concat("\n\n\n");
            }
            try {
                await navigator.clipboard.writeText(registers + data);
                setCopySuccess("Copiado!");
            } catch (err) {
                setCopySuccess("Não foi possível copiar o conteúdo.");
            }
        } else {
            setCopySuccess("Não foi possível copiar o conteúdo.");
        }
        setTimeout(() => setCopySuccess(null), 1200);
    };
    
    async function fetchNegotiationStatus() 
    {
        const response = await fetch(`/api/client/${account}/TradingStatus`);
        
        if (!response.ok)
        {
            const error = await response.json();
            throw new Error(error?.message);
        }
        
        return await response.json();
    }
    
    async function postNegotiationStatus(isBlocked: boolean)
    {
        const data = 
        {
            isBlocked
        }
        const requestOptions = 
        {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json' },
            body: JSON.stringify(data)
        }
        
        const response = await fetch(`/api/client/${account}/SetTradingStatus`, requestOptions);
        
        if (!response.ok)
        {
            const error = await response.json();
            throw new Error(error?.message);
        }
    }

    async function fetchRegister() {
        const response = await fetch(`/api/client/${account}/register`);

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error?.message);
        }

        const data = await response.json();

        const register = {
            ...data,
            document: formatCpfCnpj(data.document),
        };

        return register;
    }

    async function fetchClientPolicyId() {
        const response = await fetch(`/api/client/${account}/policy`);

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error?.message);
        }

        const data = await response.json();
        return data.id;
    }

    async function fetchPolicy(id) {
        const response = await fetch(`/api/policy/${id}`);

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error?.message);
        }

        const data = await response.json();
        return data;
    }

    async function handlePolicyChange() {
        try {
            const policyId = await fetchClientPolicyId();
            const policy = await fetchPolicy(policyId);

            setPolicy(policy);
        } catch (error) {
            console.log(error);
        }
    }
    
    async function updateClientData(){
        try {
            setRegister(null)
            setUpdatePolicyGrid(true);
            const [policyId] = await Promise.all([
                fetchClientPolicyId(),
            ])

            const policy = await fetchPolicy(policyId)
            setPolicy(policy)
            setUpdatePolicyGrid(false);

            const [register, negotiationStatus] = await Promise.all([
                fetchRegister(),
                fetchNegotiationStatus()
            ])

            setRegister(register)
            setNegotiationStatus(negotiationStatus);

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        (async () => {
            await updateClientData();
        })()
    }, [account]);

   async function loadGrids() {
        var count = 0;
        do {
            count++;
            var response = [];
            var grids = [];
            var gridsValues = [];

            var elements = document.getElementsByClassName("int-accordion-item");

            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                grids.push(element.querySelectorAll("h3")[0].textContent);
                gridsValues.push(element?.id?.includes("loading"));
            }

            for (var i = 0; i < grids.length; i++) {
                response.push(
                    <tr
                        style={{
                            marginTop: "10px",
                            fontWeight: 600,
                            fontSize: "16px",
                        }}
                    >
                        <td style={{ padding: "2px", paddingRight: "20px" }}>
                            {grids[i]}
                        </td>
                        <td>{gridsValues[i] || count <= 1 ? <Loader size="sm" flex /> : "✅"}</td>
                    </tr>
                );
            }
            setToastElements(<table><tbody>{response}</tbody></table>);
            await sleep(1500);
        } while (gridsValues.includes(true) || count <= 1 );
    }

    async function handleLoadingItems() {
        try {
            await updateClientData();
            setIsToast(true);
            await loadGrids();
        
            await sleep(1200);
            setIsToast(false);
            setUpdateAllGrids(false);

        } catch (error) {
            console.log(error);
        }
    }
    const handleChangeClientPolicyToDefault = async () => {

        try {
            const bodyRequest = {
                policyId: 1,
                accounts: [account]
            }

            const options = {
                method: "PUT",
                headers: { 'Content-Type' : 'application/json' },
                body: JSON.stringify(bodyRequest),
            }

            const response = await fetch(`/api/policy/updatePolicyClient`, options)

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }
            setUpdatePolicyGrid(true)
        } catch (err) {
            console.log(err)
        }
    }

    const setPageDefault = (value) => {
        window.localStorage.setItem('defaultPage', value);
    }

    useEffect(() => {
        let defaultPage = window.localStorage.getItem('defaultPage');
        
        if(defaultPage === "oldPage") {
            setTabsPage("oldPage");
        } 
        if(defaultPage === "newPage" || defaultPage === null) {
            setTabsPage("newPage");
        }
    }, []);

    return (
        <div
            data-testid="client-details"
            style={{ maxWidth: "1360px", margin: "0 auto" }}
        >
            <div>
                {isToast && (
                    <ToastNotification
                        children={toastElements}
                        onClose={() => setIsToast(false)}
                    />
                )}
            </div>
            <SectionWrapper 
                header="Detalhes do Cliente" 
                buttonGroup={
                    <div className="int-d-flex int-align-items-center int-justify-content-end">
                        <p>Página de Tabs</p>  
                        <ButtonGroup margin="int-ml-4">
                            <Button
                                variant={tabsPage === "oldPage" ? "outline" : "solid"}
                                onClick={() => {
                                    setTabsPage("newPage");
                                    setPageDefault("newPage");
                                }}
                            >
                                ON
                            </Button>

                            <Button
                                variant={tabsPage === "newPage" ? "outline" : "solid"}
                                onClick={() => {
                                    setTabsPage("oldPage");
                                    setPageDefault("oldPage");
                                }}
                            >
                                OFF
                            </Button>
                        </ButtonGroup>
                    </div>
                }>

                <div className="int-d-flex int-justify-content-between int-mt-4">
                    <div>
                        <H2>{register?.name || "Nome: ------"}</H2>

                        <div className="int-d-flex int-align-items-center">
                            <p>Conta: {account}</p>
                            <p className="int-ml-3">
                                CPF/CNPJ: {register?.document}
                            </p>
                            <p className="int-ml-3">
                                Qualificado:{" "}
                                {register?.qualified === undefined
                                    ? "------"
                                    : formatBoolean(register?.qualified)}
                            </p>
                            <p className="int-ml-3">
                                Assessor: {register?.advisorName ?? "------"} -{" "}
                                {register?.advisor ?? "------"}
                            </p>
                            <p className="int-ml-3">
                                Status: {register?.status || "------"}
                            </p>
                            {/* <p className="int-ml-3">Negociação: {negotiationStatus?.isBlocked === undefined ? "------" : negotiationStatus.isBlocked ? "Bloqueado" : "Desbloqueado"}</p> */}
                        </div>
                    </div>

                    <div>
                        <H2>
                            Política:{" "}
                            {policy ? (
                                isAdmin() ? (
                                <Link href={`/policies/${policy.id}`}>
                                    {policy.name}
                                </Link> ) : ( <>{policy.name}</> )  
                            ) : (
                                "------"
                            )}
                        </H2>
                    </div>
                </div>

                <div className="int-d-flex int-justify-content-end int-mt-2">
                    {copySuccess && <p className="int-my-auto int-mr-3">{copySuccess}</p>}
                    <Button
                        margin="int-ml-2"
                        variant="outline"
                        onClick={() => setShowChangePolicyModal(true)}
                        disabled={!isAdmin() || policy?.name == "Master"}
                    >
                        Mover para Política Master
                    </Button>
                    

                    <Button
                        margin="int-ml-2"
                        variant="outline"
                        onClick=
                            {
                                async () =>
                                {
                                    if (negotiationStatus === undefined)
                                        return;
    
                                    await postNegotiationStatus(!negotiationStatus.isBlocked);
                                    await updateClientData();
                                }
                            }
                    >
                        {negotiationStatus === undefined ? "------" : negotiationStatus.isBlocked ? "Desbloquear negociação" : "Bloquear negociação"}
                    </Button>

                    <Button
                        margin="int-ml-2"
                        variant="outline"
                        onClick={handleCopyTableToClipboard}
                    >
                        Copiar detalhes
                        {
                            <FiClipboard
                                size={22}
                                color="var(--int-colors-orange-400)"
                                style={{ cursor: "pointer", marginLeft: "6px" }}
                                data-testid="clipboard-button"
                            />
                        }
                    </Button>

                    <Button
                        margin="int-ml-2"
                        variant="outline"
                        onClick={() => {
                            setUpdateAllGrids(true);
                            handleLoadingItems()
                        }}
                        disabled={isToast}
                    >
                        Atualizar Página
                    </Button>

                    <Button
                        margin="int-ml-2"
                        variant="outline"
                        disabled={tabsPage === "newPage"}
                        onClick={() => {
                            setAllAccordionOpen({
                                isAllAccordionOpen: true,
                                count,
                            });
                            setCount(count + 1);
                        }}
                    >
                        Expandir Tudo
                    </Button>

                    <Button
                        margin="int-ml-2"
                        variant="outline"
                        disabled={tabsPage === "newPage"}
                        onClick={() => {
                            setAllAccordionOpen({
                                isAllAccordionOpen: false,
                                count,
                            });
                            setCount(0);
                        }}
                    >
                        Recolher Tudo
                    </Button>
                </div>
            </SectionWrapper>


            {tabsPage === "newPage" ? (
                <TabsPage
                    account={account}
                    clientLimit={clientLimit}
                    allAccordionsOpen={allAccordionsOpen}
                    updateAllGrids={updateAllGrids}
                    updatePolicyGrid={updatePolicyGrid}
                    handlePolicyChange={handlePolicyChange}
                />
            ) : null }

            
            {tabsPage === "oldPage" ? (
                <>
                    <Balance
                        account={account}
                        id="balance-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <PatrimonyLimits
                        account={account}
                        clientLimit={clientLimit}
                        id="patrimonyLimits-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}
                    />

                    <LimitsByCategory
                        account={account}
                        clientLimit={clientLimit}
                        id="limitsByCategory-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}
                    />

                    <Strategies
                        account={account}
                        id="financing-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <Custody
                        account={account}
                        id="custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <BovespaPositionD0
                        account={account}
                        id="bovespa-position-d0-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <BmfCustody
                        account={account}
                        id="bmf-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <GoldCustody
                        account={account}
                        id="gold-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false} 
                    />
                    
                    <BmfPositionD0
                        account={account}
                        id="bmf-position-d0-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <FutOpc
                        account={account}
                        id="futOpc-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}           
                    />

                    <FlexOpcCustody
                        account={account}
                        id="flexOpc-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}           
                    />

                    <TermCustody
                        account={account}
                        id="term-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <BtcCustodyTaker
                        account={account}
                        id="btc-custody-tomador-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <BtcCustodyDonor
                        account={account}
                        id="btc-custody-doador-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <Guarantee
                        account={account}
                        id="guarantee-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <Margin
                        account={account}
                        id="margin-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <FixedIncomeCustody
                        account={account}
                        id="fixed-income-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <TesouroDiretoCustody
                        account={account}
                        id="tesouro-direto-custody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <FundsCustody
                        account={account}
                        id="funds-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <ClubsCustody
                        account={account}
                        id="clubsCustody-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <CustodyUnknownMarket
                        account={account}
                        id="custody-unknown-market"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}           
                    />

                    <IpoReserve
                        account={account}
                        id="ipoReserve-copyToClipboard"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />

                    <Policy
                        account={account}
                        id="policy"
                        handlePolicyChange={handlePolicyChange}
                        allAccordionsOpen={allAccordionsOpen}    
                        updateAllGrids={updateAllGrids}                   
                        updatePolicyGrid={updatePolicyGrid}
                        autoOpenAccordion={false}                   
                    />

                    <OrdersHistory
                        account={account}
                        id="active-orders"
                        allAccordionsOpen={allAccordionsOpen}
                        updateAllGrids={updateAllGrids}
                        autoOpenAccordion={false}            
                    />
                </>
            ) : null}


            <Modal
                header="Mover o cliente para política Master"
                isOpen={showChangePolicyModal}
                onClose={() => setShowChangePolicyModal(false)}
            >
                <ModalBody>
                    <p>
                        Você tem certeza que deseja Mover o cliente {account} para a política Master?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button                    
                        variant="outline"
                        data-testid="details-change-policy-cancel"
                        onClick={() => setShowChangePolicyModal(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        margin="int-ml-2"
                        data-testid="details-change-policy-submit"
                        onClick={async () => {
                            setShowChangePolicyModal(false)
                            await handleChangeClientPolicyToDefault();
                            await updateClientData();
                        }}
                    >
                        Alterar
                    </Button>

                </ModalFooter>
            </Modal>
        </div>
    );
}

import React, { useEffect, useState } from 'react'
import CSS from 'csstype';

import './styles.css'

interface MultipleCheckboxFilterProps {
    value: string[] | { [key: string] : string },
    parentQueryState?: string,
    setParentQueryState?: (value: string) => void,
    columns?: number,
    style?: CSS.Properties,
    label?: string
}

const MultipleCheckboxFilter = ({ 
  label,
  value, 
  parentQueryState,
  setParentQueryState,
  columns = 2,
  style = {}
}: MultipleCheckboxFilterProps) => {
  const [checkboxItems, setCheckboxItems] = useState([])
  const [activeItems, setActiveItems] = useState([])

  useEffect(() => {
      const parsedObject = Object.entries(value)
      const parsedValue = parsedObject.map((e, i) => ({id: i, value: e[0]}))

      setCheckboxItems(parsedValue)
  }, [value])

  useEffect(() => {
    if (parentQueryState) {
      const parentValuesArr = parentQueryState.split(',')

      setActiveItems(parentValuesArr)
    }
  }, [])

  useEffect(() => {
    if (parentQueryState === '') {
      setActiveItems([])
    }
  }, [parentQueryState])

  useEffect(() => {
    const query = activeItems.join(', ')

    setParentQueryState(query)
  }, [activeItems])

  useEffect(() => {
    if (parentQueryState === undefined)
      setActiveItems([])
  }, [parentQueryState])

  const onFilterChange = (item) => {
    if (activeItems.includes(item.value)) {
      const filterIndex = activeItems.indexOf(item.value)
      const newFilterArr = [...activeItems]
    
      newFilterArr.splice(filterIndex, 1)

      setActiveItems(newFilterArr)
    } else { setActiveItems([...activeItems, item.value]) }
  }

  return (
    <>      
    {label && <label className="int-form-label">{label}</label>}
    <div 
        className="multiple-check-container" 
        style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, ...style}}
    >
      { checkboxItems?.map(item => (    
        <div 
          className={activeItems.includes(item.value) ? 'active' : ''}
          key={item.id}
          onClick={() => onFilterChange(item)} 
        >
          { value[item.value] }
        </div>
      ))}
    </div>
    </>    
  )
}

export default MultipleCheckboxFilter

import React, { ChangeEvent, useState } from 'react'

import { Button, Checkbox, TagsInput } from '../../../components/Forms'
import { SimpleModal } from '../../../components/Modal'
import { Loader } from '../../../components/Loader'
import { SmartTable } from '../../../components/Table'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from '../../../components/Alert'

import { removeArrayItem } from '../../../common/variables'
import { API_HEADER } from '../../../constants/api'

interface ClientsSectionProps {
    id: string;
    code: string;
    operatorClients?: string[];
    fetchOperatorData: () => void;
}

export function ClientsSection({ id, code, operatorClients = [], fetchOperatorData }: ClientsSectionProps) {
    const [inputValue, setInputValue] = useState('');
    const [clientsArr, setClientsArr] = useState([])

    const [isUpdatingOperatorData, setIsUpdatingOperatorData] = useState(false)
    const [isShowingSuccessDeleteModal, setIsShowingSuccessDeleteModal] = useState(false)
    const [isShowingSuccessUpdateModal, setIsShowingSuccessUpdateModal] = useState(false)
    
    const [successUpdateOperatorDataMessage, setSuccessUpdateOperatorDataMessage] = useState('')
    const [error, setError] = useState<Error>()

    const allChecked = operatorClients.every(x => clientsArr.includes(x))
    const isIndeterminate = operatorClients.some(i => clientsArr.includes(i)) && !allChecked
    
    function onAdd(array: string[]) {
        const newArr = [...clientsArr, ...array].filter((item, index, arr) => {
            return arr.indexOf(item) === index;
        })

        setClientsArr(newArr)
    }

    function onChange({ target }: ChangeEvent<HTMLInputElement>) {
        let inputValue = target.value

        inputValue = inputValue.replace(/[^0-9,\s]/g, '')

        setInputValue(inputValue)
    }
    
    async function handleUpdateClients() {
        setIsUpdatingOperatorData(true)
        setError(null)

        try {
            const data = {
                clients: clientsArr
            }

            const options = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(data)
            }

            const response = await fetch(`/api/enteringTrader/${code}/clients`, options)
            
            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const message = await response.json()

            setSuccessUpdateOperatorDataMessage(message)
            setIsShowingSuccessUpdateModal(true)
        } catch (err) {
            setError(err)
        } finally {
            setIsUpdatingOperatorData(false)
        }
    }
    
    async function handleDeleteClients() {
        setIsUpdatingOperatorData(true)
        setError(null)

        try {
            const data = {
                clients: clientsArr
            }

            const options = {
                method: "DELETE",
                headers: API_HEADER,
                body: JSON.stringify(data)
            }

            const response = await fetch(`/api/enteringTrader/${code}/clients`, options)
            
            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }
            
            const message = await response.json()

            setSuccessUpdateOperatorDataMessage(message)
            setIsShowingSuccessDeleteModal(true)
        } catch (err) {
            setError(err)
        } finally {
            setIsUpdatingOperatorData(false)
        }
    }

    const checkBoxColumnHandle = ({ target }) => {
        if (target.checked) {
            setClientsArr(clientsArr.concat([target.value]))
        } else {
            setClientsArr(clientsArr.filter(i => i !== target.value))
        }  
    }
    
    const allCheckBoxesHandle = ({ target }) => {
        if (target.checked) {
            const items = operatorClients.filter(x => !clientsArr.includes(x));
            setClientsArr(clientsArr.concat(items))
        } else {
            setClientsArr(clientsArr.filter(i => !operatorClients.includes(i)))
        }
    }

    const columns = [
        {
            id: 'Seleção',
            Header: () => 
                <Checkbox 
                    data-testid="table-checkbox-header"
                    onChange={allCheckBoxesHandle} 
                    isChecked={allChecked} 
                />,
            Cell: ({ row }) => 
                <Checkbox 
                    data-testid="table-checkbox-body"
                    value={row.original} 
                    onChange={checkBoxColumnHandle} 
                    isChecked={clientsArr.some(i => i === row.original)} 
                />,
            width: '40px',
            minWidth: 40
        },
        {
            Header: 'Conta',
            Cell:  ({ row }) => row.original
        }
    ]

    return(
        <>
            <SectionWrapper
                id={id}
                headerTag="h3"
                header="Clientes"
                isAccordion
            >
                { operatorClients.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum cliente foi encontrado
                    </p>
                ) : (
                    <SmartTable 
                        data={operatorClients} 
                        columns={columns} 
                        localStorageKey={'OperatorClientsSection'} 
                        columnsResizable
                    />
                )}

                { isUpdatingOperatorData && <Loader flex margin="int-mt-5" /> }

                <TagsInput 
                    maxLength={25}
                    id="clients"
                    label="Clientes"
                    placeholder="Clientes"
                    inputValue={inputValue}
                    tagsArr={clientsArr}
                    onEnter={val => setInputValue(val)}
                    onChange={onChange}
                    onRemove={index => setClientsArr(
                        removeArrayItem(index, clientsArr)
                    )}
                    onAdd={onAdd}
                />

                <div className="int-d-flex int-justify-content-end int-mt-1">
                    <Button
                        disabled={clientsArr.length <= 0 || isUpdatingOperatorData}
                        onClick={handleUpdateClients}
                    >
                        Adicionar Clientes
                    </Button>

                    <Button
                        disabled={clientsArr.length <= 0 || isUpdatingOperatorData}
                        variant="outline"
                        margin="int-ml-2"
                        onClick={handleDeleteClients}
                    >
                        Remover Clientes
                    </Button>
                </div>

                { error?.message && (
                    <Alert
                        margin="int-mt-5"
                    >
                        {error.message}
                    </Alert>
                )}
            </SectionWrapper >
            
            {/*Modal: Sucesso Atualizar Clientes */}
            <SimpleModal 
                header="Os clientes foram adicionados"
                isOpen={isShowingSuccessUpdateModal}
                onClose={() => setIsShowingSuccessUpdateModal(false)}
                bodyMessage={successUpdateOperatorDataMessage}
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchOperatorData()
                        setIsShowingSuccessUpdateModal(false)
                    }
                }}
            />

            {/* Modal: Sucesso Remover Clientes */}
            <SimpleModal 
                header="Os clientes foram removidos"
                isOpen={isShowingSuccessDeleteModal}
                onClose={() => setIsShowingSuccessDeleteModal(false)}
                bodyMessage={successUpdateOperatorDataMessage}
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchOperatorData()
                        setIsShowingSuccessDeleteModal(false)
                    }
                }}
            />
        </>
    )
}
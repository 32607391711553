import React from 'react'

import { NavLink } from '../NavLink'

interface DropdownItemProps {
    children: string;
    href: string;
    blank?: boolean;
    shouldMatchExactHref?: boolean
}

export function DropdownItem({ children, href, blank = false, shouldMatchExactHref = false }: DropdownItemProps) {
    return(
        <NavLink 
            href={href} 
            blank={blank} 
            shouldMatchExactHref={shouldMatchExactHref}
        >
            {children}
        </NavLink>
    )
}
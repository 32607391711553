import React, { Fragment, useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatDecimal, formatDate, formatInteger } from '../../../common/formatters'

export function TesouroDiretoCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [tesouroDireto, setTesouroDireto] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientTesouroDiretoData() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/TesouroDiretoCustody`);

            const data = await response.json();

            const tesouroDiretoCustodyItemsData = data?.map(item => ({
                ...item,
                nmTitulo: item.nmTitulo,
                dataVencimento: formatDate(item.dataVencimento),
                qtdDisponivel: formatDecimal(item.saldoAnterior),
                qtdBloqueado: formatDecimal(item.qtdBloqueado),
                valorInvestimento: formatDecimal(item.valorInvestimento),
                valorBruto: formatDecimal(item.valorBruto),
                valorBloqueado: formatDecimal(item.valorBloqueado),
                indexador: item.indexador?.toUpperCase()
            }))

            setTesouroDireto(tesouroDiretoCustodyItemsData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientTesouroDiretoData()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientTesouroDiretoData();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.nmTitulo,
        },
        {
            Header: 'Vencimento',
            accessor: data => data.dataVencimento,
        },
        {
            Header: 'Qtd. Disponível',
            accessor: data => data.qtdDisponivel,
            columnAlign: 'right',
            hint: 'Quantidade Disponível'
        },
        {
            Header: 'Qtd. Bloqueado',
            accessor: data => data.qtdBloqueado,
            columnAlign: 'right',
            hint: 'Quantidade Bloqueado'
        },
        {
            Header: 'Valor Aplicado',
            accessor: data => data.valorInvestimento,
            columnAlign: 'right',
        },
        {
            Header: 'Valor Disponível',
            accessor: data => data.valorBruto,
            columnAlign: 'right',
        },
        {
            Header: 'Valor Bloqueado',
            accessor: data => data.valorBloqueado,
            columnAlign: 'right',
        },
        {
            Header: 'Índice',
            accessor: data => data.indexador,
        },
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Tesouro Direto"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={tesouroDireto.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientTesouroDiretoData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '': 
            (
                <SmartTable data={tesouroDireto} columns={columns} localStorageKey={'ClientBalanceData'}/>
            )}
        </SectionWrapper>
    );
}


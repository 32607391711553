import React, { useEffect, useState } from 'react'

import { H2, H4 } from '../../components/Typography'
import { Button, Input, Field } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import MultipleCheckboxFilter from '../../components/MultipleCheckboxFilter'
import {NumberRangeColumnFilterVertical, SmartTable} from '../../components/Table'
import { SelectColumnFilter, NumberRangeColumnFilter } from '../../components/Table/Filters'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'
import { FiSearch } from 'react-icons/fi'

import { objToQueryString, dateQueryParse } from '../../common/api.js';
import { formatInteger, formatDate, formatDecimal } from '../../common/formatters';
import { sanitizeName } from '../../common/variables'
import { contractTypeBtcValues, contractTypeBtcEnum } from '../../common/enums.js';
import { removeKeyObject } from '../../common/variables.js'

import { useQueryParams } from '../../hooks/useQueryParams'
import { SimpleModal } from '../../components/Modal/SimpleModal'
import { Link } from '../../components/Link'

type QueryObject = {
    accountStart?: string;
    accountEnd?: string;
    dtOpeningStart?: string;
    dtOpeningEnd?: string;
    dtDuoStart?: string;
    dtDuoEnd?: string;
    symbols?: string;
    contractNumbers?: string;
    type?: string;
}

export function RiskMonitor() {
    
    const [filtering, setFiltering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState<[]>();
    const [lastUpdate, setLastUpdate] = useState<string>("");
    const [isRefreshing, setIsRefreshing] = useState(false)
    
    const today = new Date().toISOString().substr(0, 10);

    useEffect(() => {
        
        const fetchRiskMonitorData = async () => {
            setLoading(true)
            await fetchOrderData()
        };
        
        const timeout = setInterval(() => {
            fetchRiskMonitorData();
        }, error ? 1000 * 10 : 1000 * 2);
        
        fetchRiskMonitorData();
        
        return () => clearTimeout(timeout);
        
    }, [])

    const columns = [
        {
            Header: 'Conta',
            accessor: 'account',
            Cell: ({ value }) => <Link href={`/client/${value}`}>{value}</Link>,
        },
        {
            Header: 'Limite Disponível',
            accessor: 'limit',
            Cell: ({ value }) => `${formatDecimal(value)}`,
            columnAlign: 'right'
        },
        {
            Header: 'Financeiro',
            accessor: 'balance',
            Cell: ({ value }) => `${formatDecimal(value)}`,
            columnAlign: 'right'
        },
        {
            Header: 'Limite Base',
            accessor: 'initialBasicLimit',
            Cell: ({ value }) => `${formatDecimal(value)}`,
            columnAlign: 'right'
        },
        {
            Header: 'Limite BMF usado',
            accessor: 'bmfLimitUsed',
            Cell: ({ value }) => `${formatDecimal(value)}`,
            columnAlign: 'right'
        },
        {
            Header: 'Limite Bovespa usado',
            accessor: 'bovespaLimitUsed',
            Cell: ({ value }) => `${formatDecimal(value)}`,
            columnAlign: 'right'
        },
        {
            Header: 'Lucro Prejuizo Total',
            accessor: 'totalProfitAndLoss',
            Cell: ({ value }) => `${formatDecimal(value)}`,
            columnAlign: 'right'
        },
    ]

    const fetchOrderData = async () => {
        try {
            
            const response = await fetch(`/api/RiskMonitor`)

            setLoading(false)
            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()

            setResults(data)
            setLastUpdate(new Date().toLocaleDateString([], { hour: '2-digit', minute:"2-digit", second:"2-digit", hour12: false }));
            
        } catch(err) {
            setError(true)
        }
        
        
    }
    
    return(
        
        <SectionWrapper
            header="Monitor de Risco"
            searchButton={
                <></>
            }>

            <span>Última atualização: {lastUpdate}</span>

            { results ? (
            <SmartTable 
                columnFilters 
                columns={columns} 
                data={results} 
                localStorageKey={'RiskMonitor'} 
                minToPagination={100}
                fetchData={fetchOrderData}
                autoRefresh={true}
            />

        ) : null
        }

        </SectionWrapper>
    
    )
}

import React from 'react';

import "./styles.css"

interface LoaderProps {
    size?: "sm" | "md";
    align?: "center" | "start" | "end";
    margin?: string;
    color?: string;
    flex?: boolean;
    full?: boolean;
}

export function Loader({
    size = "md", 
    align = 'center',
    margin,
    color = "",
    flex = false,
    full = false
}: LoaderProps) {
    if(flex) {
        return (
            <div 
                className={`
                    int-d-flex
                    int-justify-content-${align}
                    ${margin}
                `}
            >
                <div data-testid="loader" className={`int-loader int-loader-${size}`} style={color != null ? {borderColor:color} : {}}/>
            </div>
        )
    }

    if (full) {
        return (
            <div 
                className={`
                    int-loader-full-overlay
                    int-d-flex
                    int-justify-content-${align}
                    int-align-items-center
                    ${margin}
                `}
            >
                <div className={`int-loader int-loader-${size} `} style={color != null ? {borderColor:color} : {}} />
            </div>
        )
    }

    return(
        <div className={`int-loader int-loader-${size}` } style={color != null ? {borderColor:color} : {}} />
    )
}
import React, { useEffect, useState } from 'react'

import Routes from './routes';

import { Layout } from './components/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import ThemeProvider from './contexts/ThemeContext';

import { getToken, isDisabledAuthentication, logoutService, setDisabledAuthentication } from './services/auth'

import '@fontsource/livvic/400.css'
import '@fontsource/livvic/500.css'
import '@fontsource/livvic/600.css'

const originalFetch = fetch
const history = createBrowserHistory()

window.fetch = async (url, options) =>  {
  let opt : any = options

  if (options === undefined) {
    opt = {
      headers: {
        Authorization: `BEARER ${getToken()}`
      }
    }
  } else {
    opt = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `BEARER ${getToken()}`
      }
    }
  }

  const request = await originalFetch(url, opt)

    if (request.status === 401 || isDisabledAuthentication()) {
    logoutService()
    setDisabledAuthentication(false)
    window.location.reload()
  }

  return request
}

var oldHref = document.location.href;

window.onload = function () {
    var bodyList = document.querySelector("body")

    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (oldHref != document.location.href) {
                oldHref = document.location.href;
                if (isDisabledAuthentication())
                    window.location.reload()
            }
        });
    });

    var config = {
        childList: true,
        subtree: true
    };

    observer.observe(bodyList, config);
};

function App() {
  const [version, setVersion] = useState("")

  async function fetchVersion() {
    const response = await fetch('/api/settings/version')

    if (!response.ok) {
      console.log("Erro ao carregar versão do sistema")
    }

    const data = await response.json()

    setVersion(data.version)
  }

  async function fetchAuthenticationInfo() {
    const response = await fetch('/api/signIn')

    if (!response.ok) {
      console.log("Erro ao carregar informações de autenticação")
    }

    const data = await response.json()

    setDisabledAuthentication(data.disabled)
  }

  useEffect(() => {
    fetchVersion()
    fetchAuthenticationInfo()
  }, [])

  useEffect(() => {
    document.title = `InteliRisk | ${version}`
  }, [version])

    return (
        <Router>
          <ThemeProvider>
            <Layout>
              <Routes />
            </Layout>
          </ThemeProvider>
        </Router>
    );
}

export default App;

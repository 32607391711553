import React, { useEffect, useState } from "react";

import { Link } from '../../../components/Link'
import { Alert } from '../../../components/Alert';
import { Loader } from '../../../components/Loader';
import { SmartTable } from '../../../components/Table';
import { getPolicyNameById } from '../../../common/search';
import { SectionWrapper } from '../../../components/SectionWrapper';
import { Button, Checkbox, Select, Input } from '../../../components/Forms';
import { SelectColumnFilter } from '../../../components/Table/Filters';
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal';

import { API_HEADER } from '../../../constants/api';

import { DownloadXLSXButton } from '../../../components/Forms/DownloadXLSXButton';
import { UploadCSVModal } from "../../../components/UploadCSV/UploadCSVModal";

type PolicyParameters = {
    id: string;
    name: string;
    policyType: string;
    shared: boolean;
    isInternal: boolean;
}

type AdvisorParameters = {
    id: string;
    policyId: number;
    policyName: string;
}

export function AdvisorsPolicies() {
    const [policies, setPolicies] = useState<PolicyParameters[]>([]);
    const [accounts, setAccounts] = useState<AdvisorParameters[]>([]);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showAddOrUpdateModal, setShowAddOrUpdateModal] = useState(false);
    const [showLoaderFull, setShowLoaderFull] = useState(false);
    const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
    const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
    const [showSuccessAddOrUpdateModal, setShowSuccessAddOrUpdateModal] = useState(false);
    const [showErrorAddOrUpdateModal, setShowErrorAddOrUpdateModal] = useState(false);

    const [associatedPolicy, setAssociatedPolicy] = useState();
    const [sortedPolicyItems, setSortedPolicyItems] = useState([]);
    const [advisorsParamsExport, setAdvisorsParamsExport] = useState([]);

    const [account, setAccount] = useState("");
    const [allAdvisors, setAllAdvisors] = useState<string[]>([]);
    const [selectedAdvisors, setSelectedAdvisors] = useState<string[]>([]);

    const [error, setError] = useState(false);
    const [errorUpdateParam, setErrorUpdateParam] = useState("")
    const [checkName, setCheckName] = useState([])
    const [notFoundAdvisorIds, setNotFoundAdvisorIds] = useState([])
    const [notFoundPolicyIds, setNotFoundPolicyIds] = useState([])
    const [privatePolicyIds, setPrivatePolicyIds] = useState([])

    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);

    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const allChecked = allAdvisors.every(x => selectedAdvisors.includes(x));

    const onChange = e => setAssociatedPolicy(e.target.value.toUpperCase().trimStart());

    const checkBoxColumnHandle = ({ target }) => {
        if (target.checked) {
            setSelectedAdvisors(selectedAdvisors.concat([target.value]))
        } else {
            setSelectedAdvisors(selectedAdvisors.filter(i => i !== target.value))
        }
    }

    const allCheckBoxesHandle = ({ target }) => {

        if (target.checked) {
            const items = allAdvisors.filter(x => !selectedAdvisors.includes(x));
            setSelectedAdvisors(selectedAdvisors.concat(items))
        } else {
            setSelectedAdvisors(selectedAdvisors.filter(i => !allAdvisors.includes(i)))
        }
    }

    const onFiltersChange = (rows) => {
        const data = rows.map(item => item.original.id)

        setAllAdvisors(data)
        setSelectedAdvisors([])
    }

    const columns = [
        {
            id: 'Seleção',
            Header: () =>
                <Checkbox
                    data-testid="table-checkbox-header"
                    onChange={allCheckBoxesHandle}
                    isChecked={allChecked}
                />,
            Cell: ({ row }) =>
                <Checkbox
                    data-testid="table-checkbox-body"
                    value={row.original.id}
                    onChange={checkBoxColumnHandle}
                    isChecked={selectedAdvisors.some(i => i === row.original.id)}
                />,
            width: '1%'
        },
        {
            Header: 'Conta',
            accessor: 'id',
            columnAlign: 'left',
        },
        {
            Header: 'Política',
            accessor: props => getPolicyNameById(policies, props.policyId),
            Cell: ({ row }) => PolicyName(row.original),
            Filter: SelectColumnFilter,
        }
    ]

    const PolicyName = (item) => {
        if (item.policyId === undefined)
            return "-"

        return (
            <Link href={`/policies/${item.policyId}`}>
                {getPolicyNameById(policies, item.policyId)}
            </Link>
        )
    }

    async function fetchPolicyData() {
        try {
            const response = await fetch(`/api/policy`)

            if (!response.ok) {
                throw new Error("")
            }
            const data = await response.json()
            setPolicies(data)

        } catch (err) {
            console.log(err)
        }
    }

    async function fetchAdvisorData() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/advisor/searchAll`)

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }
            const data = await response.json()
            setAccounts(data)

        } catch (err) {
            console.log(err)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchPolicyData()
        fetchAdvisorData()
    }, [])

    useEffect(() => {
        if (accounts?.length > 0) {
            const params = accounts
                .map(x => ({
                    "Conta": x.id,
                    "Politica": getPolicyNameById(policies, x.policyId)
                }))

            setAdvisorsParamsExport(params)
            setAllAdvisors(accounts.map(x => x.id))
        }
    }, [accounts])

    useEffect(() => {
        const sortedArray = policies?.filter(x => !!x.shared && !x.isInternal && x.policyType === "Client");
        setSortedPolicyItems(sortedArray);
    }, [policies]);

    /** Substitui as políticas do assesores (API) */
    const handlePolicy = async (arr) => {
        setError(false);
        setDisabled(true);
        setShowLoaderFull(true);
        setNotFoundAdvisorIds([]);
        setNotFoundPolicyIds([]);
        setPrivatePolicyIds([]);

        try {
            const arrNames = arr.map(x => x.policyName)
            const policiesNames = policies.map(x => x.name.toUpperCase())

            const isInArray = arrNames.some(x => !policiesNames.includes(x))

            if (isInArray) {
                const names = arrNames.filter(x => !policiesNames.includes(x))
                setCheckName(names)
                setShowLoaderFull(false)
                setShowErrorUpdateModal(true)
                return
            }

            const requestBody = arr.map((item) => ({
                id: item.id,
                policyId: policies?.find(x => x.name.toUpperCase().trim() === item.policyName)?.id
            }))

            const requestOptions = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(requestBody)
            };

            const response = await fetch(`/api/Policy/updateAdvisorsList`, requestOptions)

            if (!response.ok) {
                setShowErrorUpdateModal(true)
                setError(true)
                setShowLoaderFull(false);
                return setDisabled(false);
            }

            var data = await response.json()
            setNotFoundAdvisorIds(data.notFoundAdvisorIds)
            setNotFoundPolicyIds(data.notFoundPolicyIds)
            setPrivatePolicyIds(data.privatePolicyIds)

            setShowLoaderFull(false);
            setShowSuccessUpdateModal(true)
        } catch (err) {
            console.log(err)
            setShowLoaderFull(false);
            setError(true)
        } finally {
            setDisabled(false)
        }
    };

    /** Substitui a políticas do assessores (API) */
    const changePolicyList = async (event) => {
        event.preventDefault();
        setDisabled(true)
        setError(false);

        try {
            if (associatedPolicy === undefined) {
                setDisabled(false);
                setError(true)
                return setErrorUpdateParam("Selecione uma política para associar ao assessor.")
            }

            const requestBody = {
                ids: account != "" ? selectedAdvisors.concat([account]) : selectedAdvisors,
                policyId: parseFloat(associatedPolicy)
            }

            const requestOptions = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(requestBody),
            };

            const response = await fetch(`/api/policy/updateAdvisors`, requestOptions)

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            setShowLoaderFull(false)

        } catch (err) {
            console.log(err)
            setShowLoaderFull(false);
            setError(true)
        } finally {
            setDisabled(false)
        }

        setDisabled(false);
    };

    return (
        <>
            <SectionWrapper header="Assessores / Políticas">
                <div className="int-d-flex int-align-items-center int-justify-content-between int-mt-4">
                    <div className="int-d-flex">
                        <DownloadXLSXButton
                            disabled={accounts.length <= 0}
                            dataJs={advisorsParamsExport}
                            fileName={`lista_de_assessores_e_politicas_${new Date(Date.now()).toLocaleDateString()}`}
                            variant="outline"
                        >
                            Exportar arquivo
                        </DownloadXLSXButton>

                        <Button
                            data-testid="import-arq"
                            variant="outline"
                            margin="int-ml-3"
                            onClick={() => {
                                setIsCSVModalOpen(true)
                                setError(false)
                            }}
                        >
                            Importar arquivo
                        </Button>

                        <Button
                            data-testid="add-or-update-accounts"
                            margin="int-ml-3"
                            variant="outline"
                            onClick={() => {
                                setShowAddOrUpdateModal(true)
                            }}
                        >
                            Adicionar / Editar assessor
                        </Button>

                        <Button
                            data-testid="update-item"
                            disabled={selectedAdvisors.length <= 0}
                            margin="int-ml-3"
                            variant="outline"
                            onClick={() => {
                                setShowUpdateModal(true)
                            }}
                        >
                            Editar itens selecionados
                        </Button>
                    </div>
                </div>

                {isLoading && <Loader flex />}

                {isLoading ? '' :
                    (
                        <SmartTable
                            data={accounts ?? []}
                            columns={columns}
                            columnFilters
                            columnsResizable
                            localStorageKey={'AdvisorsPolicy'}
                            onFiltersChange={onFiltersChange}
                        />
                    )}

            </SectionWrapper>

            <UploadCSVModal
                isModalOpen={isCSVModalOpen}
                onHide={() => setIsCSVModalOpen(false)}
                onAdd={handlePolicy}
                header={`Importar / Substituir`}
                model={"Assessores"}
                policyApplication={true}
            />

            {/* Modal - Mensagem de edição com sucesso  */}
            <SimpleModal
                header="Alteração das políticas dos assessores"
                isOpen={showSuccessUpdateModal}
                onClose={() => setShowSuccessUpdateModal(false)}
                bodyMessage={
                    <div>
                        <p>
                            As políticas dos assessores foram atualizadas com sucesso!
                        </p>
                        {notFoundAdvisorIds.length == 0 ? ("") : (
                            <Alert margin="int-mt-4">
                                Exceto para o(s) assessor(es) não encontrado(s): {" "}
                                {notFoundAdvisorIds
                                    .filter((i, j) => notFoundAdvisorIds.indexOf(i) === j)
                                    .join(", ")}.
                            </Alert>

                        )}
                        {notFoundPolicyIds.length == 0 ? ("") : (
                            <Alert margin="int-mt-4">
                                Exceto para a(s) política(s) não encontrada(s): {" "}
                                {notFoundPolicyIds
                                    .filter((i, j) => notFoundPolicyIds.indexOf(i) === j)
                                    .join(", ")}.
                            </Alert>
                        )}
                        {privatePolicyIds.length == 0 ? ("") : (
                            <Alert margin="int-mt-4">
                                Exceto para a(s) política(s) privada(s): {" "}
                                {privatePolicyIds
                                    .filter((i, j) => privatePolicyIds.indexOf(i) === j)
                                    .join(", ")}.
                            </Alert>
                        )}
                    </div>
                }
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        setSelectedAdvisors([])
                        setNotFoundAdvisorIds([])
                        setNotFoundPolicyIds([])
                        setPrivatePolicyIds([])
                        fetchAdvisorData()
                        setShowSuccessUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Mensagem de erro na edição */}
            <SimpleModal
                header="Erro ao alterar as políticas dos assessores"
                isOpen={showErrorUpdateModal}
                onClose={() => setShowErrorUpdateModal(false)}
                bodyMessage={`Ocorreu um erro ao atualizar as políticas dos assessores,
                não foram encontradas as políticas: ${checkName.filter((este, i) => checkName.indexOf(este) === i)}`}
                footerButton={{
                    label: 'Fechar',
                    onClick: () => {
                        setSelectedAdvisors([])
                        fetchAdvisorData()
                        setShowErrorUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Edição de política  */}
            <Modal
                isOpen={showUpdateModal}
                header="Alterar Política"
                onClose={() => setShowUpdateModal(false)}
            >
                <form onSubmit={event => changePolicyList(event)}>
                    <ModalBody>
                        <Select
                            data-testid="change-policy-select"
                            isRequired
                            label="Selecione uma política"
                            name="policyId"
                            value={associatedPolicy}
                            onChange={onChange}
                        >
                            <option hidden>Selecione um nome</option>
                            {sortedPolicyItems.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>

                        {error && (
                            <Alert margin="int-mt-4">
                                <p>{errorUpdateParam}</p>
                            </Alert>
                        )}

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setShowUpdateModal(false);
                                setError(false)
                            }}>
                            Cancelar
                        </Button>
                        <Button
                            data-testid="change-policy-submit"
                            margin="int-ml-2"
                            type="submit"
                            disabled={disabled}
                            onClick={() => {
                                setShowLoaderFull(true)
                                setShowUpdateModal(false)
                                setShowSuccessUpdateModal(true)
                                setError(false)
                            }}>
                            Alterar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Mensagem adição / edição com sucesso  */}
            <SimpleModal
                header="Adição / edição do assessor"
                isOpen={showSuccessAddOrUpdateModal}
                onClose={() => setShowSuccessAddOrUpdateModal(false)}
                bodyMessage="A política do assessor foi alterada com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        setSelectedAdvisors([])
                        fetchAdvisorData()
                        setShowSuccessAddOrUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Mensagem de erro na atualização  */}
            <SimpleModal
                header="Erro ao alterar a política do assessor"
                isOpen={showErrorAddOrUpdateModal}
                onClose={() => setShowErrorUpdateModal(false)}
                bodyMessage={"Ocorreu um erro ao atualizar a política do assessor."}
                footerButton={{
                    label: 'Fechar',
                    onClick: () => {
                        setSelectedAdvisors([])
                        setShowErrorAddOrUpdateModal(false)
                    }
                }}
            />

            {/* Modal - Edição / Adição de assessor e política  */}
            <Modal
                isOpen={showAddOrUpdateModal}
                header="Adicionar / Editar assessor e política"
                onClose={() => setShowAddOrUpdateModal(false)}
            >
                <form onSubmit={event => changePolicyList(event)}>
                    <ModalBody>
                        <div className="int-row">
                            <div className="int-col int-pl-0">
                                <Input
                                    isRequired
                                    label="Assessor"
                                    name="account"
                                    placeholder="Assessor"
                                    value={account}
                                    onChange={(e) => setAccount(e.target.value)}
                                />
                            </div>
                            <div className="int-col int-pl-0">
                                <Select
                                    data-testid="change-policy-select"
                                    isRequired
                                    label="Selecione uma política"
                                    name="policyId"
                                    value={associatedPolicy}
                                    onChange={onChange}
                                >
                                    <option hidden>Selecione um nome</option>
                                    {sortedPolicyItems.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {error && (
                            <Alert margin="int-mt-4">
                                <p>{errorUpdateParam}</p>
                            </Alert>
                        )}

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setShowAddOrUpdateModal(false);
                                setError(false)
                            }}>
                            Cancelar
                        </Button>
                        <Button
                            data-testid="change-policy-submit"
                            margin="int-ml-2"
                            type="submit"
                            onClick={() => {
                                setSelectedAdvisors([])
                                setShowAddOrUpdateModal(false)
                                setShowErrorAddOrUpdateModal(false)
                                setError(false)
                                setShowSuccessAddOrUpdateModal(true);
                            }}>
                            Alterar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {showLoaderFull && <Loader full />}
        </>
    )
}
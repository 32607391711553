import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatInteger, formatDecimal } from '../../../common/formatters';

export function FutOpc({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [futOpc, setFutOpc] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientFutOpcData() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/futOpc`);

            const data = await response.json();

            const futOpcData = data?.map(item => ({
                ...item,
                symbol: item.symbol,
                available: formatInteger(item.available),
                execBuyQty: formatInteger(item.execBuyQty),
                execSellQty: formatInteger(item.execSellQty),
                total: formatInteger(item.total),
                currentPrice: formatDecimal(item.currentPrice),
                value: formatDecimal(item.value)
            }))

            setFutOpc(futOpcData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientFutOpcData()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientFutOpcData();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol,
        },
        {
            Header: 'Qtd.',
            accessor: data => data.available,
            columnAlign: 'right',
            hint: 'Quantidade'
        },
        {
            Header: 'Compras',
            accessor: data => data.execBuyQty,
            columnAlign: 'right',
        },
        {
            Header: 'Vendas',
            accessor: data => data.execSellQty,
            columnAlign: 'right',
        },
        {
            Header: 'Qtd. Total',
            accessor: data => data.total,
            columnAlign: 'right',
            hint: 'Quantidade Total'
        },
        {
            Header: 'Última Cotação',
            accessor: data => data.currentPrice,
            columnAlign: 'right',
        },
        {
            Header: 'Valor',
            accessor: data => data.value,
            columnAlign: 'right',
        },
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Opções BM&F"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={futOpc.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientFutOpcData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={futOpc} columns={columns} localStorageKey={'ClientFutOpcData'}/>
                )}
        </SectionWrapper>
    );
}


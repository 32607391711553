import React, { useEffect, useState } from 'react'

import { Link } from '../../../components/Link'
import { SearchInput } from '../../../components/SearchInput'
import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'

import { useQueryParams } from '../../../hooks/useQueryParams'

import { getPolicyNameById } from '../../../common/search'
import { sanitizeName } from '../../../common/variables';
import { isAdmin } from '../../../services/auth'

type Client = {
    account: string;
    document: string;
    name: string;
    policyId: number;
    policyName: string;
    status: boolean;
}

export function SearchClients() {
    const { setParam, updateQuery } = useQueryParams()

    const [query, setQuery] = useState(setParam("q"));

    const [clients, setClients] = useState<Client[]>();
    const [policies, setPolicies] = useState([]);

    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(`/api/policy`)

                if(!response.ok) 
                    throw new Error('Ocorreu um erro ao buscar políticas')

                const data = await response.json()
                setPolicies(data)
            } catch (err) {
                console.log(err)
            }
        })()
    }, []);

    async function handleSearch(event) {
        event.preventDefault()

        setIsSearching(true)

        updateQuery(`q=${query}`)

        try {
            const response = await fetch(`/api/client/search?q=${query}`)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()

            const results = data.map(item => ({
                ...item,
                policyName: getPolicyNameById(policies, item.policyId)
            }))
            setClients(results)
        } catch (err) {
            console.log(err)
        } finally {
            setIsSearching(false)
        }
    }

    return (
        <SectionWrapper header="Buscar Cliente">
            <p className="int-my-4">
                Escreva a Conta, CPF, Nome ou Política vinculada ao cliente e 
                busque seu registro para obter mais informações sobre
            </p>

            <SearchInput
                value={query}
                required
                placeholder="Pesquise"
                isFetchingData={isSearching}
                onChange={e => setQuery(e.target.value?.toUpperCase())}
                onSubmit={e => handleSearch(e)}
            />

            { isSearching && <Loader flex /> }

            {isSearching ? '' : clients && clients.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : clients ? (
                <table className="int-table int-mt-3">
                    <thead>
                        <tr>
                            <th>Conta</th>
                            <th>CPF</th>
                            <th>Nome</th>
                            <th>Política</th>
                            <th>Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {clients?.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Link href={`/client/${item?.account}`}>
                                        {item?.account}
                                    </Link>
                                </td>
                                <td>{item?.document}</td>
                                <td>{item?.name}</td>
                                {isAdmin() ? 
                                (
                                <td>
                                    <Link href={`/policies/${item?.policyId}`}>
                                        {item?.policyName}
                                    </Link>
                                </td>
                                ) :
                                (
                                <td>
                                    {item?.policyName}
                                </td>
                                )}
                                <td>{item?.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table >
            ) : '' }
        </SectionWrapper>
    )
}   

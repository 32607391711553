import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs";

import { Loader } from "../../../components/Loader";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { SmartTable, TableCell } from "../../../components/Table";

import { formatDecimal } from "../../../common/formatters";

type ClientBalance = {
    availableD0: number;
    initD1: number;
    initD2: number;
    d1Intraday: number;
    d2Intraday: number;
    initTotal: number;
    currentTotal: number;
};

interface BalanceProps {
    account: string;
    id: string;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
    updateAllGrids: boolean;
    autoOpenAccordion?: boolean;
}

export function Balance({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }: BalanceProps) {
    const [balance, setBalance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientBalanceData() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/client/${account}/balance`);

            if (!response.ok) {
                throw new Error(
                    "Occoreu um erro ao buscar o Financeiro do cliente"
                );
            }

            const data = await response.json();

            const balance = [{
                ...data,
                availableD0: formatDecimal(data.availableD0),
                initD1: formatDecimal(data.initD1),
                initD2: formatDecimal(data.initD2),
                initTotal: formatDecimal(data.initTotal),

                d1Intraday: formatDecimal(data.d1Intraday),
                d2Intraday: formatDecimal(data.d2Intraday),
                currentTotal: formatDecimal(data.currentTotal)
            }];

            setBalance(balance);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientBalanceData()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientBalanceData();
    }, [account]);

    const columns = [
        {
            Header: 'Disponível / Ini. D+1',
            accessor: data => data.availableD0
        },
        {
            Header: 'Ini. D+1',
            accessor: data => data.initD1,
        },
        {
            Header: 'Ini. D+2',
            accessor: data => data.initD2,
        },
        {
            Header: 'Intraday D+1',
            accessor: data => data.d1Intraday,
        },
        {
            Header: 'Intraday D+2',
            accessor: data => data.d2Intraday,
        },
        {
            Header: 'Total',
            accessor: data => data.currentTotal,
        },
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Financeiro (Bovespa)"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientBalanceData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? ("") : 
            (
                <SmartTable data={balance} columns={columns} localStorageKey={'ClientBalanceData'}/>
            )}
        </SectionWrapper>
    );
}

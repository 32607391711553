import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import { FiSearch } from 'react-icons/fi'

import { Loader } from '../../Loader'

import "./navbar.css"

export function NavBar() {
    const [quest, setQuest] = useState("");
    const [searchFailed, setSearchFailed] = useState(false);
    const [searching, setSearching] = useState(false);

    const history = useHistory();

    async function Search(e) {
        e.preventDefault();

        setSearchFailed(false);
        setSearching(true);
        try {
            const response = await fetch(`/api/client/${quest}/register`);

            if (response.ok) {
                history.push(`/client/${quest}`);
            } else {
                setSearchFailed(true);
            }

            setQuest('');
        } catch (error) {
            console.log(error);
        }finally{
            setSearching(false);
        }
    }

    React.useEffect(() => {
        const id = setTimeout(() => {
            setSearchFailed(false);
        }, 2000);
    
        return () => clearTimeout(id);
      }, [searching]);

    return (
        <form className="int-form-organization" onSubmit={(e) => Search(e)}>
            <div
                className="int-input-organization"
            >
                <input 
                    type="number"
                    className="int-form-search"
                    onChange={e => setQuest(e.target.value)} 
                    value={quest}
                    autoFocus
                />
                <div className="int-group-right-icon" style={{height: '100%'}} onClick={Search}>
                { 
                    !searching 
                    ? <FiSearch color="#FF9900"/> 
                    : <Loader size="sm" />
                }
                </div>
            </div>
            {searchFailed &&
                <div className="int-cont-alert">
                    <div className="triangle"/>
                    <div className="box-error-message">
                        <p className="error-message">Cadastro não Encontrado</p>
                    </div>   
                </div>
            }
        </form>
    )
}
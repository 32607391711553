import React, { ReactNode } from "react";

interface BreadcrumbItemProps {
    children: ReactNode;
}

export function BreadcrumbItem({ children }: BreadcrumbItemProps) {
    return(
        <li>{children}</li>
    )
}
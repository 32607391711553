import React, { 
    ReactNode, 
    HTMLAttributes, 
    useEffect, 
    useState, 
    useRef 
} from 'react'

interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    label: string;
    align?: 'left' | 'right';
    margin?: string,
    isDisabled: boolean
}

export function Dropdown({ 
    children, 
    label, 
    align = 'left', 
    margin,
    isDisabled,
    ...rest 
}: DropdownProps) {
    const node = useRef(null)

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => {
            document.removeEventListener("mousedown", handleClick)
        }
    }, [])

    function handleClick(e) {
        if(node.current.contains(e.target)) {
            return
        }

        setIsDropdownOpen(false)
    }

    return(
        <div 
            ref={node}
            className={`
                int-dropdown int-dropdown-${align} 
                ${isDropdownOpen ? 'active' : ''}
                ${margin}
            `} 
            {...rest}
        >
            <button 
                disabled={isDisabled}
                className="int-btn int-dropdown-toggle" 
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                {label}
            </button>
            
            <ul className="int-dropdown-list">
                {children}
            </ul>
        </div>
    )
}
import React, { ReactNode, SelectHTMLAttributes } from 'react'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    children: ReactNode;
    name: string;
    label?: string;
    isRequired?: boolean;
    value?: string;
    index?: number;
}

export function Select({ 
    label, 
    name, 
    children, 
    isRequired = false, 
    value,
    onChange = () => {},
    index = 0,
    ...rest 
}: SelectProps) {
    return(
        <div id={name}>
            {label && <label className="int-form-label">{label} {isRequired ? <span style={{ color: 'var(--int-colors-red-500)' }}>*</span> : ''}</label>}
            <select 
                className="int-form-select"
                required={isRequired}
                name={name}
                value={value}
                onChange={(e) => [onChange(e)]}
                {...rest}
            >
                {children}
            </select>
        </div>
    )
}
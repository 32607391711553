import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

import { UploadCSVModal } from '../../../components/UploadCSV/UploadCSVModal';

import { Button, Input, Textarea, TagsInput, Select } from '../../../components/Forms'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal';
import { Loader } from '../../../components/Loader';
import { SectionWrapper } from '../../../components/SectionWrapper';
import { Alert } from '../../../components/Alert';

import { removeArrayItem, sanitizeName } from '../../../common/variables'
import { API_HEADER } from '../../../constants/api';

type MarketObject = {
    symbol: string;
    market: string;
}

export function NewManualVirtualMarket() {
    const history = useHistory()

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [symbol, setSymbol] = useState()
    const [symbolsItems, setSymbolsItems] = useState([])
    const [symbolsWithMarketItems, setSymbolsWithMarketItems] = useState<MarketObject[]>([])
    const [allMarkets, setAllMarkets] = useState([]);

    const [showVirtualMarketModal, setShowVirtualMarketModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [symbolsError, setSymbolsError] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);

    const form = React.useRef(null)
    
    useEffect(() => {
        allStandardMarketsFetch()
    }, [])

    async function allStandardMarketsFetch() {
        try {
            const response = await fetch('/api/policy/market/Standard')

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()
            setAllMarkets(data)
        } catch (err) {
            console.log(err)
        }
    }

    const handleVirtualMarket = async (event) => {
        event.preventDefault()   
        setShowVirtualMarketModal(false)

        setError(false)
        setSymbolsError(false)

        if (form.current.checkValidity() === false) {
            setDisabled(false)
            return event.stopPropagation()
        }

        if (symbolsItems.length === 0) {
            setDisabled(false)
            setSymbolsError(true)
            return event.stopPropagation()
        } 

        for(var i = 0; i < symbolsItems.length; i++){            
            let symbol = symbolsItems[i];
            var index = symbolsWithMarketItems.findIndex(x => x.symbol == symbol);

            if(index == -1){
                setSymbolsWithMarketItems((oldArray) => [...oldArray, {symbol: symbol, market: "TODOS"}]);
            }
        }

        setShowVirtualMarketModal(true)
    }

    const handleVirtualMarketFetch = async (event) => {
        event.preventDefault()
        setDisabled(true)
        setShowVirtualMarketModal(false)

        try {
            const bodyRequest = {
                id: name,
                description,
                symbols: symbolsWithMarketItems.map((item) => ({
                    symbol: item.symbol,
                    market: item.market
                }))
            }  
            const options = {
                method: "POST",
                headers: API_HEADER,
                body: JSON.stringify(bodyRequest),
            };

            const response = await fetch(`/api/virtualMarket`, options)

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            setShowSuccessModal(true)
        } catch(err) {
            setErrorMessage(err.message)
            setError(true)
        } finally {
            setDisabled(false)
        }
    }

    const onRemove = (index, itemsArr) => {
        var symbol = symbolsItems[index];
        setSymbolsItems(removeArrayItem(index,itemsArr))
        setSymbolsWithMarketItems([...symbolsWithMarketItems.filter(i => i.symbol != symbol)]);
    }

    const onChange = e => setSymbol(sanitizeName(e.target.value, true))
    
    const onChangeMarket = (name, market) => {
        var index = symbolsWithMarketItems.findIndex(x => x.symbol == name);
        let newArr = [...symbolsWithMarketItems]
        newArr[index].market = market
        
        setSymbolsWithMarketItems(newArr)
    }

    const onAdd = arr => {
        const newArr = [...symbolsItems, ...arr].filter((item, index, arr) => { 
            return arr.indexOf(item) === index
        })

        setSymbolsItems(newArr)
    }

    const onAddBySheet = arr => {
        const newArr = [...symbolsItems, ...arr.map(i => i.symbol)].filter((item, index, arr) => {
            return arr.indexOf(item) === index
        })
        
        setSymbolsWithMarketItems([...arr])
        setSymbolsItems(newArr)
    }

    const onEnter = s =>{
        setSymbol(s)
    }

    return(
        <>
            <SectionWrapper header="Nova Lista de Ativos">
                <form onSubmit={handleVirtualMarket} ref={form}>
                    <Input
                        data-testid="create-virtual-market-name"
                        isRequired
                        label="Nome do mercado"
                        name="name"
                        placeholder="Nome da lista de ativos"
                        value={name}
                        onChange={(e) => setName(sanitizeName(e.target.value, true))}
                    />

                    <Textarea 
                        data-testid="create-virtual-market-description"
                        margin="int-mt-2"
                        label="Descrição"
                        name="description"
                        placeholder="Descrição da lista de ativos"
                        value={description}
                        onChange={(e) => setDescription(e.target.value.trimStart())}
                    />

                    <TagsInput 
                        id="symbols"
                        label="Ativos" 
                        required 
                        placeholder="Ativos"
                        inputValue={symbol}
                        tagsArr={symbolsItems}
                        onChange={onChange}
                        onRemove={(index) => onRemove(index,symbolsItems)}
                        onAdd={onAdd}
                        onEnter={onEnter} 
                    />

                    <Button
                        margin="int-my-4" 
                        variant="outline" 
                        onClick={() => setIsCSVModalOpen(true)} 
                    >
                        Importe os Ativos
                    </Button>

                    {symbolsError && 
                        <Alert 
                            margin="int-mt-5" 
                        >
                            Adicione pelo menos um ativo para o novo mercado virtual
                        </Alert>
                    }

                    <Button 
                        data-testid="create-virtual-market-submit"
                        isFullWidth 
                        disabled={disabled}  
                        type="submit"
                    >
                        {disabled ? <Loader/>: 'Cadastrar mercado virtual'} 
                    </Button>
                    
                    {error && 
                        <Alert 
                            margin="int-mt-5"
                        >
                            {errorMessage}
                        </Alert>
                    }
                </form>
            </SectionWrapper>

            {/* Modal - Mensagem cadastro com sucesso  */}
            <SimpleModal 
                header="Mercado Virtual cadastrado com sucesso!"
                isOpen={showSuccessModal}
                onClose={() => setShowSuccessModal(false)}
                bodyMessage="O novo mercado virtual foi cadastrado com sucesso!"
                footerButtons={[
                    {
                        label: "Novo Mercado Virtual",
                        onClick: () => history.go(0),
                        variant: 'outline'
                    },
                    {
                        label: "Lista de Mercados Virtuais",
                        onClick: () => history.push('/virtual-market'),
                        margin: 'int-ml-2'
                    }
                ]}
            />

            {/* Modal - Adicionar lista de ativos  */}
            <Modal
                isOpen={showVirtualMarketModal}
                header="Adicionar lista de ativos"
                onClose={() => setShowVirtualMarketModal(false)}
            >
                <form onSubmit={handleVirtualMarketFetch}>
                    <ModalBody>
                        <table className="int-table int-mt-3">
                            <tbody>
                                {symbolsWithMarketItems.map((row, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{row.symbol}</td>
                                            <td>
                                                <Select
                                                    data-testid="change-virtual-market-item-select"
                                                    name="market"
                                                    isRequired
                                                    onChange={(event) => onChangeMarket(row.symbol, event.target.value)}
                                                    value={row.market}
                                                >
                                                    <option hidden>Selecione um nome</option>
                                                    {allMarkets.map((item, index) => (
                                                        
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setError(false);
                                setShowVirtualMarketModal(false);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            data-testid="change-virtual-market-update-submit"
                            margin="int-ml-2"
                            type="submit"
                            disabled={disabled}
                            onClick={() => {
                                setError(false);
                            }}
                        >
                            Alterar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
            
            <UploadCSVModal 
                isModalOpen={isCSVModalOpen}
                onHide={() => setIsCSVModalOpen(false)}
                onAdd={onAddBySheet}
                header={"Importar Lista de Ativos"}
                model={"Ativos"}
            />
        </>
    )
}
import React, { useEffect, useState } from 'react'

import { H4 } from '../../components/Typography'
import { Button, Field } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import { SmartTable } from '../../components/Table'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'

import { objToQueryString, dateQueryParse } from '../../common/api.js';
import { formatDateTime, formatDecimal, formatInteger } from '../../common/formatters';

import { useQueryParams } from '../../hooks/useQueryParams'
import { FiSearch } from 'react-icons/fi'
import { SimpleModal } from '../../components/Modal/SimpleModal'

type QueryObject = {
    account?: string;
    movementDate?: string;
}

export function MarginGuranteeHistory() {
    const { setParam, updateQuery } = useQueryParams()
    const [queryObject, setQueryObject] = useState<QueryObject>();

    const [margin, setMargin] = useState<[]>()
    const [guarantee, setGuarantee] = useState<[]>()

    const [account, setAccount] = useState(setParam("account"));
    const [movementDate, setMovementDate] = useState(setParam("movementDate"));
    
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(true);
    const [searched, setSearched] = useState(false)

    useEffect(() => {
        if (queryObject) {

            (async function () {
                await fetchMargin()
                await fetchGuarantee()
                setLoading(false)
            })();
        }
    }, [queryObject])


    const columnsGuarantee = [
        {
            Header: 'Conta',
            accessor: 'account',
        },
        {
            Header: 'Tipo de Garantia',
            accessor: 'guaranteeType',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },
        {
            Header: 'Data Vencimento',
            accessor: 'dueDate',
            Cell: ({ value }) => formatDateTime(value),
        },
        // {
        //     Header: 'Número Depósito',
        //     accessor: 'id',
        //     Cell: ({ value }) => value,
        // },
        {
            Header: 'Cód. ldt. Garantia',
            accessor: 'asset',
            Cell: ({ value }) => value?.toUpperCase() ?? '-'
        },
        {
            Header: 'Qdt. Depósito',
            accessor: 'quantity',
            Cell: ({ value }) => formatInteger(value),
        },
        {
            Header: 'Valor Aceito Garantia',
            accessor: 'value',
            Cell: ({ value }) => formatDecimal(value)
        }
    ]

    const columnsMargin = [
        {
            Header: 'Conta',
            accessor: 'account',
        },
        {
            Header: 'Margem Requerida',
            accessor: 'requiredMargin',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Valor Tot. da Garantia',
            accessor: 'depositedMargin',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Valor Excedente',
            accessor: 'valueExcess',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Valor Excedente em Espécie',
            accessor: 'valueExcessCash',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Valor Devolvido',
            accessor: 'valueReturn',
            Cell: ({ value }) => formatDecimal(value)
        },
        {
            Header: 'Chamada de Margin',
            accessor: 'marginCall',
            Cell: ({ value }) => formatDecimal(value)
        }
    ]

    const apiQueryString = objToQueryString({
        ...queryObject,
        account: queryObject?.account,
        movementDate: dateQueryParse(queryObject?.movementDate)
    });

    const fetchMargin = async () => {
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {
            setShowFilterModal(false);
            setFiltering(false);
            const response = await fetch(`/api/marginGuarantee/margin?${apiQueryString}`)

            if (!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()
            setMargin(data)
        } catch (err) {
            setError(true)
        }
    }

    const fetchGuarantee = async () => {
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {
            setShowFilterModal(false);
            setFiltering(false);
            const response = await fetch(`/api/marginGuarantee/guarantee?${apiQueryString}`)

            if (!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()
            setGuarantee(data)
        } catch (err) {
            setError(true)
        }
    }


    const cleanFilters = () => {
        setAccount("");
        setMovementDate("");

        updateQuery(" ")
    }

    const setQueryObjectFilters = () => {
        const queryStringFilters = {
            account: account,
            movementDate: movementDate
        };
        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();
        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const handleSearch = async event => {
        event.preventDefault()
        setLoading(true)
        setError(false)

        if (!searched)
            setSearched(true)

        setQueryObjectFilters()
    }

    const setFinalOpeningDateOnBlur = () => {
        if (movementDate === '' || movementDate === undefined) {
            const date = new Date().toISOString().substr(0, 10) + "T" + "00:00";
            setMovementDate(date)
        }
    }
    const renderFilterElements = () => {
        return(
            <form onSubmit={e => handleSearch(e)}>
                <div className="int-mt-4 int-row">
                    <div className="int-col">
                        <H4>Conta do cliente</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <Field
                                    data-testid="marginGuaranteeHistory-client"
                                    format="Number"
                                    value={account || ''}
                                    onChange={(e) => setAccount(e?.target?.value.trimStart())}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="int-col">
                        <H4>Data de Pregão</H4>
                        <div className="int-row">
                            <div className="int-col">
                                <DateTime
                                    name="movementDate"
                                    value={movementDate}
                                    setDateTime={setMovementDate}
                                    onBlurDate={setFinalOpeningDateOnBlur}
                                    timeDefault="00:00"
                                    notShowTimeInput
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {filtering && ( 
                    <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
                )}
            </form>

        )
    }
    return (
            <SectionWrapper 
                header="Histórico de Margens e Garantias"        
                searchButton={
                    <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
                }>
            <SimpleModal 
                    header="Filtros de busca"
                    isOpen={showFilterModal}
                    onClose={() => setShowFilterModal(false)}
                    bodyMessage={renderFilterElements()}
                    footerButtons={[
                        {
                            label: 'Limpar Todos os filtros',
                            onClick:() => cleanFilters(),
                            variant: 'outline'
                        },   
                        {
                            label: 'Buscar',
                            onClick: (e) => {
                                handleSearch(e)
                            },
                            margin: 'int-ml-2'
                        }
                    ]}
                />                        
            {loading && <Loader flex />}

            {error && (
                <Alert>Ocorreu um erro na busca</Alert>
            )}

            {loading || error || filtering? '' : margin && guarantee && margin.length === 0 && guarantee.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : margin && guarantee && (
                <React.Fragment>
                    <SectionWrapper
                        headerTag="h2"
                        header="Garantias"
                        isAccordion
                    >
                        <SmartTable 
                        gapColumnsFilter={2}
                        columnsResizable  
                        lockHeader = {true}
                        columnFilters 
                        columns={columnsGuarantee} 
                        data={guarantee} 
                        localStorageKey={'GuaranteeHistory'} 
                        />
                    </SectionWrapper>

                    <SectionWrapper
                        headerTag="h2"
                        header="Margens"
                        isAccordion
                    >
                        <SmartTable 
                        gapColumnsFilter={2}
                        columnsResizable  
                        lockHeader = {true}
                        columnFilters 
                        columns={columnsMargin} 
                        data={margin} 
                        localStorageKey={'MarginHistory'} 
                        />
                    </SectionWrapper>
                </React.Fragment>
            )}
        </SectionWrapper>
    )
}

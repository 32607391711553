import React, { useContext } from "react";

import Logo from "../../assets/logo.svg";
import LogoBlack from "../../assets/logo-black.svg";
import Background from "../../assets/web-risco.png";
import Background1 from "../../assets/web-risco.svg";

import { ThemeContext } from "../../contexts/ThemeContext";

export function Home() {
    const context = useContext(ThemeContext);

    return (
        <div
            className="int-d-flex int-flex-column int-align-items-end
                int-justify-content-start int-pr-32"
        >
            <span
                className="int-mt-12"
                style={{
                    fontSize: "4.5rem",
                    fontWeight: 400,
                }}
            >
                Bem Vindo
            </span>

            <img
                className="int-mt-2"
                style={{ height: "64px" }}
                src={context?.theme === "dark" ? Logo : LogoBlack}
                alt="Intelirisk"
            />

            <p
                className="int-mt-6 int-text-end"
                style={{ fontSize: "1.25rem" }}
            >
                Sistema de gerenciamento de Risco <br /> da{" "}
                <b className="int-text-uppercase">Intelitrader Tecnologia</b>
            </p>

            <img
                style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    height: "650px",
                    zIndex: -1,
                    opacity: 0.3,
                }}
                src={Background1}
                alt="Imagem do fundo"
            />
        </div>
    );
}

// Informa se os campos são required para a validação
export const balance = {
    gridName: 'Financeiro',
    available: {
        friendlyName: 'Disponível',
        name: 'available',
        required: true
    },
    initD0: {
        friendlyName: 'Projetado D0',
        name: 'initD0',
        required: true
    },
    initD1: {
        friendlyName: 'Projetado D1',
        name: 'initD1',
        required: true
    },
    initD2: {
        friendlyName: 'Projetado D2',
        name: 'initD2',
        required: true
    },
};

export const newOrder = {
    gridName: 'Nova Ordem',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    ordQty: {
        friendlyName: 'Quantidade',
        name: 'ordQty',
        required: true
    },
    side: {
        friendlyName: 'C/V',
        name: 'side',
        required: true
    },
    ordType: {
        friendlyName: 'Tipo',
        name: 'ordType',
        required: true
    },
    timeInForce: {
        friendlyName: 'Validade',
        name: 'timeInForce',
        required: true
    },
    status: {
        friendlyName: 'Quantidade',
        name: 'ordQty',
        required: true
    },
    price: {
        friendlyName: 'Preço',
        name: 'price',
        required: true
    },
    account: {
        friendlyName: 'Conta do cliente',
        name: 'account',
        required: true
    }
}

export const newOrderOperator = {
    ...newOrder,
    enteringTrader: {
        friendlyName: 'Letra do operador',
        name: 'enteringTrader',
        required: true
    },
}

export const intradayPosition = {
    gridName: 'Posição Intraday - Cliente',
    gridNameOperator: 'Posição Intraday - Operador',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    openBuyQty: {
        friendlyName: 'Compras em aberto',
        name: 'openBuyQty',
        required: true
    },
    execBuyQty: {
        friendlyName: 'Compras executadas',
        name: 'execBuyQty',
        required: true
    },
    execSellQty: {
        friendlyName: 'Vendas executadas',
        name: 'execSellQty',
        required: true
    },
    openSellQty: {
        friendlyName: 'Vendas em aberto',
        name: 'openSellQty',
        required: true
    },
    execSellAvgPrice: {
        friendlyName: 'PM Vendas Executadas',
        name: 'execSellAvgPrice',
        required: true
    },
    execBuyAvgPrice: {
        friendlyName: 'PM Compras Executadas',
        name: 'execBuyAvgPrice',
        required: true
    },
    openSellAvgPrice: {
        friendlyName: 'PM Vendas Em Aberto',
        name: 'openSellAvgPrice',
        required: true
    },
    openBuyAvgPrice: {
        friendlyName: 'PM Compras Em Aberto',
        name: 'openBuyAvgPrice',
        required: true
    },
    operatorParameter: {
        friendlyName: '',
        name: 'operatorParameter',
        required: false
    },
}

export const custody = {
    gridName: 'Custódia Bovespa',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    market: {
        friendlyName: 'Mercado',
        name: 'market',
        required: true
    },
    wallet: {
        friendlyName: 'Carteira',
        name: 'wallet',
        required: true
    },
    available: {
        friendlyName: 'Disponível',
        name: 'available',
        required: true
    },
    pending: {
        friendlyName: 'Pendente',
        name: 'pending',
        required: true
    },
    projectedD0: {
        friendlyName: 'A liquidar',
        name: 'projectedD0',
        required: true
    },
    projectedD1: {
        friendlyName: 'D1',
        name: 'projectedD1',
        required: true
    },
    projectedD2: {
        friendlyName: 'D2',
        name: 'projectedD2',
        required: true
    },
}

export const operatorCustody = {
    ...custody,
    amount: {
        friendlyName: '',
        name: 'amount',
        required: false
    },
    covered: {
        friendlyName: '',
        name: 'covered',
        required: false
    },
    covering: {
        friendlyName: '',
        name: 'covered',
        required: false
    },
    currentPrice: {
        friendlyName: '',
        name: 'currentPrice',
        required: false
    },
    guaranteeFactor: {
        friendlyName: '',
        name: 'guaranteeFactor',
        required: false
    },
    guaranteeValue: {
        friendlyName: '',
        name: 'guaranteeValue',
        required: false
    },
    total: {
        friendlyName: '',
        name: 'total',
        required: false
    },
}

export const termCustody = { 
    gridName: 'Custódia de Termos',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    available: {
        friendlyName: 'Disponível',
        name: 'available',
        required: true
    },
    tradeValue: {
        friendlyName: 'PU Registro',
        name: 'tradeValue',
        required: true
    },
}

export const bmfCustody = { 
    gridName: 'Custódia BMF',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    available: {
        friendlyName: 'Disponível',
        name: 'available',
        required: true
    },
    projectedD1: {
        friendlyName: 'D1',
        name: 'projectedD1',
        required: true
    },
}

export const goldCustody = {
    gridName: 'Custódia de Ouro',
    availableGrams: {
        friendlyName: 'Gramas Disponíveis',
        name: 'availableGrams',
        required: true
    },
}

export const futOpcCustody = { 
    gridName: 'Opções de Contratos Futuros',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    available: {
        friendlyName: 'Disponível',
        name: 'available',
        required: true
    },
}

export const flexOpcCustody = {
    gridName: 'Custódia de Opções Flexíveis',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    premio: {
        friendlyName: 'Prêmio',
        name: 'premio',
        required: true
    },
    baseSize: {
        friendlyName: '	Tamanho base',
        name: 'baseSize',
        required: true
    },
    naturezaOperacao: {
        friendlyName: 'C/V',
        name: 'naturezaOperacao',
        required: true
    },
}

export const btcCustody = { 
    gridName: 'Custódia de BTC',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    taker: {
        friendlyName: 'Disponível Tomador',
        name: 'taker',
        required: true
    },
    donor: {
        friendlyName: 'Disponível Doador',
        name: 'donor',
        required: true
    },
}

export const fixedIncomeCustody = { 
    gridName: 'Custódia de Renda Fixa',
    symbol: {
        friendlyName: 'Título',
        name: 'symbol',
        required: true
    },
    financialCurrentValue: {
        friendlyName: 'Valor Atual',
        name: 'financialCurrentValue',
        required: true
    },
    guaranteeFactor: {
        friendlyName: 'Fator de Garantia',
        name: 'guaranteeFactor',
        required: true
    },
}

export const tesouroDiretoCustody = { 
    gridName: 'Custódia de Tesouro Direto',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    valorBruto: {
        friendlyName: 'Valor Disponível',
        name: 'valorBruto',
        required: true
    },
    guaranteeFactor: {
        friendlyName: 'Fator de Garantia',
        name: 'guaranteeFactor',
        required: true
    },
}

export const marketData = {
    gridName: 'Cotação',
    symbol: {
        friendlyName: 'Ativo',
        name: 'symbol',
        required: true
    },
    underlyingSymbol: {
        friendlyName: 'Ativo Objeto',
        name: 'underlyingSymbol',
        required: false
    },
    lastPrice: {
        friendlyName: 'Último preço',
        name: 'lastPrice',
        required: true
    },
    closingPrice: {
        friendlyName: 'Preço de fechamento',
        name: 'closingPrice',
        required: true
    },
    askPrice: {
        friendlyName: 'Melhor Preço Venda',
        name: 'askPrice',
        required: true
    },
    bidPrice: {
        friendlyName: 'Melhor Preço Compra',
        name: 'price',
        required: true
    },
    priceDivisor: {
        friendlyName: 'Preço Divisor',
        name: 'priceDivisor',
        required: true
    },
    market: {
        friendlyName: 'Mercado',
        name: 'market',
        required: true
    },
    margin: {
        friendlyName: 'Margem',
        name: 'margin',
        required: true
    },
    contractMultiplier: {
        friendlyName: '	Tamanho do contrato',
        name: 'contractMultiplier',
        required: true
    },
    maturityDate: {
        friendlyName: 'Data de vencimento',
        name: 'maturityDate',
        required: true
    },
    currencyFactor: {
        friendlyName: 'Moeda',
        name: 'currencyFactor',
        required: true
    },
}

export const parameters = {
    gridName: 'Política - Cliente',
    gridNameOperator: 'Política - Operador',
    name: {
        friendlyName: 'Nome',
        name: 'name',
        required: true
    },
    market: {
        friendlyName: 'Mercado',
        name: 'market',
        required: true
    },
    value: {
        friendlyName: 'Valor',
        name: 'value',
        required: true
    },
    operatorParameter: {
        friendlyName: '',
        name: 'operatorParameter',
        required: false
    },
    symbol: {
        friendlyName: 'Símbolo',
        name: 'symbol',
        required: false
    },
    friendlyName: {
        friendlyName: '',
        name: 'friendlyName',
        required: false
    },
}

export const clientRegistryObj = {
    isQualified: true,
    advisor: false
}

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import { NavMenu } from './NavMenu';
import { Footer } from './Footer';

export function Layout({ children }) {
    const { pathname } = useLocation();
    const [mainClassPadding, setMainClassPadding] = useState("");
    const [footerDisplayStyle, setFooterDisplayStyle] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        changeHistoryPagesStyle();
    }, [pathname]);

    function anyContains (url, items) {
        var anyContains = false;
        for(var i = 0; i < items.length; i++){
            if(url.includes(items[i])){
                anyContains = true;
                break;
            };
        };
        return anyContains;
    };

    function changeHistoryPagesStyle() {
        const rotasRelatorios = [
            "btc-history",
            "checking-account-history",
            "funds-transaction-history",
            "fixed-income-transaction-history",
            "margin-guarantee-history",
            "tesouro-direto-history",
            "order-tracking",
            "order-respecified-tracking",
            "history",
            "holidays",
            "new-auto-virtual-market"
        ];
        if (anyContains(pathname, rotasRelatorios)) {
            setFooterDisplayStyle('none');
            setMainClassPadding('');
        } else {
            setFooterDisplayStyle('');
            setMainClassPadding('int-py-16');
        };
    };

    return (
        <>
            <NavMenu />
            <main 
                className={"int-px-4 " + mainClassPadding}
                style={{
                    position: 'relative',
                    minHeight: 'calc(100vh - 292px)',
                    overflow: "hidden",
                }}
            >
                <div className="int-section-wrapper">
                    {children}
                </div>
            </main>
            <div style={footerDisplayStyle != '' ? { display: footerDisplayStyle}: {}}> 
                <Footer />
            </div>

        </>
    );
}

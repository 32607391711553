import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs"

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatInteger, formatDecimal } from '../../../common/formatters';


export function TermCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [termCustody, setTermCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientTermCustodyData() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/termCustody`);

            const data = await response.json();

            const termCustodyData = data?.map(item => ({
                ...item,
                symbol: item.symbol,
                available: formatInteger(item.available),
                currentPrice: formatDecimal(item.currentPrice),
                registryAmount: formatDecimal(item.registryAmount),
                currentAmount: formatDecimal(item.currentAmount),
                profit:formatDecimal(item.profit),
            }))

            setTermCustody(termCustodyData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientTermCustodyData()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientTermCustodyData();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol,
        },
        {
            Header: 'Quantidade',
            accessor: data => data.available,
            columnAlign: 'right',
        },
        {
            Header: 'Preço Atual',
            accessor: data => data.currentPrice,
            columnAlign: 'right',
        },
        {
            Header: 'Total Registro',
            accessor: data => data.registryAmount,
            columnAlign: 'right',
        },
        {
            Header: 'Total Atual',
            accessor: data => data.currentAmount,
            columnAlign: 'right',
        },
        {
            Header: 'Lucro / Prejuízo',
            accessor: data => data.profit,
            columnAlign: 'right',
        },
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Termo"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={termCustody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientTermCustodyData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={termCustody} columns={columns} localStorageKey={'ClientTermCustodyData'}/>
                )}
        </SectionWrapper>
    );
}


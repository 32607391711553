import React, { useEffect, useState } from 'react';
import { BsArrowClockwise } from 'react-icons/bs';

import { SmartTable } from '../../../components/Table'
import { Loader } from '../../../components/Loader'; 
import { SectionWrapper } from '../../../components/SectionWrapper'

import { formatInteger, formatDecimal } from '../../../common/formatters'

export function BovespaPositionD0({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [bovespaPositionD0, setBovespaPositionD0] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientBovespaPositionD0Data () {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/bovespaPositionD0`)
            
            if(!response.ok) {
                throw new Error("Ocorreu um erro ao buscar a Posição Intraday - Bovespa do cliente")
            }

            const data = await response.json()
            setBovespaPositionD0(data)
        } catch (err) {
            console.log(err)
        }

        setIsLoading(false)
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientBovespaPositionD0Data()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientBovespaPositionD0Data()
    }, [account])

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol.toUpperCase(),
        },
        {
            Header: 'Qtd. Inicial',
            //a qtd inicial de ouro vem em gramas portanto pode ser decimal
            accessor:  data => data.isGold ? formatDecimal(data.initQty) : formatInteger(data.initQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Quantidade Inicial'
        },
        {
            Header: 'Qtd. Termos',
            accessor: data => formatInteger(data.termCustodyQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Quantidade Termos'
        },
        {
            Header: 'Qtd. BTC Tomador',
            accessor: data => formatInteger(data.btcTakerQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Quantidade BTC Tomador'
        },
        {
            Header: 'Compra exec',
            accessor: data => formatInteger(data.execBuyQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Compra executada'
        },
        {
            Header: 'Ven exec',
            accessor: data => formatInteger(data.execSellQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Venda Executada'
        },
        {
            Header: 'Comp abert',
            accessor: data => formatInteger(data.openBuyQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Compra Abertura'
        },
        {
            Header: 'Ven abert',
            accessor: data => formatInteger(data.openSellQty),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Venda Abertura'
        },
        {
            Header: 'PM Compra exec.',
            accessor: data => formatDecimal(data.execBuyAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Compra Executada'
        },
        {
            Header: 'PM Venda exec.',
            accessor: data => formatDecimal(data.execSellAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Venda Executada'
        },
        {
            Header: 'PM Compra abert',
            accessor: data => formatDecimal(data.openBuyAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Compra Abertura'
        },
        {
            Header: 'PM Venda abert',
            accessor: data => formatDecimal(data.openSellAvgPrice),
            columnAlign: 'right',
            singleLine: false,
            hint: 'PM Venda Abertura'
        },
        {
            Header: 'Posic exec',
            accessor: data => formatInteger(data.execPosition),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Posição Executada'
        },
        {
            Header: 'Posic atual',
            accessor: data => formatInteger(data.currentPosition),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Posição Atual'
        },
        {
            Header: 'Cotação',
            accessor: data => formatDecimal(data.currentPrice),
            columnAlign: 'right',
        },
        {
            Header: 'Limite',
            accessor: data => formatDecimal(data.limit),
            columnAlign: 'right',
        },
        {
            Header: 'L/P',
            accessor: data => formatDecimal(data.profit),
            columnAlign: 'right',
        },
        {
            Header: 'Fat. Alav.',
            accessor: data => formatDecimal(data.leverageFactor),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Faturamento Alavancado'
        },
        {
            Header: 'Bônus Alav.',
            accessor: data => formatDecimal(data.leverageAdjust),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Bônus Alavancado'
        },
        {
            Header: 'Limite Alav.',
            accessor: data => formatDecimal(data.leverageLimit),
            columnAlign: 'right',
            singleLine: false,
            hint: 'Limite Alavancado'
        },
    ]

    return (
        <SectionWrapper 
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Posição Intraday - Bovespa"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={bovespaPositionD0.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientBovespaPositionD0Data}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' : 
            (
                <SmartTable data={bovespaPositionD0} columns={columns} localStorageKey={'ClientBovespaD0Position'} /> 
            )}
        </SectionWrapper>
    )
}
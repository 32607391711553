import React, { useContext, useEffect, useState } from "react"
import { Link, useHistory } from 'react-router-dom'
import { SunDim, Moon } from "phosphor-react"

import Logo from '../../../assets/logo.svg'
import LogoBlack from '../../../assets/logo-black.svg'

import { ThemeContext } from "../../../contexts/ThemeContext"
import { Modal, ModalBody, ModalFooter } from "../../Modal"
import { Button } from "../../Forms/Button"

import { NavBar } from "./NavBar"
import { NavLink } from "./NavLink";
import { Dropdown, DropdownItem } from './Dropdown'

import { isAuthenticated, logoutService, getUser, isDisabledAuthentication, isAdmin } from '../../../services/auth'

import "./styles.css"


export function NavMenu() {
    const [monitorUrl, setMonitorUrl] = useState("");
    const [showLogout, setShowLogout] = useState(false);

    const history = useHistory()

    const context = useContext(ThemeContext)

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('/api/settings/monitorUrl')

                if (!response.ok)
                    throw new Error('Não foi possível obter a URL do Monitor')

                const data = await response.json()
                setMonitorUrl(data.url)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])

    return (
        <header
            className="int-d-flex int-w-100 int-mx-auto int-px-4 int-align-items-center int-justify-content-between"
            style={{
                height: '6rem',
                boxShadow: `${context?.theme === "dark" ? "0px 8px 12px rgba(51, 56, 62, 0.5)" : "1px 2px 10px rgba(51, 56, 62, 0.2)"}`
            }}
        >
            <Link to="/">
                <img src={context?.theme === "dark" ? Logo : LogoBlack} alt="Intelirisk" />
            </Link>

            <nav className="int-navbar">
                {isAuthenticated() ? (
                    <>
                        {isAdmin() || isDisabledAuthentication() ? (
                            <>
                                <NavBar />

                                <NavLink href="/client">Clientes</NavLink>

                                <Dropdown label="Operadores">
                                    <DropdownItem href="/operators">
                                        Lista de Operadores
                                    </DropdownItem>

                                    <DropdownItem href="/new-operator">
                                        Novo Operador
                                    </DropdownItem>
                                </Dropdown>

                                <Dropdown label="Políticas">
                                    <DropdownItem href="/policies">
                                        Lista de Políticas
                                    </DropdownItem>

                                    <DropdownItem href="/new-policy">
                                        Nova Política
                                    </DropdownItem>


                                    <DropdownItem href="/clients-policies">
                                        Clientes / Políticas
                                    </DropdownItem>
                                    <DropdownItem href="/operators-policies">
                                        Operadores / Políticas
                                    </DropdownItem>
                                    <DropdownItem href="/advisors-policies">
                                        Assessores / Políticas
                                    </DropdownItem>
                                </Dropdown>

                                <Dropdown label="IPOs">
                                    <DropdownItem href="/ipo-registries">
                                        Lista de Registros de IPO
                                    </DropdownItem>

                                    <DropdownItem href="/new-ipo-registry">
                                        Novo Registro de IPO
                                    </DropdownItem>
                                </Dropdown>

                                <Dropdown label="Lista de Ativos">
                                    <DropdownItem href="/virtual-market">
                                        Lista de Ativos
                                    </DropdownItem>
                                    <DropdownItem href="/new-auto-virtual-market">
                                        Nova Lista de Ativos Automatizada
                                    </DropdownItem>
                                    <DropdownItem href="/new-manual-virtual-market">
                                        Nova Lista de Ativos Manual
                                    </DropdownItem>
                                </Dropdown>

                                <Dropdown label="MarketData">
                                    <DropdownItem href="/market-data">
                                        Cotação
                                    </DropdownItem>
                                    <DropdownItem href="/new-mtm-registry">
                                        Marg. T. Máx
                                    </DropdownItem>
                                </Dropdown>

                                {/* <NavLink href="/market-data">Cotação</NavLink> */}

                                <Dropdown label="Relatórios">
                                    <DropdownItem href="/history">Hist. Validações</DropdownItem>
                                    <DropdownItem href="/order-tracking">Hist. Ordens</DropdownItem>
                                    <DropdownItem href="/order-respecified-tracking">Hist. Ordens Reespecificadas</DropdownItem>
                                    <DropdownItem href="/tesouro-direto-history">Hist. Movimentação de TD</DropdownItem>
                                    <DropdownItem href="/fixed-income-transaction-history">Hist. Movimentação de Renda Fixa</DropdownItem>
                                    <DropdownItem href="/funds-transaction-history">Hist. Movimentação de Fundos</DropdownItem>
                                    <DropdownItem href="/margin-guarantee-history">Hist. Margens e Garantias</DropdownItem>
                                    <DropdownItem href="/holidays">Hist. Feriados</DropdownItem>
                                    <DropdownItem href="/checking-account-history">Hist. Extratos de Conta Corrente</DropdownItem>
                                    <DropdownItem href="/btc-history">Hist. Contratos de BTC</DropdownItem>
                                    <DropdownItem href="/conditional-orders-history">Hist. Ordens Condicionais</DropdownItem>
                                    <DropdownItem href="/scheduled-orders-history">Hist. Ordens Agendadas</DropdownItem>
                                    <DropdownItem href="/risk-monitor">Monitor de Risco</DropdownItem>
                                    <DropdownItem href="/limit-reports-history">Relatórios de Limite</DropdownItem>
                                </Dropdown>

                                <Dropdown label="Simuladores">
                                    <DropdownItem shouldMatchExactHref href="/simulator">
                                        Clientes
                                    </DropdownItem>

                                    <DropdownItem href="/simulator/operator">
                                        Operadores
                                    </DropdownItem>
                                </Dropdown>

                                <Dropdown align="right" label="Manutenção (TI)">
                                    <DropdownItem href="/connections">Conexões</DropdownItem>
                                    <DropdownItem href="/configurations">Configurações</DropdownItem>
                                    <DropdownItem blank href={monitorUrl}>Monitor</DropdownItem>
                                    <DropdownItem blank href="/swagger">Swagger</DropdownItem>
                                </Dropdown>
                            </>
                        ) : (
                            <>
                                <NavLink href="/client">Clientes</NavLink>

                                <Dropdown label="Operadores">
                                    <DropdownItem href="/operators">
                                        Lista de Operadores
                                    </DropdownItem>

                                    <DropdownItem href="/new-operator">
                                        Novo Operador
                                    </DropdownItem>
                                </Dropdown>

                                <Dropdown label="Relatórios">
                                    <DropdownItem href="/history">Hist. Validações</DropdownItem>
                                    <DropdownItem href="/order-tracking">Hist. Ordens</DropdownItem>
                                </Dropdown>
                            </>
                        )}
                        {!isDisabledAuthentication() && (
                            <Dropdown align="right" label={getUser()}>
                                <p
                                    onClick={() => setShowLogout(true)}
                                    style={{ cursor: "pointer" }}
                                    className="int-py-2 int-px-4"
                                >
                                    Sair
                                </p>
                            </Dropdown>
                        )}
                    </>
                ) : (
                    <NavLink href="/signIn">Entrar</NavLink>
                )}
                <button
                    onClick={context?.toggleThemeMode}
                    style={{ backgroundColor: "transparent" }}
                    data-testid="button-theme"
                >
                    {context?.theme === "dark" ? <Moon size={24} color="#ffffff" /> : <SunDim size={24} />}
                </button>
            </nav>

            <Modal
                header="Sair"
                isOpen={showLogout}
                onClose={() => setShowLogout(false)}
            >
                <ModalBody>
                    <p>Tem certeza que deseja sair?</p>
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() => setShowLogout(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        margin="int-ml-2"
                        onClick={() => {
                            logoutService()
                            history.push('/signIn')
                            setShowLogout(false)
                        }}
                    >
                        Sair
                    </Button>
                </ModalFooter>
            </Modal>
        </header>
    )
}

import React, { useRef, useState, useEffect } from 'react';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';

import { Button } from '../';
import { Alert } from '../../Alert'

import { readExcel } from '../../../common/readExcel';

import "./styles.css";

interface UploadCSVInputProps<T> {
    /**
     * Receives data loaded from the file as an array of objects.
     */
    onFileRead: (data: T[]) => void;

    /**
     * Executes a function when canceling the uploaded file
     */
    onCancelUploadedFile?: () => void;

    isModalOpen?: boolean;
}

export function UploadCSVInput<T>({ 
    onFileRead, 
    onCancelUploadedFile = () => {},
    isModalOpen = false
}: UploadCSVInputProps<T>) {
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [importedFile, setImportedFile] = useState<File | null>();
    const [isFileExtensionSupported, setIsFileExtensionSupported] = useState(
        true
    );
    const [isReadExcelError, setIsReadExcelError] = useState(false);

    async function handleReadCSVFile() {
        setIsFileExtensionSupported(true);
        setIsReadExcelError(false);
        setImportedFile(null);

        const fileList = inputFileRef?.current?.files;
        var allowedExtensions = /(\.xlsx|\.xls)$/i;

        if (!fileList || fileList.length < 0) return;

        const file = fileList[0];

        if (!allowedExtensions.exec(file?.name)) {
            setIsFileExtensionSupported(false);
            return;
        }

        setImportedFile(file);

        try {
            const data = await readExcel<T>(file);
            onFileRead(data);
        } catch (err) {
            setIsReadExcelError(true);
        }
    }

    function handleCancelUploadedFile() {
        setImportedFile(null);
        onCancelUploadedFile();
    }

    useEffect(() => {
        if(!isModalOpen){
            handleCancelUploadedFile();
        }         
    }, [isModalOpen])
    return (
        <div className="file-wrapper">
            {importedFile ? (
                <div className="imported">
                    <p>{importedFile.name}</p>
                    <Button 
                        margin="int-mt-2"
                        variant="outline" 
                        onClick={handleCancelUploadedFile}
                    >
                        Cancelar arquivo
                    </Button>
                </div>
            ) : (
                <label id="csv-file" htmlFor="file">
                    <input
                        type="file"
                        ref={inputFileRef}
                        id="file"
                        name="file"
                        accept=".xls,.xlsx"
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDrop={handleReadCSVFile}
                        onChange={handleReadCSVFile}
                    />

                    <div className="content">
                        <BsFileEarmarkSpreadsheet 
                            size={56} 
                            fill="var(--chakra-colors-orange-400)" 
                        />

                        <b className="int-mt-1">Segure e arraste seu arquivo para cá</b>
                        <b 
                            style={{
                                color: 'var(--int-colors-gray-200)'
                            }}
                        >
                            OU
                        </b>

                        <Button
                            margin="int-mt-2"
                            onClick={() => inputFileRef?.current?.click()}
                        >
                            Selecione um arquivo
                        </Button>
                    </div>
                </label>
            )}

            {!isFileExtensionSupported && (
                <Alert>
                    Por favor importe as seguintes extensões suportadas: .xls e 
                    .xlsx
                </Alert>
            )}

            {isReadExcelError && (
                <Alert>
                    Não foi possível extrair dados do arquivo! Certifique-se que
                    seu arquivo é igual ao modelo disponível para download e
                    que os campos obrigatórios estão preenchidos
                </Alert>
            )}
        </div>
    );
}

import React, { useEffect, useState, useCallback, } from "react"
import { useParams, useHistory } from "react-router-dom"

import { ParamsSearchModal } from './ParamsSearchModal'

import { Button, Input, Field, Select, PolicyField, DateTime } from '../../../components/Forms'
import { Modal, ModalBody, ModalFooter, SimpleModal } from '../../../components/Modal'
import { Link } from '../../../components/Link'
import { Tooltip } from '../../../components/Tooltip'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { Alert } from '../../../components/Alert'
import { Breadcrumb, BreadcrumbItem } from '../../../components/Breadcrumb'
import { GuaranteeFactorParameters } from './GuaranteeFactorParameters'
import { LeverageFactorParameters } from './LeverageFactorParameters'
import { FixedIncomeParameters } from './FixedIncomeParameters'
import { CommonParameters } from './CommonParameters'
import { CustomBmfParameters } from './CustomBmfParameters'
import { SectionClientsPage } from "./SectionClientsPage"

import { FundsParameters } from "./FundsParameters";

import { formatObjectNumberValues } from "../../../common/formatters"
import { getPolicyParentNames, getPolicyNameById } from "../../../common/search"

import { getMarketForParameter, disableMarketSelection, checksIfParameterIsMarket, disableSymbolSelection } from '../../../common/checkers'

import { API_HEADER } from "../../../constants/api"
import { ClubsParameters } from "./ClubsParameters"

type Params = {
    id: string
}

type Parameters = {
    name: string;
    friendlyName?: string;
    market: string;
    symbol?: string;
    comments?: string;
    expirationDate?: string;
    value: string;
    description?: string;
    policyId: number;
    category: string;
}

type BmfParameterKey = {
    market: string;
    symbol: string;
}

type BmfParameter = {
    key: BmfParameterKey;
    values: { [parameter: string]: number };
    policyIds: { [parameter: string]: number };
    isAllInheritedParams: boolean
}

type FixedIncomeParameter = {
    issuer: string;
    symbol: string;
    redeemable: boolean;
    value?: string;
    policyId?: number;
    comments?: string;
}

type FundsParameter = {
    fundName: string;
    id: string;
    settlementCycle: string;
    riskClassification: string;
    value?: string;
    policyId?: number;
    comments?: string;
}

type ClubsParameter = {
    clubName: string;
    id: string;
    value?: string;
    policyId?: number;
    comments?: string;
}

type Policy = {
    id: number;
    name: string;
    policyType: "Client" | "EnteringTrader";
    parameters: Parameters[];
    bmfParameters: BmfParameter[];
    fixedIncomeParameters: FixedIncomeParameter[];
    fundsParameters: FundsParameter[];
    clubsParameters: ClubsParameter[];
    shared: boolean;
    isMaster: boolean;
}

type Param = {
    name: string;
    friendlyName?: string;
    market: string;
    symbol?: string;
    comments?: string;
    expirationDate?: string;
    value: string;
    description?: string;
    isNameDisabled?: boolean;
    isMarketDisabled?: boolean;
}

type SearchParam = {
    name: string;
    friendlyName: string;
}

type Conflict = {
    friendlyName: string;
    newParamFriendlyName: string;
    market: string;
    symbol: string;
}

const today = new Date().toISOString().substr(0, 10);

const conflictTableStyle = {
    paddingRight: 10,
    paddingLeft: 10
}

export function PolicyDetails() {
    let { id } = useParams<Params>();
    const history = useHistory();

    const [validatedNewParam, setValidatedNewParam] = useState(false);
    const [validatedUpdateParam, setValidatedUpdateParam] = useState(false);

    const [errorDeletedParam, setErrorDeletedParam] = useState<Error | null>();
    const [errorUpdatedParam, setErrorUpdatedParam] = useState<Error | null>();
    const [errorNewParam, setErrorNewParam] = useState<Error | null>();

    const [paramsCommon, setParamsCommon] = useState([]);
    const [paramsGuaranteeFactor, setParamsGuaranteeFactor] = useState([]);
    const [paramsLeverageFactor, setParamsLeverageFactor] = useState([]);
    const [paramsFixedIncome, setParamsFixedIncome] = useState([]);
    const [paramsFunds, setParamsFunds] = useState([]);
    const [paramsClubs, setParamsClubs] = useState([]);
    const [paramsCustomBmf, setParamsCustomBmf] = useState([]);
    const [policy, setPolicy] = useState<Policy>();
    const [policyItems, setPolicyItems] = useState([]);

    const [addParamKey, setAddParamKey] = useState<Param>()
    const [updatedParam, setUpdatedParam] = useState<Param>();
    const [deletdParam, setDeletedParam] = useState<Param | null>();
    const [newParam, setNewParam] = useState<Param | null>();
    const [searchParam, setSearchParam] = useState<SearchParam | null>();

    const [clientKeys, setClientKeys] = useState([]);
    const [enteringTraderKeys, setEnteringTraderKeys] = useState([]);
    const [keys, setKeys] = useState([]);
    const [allMarkets, setAllMarkets] = useState([]);
    const [standardMarkets, setStandardMarkets] = useState([]);

    const [showUpdatedParameter, setShowUpdatedParameter] = useState(false);
    const [showDeletedParameter, setShowDeletedParameter] = useState(false);
    const [showFormAddParameter, setShowFormAddParameter] = useState(false);
    const [showFormUpdatedParameter, setShowFormUpdatedParameter] = useState(false);
    const [showAddParameter, setShowAddParameter] = useState(false);

    const [showDeletedPolicyConfirm, setShowDeletedPolicyConfirm] = useState(false);
    const [deletePolicytConfirm, setDeletePolicytConfirm] = useState("")
    const [showDeletedPolicyMessage, setShowDeletedPolicyMessage] = useState(false);
    const [deletedPolicyMessage, setDeletedPolicyMessage] = useState("");
    const [errorDeletedPolicy, setErrorDeletedPolicy] = useState<Error>();

    const [showConflictedParamConfirm, setShowConflictedParamConfirm] = useState(false);
    const [errorConflictParam, setErrorConflictParam] = useState<Error>();
    const [conflict, setConflict] = useState<Conflict>();

    const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false)

    const [showSearchParamsModal, setShowSearchParamsModal] = useState(false)

    const [disabled, setDisabled] = useState(false);

    const commonPropsParametersComp = {
        id,
        keys,
        policy,
        policyItems,
        setUpdatedParam,
        setDeletedParam,
        setErrorDeletedParam,
        setErrorNewParam,
        setShowSearchParamsModal,
        setShowFormUpdatedParameter,
        setShowFormAddParameter,
        setShowDeletedParameter,
    }

    useEffect(() => {
        if (policy?.policyType === "Client") {
            setKeys(clientKeys)
            
        } else if (policy?.policyType === "EnteringTrader") {
            setKeys(enteringTraderKeys)
        }
    }, [policy])

    useEffect(() => {
        const handleData = async () => {
            const keysFetch = await fetch('/api/policy/keys');
            keysFetch
                .json()
                .then(res => {
                    setClientKeys(res.map(g => g.filter(x => x.isClient)).filter(x => x.length > 0))
                    setEnteringTraderKeys(res.map(g => g.filter(x => x.isEnteringTrader)).filter(x => x.length > 0))
                })
                .catch((error) => console.log(error));

            const allMarketsFetch = await fetch(`/api/policy/market`)
            allMarketsFetch
                .json()
                .then(res => setAllMarkets(res))
                .catch((error) => console.log(error));

            const standardMarketsFetch = await fetch(`/api/policy/market/standard`)
            standardMarketsFetch
                .json()
                .then(res => setStandardMarkets(res))
                .catch((error) => console.log(error));

            const policiesFetch = await fetch('/api/policy');
            policiesFetch
                .json()
                .then(res => setPolicyItems(res))
                .catch((error) => console.log(error));

            const policyFetch = await fetch(`/api/policy/${id}`)
            policyFetch
                .json()
                .then(res => setPolicy(res))
                .catch((error) => console.log(error));
        }

        handleData();
    }, [id]);

    /** Atualiza os dados dos parâmetros listados */
    useEffect(() => {
        const paramsCommonFiltered = policy?.parameters?.filter(p => p.category === 'Common')
        setParamsCommon(paramsCommonFiltered)

        const paramsGuaranteeFactorFiltered = policy?.parameters?.filter(p => p.category === 'GuaranteeFactor')
        setParamsGuaranteeFactor(paramsGuaranteeFactorFiltered)

        const paramsLeverageFactorFiltered = policy?.parameters?.filter(p => p.category === 'LeverageFactor')
        setParamsLeverageFactor(paramsLeverageFactorFiltered)

        const paramsFixedIncomeFiltered = policy?.fixedIncomeParameters
            .map(item => { const x = { ...item, policyName: getPolicyNameById(policyItems, item.policyId) }; return x })
        setParamsFixedIncome(paramsFixedIncomeFiltered)

        const paramsFundsFiltered = policy?.fundsParameters
            .map(item => { const x = { ...item, policyName: getPolicyNameById(policyItems, item.policyId) }; return x })
        setParamsFunds(paramsFundsFiltered)

        const paramsClubsFiltered = policy?.clubsParameters
            .map(item => { const x = { ...item, policyName: getPolicyNameById(policyItems, item.policyId) }; return x })
        setParamsClubs(paramsClubsFiltered)

        const paramsCustomBmfFiltered = policy?.bmfParameters
        setParamsCustomBmf(paramsCustomBmfFiltered)

    }, [keys, policy]);

    /** Atualiza os detalhes da chave no parâmetro */
    useEffect(() => {
        const key = keys.flat().find(item => item.name === newParam?.name);
        return setAddParamKey(key);
    }, [keys, newParam]);

    /** Atualiza dados do parêmetro de edição */
    const handleUpdatedParam = useCallback(
        (event) => {
            setUpdatedParam({
                ...updatedParam,
                [event.currentTarget.name]: event.currentTarget.value
            });
        },
        [updatedParam]
    );

    /** Listagem dos dados API */
    const fetchData = async () => {
        const keysFetch = await fetch('/api/policy/keys');
        keysFetch
            .json()
            .then(res => {
                setClientKeys(res.map(g => g.filter(x => x.isClient)).filter(x => x.length > 0))
                setEnteringTraderKeys(res.map(g => g.filter(x => x.isEnteringTrader)).filter(x => x.length > 0))
            })
            .catch((error) => console.log(error));

        const allMarketsFetch = await fetch(`/api/policy/market`)
        allMarketsFetch
            .json()
            .then(res => setAllMarkets(res))
            .catch((error) => console.log(error));

        const standardMarketsFetch = await fetch(`/api/policy/market/standard`)
        standardMarketsFetch
            .json()
            .then(res => setStandardMarkets(res))
            .catch((error) => console.log(error));

        const policyFetch = await fetch(`/api/policy/${id}`)
        if (policyFetch.status !== 200) {
            if (policy.policyType === "Client")
                history.push(`/client/${policy.name}`);
            else
                history.push(`/operators/${policy.name}`);
        } else {
            policyFetch
                .json()
                .then(res => setPolicy(res))
                .catch((error) => console.log(error));
        }

        const policiesFetch = await fetch('/api/policy');
        policiesFetch
            .json()
            .then(res => setPolicyItems(res))
            .catch((error) => console.log(error));
    };

    /** Atualizar parâmetro API */
    const submitUpdateParam = (event) => {
        event.preventDefault();

        setDisabled(true);
        setErrorUpdatedParam(null);

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setValidatedUpdateParam(true);
            setDisabled(true);
            return event.stopPropagation();
        }

        conflictingParameter().then(conflicted => {
            if (conflicted?.hasConflict == true) {
                setConflict(conflicted)
                setShowConflictedParamConfirm(true)
                return event.stopPropagation();

            } else {
                const parameter = formatObjectNumberValues(updatedParam, ['value'])

                const options = {
                    method: "PUT",
                    headers: API_HEADER,
                    body: JSON.stringify(parameter)
                };

                fetch(`/api/policy/${id}/parameter`, options)
                    .then(async (response) => {
                        if (response.status !== 200) {
                            setErrorUpdatedParam(await response.json());
                            return setDisabled(false);
                        }
                        setShowFormUpdatedParameter(false);
                        setShowUpdatedParameter(true);
                        return setDisabled(false);
                    })
                    .catch((error) => console.log(error));
            }
        }
        )
    };

    /** Apagar parâmetro API*/
    const submitParamDelete = () => {
        setDisabled(true);

        const requestBody = {
            name: deletdParam.name,
            market: deletdParam.market,
            symbol: deletdParam.symbol,
        }

        const options = {
            method: "DELETE",
            headers: API_HEADER,
            body: JSON.stringify(requestBody)
        };

        fetch(`/api/policy/${id}/parameter`, options)
            .then(async (response) => {
                if (response.status !== 200) {
                    setErrorDeletedParam(await response.json());
                    return setDisabled(false);
                }

                setShowDeletedParameter(false);
                fetchData();
                return setDisabled(false);
            })
            .catch((error) => console.log(error));
    };

    /** Atualiza os dados do novo parâmetro */
    const handleNewParam = (event, mapValue = (e) => e) => {
        const { name, value } = event.target;

        if (name != "name")
            return setNewParam({ ...newParam, [name]: mapValue(value) });

        return setNewParam({ ...newParam, [name]: mapValue(value), market: getMarketForParameter(keys, value), symbol: "" });
    };

    /** Adiconar novo parâmetro */
    const submitNewParam = (event) => {
        event.preventDefault();

        setDisabled(true);
        setErrorNewParam(null);

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setValidatedNewParam(true);
            setDisabled(false);
            return event.stopPropagation();
        }

        if (checkDuplicateParameter()) {
            setDisabled(false);
            setErrorNewParam(new Error("Esse parâmetro já existe na política!"))
            return event.stopPropagation();
        }

        conflictingParameter().then(conflicted => {
            if (conflicted?.hasConflict == true) {
                setConflict(conflicted)
                setShowConflictedParamConfirm(true)
                return event.stopPropagation();

            } else {
                const parameter = formatObjectNumberValues(newParam, ['value'])

                const options = {
                    method: "POST",
                    headers: API_HEADER,
                    body: JSON.stringify(parameter),
                };

                fetch(`/api/policy/${id}/parameter`, options)
                    .then(async (response) => {
                        if (response.status !== 200) {
                            setErrorNewParam(await response.json());
                            return setDisabled(false);
                        }

                        setShowFormAddParameter(false);
                        setShowAddParameter(true);
                        return setDisabled(false);
                    })
                    .catch((error) => console.log(error))
            }
        })
    };

    /** Apagar política API*/
    const submitPolicyDelete = () => {
        setDisabled(true);
        setErrorDeletedPolicy(null)

        const options = {
            method: "DELETE",
            headers: API_HEADER
        };

        fetch(`/api/policy/${policy.id}`, options)
            .then(async (response) => {
                if (response.status === 200) {
                    setShowDeletedPolicyConfirm(false);
                    history.push('/policies');
                } else if (response.status === 422) {
                    setDeletedPolicyMessage(await response.json());
                    setShowDeletedPolicyConfirm(false);
                    setShowDeletedPolicyMessage(true);
                } else {
                    setErrorDeletedPolicy(await response.json());
                }

                return setDisabled(false);
            })
            .catch((error) => console.log(error));
    }

    /** Verifica se o novo parâmetro já existe */
    const checkDuplicateParameter = () => {
        const allParameters = [...policy.parameters];
        const newParameter = (typeof newParam === "undefined" || newParam == null) ? { ...updatedParam } : { ...newParam };
        const parametersSamePolicy = allParameters.filter(parameter => parameter.policyId.toString() === id);

        const duplicates = parametersSamePolicy.filter(parameter => {
            return (
                parameter.name === newParameter.name &&
                parameter.market === newParameter.market &&
                (parameter.symbol?.toUpperCase() ?? "") == (newParameter.symbol?.toUpperCase() ?? "")
            )
        })

        return duplicates.length > 0;
    }

    const conflictingParameter = () => {
        const param = typeof newParam === "undefined" || newParam == null
            ? {
                "name": updatedParam.name,
                "market": updatedParam.market,
                "value": updatedParam.value,
                "symbol": updatedParam.symbol,
                "comments": updatedParam.comments,
                "expirationDate": updatedParam.expirationDate
            }
            : { ...newParam }

        const options = {
            method: "POST",
            headers: API_HEADER,
            body: JSON.stringify(param)
        };
        setErrorConflictParam(null)

        var result =
            fetch(`/api/policy/${id}/hasConflicts`, options)
                .then(async (response) => {
                    if (response.status !== 200) {
                        setErrorConflictParam(await response.json());
                        return response.json().then(data => data);
                    }
                    return response.json().then(data => data)
                })
                .catch((error) => console.log(error));
        return result
    }

    /* Decide e realiza inserção ou alteração de parâmetro em política */
    const submitConflictParam = () => {
        setShowConflictedParamConfirm(false)

        if (checkDuplicateParameter()) {
            const parameter = formatObjectNumberValues(updatedParam, ['value'])
            const options = {
                method: "PUT",
                headers: API_HEADER,
                body: JSON.stringify(parameter)
            };

            fetch(`/api/policy/${id}/parameter`, options)
                .then(async (response) => {
                    if (response.status !== 200) {
                        setErrorUpdatedParam(await response.json());
                        return setDisabled(false);
                    }
                    setShowFormUpdatedParameter(false);
                    setShowUpdatedParameter(true);
                    return setDisabled(false);
                })
                .catch((error) => console.log(error));

        } else {
            const parameter = formatObjectNumberValues(newParam, ['value'])
            const options = {
                method: "POST",
                headers: API_HEADER,
                body: JSON.stringify(parameter),
            };

            fetch(`/api/policy/${id}/parameter`, options)
                .then(async (response) => {
                    if (response.status !== 200) {
                        setErrorNewParam(await response.json());
                        return setDisabled(false);
                    }

                    setShowFormAddParameter(false);
                    setShowAddParameter(true);
                    return setDisabled(false);
                })
                .catch((error) => console.log(error))
        }
    }

    const markets = (name) => {
        if (checksIfParameterIsMarket(keys, name)) {
            return standardMarkets
        } else {
            return allMarkets
        }
    }

    return (
        <>
            <SectionWrapper
                header={`Política: ${policy?.name} ${`(${policy?.policyType === "Client" ? 'Cliente' : 'Operador'} / ${policy?.shared ? 'Compartilhada' : 'Privada'})`}`}
            >
                <div className="int-d-flex int-align-items-center int-justify-content-between">
                    <div>
                        <Breadcrumb>
                            {getPolicyParentNames(policyItems, policy).map(data => (
                                <BreadcrumbItem key={data.id}>
                                    <Link href={`/policies/${data.id}`}>{data.name}</Link>
                                </BreadcrumbItem>
                            ))}

                            {getPolicyParentNames(policyItems, policy).length > 0 && (
                                <BreadcrumbItem>
                                    <p>{policy?.name}</p>
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                    </div>

                    <Button
                        variant="outline"
                        onClick={() => setShowDeletedPolicyConfirm(true)}
                        disabled={policy?.isMaster}
                    >
                        Apagar Política
                    </Button>
                </div>
            </SectionWrapper>

            <CommonParameters
                paramsCommon={paramsCommon}
                {...commonPropsParametersComp}
            />

            <CustomBmfParameters
                setShowSearchParamsModal={setShowSearchParamsModal}
                keys={keys} fetchData={fetchData}
                policyId={id}
                policy={policy}
                paramsCustomBmf={paramsCustomBmf} />

            {policy?.policyType === "Client" && (
                <>
                    <GuaranteeFactorParameters
                        fetchData={fetchData}
                        setDisabled={setDisabled}
                        disabled={disabled}
                        setNewParam={setNewParam}
                        paramsGuaranteeFactor={paramsGuaranteeFactor}
                        setSearchParam={setSearchParam}
                        {...commonPropsParametersComp}
                    />

                    <FixedIncomeParameters
                        setDisabled={setDisabled}
                        disabled={disabled}
                        {...commonPropsParametersComp}
                    />

                    <FundsParameters
                        setShowSearchParamsModal={setShowSearchParamsModal}
                        setDisabled={setDisabled}
                        disabled={disabled}
                        setSearchParam={setSearchParam}
                        {...commonPropsParametersComp}
                    />
                    <ClubsParameters
                        setShowSearchParamsModal={setShowSearchParamsModal}
                        setDisabled={setDisabled}
                        disabled={disabled}
                        setSearchParam={setSearchParam}
                        {...commonPropsParametersComp}
                    />
                    <LeverageFactorParameters
                        fetchData={fetchData}
                        setDisabled={setDisabled}
                        disabled={disabled}
                        setNewParam={setNewParam}
                        setSearchParam={setSearchParam}
                        paramsLeverageFactor={paramsLeverageFactor}
                        {...commonPropsParametersComp}
                    />
                </>
            )}

            <SectionClientsPage />

            {/* Modal - Formulário Atualizar parâmetro */}
            <Modal
                size="xlg"
                header="Editar Parâmetro"
                isOpen={showFormUpdatedParameter}
                onClose={() => setShowFormUpdatedParameter(false)}
            >
                <form onSubmit={submitUpdateParam}>
                    <ModalBody>
                        <div className="int-row">
                            <div className="int-col">
                                <Input
                                    isRequired
                                    disabled
                                    label="Nome"
                                    name="friendlyName"
                                    placeholder="Nome"
                                    value={updatedParam?.friendlyName}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <Input
                                    isRequired
                                    disabled
                                    label="Mercado"
                                    name="market"
                                    placeholder="Mercado"
                                    value={updatedParam?.market}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <Input
                                    disabled
                                    label="Símbolo"
                                    name="symbol"
                                    placeholder="Símbolo"
                                    value={updatedParam?.symbol}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <PolicyField
                                    isRequired
                                    label="Valor"
                                    keys={keys.flat()}
                                    keyName={updatedParam?.name}
                                    name="value"
                                    value={updatedParam?.value}
                                    onChange={handleUpdatedParam}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <Input
                                    label="Comentário"
                                    disabled={
                                        updatedParam ?
                                            Object.keys(updatedParam)?.length == 0
                                            : false
                                    }
                                    name="comments"
                                    placeholder="Comentário"
                                    value={updatedParam?.comments}
                                    onChange={handleUpdatedParam}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <DateTime
                                    label="Validade"
                                    name="validity"
                                    value={updatedParam?.expirationDate}
                                    minDate={today}
                                    maxDate={"9999-12-31"}
                                    setDateTime={date => date ? setNewParam({ ...newParam, ['expirationDate']: date }) : setNewParam(null)}
                                />
                            </div>
                        </div>

                        {errorUpdatedParam && (
                            <Alert>
                                Ocorreu um erro ao editar o parâmetro: {errorUpdatedParam.message}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowFormUpdatedParameter(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            margin="int-ml-2"
                            type='submit'
                        >
                            Atualizar parâmetro
                        </Button>
                    </ModalFooter>
                </form>

            </Modal>

            {/* Modal - Mensagem remoção com sucesso  */}
            <SimpleModal
                header="Os dados foram alterados com sucesso"
                isOpen={showSuccessDeleteModal}
                onClose={() => setShowSuccessDeleteModal(false)}
                bodyMessage=""
                footerButton={{
                    label: "Concluir",
                    onClick: () => { setShowSuccessDeleteModal(false) }
                }}
            />

            {/* Modal - Formulário Adicionar novo parâmetro */}
            <Modal
                size="xxlg"
                header="Adicionar novo parâmetro"
                isOpen={showFormAddParameter}
                onClose={() => setShowFormAddParameter(false)}
            >
                <form onSubmit={submitNewParam}>
                    <ModalBody>
                        <div className="int-row">
                            <div className="int-col-3">
                                <div  className="int-d-flex int-align-items-center">
                                    <Select
                                        isRequired
                                        label="Nome"
                                        name="name"
                                        disabled={newParam?.isNameDisabled}
                                        value={newParam?.name ?? ""}
                                        onChange={handleNewParam}
                                        data-testid="selectNameParam"
                                    >
                                        <option hidden value="">Selecione um nome</option>
                                        {keys.map((group, index) => (
                                            <optgroup label={group[0].groupName} key={index}>
                                                {group.map((item, index) => (
                                                    <option key={index} value={item.name}>
                                                        {item.friendlyName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </Select>

                                    <div className="int-ml-2">
                                        <Tooltip label={addParamKey?.description} />
                                    </div>
                                </div>
                            </div>

                            <div className="int-col int-pl-0">
                                <Select
                                    isRequired
                                    label="Mercado"
                                    name="market"
                                    
                                    disabled={disableMarketSelection(keys,newParam)}
                                    value={newParam?.market ?? ""}
                                    onChange={handleNewParam}

                                    data-testid="selectMarketParam"
                                >
                                    <option hidden value="">Selecione um mercado</option>
                                    {markets(newParam?.name).map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </Select>
                            </div>

                            <div className="int-col int-pl-0">
                                <Field
                                    format="Symbol"
                                    label="Símbolo"
                                    name="symbol"
                                    value={newParam?.symbol ?? ""}
                                    onChange={event => handleNewParam(event, (n) => n.trim().toUpperCase())}
                                    disabled={disableSymbolSelection(keys, newParam)}
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <PolicyField
                                    isRequired
                                    label="Valor"
                                    keys={keys.flat()}
                                    keyName={newParam?.name}
                                    name="value"
                                    value={newParam?.value ?? ""}
                                    onChange={handleNewParam}
                                    data-testid="value"
                                />
                            </div>

                            <div className="int-col int-pl-0">
                                <Input
                                    label="Comentário"
                                    name="comments"
                                    value={newParam?.comments ?? ""}
                                    onChange={event => handleNewParam(event, (n) => n.trimStart())}
                                />
                            </div>
                            <div className="int-col int-pl-0">
                                <DateTime
                                    label="Validade"
                                    name="validity"
                                    value={newParam?.expirationDate}
                                    minDate={today}
                                    maxDate={"9999-12-31"}
                                    setDateTime={date => date ? setNewParam({ ...newParam, ['expirationDate']: date }) : setNewParam(null)}
                                />
                            </div>
                        </div>

                        {errorNewParam?.message && (
                            <Alert>
                                Ocorreu um erro: {errorNewParam?.message}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowFormAddParameter(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            margin="int-ml-2"
                            type='submit'
                            data-testid="addButtonFinal"
                        >
                            Adicionar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Modal - Mensagem Adicionar Parâmetro  */}
            <SimpleModal
                header="O parâmetro foi adicionado!"
                isOpen={showAddParameter}
                onClose={() => setShowAddParameter(false)}
                bodyMessage="O parâmetro foi adicionado com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchData();
                        setShowAddParameter(false);
                        setNewParam(null)
                    }
                }}
            />

            {/* Modal - Mensagem Atualizar Parâmetro */}
            <SimpleModal
                header="O parâmetro foi atualizado!"
                isOpen={showUpdatedParameter}
                onClose={() => setShowUpdatedParameter(false)}
                bodyMessage="O parâmetro que você editou foi atualizado com sucesso!"
                footerButton={{
                    label: 'Concluir',
                    onClick: () => {
                        fetchData();
                        setShowUpdatedParameter(false);
                        setUpdatedParam(null);
                    }
                }}
            />

            {/* Modal - Mensagem Apagar Parâmetro */}
            <Modal
                header="Tem certeza que quer apagar o parâmetro?"
                isOpen={showDeletedParameter}
                onClose={() => setShowDeletedParameter(false)}
            >
                <ModalBody>
                    <p>
                        Caso você apague esse parâmetro, não será possível desfazer
                        essa ação.
                    </p>

                    {errorDeletedParam && (
                        <Alert>
                            Ocorreu um erro ao apagar o parâmetro: {errorDeletedParam.message}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() => setShowDeletedParameter(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        disabled={disabled}
                        margin="int-ml-2"
                        onClick={() => submitParamDelete()}
                    >
                        Apagar
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal - Apagar Política - Confirmação */}
            <Modal
                header="Você tem certeza que quer apagar a política atual?"
                isOpen={showDeletedPolicyConfirm}
                onClose={() => setShowDeletedPolicyConfirm(false)}
            >
                <ModalBody>
                    <p>
                        Essa ação <b>não pode</b> ser desfeita. Isso fará com que a
                        política e todos os seus dados sejam apagados
                        <b> permanentemente</b>.
                    </p>
                    <p>Escreva <b>'{policy?.name.toUpperCase().trim()}'</b> para confirmar.</p>

                    <Input
                        name="policyName"
                        value={deletePolicytConfirm}
                        onChange={event => setDeletePolicytConfirm(event.target.value.toUpperCase().trim())}
                    />

                    <Button
                        margin="int-mt-3"
                        isFullWidth
                        variant="outline"
                        onClick={submitPolicyDelete}
                        disabled={policy?.name.toUpperCase().trim() === deletePolicytConfirm ? false : true}
                    >
                        Apagar Política
                    </Button>

                    {errorDeletedPolicy?.message && (
                        <Alert>
                            Ocorreu um erro ao apagar a política: {errorDeletedPolicy.message}
                        </Alert>
                    )}
                </ModalBody>
            </Modal>

            {/* Modal - Parâmetros conflitantes - Confirmação */}
            <Modal
                header="Possível conflito entre parâmetros."
                isOpen={showConflictedParamConfirm}
                onClose={() => setShowConflictedParamConfirm(false)}
            >
                <ModalBody>
                    <table>
                        <tr>
                            <th>Nome:</th>
                            <td style={conflictTableStyle}> {conflict?.newParamFriendlyName}</td>
                            <td>{conflict?.friendlyName}</td>
                        </tr>
                        <tr>
                            <th>Mercado:</th>
                            <td style={conflictTableStyle}>{newParam?.market || updatedParam?.market}</td>
                            <td>{conflict?.market}</td>
                        </tr>
                        <tr>
                            <th>Símbolo:</th>
                            <td style={conflictTableStyle}>{newParam?.symbol || updatedParam?.symbol || "Não informado"}</td>
                            <td>{conflict?.symbol || "Não informado"}</td>
                        </tr>
                    </table>

                    {errorConflictParam?.message && (
                        <Alert>
                            Ocorreu um erro ao submeter o parâmetro: {errorConflictParam.message}
                        </Alert>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant="outline"
                        onClick={() => setShowConflictedParamConfirm(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        margin="int-ml-2"
                        onClick={() => submitConflictParam()}
                    >
                        Adicionar mesmo assim
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal - Apagar Política - Mensagem do resultado */}
            <SimpleModal
                header="Erro ao excluir a política"
                isOpen={showDeletedPolicyMessage}
                onClose={() => {
                    setShowDeletedPolicyMessage(false);
                    setDeletedPolicyMessage("");
                }}
                bodyMessage={deletedPolicyMessage}
                footerButton={{
                    label: 'Fechar',
                    onClick: () => {
                        setShowDeletedPolicyMessage(false);
                        setDeletedPolicyMessage("");
                    }
                }}
            />

            <ParamsSearchModal
                policies={policyItems}
                keys={keys}
                policyId={id}
                show={showSearchParamsModal}
                parameterType={searchParam}
                onHide={() => { setShowSearchParamsModal(false), setSearchParam(null) }}
            />
        </>
    );
}

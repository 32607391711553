import React, { Fragment, useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs";

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from "../../../components/Table";

import { formatInteger, formatDecimal } from '../../../common/formatters';

export function Custody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [custody, setCustody] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchClientCustody() {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/client/${account}/variableIncomeCustody`);

            const data = await response.json();

            const custodyItemsData = data?.items.map(item => ({
                ...item,
                available: formatInteger(item.available),
                projectedD0: formatInteger(item.projectedD0),
                projectedD1: formatInteger(item.projectedD1),
                projectedD2: formatInteger(item.projectedD2),
                pending: formatInteger(item.pending),
                total: formatInteger(item.total),
                currentPrice: formatDecimal(item.currentPrice),
                covered: formatInteger(item.covered),
                covering: formatInteger(item.covering),
                guaranteeFactor: item.guaranteeFactor != undefined ? (item.guaranteeFactor * 100).toFixed(0) + "%" : "-",
                guaranteeValue: formatDecimal(item.guaranteeValue),
                amount: formatDecimal(item.amount)
            }))

            setCustody(custodyItemsData);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        if(updateAllGrids){
            fetchClientCustody()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientCustody();
    }, [account]);

    const columns = [
        {
            Header: 'Ativo',
            accessor: data => data.symbol.toUpperCase(),
        },
        {
            Header: 'Carteira',
            accessor: data => data.wallet,
            columnAlign: 'right',
        },
        {
            Header: 'Disponível',
            accessor: data => data.available,
            columnAlign: 'right',
        },
        {
            Header: 'A liquidar',
            accessor: data => data.projectedD0,
            columnAlign: 'right',
        },
        {
            Header: 'D1',
            accessor: data => data.projectedD1,
            columnAlign: 'right',
        },
        {
            Header: 'D2',
            accessor: data => data.projectedD2,
            columnAlign: 'right',
        },
        {
            Header: 'Pendente',
            accessor: data => data.pending,
            columnAlign: 'right',
        },
        {
            Header: 'Total',
            accessor: data => data.total,
            columnAlign: 'right',
        },
        {
            Header: 'Coberto',
            accessor: data => data.covered,
            columnAlign: 'right',
        },
        {
            Header: 'Cobrindo',
            accessor: data => data.covering,
            columnAlign: 'right',
        },
        {
            Header: 'Cotação',
            accessor: data => data.currentPrice,
            columnAlign: 'right',
        },
        {
            Header: 'Valor',
            accessor: data => data.amount,
            columnAlign: 'right',
        },
        {
            Header: '% Limite',
            accessor: data => data.guaranteeFactor,
            columnAlign: 'right',
        },
        {
            Header: 'Valor c/ Deságio',
            accessor: data => data.guaranteeValue,
            columnAlign: 'right',
        }
    ]

    return (
        <SectionWrapper 
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Ações Vista e Opções"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={custody.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientCustody}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' : 
            (
                <SmartTable data={custody} columns={columns} localStorageKey={'ClientCustody'} /> 
            )}
        </SectionWrapper>
    )
}

import React, { useEffect, useState, Fragment } from 'react';
import { BsArrowClockwise } from 'react-icons/bs';

import { Loader } from '../../../components/Loader'; 
import { SectionWrapper } from '../../../components/SectionWrapper'
import { SmartTable, TableCell } from '../../../components/Table';

import { formatDecimal, formatDate, formatInteger } from '../../../common/formatters'

export function FundsCustody({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion }) {
    const [funds, setFunds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchClientFundsData () {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/fundsCustody`)
            
            if(!response.ok) {
                throw new Error("Occoreu um erro ao buscar os fundos de investimentos do cliente")
            }

            const fundsData = await response.json()
            setFunds(fundsData)
        } catch (err) {
            console.log(err)
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if(updateAllGrids){
            fetchClientFundsData()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        fetchClientFundsData()
    }, [account])


    const columns = [
        {
            Header: 'CNPJ',
            accessor: data => data.fundId,
        },
        {
            Header: 'Nome',
            accessor: data => data.fundName ?? '-',
            minWidth: 150,
        },
        {
            Header: 'Instituição',
            accessor: data => data.instituitionName ?? '-',
        },
        {
            Header: 'Qtd. Cotas',
            accessor: data => formatDecimal(data.quotasQty),
            singleLine: false,
            hint: "Quantidade de Cotas"
        },
        {
            Header: 'Qtd. Cotas Bloq.',
            accessor: data => formatDecimal(data.blockedQty),
            singleLine: false,
            hint: "Quantidade de Cotas Bloqueadas"
        },
        {
            Header: 'Preço p/ Cota',
            accessor: data => formatDecimal(data.quotaValue),
            singleLine: false,
            hint: "Preço para Cotas"
        },
        {
            Header: 'Valor Bruto',
            singleLine: false,
            accessor: data => formatDecimal(data.grossValue),
        },
        {
            Header: 'IR',
            accessor: data => formatDecimal(data.ir),
        },
        {
            Header: 'IOF',
            accessor: data => formatDecimal(data.iof),
        },
        {
            Header: 'Valor Bloqueado',
            singleLine: false,
            accessor: data => formatDecimal(data.blockedValue),
        },
        {
            Header: 'Valor Líquido',
            accessor: data => formatDecimal(data.netValue),
        },        
        {
            Header: 'Fat. Garantia',
            accessor: data => formatDecimal(data.guaranteeFactor),
            singleLine: false,
            hint: "Fator de Garantia"
        },
        {
            Header: 'Garantia',
            accessor: data => formatDecimal(data.guaranteeValue),
        }
    ]

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Fundos de Investimento"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            isEmpty={funds.length <= 0}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientFundsData}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' :
                (
                    <SmartTable data={funds} columns={columns} localStorageKey={'ClientFundsCustodyParams'} />
                )}
        </SectionWrapper>
    )
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { H2, H3 } from '../../../components/Typography'
import { Textarea } from "../../../components/Forms";
import { Link } from '../../../components/Link'
import { SectionWrapper } from "../../../components/SectionWrapper";

import { formatDateTime } from '../../../common/formatters';

type Params = {
    id: string;
}

type Client = {
    name: string;
}

type Valuation = {
    dtReceived: string;
    totalTimeMs: string;
}

export function HistoryDetails() {
    let { id } = useParams<Params>();

    const [valuation, setValuation] = useState<Valuation>();
    const [client, setClient] = useState<Client>();
    const [account, setAccount] = useState();

    useEffect(() => {
        const fetchData = async() => {
            const response = await fetch(`/api/RiskValuation/${id}`)

            if (response.ok) {
                const data = await response.json();

                setValuation(data);

                setAccount(data.account);
            }
        };

        fetchData();
    }, [id])

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`/api/client/${account}/register`)

            if (response.ok) {
                const data = await response.json();
                setClient(data);
            }
        };

        fetchData();
    }, [account]);

    return (
        <SectionWrapper
            header={`Validação: ${id.split('-', 1)[0]}`}
        >
            <div className="int-mt-3">
                {!account ? <p>Nenhum cliente foi encontrado</p> : 
                (
                    <H2>
                        <Link href={`/client/${account}`}>
                            {client?.name || `Cliente ${account}`}
                        </Link>
                    </H2>
                )}

                <div className="int-d-flex">
                    <p>ID: {id}</p>

                    <p className="int-ml-3">Data: {formatDateTime(valuation?.dtReceived)}</p>

                    <p className="int-ml-3">Duração: {valuation?.totalTimeMs || '-----'} ms</p>
                </div>
            </div>

            <H3 margin="int-mt-6">Contexto</H3>
            <Textarea
                name="context"
                rows={valuation? JSON.stringify(valuation, undefined, 2).split(/\r\n|\r|\n/).length + 5 : 12}
                readOnly
                value={valuation ? JSON.stringify(valuation, undefined, 2) : 'Sem dados do Contexto da validação'}
            />
        </SectionWrapper>
    );
};

import React, { InputHTMLAttributes } from 'react';
import "./styles.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    value?: string;
    name: string;
    label?: string;
    isRequired?: boolean;
    hintIcon?: boolean | string,
    index?: number;
}


export function Input({ 
    value= "",
    name, 
    label, 
    isRequired = false,
    hintIcon,
    children,
    onChange = () => {},
    index = 0,
    ...rest
}: InputProps) { 
    if (!label) {
        return (
            <input
                value={value}
                className="int-form-control"
                id={name} 
                name={name}
                required={isRequired} 
                onChange={e => [
                    onChange(e), 
                ]}
                {...rest}
            />
        )
    }

    return(
        <div className='input-container'>
            <div className="label-header">
                {label && <label className="int-form-label">{label} {isRequired ? <span style={{ color: 'var(--int-colors-red-500)' }}>*</span> : ''}</label>} {hintIcon && <span>{children}</span>}
            </div>
            <input 
                value={value}
                className="int-form-control"
                id={name} 
                name={name}
                required={isRequired} 
                onChange={e => [
                    onChange(e), 
                ]}
                {...rest}
            />
        </div>
    )
}

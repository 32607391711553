import React, { useEffect, useState } from "react";

import { Button, Field, Select } from "../../../components/Forms"
import { Modal, ModalBody, ModalFooter } from '../../../components/Modal';
import { Loader } from '../../../components/Loader'
import { SmartTable } from "../../../components/Table";
import { Alert } from "../../../components/Alert";

import { formatParamValue } from "../../../common/formatters";
import { objToQueryString } from "../../../common/api.js";

type Entry = {
    name: string;
}

type Results = {
    entry: Entry
}

export function ParamsSearchModal({ policies, keys, policyId, show, onHide, parameterType }) {
    const [results, setResults] = useState<Results | null>();
    const [formatedResults, setFormatedResults] = useState([]);
    const [parameter, setParameter] = useState("");
    const [symbol, setSymbol] = useState("");
    const [market, setMarket] = useState("");
    const [allMarkets, setAllMarkets] = useState([]);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        allStandardMarketsFetch()
    }, [])

    useEffect(() => {
        if (parameterType == null) return;

        setParameter(parameterType?.name)

    }, [parameterType]);

    useEffect(() => {
        if (!results?.entry) return;

        let resultsArray = Array(results?.entry);

        const parsedResults = resultsArray.map((item) => {
            let item2 = keys.flat().find((i2) => i2.name === item.name);
            return item2 ? { ...item, ...item2 } : item;
        });

        setFormatedResults(parsedResults);
    }, [keys, results]);

    function cleanFilters() {
        setParameter('');
        setSymbol("");
        setMarket("");
    }

    async function allStandardMarketsFetch() {
        try {
            const response = await fetch('/api/policy/market/Standard')

            if(!response.ok) {
                const error = await response.json()
                throw new Error(error?.message)
            }

            const data = await response.json()
            setAllMarkets(data)
        } catch (err) {
            console.log(err)
        }
    }
    function handleParamsSearch(event) {
        event.preventDefault();

        setFormatedResults([])
        setResults(null)
        setLoading(true);
        setDisabled(true);
        setError(null);

        const queryString = objToQueryString({
            policyId,
            parameter,
            symbol,
            market
        });

        fetch(`/api/policy/search?${queryString}`)
            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();

                    setResults(data);
                } else if (res.status === 400) {
                    setError(new Error(`Não foi encontrada política com o id: ${policyId}`));
                } else {
                    setError(new Error("Ocorreu um erro ao buscar os parâmetros."));
                }
            })
            .catch((err) => setError(err))
            .finally(() => {
                setLoading(false);
                setDisabled(false);
                parameterType = null;
            });
    }
    const columns = [
        {
            Header: "Nome",
            accessor: "friendlyName",
        },
        {
            Header: "Mercado",
            accessor: "market.enumValue",
            Cell: ({ value }) => (value ? value?.toUpperCase() : ""),
        },
        {
            Header: "Ativo",
            accessor: "symbol",
            Cell: ({ value }) => (value ? value?.toUpperCase() : ""),
        },
        {
            Header: "Valor",
            accessor: (data) => formatParamValue(data),
        },
        {
            Header: "Política",
            accessor: (data) => getPolicyNameById(policies, data.policyId),
        },
    ];

    const getPolicyNameById = (all, id) => {
        const parent = all.find((x) => x.id === id);

        return parent?.name;
    };                

    return (
        <Modal
            size="xlg"
            header="Busca de Parâmetros"
            isOpen={show} 
            onClose={() => {onHide(), setParameter(null)}}
        >

            <ModalBody>
                <form onSubmit={(event) => handleParamsSearch(event)}>
                    <div className="int-row">
                        <div className="int-col">
                            <Select
                                data-testid="search-parameter"
                                isRequired
                                label="Parâmetro"
                                name="parameter"
                                value={parameter}
                                onChange={(event) => setParameter(event.target.value)}
                            >
                                <option hidden value="">
                                    Selecione um nome
                                </option>
                                {keys.map((group, index) => (
                                    <optgroup label={group[0].groupName} key={index}>
                                        {group.map((item, index) => (
                                            <option key={index} value={item.name}>
                                                {item.friendlyName}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </Select>
                            
                        </div>

                        <div className="int-col int-pl-0">
                            <Field
                                data-testid="search-symbol"
                                required
                                label="Ativos"
                                name="symbol"
                                format="Symbol"
                                value={symbol}
                                onChange={(e) =>
                                    setSymbol(e?.target?.value.trimStart().toUpperCase())
                                }
                            />
                        </div>
                        
                        <div className="int-col int-pl-0">
                        <Select
                                data-testid="search-market"                                
                                label="Mercado"
                                name="market"
                                value={market}
                                onChange={(event) => setMarket(event.target.value)}
                            >
                                <option hidden value="">
                                    Selecione um mercado
                                </option>
                                {allMarkets.map((item, index) => (
                                     <option key={index} value={item}>
                                        {item}
                                     </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="int-d-flex int-justify-content-end int-mt-2">
                        <Button
                            id="filter"
                            variant="outline"
                            onClick={cleanFilters}
                        >
                            Limpar todos os filtros
                        </Button>

                        <Button
                            margin="int-ml-2"
                            type="submit"
                            disabled={disabled}
                        >
                            Buscar
                        </Button>
                    </div>
                </form>

                {loading && <Loader flex/>}

                {error?.message && (
                    <Alert 
                        margin="int-mt-5"
                    >
                        {error?.message}
                    </Alert>
                )}

                {loading || error?.message || !formatedResults ? "" : formatedResults.length <= 0 ? (
                    <p className="int-mt-5 int-text-center">
                        Nenhum resultado foi encontrado
                    </p>
                ) : (
                    <SmartTable columns={columns} data={formatedResults} localStorageKey={'PolicyParamsSearchModal'} />
                )}
            </ModalBody>

            <ModalFooter>
                <Button variant="outline" onClick={() => {onHide(), setParameter(null)}}>
                    Fechar busca
                </Button>
            </ModalFooter>
        </Modal>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';

import { Button, Field, Select } from '../../components/Forms';
import { SectionWrapper } from '../../components/SectionWrapper';

import { removeArrayItem, setArrayOfObjects } from '../../common/variables';

type TCustody = {
    symbol: string;
    market: string;
    wallet: string;
    available: string;
    pending: string;
    projectedD0: string;
    projectedD1: string;
    projectedD2: string;
}

interface CustodyProps {
    custody: TCustody[];
    setCustody: (value: TCustody[]) => void;
    custodyItem: TCustody;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
    setValidationErrors: React.Dispatch<React.SetStateAction<[]>>;
    deleteOldErrors: (headerName: string, index: number) => void;
}

const headerName = "Custódia Bovespa";

export function Custody({ custody, setCustody, custodyItem, allAccordionsOpen, setValidationErrors,
    deleteOldErrors }: CustodyProps) {

    const [standardMarkets, setStandardMarkets] = useState<string[]>([])
    const highlightedItemRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('/api/policy/market/standard')

                if (!response.ok) {
                    const error = await response.json()
                    throw new Error(error?.message)
                }

                const data = await response.json()
                setStandardMarkets(data)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])

    function addNewCustody() {
        if (custody.length !== 0) resetAnimation();
        setCustody([ custodyItem, ...custody ]);
        if (custody.length !== 0) activateAnimation();
    }

    function resetAnimation(): void {
        highlightedItemRef.current.style.animation = '';
        highlightedItemRef.current.style.animationIterationCount = '';
        void highlightedItemRef.current.offsetWidth;
    }

    function activateAnimation(): void {
        const animation = 'smooth-fade-in 3s linear forwards 1';
        highlightedItemRef.current.style.animation = animation;
        highlightedItemRef.current.children[0].children[0].children[0].focus();
    }

    function removeAllCustody() {
        setCustody([custodyItem])
    }
    
    function removeAllErrors() {
        setValidationErrors([]);
    }

    return(
        <SectionWrapper
            headerTag="h3"
            header="Custódia Bovespa"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            // isEmpty={custody.length <= 0}
        >
            <div className="int-d-flex int-justify-content-end int-mb-4">
                <button
                    data-testid="custody-add-row"
                    type="button"
                    className="int-btn-icon int-btn-icon-outline"
                    onClick={addNewCustody}
                >
                    <FiPlus size={20} />
                </button>
            </div>
            <table className="int-table">
                <thead>
                    <tr>
                        <th>Ativo</th>
                        <th>Mercado</th>
                        <th>Carteira</th>
                        <th>Disponível</th>
                        <th>A liquidar</th>
                        <th>D1</th>
                        <th>D2</th>
                        <th>Pendente</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {custody.map((item, index) => (
                        <tr key={index} ref={index === 0 ? highlightedItemRef : null}>
                            <td>
                                <Field
                                    data-testid="custody-symbols"
                                    required
                                    format="Symbol"
                                    name="symbol"
                                    value={item?.symbol}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event, 
                                                true
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Select
                                    data-testid="custody-market"
                                    required
                                    name="market"
                                    value={item?.market}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody,
                                                index,
                                                event,
                                                true
                                            )
                                        )
                                    }
                                    index={index}
                                >
                                    <option hidden value="">Selecione um mercado</option>
                                    {standardMarkets.map((item, index) => (
                                        <option 
                                            data-testid="custody-market-options" 
                                            key={index} 
                                            value={item}
                                        >
                                            {item}
                                        </option>
                                    ))}
                                </Select>
                            </td>

                            <td>
                                <Field
                                    data-testid="custody-wallet"
                                    required
                                    format="Number"
                                    name="wallet"
                                    value={item?.wallet}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Field
                                    data-testid="custody-available"
                                    required
                                    format="Integer"
                                    name="available"
                                    value={item?.available}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Field
                                    data-testid="custody-projectedD0"
                                    required
                                    format="Integer"
                                    name="projectedD0"
                                    value={item?.projectedD0}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody,
                                                index,
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Field
                                    data-testid="custody-projectedD1"
                                    required
                                    format="Integer"
                                    name="projectedD1"
                                    value={item?.projectedD1}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Field
                                    data-testid="custody-projectedD2"
                                    required
                                    format="Integer"
                                    name="projectedD2"
                                    value={item?.projectedD2}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>

                            <td>
                                <Field
                                    data-testid="custody-pending"
                                    required
                                    format="Integer"
                                    name="pending"
                                    value={item?.pending}
                                    onChange={
                                        event => setCustody(
                                            setArrayOfObjects(
                                                custody, 
                                                index, 
                                                event
                                            )
                                        )
                                    }
                                    index={index}
                                />
                            </td>
                            <td>
                                <BsTrashFill
                                    data-testid="custody-remove-item"
                                    size={22}
                                    color="var(--chakra-colors-orange-400)"
                                    className="ml-3 flex-shrink-0"
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                        () => [setCustody(
                                            removeArrayItem(index, custody)
                                        ),
                                            deleteOldErrors(headerName, index)
                                        ]
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="int-my-4">
                <Button
                    variant="outline"
                    disabled={custody.length < 2}
                    onClick={() => [removeAllCustody(), removeAllErrors()]}
                >
                    Remover todos os items
                </Button>
            </div>
        </SectionWrapper>
    )
}
import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';

import "./styles.css"

interface TabProps {
    label: string;
    tabIndex?: string;
    setTabSelectedRow?: Function;
    tabSelectedRow: string;
    zIndex?: number;
};

export function Tab({ 
    label, 
    tabIndex,
    setTabSelectedRow,
    tabSelectedRow,
    zIndex = 1
}: TabProps) {
    const context = useContext(ThemeContext)

    const showSelectedtab = () => {
        if(!setTabSelectedRow) {
            return;
        };
        if(tabSelectedRow != tabIndex) {
            setTabSelectedRow(tabIndex);
        };
    };

    return(
        <li className='int-tab-margin' style={{whiteSpace: "nowrap", zIndex: zIndex}}>
            <button className="int-tab-style"
                    onClick={showSelectedtab} 
                    style={{
                        borderRadius: "0 1.5rem 0 0",
                        backgroundColor: `${tabSelectedRow === tabIndex ? "#fe7b1d" : ""}`
                    }}
            >
                    {label}
            </button>
        </li>
    );
};
import React, { ReactNode } from 'react'

interface TabsProps {
    children: ReactNode;
}

export function Tabs({ children }: TabsProps) {
    return (
        <div>
            <ul className="int-tabs">
                {children}
            </ul>
        </div>
    )
} 
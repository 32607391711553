import React, { createContext, useState, useEffect } from 'react'

export const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark')

  useEffect(() => {
    if (localStorage.getItem('theme') === 'dark' || !('theme' in localStorage)) {
      setTheme('dark')
      document.body.classList.add("dark")
    } else {
      setTheme('light')
      document.body.classList.add("light")
    }
  }, [])

  function toggleThemeMode() {
    if (
      !localStorage.getItem('theme') ||
      localStorage.getItem('theme') === 'dark'
    ) {
      localStorage.theme = 'light'
      setTheme('light')
      document.body.classList.add("light")
      document.body.classList.remove("dark")
    } else {
      localStorage.theme = 'dark'
      setTheme('dark')
      document.body.classList.add("dark")
      document.body.classList.remove("light")
    }
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleThemeMode }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
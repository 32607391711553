import React from 'react'

import { ActiveLink } from './ActiveLink'

import "./styles.css"

interface NavLinkProps {
    href: string;
    children: string;
    blank?: boolean;
    shouldMatchExactHref?: boolean;
}

export function NavLink({ href, children, blank = false, shouldMatchExactHref = false, ...rest }: NavLinkProps) {
    if(blank) {
        return(
            <a href={href} target="_blank" {...rest}>
                {children}
            </a>
        )
    }

    return(
        <ActiveLink shouldMatchExactHref={shouldMatchExactHref} to={href} {...rest}>
            <p className="int-navbar-item">{children}</p>
        </ActiveLink>
    )
}
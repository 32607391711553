import React, { ReactNode } from 'react'

import "./styles.css"

interface AlertProps {
    children: ReactNode | string;
    margin?: string;
    isLoading?: boolean;
}

export function Alert({ children, margin, isLoading, ...rest }: AlertProps) {
    return(
        <div className={`int-alert ${margin}  ${isLoading ? 'is-loading' : ''}`} {...rest}>
            {children}
        </div>
    )
}
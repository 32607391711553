import React from "react";
import "./styles.css"

interface RadioButtonProps {
    margin: { left: string; top: string };
    name: string;
    policy?: string;
    options: { value: string; label: string }[];
    radioChecked: string;
    setRadioChecked: Function;
}

const RadioButton = ({ margin, name, policy, options, radioChecked, setRadioChecked }: RadioButtonProps) => {

    React.useEffect(() => {
        if (policy === "Client") {
            setRadioChecked("cliente");
        } else {
            setRadioChecked("operador");
        }
    }, [policy]);

    const onChange = ({target}) => {
        setRadioChecked(target.value)
    };

    return (
        <div className="int-radioButton" style={{marginLeft: margin.left, marginTop: margin.top}}>
            {options.map((option) => (
                <div key={option.value}>
                    <label>
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={radioChecked === option.value}
                            onChange={onChange}
                        />
                        {option.label}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default RadioButton;

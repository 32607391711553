import React from 'react'

import { Input, Field } from '../../components/Forms'
import { SectionWrapper } from '../../components/SectionWrapper'

import { setObject } from '../../common/variables'

type TClientRegistry = {
    isQualified: string;
    advisor: string;
}

interface ClientRegistryProps {
    clientRegistry: TClientRegistry;
    setClientRegistry: (value: TClientRegistry) => void;
    allAccordionsOpen?: {     
        isAllAccordionOpen: boolean;
        count: number;
    };
}

export function ClientRegistry({ clientRegistry, setClientRegistry, allAccordionsOpen }: ClientRegistryProps) {
    return (
        <SectionWrapper
            headerTag="h3"
            header="Detalhes do Cliente"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
        >
            <table className="int-table">
                <thead>
                    <tr>
                        <th>Qualificado</th>
                        <th>Assessor</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <Field
                                data-testid="client-registry-isQualified"
                                required
                                format="Bool"
                                name="isQualified"
                                value={clientRegistry?.isQualified}
                                onChange={
                                    event => setClientRegistry(
                                        setObject(event, clientRegistry)
                                    )
                                }
                            />
                        </td>
                        <td>
                            <Input
                                data-testid="client-registry-advisor"
                                name="advisor"
                                value={clientRegistry?.advisor}
                                onChange={
                                    event => setClientRegistry(
                                        setObject(event, clientRegistry)
                                    )
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </SectionWrapper>
    )
}
import React, { HTMLAttributes, ReactNode } from 'react'

import "./styles.css"

interface ListGroupProps extends HTMLAttributes<HTMLUListElement> {
    children: ReactNode;
    margin?: string;
}

export function ListGroup({ children, margin, ...rest }: ListGroupProps) {
    return(
        <ul className={`int-list-group ${margin}`} {...rest}>
            {children}
        </ul>
    )
}
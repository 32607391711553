import React, { useEffect, useState } from 'react'

import { H4 } from '../../components/Typography'
import { Button, Field } from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import { SmartTable } from '../../components/Table'
import { SectionWrapper } from '../../components/SectionWrapper'
import { Alert } from '../../components/Alert'
import { formatDecimal, formatDate } from '../../common/formatters';
import { objToQueryString, dateQueryParse } from '../../common/api.js';

import { useQueryParams } from '../../hooks/useQueryParams'
import { SimpleModal } from '../../components/Modal/SimpleModal'
import { FiSearch } from 'react-icons/fi'

type QueryObject = {
    account?: string;
    dtReleaseStart?: string;
    dtReleaseEnd?: string;
    dtSettlementStart?: string;
    dtSettlementEnd?: string;
    ids?: string;
}

export function CheckingAccountHistory() {
    const { setParam, updateQuery } = useQueryParams()
    const [queryObject, setQueryObject] = useState<QueryObject>();

    const [client, setClient] = useState(setParam("account")); 
    const [initialReleaseDate, setInitialReleaseDate] = useState(setParam("dtReleaseStart")); 
    const [finalReleaseDate, setFinalReleaseDate] = useState(setParam("dtReleaseEnd"));
    const [initialSettlementDate, setInitialSettlementDate] = useState(setParam("dtSettlementStart"));
    const [finalSettlementDate, setFinalSettlementDate] = useState(setParam("dtSettlementEnd"));

    const [showFilterModal, setShowFilterModal] = useState(true);
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [results, setResults] = useState<[]>()
    
    const [searched, setSearched] = useState(false)
    
    const today = new Date().toISOString().substr(0, 10);

    useEffect(() => {
        if (queryObject) {
        (async function () {
            await fetchOrderData()
            setLoading(false)
        })();
        }
    }, [queryObject])

    const columns = [
        {
            Header: 'Conta',
            accessor: 'account',
            width: 150
        },
        {
            Header: 'Valor Lançamento',
            accessor: 'valorLancamento',
            Cell: ({ value }) => formatDecimal(value),
            columnAlign: 'right'

        },        
        {
            Header: 'Saldo',
            accessor: 'saldo',
            Cell: ({ value }) => formatDecimal(value),
            columnAlign: 'right'
        },
        {
            Header: 'Data Lançamento',
            accessor: ({ dataLancamento }) => formatDate(dataLancamento),
            width: 150
        },   
        {
            Header: 'Data Liquidação',
            accessor: ({ dataLiquidacao }) => formatDate(dataLiquidacao),
            width: 150
        },
        {
            Header: 'Código Histórico',
            accessor: 'codigoHistorico'
        },
        {
            Header: 'Código Atividade',
            accessor: 'codigoAtividade'
        },
        {
            Header: 'Descrição',
            accessor: 'descricaoLancamento',
            width:350
        }
    ]

    const fetchOrderData = async () => {
        const apiQueryString = objToQueryString({
            ...queryObject,
            account: dateQueryParse(queryObject?.account),
            dtReleaseStart: dateQueryParse(queryObject?.dtReleaseStart),
            dtReleaseEnd: dateQueryParse(queryObject?.dtReleaseEnd),
            dtSettlementStart: dateQueryParse(queryObject?.dtSettlementStart),
            dtSettlementEnd: dateQueryParse(queryObject?.dtSettlementEnd)
        });
        if (isQueryEmpty){
            setFiltering(true);
            return;
        }
        try {
            setShowFilterModal(false)
            setFiltering(false);
            const response = await fetch(`/api/checkingAccount?${apiQueryString}`)

            if(!response.ok) {
                const error = await response.json()
                throw error
            }

            const data = await response.json()

            setResults(data)
        } catch(err) {
            setError(true)
        }
    }

    const cleanFilters = () => {
        setClient("");
        setInitialReleaseDate("");
        setFinalReleaseDate("");
        setInitialSettlementDate("");
        setFinalSettlementDate("");


        updateQuery(" ")
    }

    const setQueryObjectFilters = () => {

        const queryStringFilters = {
            account: client,
            dtReleaseStart: initialReleaseDate,
            dtReleaseEnd: finalReleaseDate,
            dtSettlementStart: initialSettlementDate,
            dtSettlementEnd: finalSettlementDate,
        };

        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();
        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const handleCheckingAccountSearch = async event => {
        event.preventDefault()
        setLoading(true)
        setError(false)

        if (!searched)
        setSearched(true)

        setQueryObjectFilters()
    }

    const setFinalReleaseDateOnBlur = () => {
        if (finalReleaseDate === '' || finalReleaseDate === undefined) {
            const date = initialReleaseDate.substr(0, 10) + "T" + "23:59";
            setFinalReleaseDate(date)
        }
    }

    const setFinalSettlementDateOnBlur = () => {
        if (finalSettlementDate === '' || finalSettlementDate === undefined) {
            const date = initialSettlementDate.substr(0, 10) + "T" + "23:59";
            setFinalSettlementDate(date)
        }
    }
    const renderFilterElements = () => {
        return(
                <form onSubmit={e => handleCheckingAccountSearch(e)}>
                    <div className="int-mt-4 int-row">
                        <div className="int-col">
                            <H4>Data de Lançamento</H4>
                            <div className="int-row">
                                <div className="int-col">
                                    <DateTime
                                        label="De"
                                        name="initialReleaseDate"
                                        value={initialReleaseDate}
                                        setDateTime={setInitialReleaseDate}
                                        maxDate={today}
                                        onBlurDate={setFinalReleaseDateOnBlur}
                                        timeDefault="00:00"
                                        notShowTimeInput
                                    />
                                </div>

                                <div className="int-col int-pl-0">
                                    <DateTime
                                        label="Para"
                                        name="finalReleaseDate"
                                        value={finalReleaseDate}
                                        setDateTime={setFinalReleaseDate}
                                        minDate={initialReleaseDate ? initialReleaseDate.substr(0, 10) : ''}
                                        maxDate={today}
                                        timeDefault="23:59"
                                        notShowTimeInput
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="int-col">
                            <H4>Data de Liquidação</H4>
                            <div className="int-row">
                                <div className="int-col">
                                    <DateTime
                                        label="De"
                                        name="initialSettlementDate"
                                        value={initialSettlementDate}
                                        setDateTime={setInitialSettlementDate}
                                        maxDate={new Date("9999-12-31").toISOString().substr(0, 10)}
                                        onBlurDate={setFinalSettlementDateOnBlur}
                                        timeDefault="00:00"
                                        notShowTimeInput
                                    />
                                </div>

                                <div className="int-col int-pl-0">
                                    <DateTime
                                        label="Para"
                                        name="finalSettlementDate"
                                        value={finalSettlementDate}
                                        setDateTime={setFinalSettlementDate}
                                        minDate={initialSettlementDate ? initialSettlementDate.substr(0, 10) : ''}
                                        maxDate={new Date("9999-12-31").toISOString().substr(0, 10)}
                                        timeDefault="23:59"
                                        notShowTimeInput
                                    />
                                </div>
                            </div>
                        </div>

                        
                    </div>

                    <div className="int-mt-4 int-row">
                        <div className="int-col">
                            <H4>Conta do cliente</H4>
                            <div className="int-row">
                                <div className="int-col">
                                    <Field
                                        data-testid="checkingAccountHistory-client"
                                        format="Number"
                                        value={client || ''}
                                        onChange={(e) => setClient(e?.target?.value.trimStart())}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {filtering && ( 
                        <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
                    )}
                </form>
                )                 
    }
    return(
        <SectionWrapper 
        header="Histórico de Extratos de Conta Corrente"
        searchButton={
            <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
        }>
                <SimpleModal 
                    header="Filtros de busca"
                    isOpen={showFilterModal}
                    onClose={() => setShowFilterModal(false)}
                    bodyMessage={renderFilterElements()}
                    footerButtons={[
                        {
                            label: 'Limpar Todos os filtros',
                            onClick:() => cleanFilters(),
                            variant: 'outline'
                        },   
                        {
                            label: 'Buscar',
                            onClick: (e) => {
                                handleCheckingAccountSearch(e)
                            },
                            margin: 'int-ml-2'
                        }
                    ]}
                />                                    
            {loading && <Loader flex/>}

            {error && ( 
                <Alert>Ocorreu um erro na busca</Alert>
            )}
            { loading || error || filtering ? '' : results && results.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) : results && (
                <SmartTable 
                columnFilters 
                columns={columns} 
                data={results} 
                gapColumnsFilter={2}
                columnsResizable 
                lockHeader 
                localStorageKey={'CheckingAccountHistorySearch'} />              
            )}
        </SectionWrapper>      
    )
}

import React, { useEffect, useState } from 'react'
import { dateQueryParse, objToQueryString } from '../../common/api';
import { formatDate } from '../../common/formatters';

import { H4 } from '../../components/Typography'
import { Button} from '../../components/Forms'
import { Loader } from '../../components/Loader'
import { DateTime } from '../../components/Forms'
import { Alert } from '../../components/Alert'
import { SectionWrapper } from '../../components/SectionWrapper'
import { SmartTable, TableCell } from '../../components/Table';
import { useQueryParams } from '../../hooks/useQueryParams'
import { FiSearch } from 'react-icons/fi';
import { SimpleModal } from '../../components/Modal/SimpleModal';

type QueryObject = {
    from?: string;
    to?: string;
}

export function Holidays() {
    const { setParam, updateQuery } = useQueryParams()
    const [holidays, setHolidays] = useState([]);
    const [queryObject, setQueryObject] = useState<QueryObject>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [from, setFrom] = useState(setParam("from")); 
    const [to, setTo] = useState(setParam("to"));
    const [searched, setSearched] = useState(false)
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(true);

    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() );
    var initDate = currentDate.toISOString().substr(0,10);

    useEffect(() => {
        setFrom(initDate)
    },[initDate])

    const columns = [{
        Header: 'Data',
        accessor: 'holidayDate'
    },{
        Header: 'Dia da semana',
        accessor: 'weekDay'
    }]

    var finalDate = new Date(new Date().getFullYear(), 11, 31)
    finalDate.setDate(finalDate.getDate() );
    var lastDate = finalDate.toISOString().substr(0,10);

    useEffect(() => {
        setTo(lastDate)
    },[lastDate])


    useEffect(() => {
        if (queryObject) {
        (async function () {
            await fetchHolidays()
            setLoading(false)
        })();
        }
    }, [queryObject])


    const fetchHolidays = async () => {   
        const apiQueryString = objToQueryString({
            ...queryObject,
            from: dateQueryParse(queryObject?.from),
            to: dateQueryParse(queryObject?.to)
            });
            if (isQueryEmpty){
                setFiltering(true);
                return;
            }
            try {
                setShowFilterModal(false);
                setFiltering(false);                
                const response = await fetch(`/api/Holiday?${apiQueryString}`)
                if(!response.ok) {
                    const error = await response.json()
                    throw error
                }
            const data = await response.json();

            const itemsData = data?.map(item => ({
                ...item,
                weekDay: item.weekDay ?? "-",
                holidayDate: formatDate(item.holidayDate)
            }))

            setHolidays(itemsData);
        } catch(err) {
            setError(true)
        } finally {
            setLoading(false)
        }
    };
    const setQueryObjectFilters = () => {

        const queryStringFilters = {
            from: from,
            to: to,

        };
        const checkIsEmpty = () => {
 
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach(item => {
                if(queryStringFilters[item] !== ''){
                    setIsQueryEmpty(false);
                };
            });
        };
        checkIsEmpty();
        setQueryObject(queryStringFilters)
        updateQuery(objToQueryString(queryStringFilters))
    }

    const handleHolidaySearch = async event => {
        event.preventDefault()
        setLoading(true)
        setError(false)

        if (!searched)
        setSearched(true)

        setQueryObjectFilters()
    }



    const cleanFilters = () => {
        setFrom("");
        setTo("");
        updateQuery(" ")
    }
    const renderFilterElements = () => {
        return(
                <form onSubmit={e => handleHolidaySearch(e)}>
                    <div className="int-mt-4 int-row">
                        <div className="int-col">
                            <H4>Data do feriado</H4>
                            <div className="int-row">
                                <div className="int-col">
                                    <DateTime
                                        notShowTimeInput={true}
                                        label="De"
                                        name="from"
                                        value={from}
                                        setDateTime={setFrom}
                                    />
                                </div>

                                <div className="int-col int-pl-0">
                                    <DateTime
                                        notShowTimeInput={true}
                                        label="Para"
                                        name="to"
                                        value={to}
                                        setDateTime={setTo}
                                        minDate={from ? from.substr(0, 10) : ''}
                                    />
                                </div>
                            </div>
                        </div>              
                    </div>
                    {filtering && ( 
                        <Alert margin='int-mt-3'>Ao menos um campo de filtragem deve ser preenchido</Alert>
                    )}
                </form>
        )
    }

    return (
        <div data-testid='holiday-history' style={{maxWidth: "1360px", margin: '0 auto'}}>
            <SectionWrapper 
                header="Histórico de Feriados"        
                searchButton={
                <Button onClick={() => setShowFilterModal(true)}><FiSearch/></Button>
                }>       
            <SimpleModal 
                    header="Filtros de busca"
                    isOpen={showFilterModal}
                    onClose={() => setShowFilterModal(false)}
                    bodyMessage={renderFilterElements()}
                    footerButtons={[
                        {
                            label: 'Limpar Todos os filtros',
                            onClick:() => cleanFilters(),
                            variant: 'outline'
                        },   
                        {
                            label: 'Buscar',
                            onClick: (e) => {
                                handleHolidaySearch(e)
                            },
                            margin: 'int-ml-2'
                        }
                    ]}
                />                        
            {loading && <Loader flex/>}
            
            {error && ( 
                <Alert>Ocorreu um erro na busca</Alert>
            )}
                
            { loading || error || filtering ? ""  : holidays && holidays.length === 0 ? (
                <p className="int-mt-5 int-text-center">
                    Nenhum resultado foi encontrado
                </p>
            ) :
            (
                <SmartTable
                    gapColumnsFilter={2}
                    columnsResizable
                    lockHeader={true}
                    columnFilters
                    columns={columns}
                    data={holidays }
                    localStorageKey={'Holidays'}
                /> 
            )}
            </SectionWrapper>
        </div>
    )
}   

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button, Input, Textarea, DateTime } from "../../../components/Forms";
import { SimpleModal } from "../../../components/Modal";
import { Loader } from "../../../components/Loader";
import { SectionWrapper } from "../../../components/SectionWrapper";
import { Alert } from "../../../components/Alert";
import { MdPlayArrow } from 'react-icons/md'

import { removeKeyObject, sanitizeName } from "../../../common/variables";
import { API_HEADER } from "../../../constants/api";
import { H4 } from "../../../components/Typography";
import { dateQueryParse, objToQueryString } from "../../../common/api";
import { useQueryParams } from "../../../hooks/useQueryParams";
import MultipleCheckboxFilter from "../../../components/MultipleCheckboxFilter";
import { marketsEnum } from "../../../common/enums";
import { formatDate } from "../../../common/formatters";
import { ThemeContext } from "../../../contexts/ThemeContext";

type QueryObject = {
    symbols?: string;
    markets?: string;
    initSettlementDate?: string;
    finalSettlementDate?: string;
    allowNoDueDateSymbols?: string;
};
type securityListItem = {
    name: string;
    market: string;
    settlementDate?: string;
};

export function NewAutoVirtualMarket() {
    const history = useHistory();
    const [queryObject, setQueryObject] = useState<QueryObject>();
    const { setParam, updateQuery } = useQueryParams();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [symbols, setSymbols] = useState(setParam("symbols"));
    const [markets, setMarkets] = useState(setParam("markets"));
    const [initSettlementDate, setInitSettlementDate] = useState(setParam("initSettlementDate"));
    const [finalSettlementDate, setFinalSettlementDate] = useState(setParam("finalSettlementDate"));
    const [allowNoDueDateSymbols, setAllowNoDueDateSymbols] = useState(false);

    const [symbolsItems, setSymbolsItems] = useState([]);
    const [symbolsResult, setSymbolsResult] = useState([]);

    const [loading, setLoading] = useState(false);
    const [searched, setSearched] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [securityListError, setSecurityListError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [symbolsError, setSymbolsError] = useState(false);
    const [isQueryEmpty, setIsQueryEmpty] = useState(true);
    const [filtering, setFiltering] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    var commomMarkets = removeKeyObject(marketsEnum, ['VIS', 'FRA']);
    const form = React.useRef(null);

    const context = useContext(ThemeContext)

    useEffect(() => {
        if (queryObject) {
            (async function () {
                await fetchSecurityList();
                setLoading(false);
            })();
        }
    }, [queryObject]);

    const fetchSecurityList = async () => {
        const apiQueryString = objToQueryString({
            ...queryObject,
            symbols: queryObject?.symbols,
            // Verifica se foi selecionado algum mercado, caso não tenha sido,
            // todos os mercados são selecionados
            markets: queryObject?.markets == "" ? Object.keys(commomMarkets)
                                                : queryObject?.markets,
            initSettlementDate: dateQueryParse(queryObject?.initSettlementDate),
            finalSettlementDate: dateQueryParse(queryObject?.finalSettlementDate),
            allowNoDueDateSymbols: queryObject?.allowNoDueDateSymbols
        });
        if (isQueryEmpty) {
            setFiltering(true);
            return;
        }
        try {
            setLoading(true);
            setFiltering(false);
            const response = await fetch(
                `/api/marketData/securityList?${apiQueryString}`
            );

            if (!response.ok) {
                const error = await response.json();
                throw error;
            }

            const data = await response.json();
            
            // Verifica se os novos dados encontrados já existem no array de itens apresentados
            // caso não existam, são adicionados na lista.
            data.forEach((item: securityListItem) => {
                var exists = symbolsResult.findIndex(x => x.name == item.name);
                if (exists == -1) {
                    setSymbolsResult((oldArray) => [item, ...oldArray]);
                }
            });

            setLoading(false);
        } catch (err) {
            setSecurityListError(true);
            console.log(err);
        }
    };
    const handleVirtualMarket = async (event) => {
        event.preventDefault();

        setDisabled(true);
        setError(false);
        setSymbolsError(false);

        if (form.current.checkValidity() === false) {
            setDisabled(false);
            return event.stopPropagation();
        }

        if (symbolsItems.length === 0) {
            setDisabled(false);
            setSymbolsError(true);
            return event.stopPropagation();
        }

        try {
            const bodyRequest = {
                id: name,
                description,
                symbols: symbolsItems.map((item) => ({
                    symbol: item.name,
                    market: item.market
                }))
            };
            
            const options = {
                method: "POST",
                headers: API_HEADER,
                body: JSON.stringify(bodyRequest),
            };

            const response = await fetch(`/api/virtualMarket`, options);

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error?.message);
            }

            setShowSuccessModal(true);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
        } finally {
            setDisabled(false);
        }
    };

    const setQueryObjectFilters = () => {
        const queryStringFilters = {
            symbols: symbols,
            markets: markets,
            initSettlementDate: initSettlementDate,
            finalSettlementDate: finalSettlementDate,
            allowNoDueDateSymbols: allowNoDueDateSymbols.toString(),
        };
        const checkIsEmpty = () => {
            let propertyNames = Object.getOwnPropertyNames(queryStringFilters);
            setIsQueryEmpty(true);
            propertyNames.forEach((item) => {
                if (queryStringFilters[item] !== "" && item != "allowNoDueDateSymbols" ) {
                    setIsQueryEmpty(false);
                }
            });
            if(allowNoDueDateSymbols){
                setIsQueryEmpty(false);
            }
        };
        checkIsEmpty();

        setQueryObject(queryStringFilters);
        updateQuery(objToQueryString(queryStringFilters));
    };
    const handleSecurityList = (event) => {
        event.preventDefault();
        setSecurityListError(false);

        if (!searched) setSearched(true);

        setQueryObjectFilters();
    };

    function cleanFilters() {
        setMarkets("");
        setSymbols("");
        setInitSettlementDate("");
        setFinalSettlementDate("");
        setAllowNoDueDateSymbols(false);
    }

    const addSymbol = (item: securityListItem) => {
        var exists = symbolsItems.findIndex(x => x.name == item.name);
        setSymbolsResult(symbolsResult.filter((i) => i.name != item.name));
        if (exists == -1) {
            setSymbolsItems((oldArray) => [...oldArray, item]);
        }
    };

    const removeSymbol = (item: securityListItem) => {
        setSymbolsItems(symbolsItems.filter((i) => i.name != item.name));
        var exists = symbolsResult.findIndex(x => x.name == item.name);
        if (exists == -1) {        
            setSymbolsResult((oldArray) => [item, ...oldArray]);
        }
    };

    const removeAllSymbols = () => {
        for (var i = 0; i < symbolsItems.length; i++) {
            removeSymbol(symbolsItems[i]);
        }
        setSymbolsItems([]);
    };

    const addAllSymbols = () => {
        for (var i = 0; i < symbolsResult.length; i++) {
            addSymbol(symbolsResult[i]);
        }
        setSymbolsResult([]);
    };
    const clearResultSymbols = () => {
        setSymbolsResult([]);
    }   

    const clearAddedSymbols = () => {
        setSymbolsItems([]);
    }   
    const handleNoDueDateSymbols = (event) => {
        event.preventDefault()
        allowNoDueDateSymbols ? setAllowNoDueDateSymbols(false) : setAllowNoDueDateSymbols(true);
    }
    return (
        <>
            <SectionWrapper header="Nova Lista de Ativos">
                <div>
                    <form onSubmit={(e) => handleSecurityList(e)}>
                        <div>
                            <SectionWrapper
                                headerTag="h3"
                                header="Filtros de Busca"
                                isAccordion
                            >                                
                                <div className="int-row">
                                    <div className="int-col">
                                        <MultipleCheckboxFilter
                                            style={{margin: '0'}}
                                            label="Mercado"
                                            value={commomMarkets}
                                            parentQueryState={markets}
                                            setParentQueryState={(s) => setMarkets(s)}
                                        />
                                    </div>                                    

                                    <div className="int-col">
                                        <div className="int-col">
                                            <Input
                                                label="Ativos (Escreva múltiplos ativos separados por vírgula)"
                                                data-testid="virtual-market-symbols"
                                                name="symbols"
                                                type="text"
                                                value={symbols}
                                                onChange={(e) =>
                                                    setSymbols(
                                                        sanitizeName(e.target.value,true)
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="int-row">
                                            <label>Data de vencimento</label>
                                            <div className="int-col">
                                                <DateTime
                                                    label="De"
                                                    name="initSettlementDate"
                                                    value={initSettlementDate}
                                                    setDateTime={
                                                        setInitSettlementDate
                                                    }
                                                    timeDefault="00:00"
                                                    notShowTimeInput
                                                />
                                            </div>
                                            <div className="int-col">
                                                <DateTime
                                                    label="Para"
                                                    name="finalSettlementDate"
                                                    value={finalSettlementDate}
                                                    setDateTime={
                                                        setFinalSettlementDate
                                                    }
                                                    timeDefault="00:00"
                                                    notShowTimeInput
                                                />
                                            </div>
                                            <div className="int-col">
                                            <div 
                                                className="multiple-check-container" 
                                            >
                                                <div 
                                                className={allowNoDueDateSymbols ? 'active' : ''}
                                                onClick={(e) => handleNoDueDateSymbols(e)} 
                                                >
                                                { "Ativos sem vencimento"}
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="int-d-flex int-row int-mt-5 int-mb-6 int-mr-0 int-justify-content-end">
                                            <Button
                                                onClick={() => cleanFilters()}
                                                margin="int-mr-4"
                                                variant="outline"
                                            >
                                                Limpar Filtros
                                            </Button>
                                            <Button
                                                onClick={(e) =>
                                                    handleSecurityList(e)
                                                }
                                            >
                                                Buscar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </SectionWrapper>
                        </div>
                    </form>

                    {filtering && (
                        <Alert margin="int-mt-3">
                            Ao menos um campo de filtragem deve ser preenchido
                        </Alert>
                    )}
                </div>

                <form onSubmit={handleVirtualMarket} className="int-mt-5" ref={form}>
                    <Input
                        data-testid="create-virtual-market-name"
                        isRequired
                        label="Nome"
                        name="name"
                        placeholder="Nome da lista de ativos"
                        value={name}
                        onChange={(e) =>
                            setName(sanitizeName(e.target.value, true))
                        }
                    />
                    <Textarea
                        data-testid="create-virtual-market-description"
                        margin="int-mt-2"
                        label="Descrição"
                        name="description"
                        placeholder="Descrição da lista de ativos"
                        rows={5}
                        value={description}
                        onChange={(e) =>
                            setDescription(e.target.value.trimStart())
                        }
                    />
                    <div className="int-row int-mt-5 int-d-flex int-justify-content-start int-align-items-start">
                        <div className="int-col int-d-flex int-justify-content-center int-align-items-center">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <H4>Busca - {!loading && symbolsResult.length}</H4>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    className="int-col int-mt-3 int-d-flex int-align-items-center int-justify-content-start int-flex-column"
                                    style={{
                                        border:
                                            symbolsResult.length > 0
                                                ? "1px solid var(--int-colors-orange-400)"
                                                : "",
                                        maxHeight: "40vh",
                                        width: "500px",
                                        position:'relative',
                                        overflow: "auto",
                                        overflowX: "hidden"                               
                                    }}
                                >
                                        {loading && <Loader margin="int-m-5" flex />}

                                        {securityListError && (<Alert>Ocorreu um erro na busca</Alert>)}

                                        {symbolsResult.length > 0 && !loading && !securityListError? (
                                        symbolsResult?.map((row, index) => {
                                            return (
                                                <tr className="int-d-flex int-align-items-center" style={{ width: "100%"}}>

                                                    <td style={{ width: "100%", borderBottom: '1px solid var(--int-colors-black)'}}>
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            onClick={() =>
                                                                addSymbol(row)
                                                            }
                                                            style={{
                                                                color: "var(--int-colors-white)",
                                                                width: "100%",
                                                                background:"var(--int-colors-gray-700)",
                                                                padding: '12px'
                                                            }}
                                                        >
                                                            <p>
                                                                {row.name}
                                                            </p>
                                                        </button>
                                                    </td>
                                                    <td style={{ width: "80%", borderBottom: '1px solid var(--int-colors-black)'}}>
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            onClick={() =>
                                                                addSymbol(row)
                                                            }
                                                            style={{
                                                                color: "var(--int-colors-white)",
                                                                width: "100%",
                                                                background:"var(--int-colors-gray-700)",
                                                                padding: '12px'
                                                            }}
                                                        >
                                                            <p>
                                                                {marketsEnum[row.market] || row.market}
                                                            </p>
                                                        </button>
                                                    </td>
                                                    <td style={{ width: "100%", borderBottom: '1px solid var(--int-colors-black)'}}>
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            onClick={() =>
                                                                addSymbol(row)
                                                            }
                                                            style={{
                                                                color: "var(--int-colors-white)",
                                                                width: "100%",
                                                                background:"var(--int-colors-gray-700)",
                                                                padding: '12px'
                                                            }}
                                                        >
                                                            <p>
                                                                {formatDate(row.settlementDate)}
                                                            </p>
                                                        </button>
                                                    </td>                                                    
                                                </tr>                                            
                                            );
                                        })                                        
                                    ) : !loading && !securityListError && (
                                        <tr
                                            className="int-text-center int-d-flex"
                                            style={{
                                                color: "var(--int-colors-white)",
                                                width: "100%",
                                                background: `${context?.theme === "dark" ? "var(--int-colors-gray-700)" : "var(--int-colors-gray-300)"}`,
                                                margin: "1px",
                                                padding: "10px"
                                            }}
                                        >
                                            <td style={{ width: "100%" }}>
                                                <div>
                                                    <p>
                                                        Nenhum item encontrado.
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                                                         
                                </tbody>
                                <tfoot>
                                    <tr className="int-d-flex" style={{ width: "100%" }}>
                                        <td style={{ width: "100%" }}>
                                            <Button
                                                margin="int-mt-5"
                                                style={{ width: "100%" }}
                                                variant="outline"
                                                onClick={() =>
                                                    clearResultSymbols()
                                                }
                                            >
                                                Remover seleção
                                            </Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div
                            className="int-col int-d-inline-flex int-flex-column int-mt-9"
                            style={{ maxWidth: "250px" }}
                        >
                            <Button onClick={() => addAllSymbols()}>
                                <MdPlayArrow style={{width: '20px', height: '20px'}}/>
                                <MdPlayArrow style={{width: '20px', height: '20px'}}/>
                            </Button>
                            <Button
                                onClick={() => removeAllSymbols()}
                                margin="int-mt-5"
                                style={{transform: 'scaleX(-1)'}}                            
                            >
                                <MdPlayArrow style={{width: '20px', height: '20px'}}/>
                                <MdPlayArrow style={{width: '20px', height: '20px'}}/>
                            </Button>
                        </div>
                        <div className="int-col int-d-flex int-justify-content-center int-align-items-center">
                            
                            <table>

                                <thead>
                                    <tr>                                        
                                        <th><H4>Adicionados na Lista - {symbolsItems.length}</H4></th>
                                    </tr>
                                </thead>
                                <tbody
                                    className="int-col int-mt-3 int-d-flex int-align-items-start int-justify-content-start int-flex-column"
                                    style={{
                                        border:
                                            symbolsItems.length > 0
                                                ? "1px solid var(--int-colors-orange-400)"
                                                : "",
                                        maxHeight: "40vh",
                                        width: "500px",
                                        overflow: "auto",
                                        overflowX: "hidden",
                                    }}
                                >
                                    {symbolsItems.length > 0 ? (
                                        symbolsItems?.map((row, index) => {
                                            return (
                                                <tr className="int-d-flex int-align-items-start int-justify-content-start" style={{ width: "100%"}}>

                                                    <td style={{ width: "100%", borderBottom: '1px solid var(--int-colors-black)'}}>
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            onClick={() =>
                                                                removeSymbol(row)
                                                            }
                                                            style={{
                                                                color: "var(--int-colors-white)",
                                                                width: "100%",
                                                                background:"var(--int-colors-gray-700)",
                                                                padding: '12px'
                                                            }}
                                                        >
                                                            <p>
                                                                {row.name}
                                                            </p>
                                                        </button>
                                                    </td>
                                                    <td style={{ width: "70%", borderBottom: '1px solid var(--int-colors-black)'}}>
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            onClick={() =>
                                                                removeSymbol(row)
                                                            }
                                                            style={{
                                                                color: "var(--int-colors-white)",
                                                                width: "100%",
                                                                background:"var(--int-colors-gray-700)",
                                                                padding: '12px'
                                                            }}
                                                        >
                                                            <p>
                                                                {marketsEnum[row.market] || row.market}
                                                            </p>
                                                        </button>
                                                    </td>
                                                    <td style={{ width: "100%", borderBottom: '1px solid var(--int-colors-black)'}}>
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            onClick={() =>
                                                                removeSymbol(row)
                                                            }
                                                            style={{
                                                                color: "var(--int-colors-white)",
                                                                width: "100%",
                                                                background:"var(--int-colors-gray-700)",
                                                                padding: '12px'
                                                            }}
                                                        >
                                                            <p>
                                                                {formatDate(row.settlementDate)}
                                                            </p>
                                                        </button>
                                                    </td>
                                                </tr>                                            
                                            );
                                        })
                                    ) : (
                                        <tr
                                            className="int-text-center int-d-flex"
                                            style={{
                                                color: "var(--int-colors-white)",
                                                width: "100%",
                                                background: `${context?.theme === "dark" ? "var(--int-colors-gray-700)" : "var(--int-colors-gray-300)"}`,
                                                margin: "1px",
                                                padding: "10px",
                                            }}
                                        >
                                            <td style={{ width: "100%" }}>
                                                <div>
                                                    <p>
                                                        Nenhum item adicionado.
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr className="int-d-flex" style={{ width: "100%" }}>
                                        <td style={{ width: "100%" }}>
                                            <Button
                                                margin="int-mt-5"
                                                style={{ width: "100%" }}
                                                variant="outline"
                                                onClick={() =>
                                                    clearAddedSymbols()
                                                }
                                            >
                                                Remover seleção
                                            </Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    {symbolsError && (
                        <Alert margin="int-mt-5">
                            Adicione pelo menos um ativo para o novo mercado
                            virtual
                        </Alert>
                    )}

                    <Button
                        data-testid="create-virtual-market-submit"
                        isFullWidth
                        margin="int-mt-5"
                        disabled={disabled}
                        type="submit"
                    >
                        {disabled ? <Loader /> : "Cadastrar mercado virtual"}
                    </Button>

                    {error && <Alert margin="int-mt-5">{errorMessage}</Alert>}
                </form>

                {/* Modal - Mensagem cadastro com sucesso  */}
                <SimpleModal
                    header="Mercado Virtual cadastrado com sucesso!"
                    isOpen={showSuccessModal}
                    onClose={() => setShowSuccessModal(false)}
                    bodyMessage="O novo mercado virtual foi cadastrado com sucesso!"
                    footerButtons={[
                        {
                            label: "Novo Mercado Virtual",
                            onClick: () => history.go(0),
                            variant: "outline",
                        },
                        {
                            label: "Lista de Mercados Virtuais",
                            onClick: () => history.push("/virtual-market"),
                            margin: "int-ml-2",
                        },
                    ]}
                />
            </SectionWrapper>
        </>
    );
}

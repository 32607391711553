import React, { useEffect, useState } from "react"
import { BsArrowClockwise } from "react-icons/bs"
import { H3 } from '../../../components/Typography'

import { Loader } from '../../../components/Loader'
import { SectionWrapper } from '../../../components/SectionWrapper'
import { TableCell } from "../../../components/Table"

import { formatDecimal } from "../../../common/formatters"

type UsedLimitGroup = {
    execBuy: number | string;
    execSell: number | string;
    openBuy: number | string;
    openSell: number | string;
    uncoveredExecSell: number | string;
    UncoveredLeverageExecSell: number | string;
}

type LimitPosition = {
    balanceAmount: number | string;
    basicLimit: number | string;
    bovespaCustodyAmount: number | string;
    termCustodyAmount: number | string;
    fixedIncomeCustodyAmount: number | string;
    tesouroDiretoCustodyAmount: number | string;
    fundsCustodyAmount: number | string;
    clubsCustodyAmount: number | string;
    guaranteesExceptSharesAmount: number | string;
    extraLimit: number | string;
    bmfAdjustTotal: number | string;
    usedLimit: number | string;
    usedLimitBmf: number | string;
    usedLimitByVisFra: UsedLimitGroup;
    usedLimitByFut: UsedLimitGroup;
    usedLimitByOption: UsedLimitGroup;
    usedLimitByGold: UsedLimitGroup;
    bovespaIntradayProfit: number | string;
    coveredSellAmount: number | string;
    btcCustodyAmountTaker: number | string;
    btcCustodyAmountDonor: number | string;
    goldCustodyAmount: number | string;
    flexOpcCustodyAmount: number | string;
    goldDaytrade: number | string;
    depositedIntradayMarging: number | string;
    refundedIntradayMarging: number | string;
    tdSettlementSyncMismatchValue: number | string;
    finSettlementSyncMismatchValue: number | string;
    fundsSettlementSyncMismatchValue: number | string;
    clubsSettlementSyncMismatchValue: number | string;
    tradeOnlyWithBalance: boolean;
    leverageAdjust: number | string;
}

type ClientLimits = {
    limitPositionD1: LimitPosition;
}

export function LimitsByCategory({ account, id, allAccordionsOpen, updateAllGrids, autoOpenAccordion,clientLimit}) {
    const [limits, setLimits] = useState<ClientLimits>()
    const [isLoading, setIsLoading] = useState(true)

    async function fetchClientLimits() {
        setIsLoading(true)

        try {
            const response = await fetch(`/api/client/${account}/limits`)

            if (!response.ok) {
                throw new Error("Ocorreu um erro ao buscar dados de limites do cliente")
            }

            const data = await response.json()

            fillLimitsPatrimony(data)

        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const fillLimitsPatrimony = (data) => {
        const limits = {
            limitPositionD1: {
                balanceAmount: formatDecimal(data.limitPositionD1?.balanceAmount),
                bovespaCustodyAmount: formatDecimal(data.limitPositionD1?.bovespaCustodyAmount),
                termCustodyAmount: formatDecimal(data.limitPositionD1?.termCustodyAmount),
                fixedIncomeCustodyAmount: formatDecimal(data.limitPositionD1?.fixedIncomeCustodyAmount),
                tesouroDiretoCustodyAmount: formatDecimal(data.limitPositionD1?.tesouroDiretoCustodyAmount),
                fundsCustodyAmount: formatDecimal(data.limitPositionD1?.fundsCustodyAmount),
                clubsCustodyAmount: formatDecimal(data.limitPositionD1?.clubsCustodyAmount),
                guaranteesExceptSharesAmount: formatDecimal(data.limitPositionD1?.guaranteesExceptSharesAmount),
                bovespaIntradayProfit: formatDecimal(data.limitPositionD1?.bovespaIntradayProfit),
                coveredSellAmount: formatDecimal(data.limitPositionD1?.coveredSellAmount),
                btcCustodyAmountTaker: formatDecimal(data.limitPositionD1?.btcCustodyAmountTaker),
                btcCustodyAmountDonor: formatDecimal(data.limitPositionD1?.btcCustodyAmountDonor),
                goldCustodyAmount: formatDecimal(data.limitPositionD1?.goldCustodyAmount),
                goldDaytrade: formatDecimal(data.limitPositionD1?.goldDaytrade),
                flexOpcCustodyAmount: formatDecimal(data.limitPositionD1?.flexOpcCustodyAmount),
                depositedIntradayMarging: formatDecimal(data.patrimony.depositedIntradayMarging),
                refundedIntradayMarging: formatDecimal(data.patrimony.refundedIntradayMarging),
                tdSettlementSyncMismatchValue: formatDecimal(data.limitPositionD1?.tdSettlementSyncMismatchValue),
                finSettlementSyncMismatchValue: formatDecimal(data.limitPositionD1?.finSettlementSyncMismatchValue),
                fundsSettlementSyncMismatchValue: formatDecimal(data.limitPositionD1?.fundsSettlementSyncMismatchValue),
                clubsSettlementSyncMismatchValue: formatDecimal(data.limiPositionD1?.clubsSettlementSyncMismatchValue),
                usedLimit: formatDecimal(data.limitPositionD1?.usedLimit),
                usedLimitBmf: formatDecimal(data.limitPositionD1?.usedLimitBmf),
                extraLimit: formatDecimal(data.limitPositionD1?.extraLimit),
                basicLimit: formatDecimal(data.limitPositionD1?.basicLimit),
                bmfAdjustTotal: formatDecimal(data.limitPositionD1?.bmfAdjustTotal),
                tradeOnlyWithBalance: data.limitPositionD1?.tradeOnlyWithBalance,
                leverageAdjust: formatDecimal(data.limitPositionD1?.leverageAdjust),
                usedLimitByVisFra: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByVisFra.uncoveredLeverageExecSell),
                },
                usedLimitByFut: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByFut.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByFut.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByFut.uncoveredLeverageExecSell),
                },
                usedLimitByOption: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByOption.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByOption.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByOption.uncoveredLeverageExecSell),
                },
                usedLimitByGold: {
                    execBuy: formatDecimal(data.limitPositionD1?.usedLimitByGold.execBuy),
                    execSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.execSell),
                    openBuy: formatDecimal(data.limitPositionD1?.usedLimitByGold.openBuy),
                    openSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.openSell),
                    uncoveredExecSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.uncoveredExecSell),
                    UncoveredLeverageExecSell: formatDecimal(data.limitPositionD1?.usedLimitByGold.uncoveredLeverageExecSell)
                }
            },

        }
        setLimits(limits)
        setIsLoading(false)
    }

    useEffect(() => {
        if (updateAllGrids) {
            fetchClientLimits()
        }
    }, [updateAllGrids]);

    useEffect(() => {
        if(clientLimit) {
            fillLimitsPatrimony(clientLimit)
        }
    }, [clientLimit])

    return (
        <SectionWrapper
            id={id + (isLoading ? '-loading' : '')}
            headerTag="h3"
            header="Limite em Uso por Categoria"
            isAccordion
            allAcordionsOpen={allAccordionsOpen}
            autoOpenAccordion={autoOpenAccordion}
        >
            <div className="int-d-flex int-justify-content-end">
                <button
                    className="int-btn-icon int-btn-icon-outline"
                    disabled={isLoading}
                    onClick={fetchClientLimits}
                >
                    <BsArrowClockwise size={20} />
                </button>
            </div>

            {isLoading && <Loader flex />}

            {isLoading ? '' : (
                    <table className="int-table int-mt-3">
                        <thead>
                            <tr>
                                <TableCell style={{ width: '50%' }} component='th' value={'Descrição'} />
                                <TableCell style={{ width: '50%' }} component='th' value={'Valor'} textAlign='right' />
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-secondary">
                                <TableCell colSpan={2} className="int-table-divider" value={'Vista'} />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Executada'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByVisFra.execBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByVisFra.openBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByVisFra.openSell} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Descoberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByVisFra.uncoveredExecSell} textAlign='right' />
                            </tr>
                            <tr className="bg-secondary">
                                <TableCell colSpan={2} className="int-table-divider" value={'BMF'} />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Executada'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByFut.execBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByFut.openBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Executada'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByFut.execSell} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByFut.openSell} textAlign='right' />
                            </tr>
                            <tr className="bg-secondary">
                                <TableCell colSpan={2} className="int-table-divider" value={'Ouro'} />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Executada'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByGold.execBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByGold.openBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Executada'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByGold.execSell} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByGold.openSell} textAlign='right' />
                            </tr>
                            <tr className="bg-secondary">
                                <TableCell colSpan={2} className="int-table-divider" value={'Opções'} />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Executada'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByOption.execBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Compra Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByOption.openBuy} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Aberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByOption.openSell} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Venda Descoberta'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitByOption.uncoveredExecSell} textAlign='right' />
                            </tr>
                            <tr className="bg-secondary">
                                <TableCell colSpan={2} className="int-table-divider" value={'Total'} />
                            </tr>
                            <tr>
                                <TableCell value={'Limite em Uso Bovespa'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimit} textAlign='right' />
                            </tr>
                            <tr>
                                <TableCell value={'Limite em Uso BMF'} />
                                <TableCell value={limits?.limitPositionD1?.usedLimitBmf} textAlign='right' />
                            </tr>
                        </tbody>
                    </table>
            )}
        </SectionWrapper>
    );
}
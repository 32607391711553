import { useLocation, useHistory } from "react-router-dom";

export function useQueryParams() {
    const { search, pathname } = useLocation();
    const history = useHistory();

    const params = new URLSearchParams(search);

    function setParam(param: string, defValue = "") {
        return params.get(param) ? params.get(param) : defValue;
    }

    function updateQuery(queryParams: string) {
        history.push(`${pathname}?${queryParams}`);
    }

    return {
        params,
        setParam,
        updateQuery,
    };
}

import React, { useEffect, useState } from "react";

import { Link } from "../../../components/Link";
import { SmartTable } from '../../../components/Table'
import { SectionWrapper } from "../../../components/SectionWrapper";

import { NumberRangeColumnFilter, SelectColumnFilter } from '../../../components/Table/Filters'

export function PoliciesList() {
    const [data, setData] = useState([]);

    /** Lista políticas API */
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('/api/policy')

                if(!response.ok) {
                    const error = await response.json()
                    throw new Error(error)
                }

                const data = await response.json()

                const policies = data.filter(item => item.isInternal === false).map(item => ({
                    ...item,
                    policyType: item.policyType === 'Client' ? 'Cliente' : 'Operador',
                    shared: item.shared ? 'Compartilhada' : 'Privada'
                }))

                setData(policies)
            } catch(err) {
                console.log(err)
            }
        })()
    }, []);

    const columns = [
        {
            Header: 'Id',
            accessor: 'id',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: 'Nome',
            accessor: 'name',
            Cell: ({ row, value }) => <Link href={`/policies/${row.values.id}`}>{value}</Link>
        },
        {
            Header: 'Política Pai',
            accessor: 'parentName',
            Cell: ({ row, value }) => row.original.parentID <= 0 ? "-" : <Link href={`/policies/${row.original.parentID}`}>{value}</Link>
        },
        {
            Header: 'Tipo',
            accessor: 'policyType',
            Filter: SelectColumnFilter,
            filter: 'includes'
        },
        {
            Header: 'Compartilhamento',
            accessor: 'shared',
            Filter: SelectColumnFilter,
            filter: 'includes'
        }
    ]

  return (
    <SectionWrapper header="Lista de Políticas">
        {data && (
            <SmartTable 
                globalFilter 
                columnFilters 
                data={data} 
                columns={columns}
                localStorageKey={'PolicyList'}
            />  
        )}
    </SectionWrapper>
  );
}
